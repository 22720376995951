import clsx from 'clsx';
import type { SearchResultListItemProps } from 'components/MainSearch/lib/types';
import React from 'react';

import styles from './styles.module.css';

export const SearchResultListItem = ({ tag = 'li', className, children, onClick }: SearchResultListItemProps) => {
	const Tag = tag;

	return (
		<Tag className={clsx(styles.item, className)} onClick={onClick}>
			{children}
		</Tag>
	);
};
