import type { PinningModel } from 'components/Table/lib/table/types/table';
import { useEffect, useState } from 'react';
import { localStorageService } from 'services/localStorageService';

export type UsePinnedColumnsProps = {
	saveConfigKey: string;
	initialPinned?: PinningModel;
};

export const usePinnedColumns = ({ saveConfigKey, initialPinned }: UsePinnedColumnsProps) => {
	const key = 'pinning::' + saveConfigKey;

	const [pinningModel, setPinningModel] = useState<PinningModel>(hydrateState(key, initialPinned));

	useEffect(() => {
		localStorageService.save(key, pinningModel);
	}, [pinningModel]);

	return { pinningModel, setPinningModel };
};

function hydrateState(key: UsePinnedColumnsProps['saveConfigKey'], initialPinned: UsePinnedColumnsProps['initialPinned']) {
	return function hydrate(): PinningModel {
		const pinningModel = localStorageService.load<PinningModel>(key);

		if (pinningModel) {
			return pinningModel;
		}
		const fallbackModel = initialPinned;

		return fallbackModel;
	};
}
