import clsx from 'clsx';
import React, { forwardRef } from 'react';

import Cell from '../BasicElements/Cell';
import type { CellProps } from '../BasicElements/Cell/types';
import styles from './styles.module.css';

type HeaderCellProps = Omit<CellProps, 'as'> & { hidden?: boolean; accessor?: string };
type RegularCellProps = Omit<CellProps, 'as'>;

export const HeaderCell = ({ children, className, hidden = false, accessor, ...restProps }: HeaderCellProps) => {
	const as = 'span';

	return (
		<Cell data-id as={as} data-hidden={hidden} data-accessor-id={accessor} className={clsx(styles.spacingHeaderCell, className)} {...restProps}>
			{children}
		</Cell>
	);
};

export const RegularCell = forwardRef<HTMLTableCellElement, RegularCellProps>(({ children, className, onClick, ...restProps }, ref) => {
	const as = 'span';

	return (
		<Cell ref={ref} as={as} className={clsx(styles.spacingRegularCell, className)} onClick={onClick} {...restProps}>
			{children}
		</Cell>
	);
});

RegularCell.displayName = 'RegularCell';
