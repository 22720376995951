// import { ContractClientSchema } from 'models/client';
import { ContractIdSchema, OrganizationIdSchema } from 'models/common/uuid';
import { z } from 'zod';

export const ContractOrganizationSchema = z.object({
	id: OrganizationIdSchema,
	title: z.string().min(1),
});

export const ContractSchema = z.object({
	id: ContractIdSchema,
	title: z.string().min(1),
	// client: ContractClientSchema,
	organization: ContractOrganizationSchema.optional(),
	expiredAt: z.coerce.date(),
});

export type Contract = z.infer<typeof ContractSchema>;
