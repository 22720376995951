import 'static/styles/style.css';

import React from 'react';

import styles from './style.module.css';
import type { IProps } from './types';

const ClientComponent: React.FC<IProps> = ({ client, cursor = 'initial', onClick }) => {
	return (
		<div className={styles.clientWrapper} onClick={onClick} style={{ cursor }}>
			<p className="--primary-700 text-sm-medium" style={{ color: 'var(--primary-700)' }}>
				{client}
			</p>
		</div>
	);
};

export default ClientComponent;
