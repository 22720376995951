import clsx from 'clsx';
import React from 'react';

import styles from './style.module.css';
import type { IProps } from './types';

const TextWrapper: React.FC<IProps> = ({
	value,
	className,
	padding,
	textAlign,
	children,
	color,
	fontWeight,
	minWidth,
	justify = 'center',
	cursor = 'initial',
	width = 'auto',
	onClick,
}) => {
	return (
		<span
			className={clsx(styles.textWrapper, className)}
			onClick={onClick}
			style={
				{
					padding: `0 ${padding}`,
					textAlign: textAlign,
					color: color,
					fontWeight: fontWeight,
					maxWidth: minWidth,
					cursor,
					'--text-wrapper-justify': justify,
					'--text-wrapper-width': width,
				} as React.CSSProperties
			}
		>
			{value} {children}
		</span>
	);
};

export default TextWrapper;
