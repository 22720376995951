import clsx from 'clsx';
import BreadCrumbs from 'components/BreadCrumbs';
import { Breadcrumb } from 'components/BreadCrumbs/types';
import Button from 'components/Button';
import ClientsTopBar from 'components/ClientsTopBar';
import ColumnsSettingsMenu from 'components/ColumnsSettingsMenu';
import FilterWrapper from 'components/FilterWrapper';
import Select from 'components/Select';
import { SelectOption } from 'components/Select/types';
import { clientsFilterGroups } from 'components/SharedFilterDrawer/utils';
import Spacer from 'components/Spacer';
import Spinner from 'components/Spinner';
import Table from 'components/Table';
import SegmentDropDownFilter from 'components/Table/Filters/SegmentDropDownFilter';
import SettingButton from 'components/Table/Filters/SettingsButton';
import Pagination from 'components/Table/Pagination';
import EmptyTable from 'components/Table/TableComponents/EmptyTable';
import MobileHeader from 'components/Table/TableComponents/MobileHeader';
import { breakPoints, CLIENTS_FILTER_QUERY_KEYS, ROUTES_URLS } from 'const';
import { useAdjustableColumns } from 'hooks/useAdustableColumns';
import { useBoolean } from 'hooks/useBoolean';
import { usePinnedColumns } from 'hooks/usePinnedColumns';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { ClientPreview } from 'models/client';
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetClientsFiltersDataQuery, useGetClientsQuery } from 'store/reducers/clients/clientsSliceApi';
import { getClientsFilterDefaultSearchParamsValues, prepareUrl } from 'utils/shared';
import { isString } from 'utils/type-guards';

import { useClientsColumns } from './columns';
import { clientsIcons } from './icons';
import styles from './style.module.css';
import type { IProps } from './types';

const FilterModal = lazy(() => import('components/FilterModal'));
const ClientsMobileTable = lazy(() => import('components/Table/MobileViews/ClientsMobileTable'));
const SharedFilterDrawer = lazy(() => import('components/SharedFilterDrawer'));

const clientPerPageOptions = ['12', '24', '48'];

const Clients: React.FC<IProps> = ({ breadcrumbs, onRowClick, searchOnClick, orderPageView, selectable = true }) => {
	const navigate = useNavigate();
	const [contractTypeFilter, setContractTypeFilter] = useState<SelectOption>({ label: 'Всі', value: '' });
	const [searchParams, setSearchParams] = useSearchParams();
	const [clientPerPage, setClientPerPage] = useState(searchParams.get('perPage') || clientPerPageOptions[0]);
	const { data: { segments, responsible, contracts, clients } = {} } = useGetClientsFiltersDataQuery(undefined);
	const { data: { data: clientsList, page, pagesCount, total } = {}, isFetching } = useGetClientsQuery(searchParams.toString());

	const filterDropdown = useBoolean();
	const filterDrawer = useBoolean();
	const visibilityInterface = useBoolean();
	const filterDropdownToggle = useStopPropagationCallback(filterDropdown.toggle);
	const filterDrawerToggle = useStopPropagationCallback(filterDrawer.toggle);
	const visibilityInterfaceToggle = useStopPropagationCallback(visibilityInterface.toggle);

	const contractFiltersWithAll = contracts === undefined ? ['Всі'] : ['Всі', ...contracts];
	const crumbs: Breadcrumb[] = breadcrumbs ?? [{ label: 'Клієнти', href: prepareUrl(ROUTES_URLS.CLIENTS) }];
	const handleClientClick = (client: ClientPreview) => {
		if (onRowClick) {
			return onRowClick(client);
		}

		const url = prepareUrl(ROUTES_URLS.CLIENT_PAGE, { id: client.id });
		navigate(url);
	};

	const handleNavigateToCreateClient = () => {
		const url = prepareUrl(ROUTES_URLS.CLIENT_CREATE);

		navigate(url);
	};

	const handleRowClick = (row: unknown) => {
		onRowClick?.(row);
	};

	const handleContractSelect = (newOption: string | SelectOption) => {
		if (isString(newOption)) {
			setContractTypeFilter({ label: newOption, value: newOption });
		} else {
			setContractTypeFilter(newOption);
		}
	};

	const columns = useClientsColumns(handleClientClick);
	const { visibilityModel, setVisibilityModel } = useAdjustableColumns(columns, { saveConfigKey: '/clients' });
	const { pinningModel, setPinningModel } = usePinnedColumns({ saveConfigKey: '/clients' });

	const hasClients = clientsList?.length > 0;

	const dataListMap = {
		client: clients,
		manager: responsible,
		segment: segments,
	};

	const getFilterTypeData = () => {
		const filterTypeData = {
			filterQueryKeys: CLIENTS_FILTER_QUERY_KEYS,
			defaultSearchParams: getClientsFilterDefaultSearchParamsValues,
			filterGroups: clientsFilterGroups,
		};

		return filterTypeData;
	};

	useEffect(() => {
		const newSearchParams = new URLSearchParams(searchParams);
		const setContractSearchParams = () => {
			newSearchParams.set('contract', contractTypeFilter.value);
			setSearchParams(newSearchParams);
		};
		const unSetContractSearchParams = () => {
			newSearchParams.delete('contract');
			setSearchParams(newSearchParams);
		};
		if (contractTypeFilter && contractTypeFilter.label !== 'Всі') {
			setContractSearchParams();
		} else {
			unSetContractSearchParams();
		}
	}, [contractTypeFilter]);

	useEffect(() => {
		const newSearchParams = new URLSearchParams(searchParams);
		const perPage = newSearchParams.get('perPage');
		const isPerPageParameterValid = !perPage || clientPerPageOptions.includes(perPage);

		if (!isPerPageParameterValid) {
			newSearchParams.set('perPage', clientPerPageOptions[0]);
			setSearchParams(newSearchParams);
		}
	}, []);

	useEffect(() => {
		const newSearchParams = new URLSearchParams(searchParams);

		if (newSearchParams.get('perPage') === clientPerPage) return;

		newSearchParams.set('perPage', clientPerPage);
		setSearchParams(newSearchParams);
	}, [clientPerPage]);

	const RenderMobileTableRow = useCallback(({ row }) => <ClientsMobileTable columnId="name" row={row} onSelect={handleRowClick} />, []);
	const RenderMobileTableHeader = useCallback(
		({ headerGroups }) => <MobileHeader justify="space-between" px="10px" headerGroups={headerGroups} renderHeaders={['chooseClient', 'sort']} />,
		[],
	);

	return (
		<div className="main-wrapper">
			<div className={clsx('container', styles.container)}>
				<BreadCrumbs crumbs={crumbs} backLink="/" onClickFilter={filterDropdownToggle} />

				<MediaQuery minWidth={breakPoints.MOBILE}>
					<ClientsTopBar
						searchOnClick={searchOnClick}
						onCreateClientButtonClick={handleNavigateToCreateClient}
						onFiltersButtonClick={filterDrawerToggle}
					/>

					<Spacer height="31px" />

					<FilterWrapper className={styles.filtersWrapper}>
						<div className={styles.filtersGroup}>
							<SegmentDropDownFilter multiple listOfFilters={segments} />

							<Select
								valuesList={contractFiltersWithAll}
								value={contractTypeFilter}
								setValue={handleContractSelect}
								className={styles.contractSelect}
								placeholder="Виберіть контракт"
							/>

							<MediaQuery minWidth={breakPoints.MOBILE}>
								<Select
									valuesList={clientPerPageOptions}
									value={clientPerPage}
									setValue={(value) => setClientPerPage(value as string)}
									label="Показувати по:"
								/>
							</MediaQuery>

							<SettingButton onClick={visibilityInterfaceToggle}>
								<ColumnsSettingsMenu
									open={visibilityInterface.isOn}
									onClose={visibilityInterfaceToggle}
									columns={columns}
									visibilityModel={visibilityModel}
									setColumns={setVisibilityModel}
								/>
							</SettingButton>
						</div>
					</FilterWrapper>
				</MediaQuery>

				{!hasClients && <EmptyTable text="Жодного клієнта не знайдено" />}

				<Suspense>
					{hasClients && (
						<div className={styles.tableContainer}>
							<Table
								{...(!!orderPageView && { pageType: 'order' })}
								allData={clientsList}
								selectable={selectable}
								visibilityModel={visibilityModel}
								pinningModel={pinningModel}
								onPinningModelChange={setPinningModel}
								columns={columns}
								mobileViewComponent={RenderMobileTableRow}
								mobileHeader={RenderMobileTableHeader}
							/>

							{pagesCount > 1 && <Pagination pagesCount={pagesCount} page={page} />}
						</div>
					)}
				</Suspense>

				<Suspense fallback={<Spinner />}>
					{filterDropdown.isOn && <FilterModal open={filterDropdown.isOn} onClose={filterDropdownToggle} />}
				</Suspense>
				<Suspense fallback={<Spinner />}>
					{filterDrawer.isOn && (
						<SharedFilterDrawer
							searchResultsCount={total}
							openDrawer={filterDrawer.isOn}
							onClose={filterDrawerToggle}
							dataList={dataListMap}
							getFilterTypeData={getFilterTypeData}
							type={'clients'}
						/>
					)}
				</Suspense>
			</div>

			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<div className="safe-area-bottom" />
				<div className={styles.bottomPanel}>
					<Button variant="default" text="Фільтрувати" icon={clientsIcons.Funnel} className={styles.filterBtn} />
					<Button
						variant="default"
						text="Створити клієнта"
						icon={clientsIcons.Plus}
						className={styles.addClientBtn}
						onClick={handleNavigateToCreateClient}
					/>
				</div>
			</MediaQuery>

			{isFetching && <Spinner />}
		</div>
	);
};

export default Clients;
