import { formatNumberToUAH, kVolumeFormatter, kWeightFormatter } from 'utils/shared';

import { useTypedOrderControllerFromContext } from '../OrderController';
import { calculateTotals, calculateTotalServicesCost } from '../OrderController/lib/utils';

interface UseOrderStatsProps {
	suborderIndex: number;
}

export const useOrderStats = ({ suborderIndex }: UseOrderStatsProps) => {
	const { watch } = useTypedOrderControllerFromContext();

	const productsRecord = watch(`suborders.${suborderIndex}.data.products`);
	const servicesRecord = watch(`suborders.${suborderIndex}.data.services`);

	const products = Object.values(productsRecord ?? {});
	const services = Object.values(servicesRecord ?? {});

	const totals = calculateTotals(products);
	const totalServicesCost = calculateTotalServicesCost(services);

	const formattedWeight = `Вага - ${kWeightFormatter(totals.weight)}`;
	const formattedVolume = `Об’єм - ${kVolumeFormatter(totals.volume)}`;
	const formattedPrice = formatNumberToUAH(totals.sum + totalServicesCost);

	return {
		formattedWeight,
		formattedPrice,
		formattedVolume,
	};
};
