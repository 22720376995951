import React from 'react';
import { Provider } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { store } from 'store';

const ProviderStore: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<Provider store={store}>
			{children}
			<Outlet />
		</Provider>
	);
};

export default ProviderStore;
