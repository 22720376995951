import 'static/styles/style.css';

import clsx from 'clsx';
import React from 'react';

import styles from './style.module.css';
import { IProps } from './types';

const Payable: React.FC<IProps> = ({ price, className }) => {
	return (
		<div className={clsx(styles.wrapper, className)}>
			<p className="text-lg-medium " style={{ color: 'var(--gray-500)' }}>
				До сплати: <span className={styles.price}>{price}</span>
			</p>
		</div>
	);
};

export default Payable;
