import { z } from 'zod';

export const UuidSchema = z.string();

export const ProductIdSchema = UuidSchema;
export const StockIdSchema = UuidSchema;
export const ServiceIdSchema = UuidSchema;
export const OrderIdSchema = UuidSchema;
export const ClientIdSchema = UuidSchema;
export const ContractIdSchema = UuidSchema;
export const ResponsibleIdSchema = UuidSchema;
export const OrganizationIdSchema = UuidSchema;
export const ProductUnitIdSchema = UuidSchema;
export const ProductBrandIdSchema = UuidSchema;
export const ProductLeftoverIdSchema = UuidSchema;
export const ProductFreeLeftoverIdSchema = UuidSchema;
export const ProductReserveIdSchema = UuidSchema;
export const ServiceGroupIdSchema = UuidSchema;
export const ServiceBrandIdSchema = UuidSchema;
export const ServiceCategoryIdSchema = UuidSchema;
export const TransactionEntityIdSchema = UuidSchema;
export const TypePriceIdSchema = UuidSchema;
export const SegmentIdSchema = UuidSchema;
export const FileIdSchema = UuidSchema;
export const BusinessOfferIdSchema = UuidSchema;
export const ColorantIdSchema = UuidSchema;
export const ClientManagerIdSchema = z.number();
export const EmployeeIdSchema = z.number();
export const EmployeeOrganizationIdSchema = z.number().or(z.string());

export type Uuid = { id: z.infer<typeof UuidSchema> };
export type ProductId = z.infer<typeof ProductIdSchema>;
export type StockId = z.infer<typeof StockIdSchema>;
export type ServiceId = z.infer<typeof ServiceIdSchema>;
export type OrderId = z.infer<typeof OrderIdSchema>;
export type ClientId = z.infer<typeof ClientIdSchema>;
export type ContractId = z.infer<typeof ContractIdSchema>;
export type ResponsibleId = z.infer<typeof ResponsibleIdSchema>;
export type OrganizationId = z.infer<typeof OrganizationIdSchema>;
export type TypePriceId = z.infer<typeof TypePriceIdSchema>;
export type EmployeeId = z.infer<typeof EmployeeIdSchema>;
export type EmployeeOrganizationId = z.infer<typeof EmployeeOrganizationIdSchema>;
export type ProductUnitId = z.infer<typeof ProductUnitIdSchema>;
export type ProductBrandId = z.infer<typeof ProductBrandIdSchema>;
export type ProductLeftoverId = z.infer<typeof ProductLeftoverIdSchema>;
export type ProductFreeLeftoverId = z.infer<typeof ProductFreeLeftoverIdSchema>;
export type ProductReserveId = z.infer<typeof ProductReserveIdSchema>;
export type ServiceGroupId = z.infer<typeof ServiceGroupIdSchema>;
export type ServiceBrandId = z.infer<typeof ServiceBrandIdSchema>;
export type ServiceCategoryId = z.infer<typeof ServiceCategoryIdSchema>;
export type TransactionEntityId = z.infer<typeof TransactionEntityIdSchema>;
export type SegmentId = z.infer<typeof SegmentIdSchema>;
export type ClientManagerId = z.infer<typeof ClientManagerIdSchema>;
export type FileId = z.infer<typeof FileIdSchema>;
export type BusinessOfferId = z.infer<typeof BusinessOfferIdSchema>;
export type ColorantId = z.infer<typeof ColorantIdSchema>;
