import React, { createContext, Fragment, ReactNode, useContext, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyType = any;

export type StackedModal = ReactNode;
export type Config<TKey> = { key: TKey };
export type StackedModalObject<TKeys> = Config<TKeys> & {
	modal: StackedModal;
};

export type IModalContext<TKeys> = {
	open: (modal: StackedModal, config: Config<TKeys>) => void;
	close: (key: TKeys) => void;
	closeAll(): void;
	getIsOpenedStatus: (key: TKeys) => boolean;
	stack: StackedModalObject<TKeys>[];
};
export type ModalProviderProps = {
	children: ReactNode;
};

export const ModalContext = createContext<IModalContext<AnyType>>(null);
export const useModalContext = () => useContext(ModalContext);

const ModalContextProvider = ModalContext.Provider;

const ModalStackProvider = <TKeys extends string>({ children }: ModalProviderProps) => {
	const [stack, setStack] = useState<StackedModalObject<TKeys>[]>([]);

	const value = useMemo<IModalContext<TKeys>>(() => {
		const append = (modal: StackedModal, config: Config<TKeys>) => {
			setStack((prev) => [...prev, { modal, key: config.key }]);
		};
		const close = (key: TKeys) => {
			setStack((prev) => prev.filter((modalObj) => modalObj.key !== key));
		};
		const closeAll = () => {
			setStack([]);
		};
		const getIsOpenedStatus = (key: TKeys) => stack.some((modalObj) => modalObj.key === key);

		return {
			open: append,
			close,
			closeAll,
			getIsOpenedStatus,
			stack,
		};
	}, [stack]);

	return (
		<ModalContextProvider value={value}>
			{children}

			<ModalsStack />
		</ModalContextProvider>
	);
};

export default ModalStackProvider;

function ModalsStack() {
	const { stack } = useModalContext();
	const stackedElements = stack.map(({ modal, key }) => <Fragment key={key}>{modal}</Fragment>);

	return createPortal(<>{stackedElements}</>, document.querySelector('#modal-root'));
}
