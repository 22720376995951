import clsx from 'clsx';
import React from 'react';

import styles from './style.module.css';
import { IProps } from './types';

const FilterWrapper: React.FC<IProps> = ({ children, className }) => {
	return <div className={clsx(styles.filtersWrapper, className)}>{children}</div>;
};

export default FilterWrapper;
