import Button from 'components/Button';
import type { IProps as ButtonProps } from 'components/Button/types';
import React from 'react';

import styles from './styles.module.css';

const ActionButton: React.FC<Partial<ButtonProps>> = ({ icon, text, onClick, hoverBg, ...restProps }) => {
	return (
		<Button
			data-id="bottom-panel-button"
			type="submit"
			onClick={onClick}
			text={text}
			icon={icon}
			variant="rounded"
			background="#d71b87"
			hoverBg={hoverBg ?? '#000'}
			className={styles.actionButton}
			{...restProps}
		/>
	);
};

export default ActionButton;
