import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import clsx from 'clsx';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
// @ts-ignore the package not typesScripted though @types are installed
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { countWhiteSpaces } from 'utils/text';

import { config } from './config';
import styles from './styles.module.css';

type ToolbarConfig = EditorProps['toolbar'] & typeof config.toolbar;

interface IProps extends Omit<EditorProps, 'onChange'> {
	value?: string;
	maxChars?: number;
	disabled?: boolean;
	hideToolbar?: boolean;
	onChange?(data: { plainText: string; html: string }): void;
	toolbarConfig?: (currentConfig: ToolbarConfig) => Partial<ToolbarConfig>;
}

const getInitialState = (content: string) => {
	return () => {
		if (content) {
			const convertedToHTML = decodeURIComponent(content);
			const blocksFromHtml = htmlToDraft(convertedToHTML);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
			return EditorState.createWithContent(contentState);
		}
		return EditorState.createEmpty();
	};
};

const RichTextEditor = (
	{ value = '', onChange, maxChars = 0, readOnly = false, disabled = false, hideToolbar = false, ...props }: IProps,
	ref: ForwardedRef<object>,
) => {
	const [editorState, setEditorState] = useState(getInitialState(value));

	const onEditorStateChange = function (rawEditorState: EditorState) {
		const plainText = rawEditorState.getCurrentContent().getPlainText('\u0001');
		const html = draftToHtml(convertToRaw(rawEditorState.getCurrentContent()));

		setEditorState(rawEditorState);
		onChange({ plainText, html });
	};

	const handleBeforeInput = () => {
		if (maxChars) {
			const isMaxCharsReached = editorState.getCurrentContent().getPlainText('\u0001').replace(/\s/g, '').length >= maxChars;

			if (isMaxCharsReached) return 'handled';
		}
	};

	const listenToLinkClick = useCallback(() => {
		const links = Array.from(document.querySelectorAll('.rdw-link-decorator-wrapper > a')) as HTMLAnchorElement[];

		links?.forEach((link) => {
			const { target, href } = link;

			link.onclick = () => {
				window.open(href, target);
			};
		});
	}, []);

	useEffect(() => {
		listenToLinkClick();
	}, [editorState, listenToLinkClick]);

	const handlePastedText = (text: string) => {
		const offset = 11; // ! do not know why but it is needed
		const currentContentState = editorState.getCurrentContent();
		const currentText = currentContentState.getPlainText('\u0001');
		const currentTextWithoutSpaces = currentText.replace(/\s/g, '');
		const charsLeft = maxChars - currentTextWithoutSpaces.length;
		const pastedTextSlice = text.slice(0, charsLeft);
		const spacesCountInSlice = countWhiteSpaces(pastedTextSlice);
		const sliceEndIndex = charsLeft + spacesCountInSlice + offset;
		const textToBePaste = text.slice(0, sliceEndIndex);

		const newText = currentText + textToBePaste;
		const newContentState = ContentState.createFromText(newText);
		const newEditorState = EditorState.createWithContent(newContentState);

		const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));

		setEditorState(newEditorState);
		onChange({ plainText: newText, html });

		return true;
	};

	const toolbarConfig = props?.toolbarConfig?.(config.toolbar) ?? config.toolbar;

	return (
		<Editor
			readOnly={readOnly || disabled}
			ref={ref as EditorProps['editorRef']}
			editorState={editorState}
			toolbarHidden={hideToolbar}
			toolbarClassName={clsx(styles.toolbar, { [styles.disabled]: disabled || readOnly })}
			editorClassName={clsx(styles.editor, { disabled: disabled || readOnly })}
			onEditorStateChange={onEditorStateChange}
			handleBeforeInput={handleBeforeInput}
			handlePastedText={handlePastedText}
			toolbar={toolbarConfig}
			localization={config.localization}
			{...props}
		/>
	);
};

export default forwardRef(RichTextEditor);
