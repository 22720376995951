import { ServerSideOptionSchema } from 'models/common/options';
import { z } from 'zod';

export const ByLimitMaxClientFilterSchema = z.number();
export const ByDaysOfDelayMaxClientFilterSchema = z.number();
export const ByClientsClientFilterSchema = z.object({ id: z.string(), name: z.string() });
export const ByManagerClientFilterSchema = ServerSideOptionSchema;
export const BySegmentClientFilterSchema = ServerSideOptionSchema;
export const ByContractClientFilterSchema = z.string();

export const ClientFiltersSchema = z.object({
	limitMax: ByLimitMaxClientFilterSchema,
	daysOfDelayMax: ByDaysOfDelayMaxClientFilterSchema,
	clients: ByClientsClientFilterSchema.array(),
	responsible: ByManagerClientFilterSchema.array().optional(),
	segments: BySegmentClientFilterSchema.array(),
	contracts: ByContractClientFilterSchema.array(),
	stocks: ServerSideOptionSchema.array().optional(),
});

export type ClientFilters = z.infer<typeof ClientFiltersSchema>;
