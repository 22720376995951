import clsx from 'clsx';
import React from 'react';

import styles from './style.module.css';
import { IProps } from './types';

const DateAndTimeComponent: React.FC<IProps> = ({ className, dateColor, timeColor, time, date, minWidth }) => {
	return (
		<span
			className={clsx(styles.timeAndDateWrapper, className)}
			style={{ minWidth: minWidth, '--date-color': dateColor, '--time-color': timeColor } as React.CSSProperties}
		>
			<span className={styles.date}>{date}</span>
			<span className={styles.time}>{time}</span>
		</span>
	);
};

export default DateAndTimeComponent;
