import clsx from 'clsx';
import React from 'react';
import { ReactComponent as PinIcon } from 'static/images/pin.svg';
import { ReactComponent as UnpinIcon } from 'static/images/unpin.svg';
import { ReactComponent as UnpinAllIcon } from 'static/images/unpin-all.svg';

import styles from './styles.module.css';
import type { PinColumnMenuProps } from './types';

const PinColumnMenu = <TData,>({ headerInstance: header, tableInstance: table, onClick }: PinColumnMenuProps<TData>) => {
	const handleLeftSidePinning = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();

		if (header.column.getIsPinned()) {
			header.column.pin(false);
		} else {
			header.column.pin('left');
		}

		onClick?.();
	};
	const handleUnpinningAll = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();

		table.getAllLeafColumns().forEach((column) => {
			column.pin(false);
		});
		onClick?.();
	};

	const isPinned = header.column.getIsPinned();
	const icon = isPinned ? <UnpinIcon /> : <PinIcon />;
	const buttonText = isPinned ? 'Відкріпити' : 'Закріпити ліворуч';

	return (
		<span className={clsx(styles.pinMenu)}>
			<button type="button" onClick={handleLeftSidePinning} className={clsx(styles.button, { [styles.isPinned]: isPinned })}>
				{icon}
				<span>{buttonText}</span>
			</button>

			<button type="button" onClick={handleUnpinningAll} className={clsx(styles.button, styles.unpinAll)}>
				<UnpinAllIcon />
				<span>Відкріпити все</span>
			</button>
		</span>
	);
};

export default PinColumnMenu;
