import clsx from 'clsx';
import React from 'react';
import { ReactComponent as InfoIcon } from 'static/images/info-circle.svg';

import styles from './styles.module.css';

export type TipCardProps = {
	message: string;
	className?: string;
};

const TipCard: React.FC<TipCardProps> = ({ message, className }) => {
	return (
		<p className={clsx(styles.infoBadge, 'text-md-regular', className)}>
			<InfoIcon />
			<span>{message}</span>
		</p>
	);
};

export default TipCard;
