import { SERVER_ENTITY_ID } from 'const';
import { ClientOptionSchema, ContractOptionSchema } from 'models/client';
import { EmployeeOptionSchema, OrganizationOptionSchema } from 'models/employee';
import { Order, Suborder } from 'models/order';
import { OrderProduct } from 'models/product';
import {
	type CatalogueService,
	type OrderService,
	MetalCuttingServiceSchema,
	MoversService,
	PaintToningService,
	TransportationService,
} from 'models/service';
import { StockOptionSchema } from 'models/stock';
import { uuid } from 'utils/shared';

import type { ProductInternalModelState, ServiceInternalModelState, SuborderTab } from './schema';

// we need to mix up some data to service id some we could add duplications to ServiceStateModel
const delimiter = '@@';

export const encodeId = (id: string): string => `${id}${delimiter}${uuid()}`;
export const decodeId = (id: string): string => id.split(delimiter)[0];

export const transformOrderServiceToInternalServiceStateModel = (
	orderService: OrderService,
	services: Record<string, CatalogueService>,
): ServiceInternalModelState => {
	const isMetalCuttingService = !!orderService.category;

	if (!isMetalCuttingService) {
		return {
			id: encodeId(orderService.id),
			amount: orderService.amount,
			code: orderService.code,
			price: orderService.price,
			title: orderService.title,
			category: null,
			service: null,
			canEditManuallyPrice: !SERVER_ENTITY_ID.ServicesNotAllowedManuallyEditPrice.includes(orderService.id),
			isMetalCuttingService: false,
		};
	}
	const accessKey = 'Послуги порізка металу';
	const catalogueService = services?.[accessKey] as MetalCuttingService;
	const subcategory = catalogueService?.subCategories.find((sub) => {
		return sub.services.find((cat) => cat.id === orderService.id);
	});
	const category = subcategory?.services?.find((cat) => orderService.id === cat.id);

	return {
		id: encodeId(orderService.id),
		amount: orderService.amount,
		title: accessKey,
		code: orderService.code,
		price: orderService.price,
		service: { id: subcategory?.id ?? '', services: subcategory?.services ?? [], serviceType: accessKey, title: subcategory?.title ?? '' },
		category: {
			id: category?.id ?? '',
			title: category?.title ?? '',
			prices: category?.prices?.map((entity) => ({ ...entity, price: String(entity?.price) })) ?? [],
		},
		canEditManuallyPrice: !SERVER_ENTITY_ID.ServicesNotAllowedManuallyEditPrice.includes(catalogueService.id),
		isMetalCuttingService: true,
	};
};
export const transformCatalogueServiceToInternalServiceStateModel = (service: CatalogueService, serviceName?: string): ServiceInternalModelState => {
	const isMetalCuttingService = MetalCuttingServiceSchema.safeParse(service);

	if (isMetalCuttingService.success) {
		const { subCategories } = isMetalCuttingService.data;
		const defaultService = subCategories[0];
		const defaultCategory = defaultService.services[0];

		return {
			id: encodeId(service.id),
			amount: '1',
			title: serviceName,
			code: defaultCategory.code,
			price: defaultCategory.prices.find((price) => price.typePrice.id === SERVER_ENTITY_ID.ServiceDYIPriceType)?.price ?? '0.00',
			service: {
				id: defaultService?.id ?? '',
				services: defaultService?.services ?? [],
				serviceType: serviceName,
				title: defaultService?.title ?? '',
			},
			category: {
				id: defaultCategory.id ?? '',
				title: defaultCategory.title ?? '',
				prices: defaultCategory.prices?.map((entity) => ({ ...entity, price: String(entity?.price) })) ?? [],
			},
			canEditManuallyPrice: !SERVER_ENTITY_ID.ServicesNotAllowedManuallyEditPrice.includes(service.id),
			isMetalCuttingService: true,
		};
	}

	const otherService = service as TransportationService | MoversService | PaintToningService;

	return {
		id: encodeId(service.id),
		amount: '1',
		code: otherService.code,
		price: otherService.prices?.find((price) => price?.typePrice?.id === SERVER_ENTITY_ID.ServiceDYIPriceType)?.price ?? '0.00',
		title: otherService.title,
		category: null,
		service: null,
		canEditManuallyPrice: !SERVER_ENTITY_ID.ServicesNotAllowedManuallyEditPrice.includes(service.id),
		isMetalCuttingService: false,
	};
};

export const transformOrderProductToProductInternalModelState = (product: OrderProduct): ProductInternalModelState => {
	return product;
};

export const getOrderControllerEntityRowId = (row: ProductInternalModelState | ServiceInternalModelState) => {
	return row.id;
};

interface TransformSuborderToSuborderTabPayload {
	suborder: Suborder;
	services: Record<string, CatalogueService>;
	index: number;
}

export const transformSuborderToSuborderTab = ({ suborder, services, index }: TransformSuborderToSuborderTabPayload): SuborderTab => {
	const client = ClientOptionSchema.safeParse(suborder.client);
	const contract = ContractOptionSchema.safeParse(suborder.contract);
	const organization = OrganizationOptionSchema.safeParse(suborder.organization);
	const stock = StockOptionSchema.safeParse(suborder.stock);
	const responsible = EmployeeOptionSchema.safeParse(suborder.responsible);

	const servicesInternalModel = suborder.services.reduce((acc, service) => {
		const model = transformOrderServiceToInternalServiceStateModel(service, services);

		return {
			...acc,
			[model.id]: model,
		};
	}, {}) as Record<string, ServiceInternalModelState>;

	const productsInternalModel = suborder.products.reduce((acc, product) => {
		const model = transformOrderProductToProductInternalModelState(product);

		return {
			...acc,
			[model.id]: model,
		};
	}, {}) as Record<string, ProductInternalModelState>;

	return {
		tabName: `Заявка ${index + 1}`,
		data: {
			id: suborder.id,
			client: client.data,
			contract: contract.data,
			organization: organization.data,
			stock: stock.data,
			responsible: responsible.data,
			index: index + 1,
			isPaid: suborder.isPaid,
			isReserved: suborder.isReserved,
			isWithoutPayment: suborder.isWithoutPayment,
			products: productsInternalModel,
			services: servicesInternalModel,
			sum: suborder.sum,
			volume: suborder.volume,
			weight: suborder.weight,
			createdAt: suborder.createdAt,
			note: suborder.note,
			number: suborder.number,
			status: suborder.status,
			isSaved: true,
			parentId: suborder.parentId,
			realizations: suborder.realizations,
		},
	};
};

interface TransformOrderToRootOrderTabPayload {
	order: Order;
	services: Record<string, CatalogueService>;
}

export const transformOrderToRootOrderTab = ({ order, services }: TransformOrderToRootOrderTabPayload): SuborderTab => {
	const servicesInternalModel = order.services?.reduce((acc, service) => {
		const model = transformOrderServiceToInternalServiceStateModel(service, services);

		return {
			...acc,
			[model.id]: model,
		};
	}, {}) as Record<string, ServiceInternalModelState>;

	const productsInternalModel = order.products.reduce((acc, product) => {
		const model = transformOrderProductToProductInternalModelState(product);

		return {
			...acc,
			[model.id]: model,
		};
	}, {}) as Record<string, ProductInternalModelState>;

	return {
		tabName: 'Основна заявка',
		data: {
			id: order.id,
			client: {
				label: order.client.name,
				value: order.client.id,
			},
			contract: {
				label: order.contract.title,
				value: order.contract.id,
			},
			organization: {
				label: order.organization.title,
				value: String(order.organization.id),
			},
			stock: {
				label: order.stock.title,
				value: order.stock.id,
			},
			responsible: {
				label: order.responsible.name,
				value: order.responsible['1c_uuid'],
			},
			index: 0,
			isPaid: Boolean(order.isPaid),
			isReserved: Boolean(order.isReserved),
			isWithoutPayment: Boolean(order.isWithoutPayment),
			products: productsInternalModel,
			services: servicesInternalModel,
			sum: order.sum,
			volume: order.volume,
			weight: order.weight,
			createdAt: order.createdAt,
			note: order.note,
			number: order.number,
			status: order.status,
			isSaved: true,
			realizations: [],
		},
	};
};
