import 'static/styles/style.css';

import clsx from 'clsx';
import Button from 'components/Button';
import SpinnerV2 from 'components/Spinner-v2';
import VirtualizedList from 'components/VirtualizedList';
import { useElementWidth } from 'hooks/useElementWidth';
import React, { MouseEvent } from 'react';

import styles from './style.module.css';
import { IProps } from './types';

const humanizeSearchByTerm = (option: number) => {
	if (option === 1) return 'клієнта';
	if (option === 0) return 'товара';

	return '';
};
const humanizeSearchInTitle = (option: number) => {
	if (option === 1) return 'Шукати в клієнтах';
	if (option === 0) return 'Шукати в назві товару';

	return '';
};

const SearchDropDown: React.FC<IProps> = ({
	option,
	options,
	renderItem,
	onItemClick,
	refetch,
	highlightMatch,
	query,
	isError,
	isLoading,
	dropdownHeight = 400,
	dropdownItemHeight = 44,
}) => {
	const { elementRef, width } = useElementWidth<HTMLUListElement>();

	const hasOptions = options?.length > 0;
	const searchByTerm = humanizeSearchByTerm(option);
	const emptyStateMessage = `Жодного ${searchByTerm} не знайдено... Спробуйте інший запит.`;
	const searchInTitle = humanizeSearchInTitle(option);
	const shouldRenderOptions = hasOptions && !isLoading;
	const shouldRenderEmptyState = options && options.length === 0 && !isLoading && !isError;

	const handleRefetch = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		refetch?.();
	};

	return (
		<div className={styles.searchBox}>
			<ul ref={elementRef} className={styles.contentWrapper}>
				<li className="text-md-bold" style={{ color: 'var(--gray-400)' }}>
					{searchInTitle}
				</li>

				<li className={styles.divider} />

				{isLoading && (
					<li className={clsx(styles.spinner, styles.requestState)}>
						<SpinnerV2 />
					</li>
				)}

				{isError && (
					<li className={styles.requestState}>
						<p className={styles.error}>Не вдалося завантажити опції...</p>
						<Button variant="small" text="Завантажити ще раз" onClick={handleRefetch} />
					</li>
				)}

				{shouldRenderEmptyState && (
					<li className={styles.emptyState}>
						<strong>{emptyStateMessage}</strong>
					</li>
				)}

				{shouldRenderOptions && (
					<VirtualizedList
						itemSize={dropdownItemHeight}
						items={options}
						width={width}
						height={dropdownHeight}
						renderItem={({ item, index, style }) => {
							if (!highlightMatch) {
								return (
									<li
										className={clsx(styles.searchResult, {
											[styles.highlight]: highlightMatch,
										})}
										key={index}
										onClick={() => onItemClick(item)}
										style={style}
									>
										<span className="line-clamp">{renderItem?.(item) ?? item.title}</span>
									</li>
								);
							}
							const text = renderItem?.(item) ?? item.title;
							const regex = new RegExp(query, 'gi');
							const newText = text.replace(regex, '<strong>$&</strong>');
							const textNode = <span className="line-clamp" dangerouslySetInnerHTML={{ __html: newText }} />;

							return (
								<li
									className={clsx(styles.searchResult, {
										[styles.highlight]: highlightMatch,
									})}
									key={index}
									onClick={() => onItemClick(item)}
									style={style}
								>
									{textNode}
								</li>
							);
						}}
					/>
				)}
			</ul>
		</div>
	);
};

export default SearchDropDown;
