import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

const ModeContext = createContext(undefined);

export const CreatingModeProvider = ({ children }: PropsWithChildren) => {
	const [creatingMode, setCreatingMode] = useState(false);

	const handleChangeCreatingMode = useCallback((value: boolean) => {
		setCreatingMode(value);
	}, []);

	return <ModeContext.Provider value={{ creatingMode, handleChangeCreatingMode }}>{children}</ModeContext.Provider>;
};

export const useCreatingMode = () => {
	const context = useContext(ModeContext);
	if (!context) {
		throw new Error('useMode must be used within a ModeProvider');
	}
	return context;
};
