import clsx from 'clsx';
import SpinnerV2 from 'components/Spinner-v2';
import VirtualizedList from 'components/VirtualizedList';
import { useStackState } from 'hooks/useStackState';
import React, { startTransition, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ReactComponent as MagnifyGlassIcon } from 'static/images/searchInMenu.svg';
import { useGetBrandsFiltersQuery, useGetCategoriesFiltersQuery } from 'store/reducers/orders/ordersSliceApi';

import QueuedFilterItem from '../../ClientsFilter/QueuedFilterItem';
import FilterHeader from './FilterHeader';
import FilterIcon from './FilterIcon';
import { useStateMachine } from './hooks/useStateMachine';
import { FilterSearchParamsKey } from './lib/const';
import { buildFiltersStackLikeStructure, hydrate } from './lib/utils';
import styles from './styles.module.css';
import type { IProps } from './types';

const ActiveFilterIcon = () => <FilterIcon isActive />;
const InActiveFilterIcon = () => <FilterIcon />;

const ProductFilter: React.FC<IProps> = ({ className, ...restProps }) => {
	const [appliedCategoriesList, appliedCategoriesStack] = useStackState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [filterBrand, setFilterBrands] = useState('');

	const stateMachine = useStateMachine(hydrate(searchParams));
	const isStackHydrated = useRef(false);

	const { data: brandsFilters, ...brandRequest } = useGetBrandsFiltersQuery(undefined);
	const { data: categoryFilters, ...categoryRequest } = useGetCategoriesFiltersQuery(searchParams.toString());

	useEffect(() => {
		if (categoryFilters && !isStackHydrated.current) {
			isStackHydrated.current = true;
			const [initialCategory] = categoryFilters;

			if (initialCategory?.parent) {
				const parents = buildFiltersStackLikeStructure(initialCategory);

				const categories = parents.map((item, index) => ({ index, item }));
				appliedCategoriesStack.pushBulk(categories);
			}
		}
	}, [categoryFilters]);

	const onGoBackFilter = (key: string) => () => {
		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.delete(key);
		setSearchParams(newSearchParams);
		startTransition(() => {
			stateMachine.set('idle');
			appliedCategoriesStack.clear();
		});
	};

	const isLast = Boolean(categoryFilters?.[0]?.isLast);
	const hasAvailableCategoryFilters =
		isStackHydrated.current &&
		!categoryRequest.isFetching &&
		categoryRequest.isSuccess &&
		!categoryRequest.isLoading &&
		categoryFilters?.length > 0 &&
		!isLast;

	const hasAvailableBrandFilters = brandsFilters?.length > 0;

	return (
		<div data-product-filter className={clsx(styles.wrapper, className)} {...restProps}>
			<div data-sticky-filter className={styles.stickyContainer}>
				{stateMachine.isIdle && (
					<div className={styles.rootControlsWrapper}>
						<QueuedFilterItem
							isActive
							title="Категорії"
							onClick={() => {
								stateMachine.set('category:applied');
							}}
							onUnselect={() => {
								stateMachine.set('idle');
							}}
							icon={ActiveFilterIcon}
						/>
						<QueuedFilterItem
							isActive
							onClick={() => {
								stateMachine.set('brand:opened');
							}}
							onUnselect={() => {
								stateMachine.set('idle');
							}}
							title="Виробники"
							icon={ActiveFilterIcon}
						/>
					</div>
				)}
				{!stateMachine.isIdle && !stateMachine.isBrandMenuOpened && (
					<FilterHeader
						title={appliedCategoriesList.length > 0 ? 'Підкатегорія' : 'Категорія'}
						onGoBackClick={onGoBackFilter(FilterSearchParamsKey.Category)}
						className={clsx(styles.header)}
					/>
				)}
				{stateMachine.isBrandMenuOpened && (
					<FilterHeader title="Виробник" onGoBackClick={onGoBackFilter(FilterSearchParamsKey.Brand)} className={clsx(styles.header)} />
				)}

				{(stateMachine.isCategoryFilterApplied || stateMachine.isBrandFilterApplied) && (
					<ul className={styles.queueItemsList}>
						{appliedCategoriesList.map((category) => (
							<QueuedFilterItem
								isActive
								title={category?.item?.title}
								key={category.item.id}
								icon={ActiveFilterIcon}
								onUnselect={() => {
									const newSearchParams = new URLSearchParams(searchParams);
									const categoryId = category.item?.parent?.id;

									if (!categoryId) {
										newSearchParams.delete('category');
									} else {
										newSearchParams.set('category', category.item?.parent?.id);
									}

									setSearchParams(newSearchParams);
									startTransition(() => appliedCategoriesStack.pop(category.index));
								}}
							/>
						))}
						{hasAvailableCategoryFilters &&
							categoryFilters.map((category) => {
								return (
									<QueuedFilterItem
										title={category.title}
										onUnselect={() => {}}
										key={category.id}
										onClick={() => {
											const newSearchParams = new URLSearchParams(searchParams);
											newSearchParams.set(FilterSearchParamsKey.Category, category.id);
											appliedCategoriesStack.push({ index: appliedCategoriesList.length, item: category });
											startTransition(() => {
												setSearchParams(newSearchParams);
											});
										}}
										icon={InActiveFilterIcon}
									/>
								);
							})}

						{categoryRequest.isFetching && (
							<div className={styles.spinner}>
								<SpinnerV2 />
							</div>
						)}
					</ul>
				)}

				{(stateMachine.isIdle || stateMachine.isBrandMenuOpened) && (
					<div className={styles.productSearchInputWrapper}>
						<label htmlFor="product-search" className="visually-hidden">
							Шукати товар
						</label>

						<MagnifyGlassIcon className={styles.icon} />

						<input
							className={clsx('text-sm-regular', styles.input)}
							value={filterBrand}
							onChange={(e) => setFilterBrands(e.currentTarget.value)}
							id="product-search"
							type="text"
							placeholder="Почніть писати бренд"
						/>
					</div>
				)}

				{(stateMachine.isIdle || stateMachine.isBrandMenuOpened) && hasAvailableBrandFilters && (
					<div className={clsx(styles.listsWrapper, styles.brandsList)}>
						<AutoSizer>
							{({ height, width }) => {
								return (
									<VirtualizedList
										items={brandsFilters}
										height={height}
										width={width}
										itemSize={50 + 8}
										renderItem={({ item: brand, index, style }) => {
											const handleBrandClick = () => {
												stateMachine.set('brand:applied');
												const newSearchParams = new URLSearchParams(searchParams);
												newSearchParams.set(FilterSearchParamsKey.Brand, brand.id);
												setSearchParams(newSearchParams);
											};
											const isActive = searchParams.get(FilterSearchParamsKey.Brand)?.toLowerCase() === brand.id.toLowerCase();

											return (
												<div style={{ ...style, paddingBottom: '8px' }}>
													<QueuedFilterItem
														isActive={isActive}
														className={styles.brandFilterItem}
														key={brand.id + index}
														title={brand.title}
														onClick={handleBrandClick}
														icon={InActiveFilterIcon}
														onUnselect={() => {
															const newSearchParams = new URLSearchParams(searchParams);
															newSearchParams.delete(FilterSearchParamsKey.Brand);
															setSearchParams(newSearchParams);
															stateMachine.set('idle');
														}}
													/>
												</div>
											);
										}}
									/>
								);
							}}
						</AutoSizer>

						{brandRequest.isFetching && (
							<div className={styles.spinner}>
								<SpinnerV2 />
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default ProductFilter;
