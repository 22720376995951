import { FilterTag } from 'components/ActiveFiltersTags/types';
import { CLIENTS_FILTER_QUERY_KEYS, HUMAN_CLIENTS_FILTER_QUERY_KEYS, HUMAN_ORDERS_FILTER_QUERY_KEYS, ORDERS_FILTER_QUERY_KEYS } from 'const';
import { SetURLSearchParams } from 'react-router-dom';
import { ReactComponent as Calendar } from 'static/images/calendar-check-01.svg';
import { ReactComponent as Case } from 'static/images/case.svg';
import { ReactComponent as Chart } from 'static/images/chart.svg';
// import { ReactComponent as Clock } from 'static/images/clock-fast.svg';
import { ReactComponent as Coin } from 'static/images/coins.svg';
import { ReactComponent as Monet } from 'static/images/coins-02.svg';
import { ReactComponent as Document } from 'static/images/file-attachment.svg';
import { ReactComponent as Box } from 'static/images/package.svg';
import { ReactComponent as Truck } from 'static/images/truck.svg';
import { ReactComponent as User } from 'static/images/user-02.svg';
import { ReactComponent as UserCircle } from 'static/images/user-circle.svg';
import { translateNameOfBadge } from 'utils/orders';

import { ClientAppliedFiltersState, OrdersAppliedFiltersState } from './types';

export type RangeTypeFilterKey = Extract<keyof typeof CLIENTS_FILTER_QUERY_KEYS, 'limit' | 'delay' | 'lastSell'>;

type FilterQueryKeys = keyof typeof ORDERS_FILTER_QUERY_KEYS | typeof CLIENTS_FILTER_QUERY_KEYS;
type VirtualizeFilterQueryKeys = Extract<FilterQueryKeys, 'responsible' | 'client'>;
const FILTERS_TO_VIRTUALIZED_LIST: VirtualizeFilterQueryKeys[] = ['client', 'responsible'];
const RANGE_TYPE_FILTERS: RangeTypeFilterKey[] = [];

export const isRangeTypeFilter = (key: string) => {
	return RANGE_TYPE_FILTERS.includes(key as RangeTypeFilterKey);
};

export const isVirtualizeFilter = (key: FilterQueryKeys) => {
	return FILTERS_TO_VIRTUALIZED_LIST.includes(key as VirtualizeFilterQueryKeys);
};

export const prepareActiveFiltersListForOrders = (searchParams: URLSearchParams) => {
	const filterKeys = Object.keys(ORDERS_FILTER_QUERY_KEYS);
	const tagsList = filterKeys.reduce((list, key) => {
		const keyRawValues = searchParams.getAll(key);

		if (!keyRawValues) return list;

		const keyValues = keyRawValues[0]?.split(',');

		if (!keyValues) return list;

		if (isRangeTypeFilter(key)) {
			const humanizeFilterQuery = HUMAN_ORDERS_FILTER_QUERY_KEYS[key];

			return [
				...list,
				{
					filterQuery: key,
					filterQueryValue: keyValues.join('–'),
					filterQueryHuman: humanizeFilterQuery('short'),
				},
			] as FilterTag[];
		}

		const filterTags: FilterTag[] = keyValues?.map((filterValue) => {
			const humanizeFilterQuery = HUMAN_ORDERS_FILTER_QUERY_KEYS[key];

			const filterTag: FilterTag = {
				filterQuery: key,
				filterQueryValue: filterValue,
				filterQueryHuman: humanizeFilterQuery('short'),
			};

			// !TEMP for demo only
			if (key === 'client') {
				filterTag.dotColor = '#ff0000';
			}

			return filterTag;
		});

		return [...list, ...filterTags];
	}, []);

	return tagsList as FilterTag[];
};

export const ordersFilterGroups = [
	{ groupName: HUMAN_ORDERS_FILTER_QUERY_KEYS.sum, queryKey: ORDERS_FILTER_QUERY_KEYS.sum, icon: Coin },
	{ groupName: HUMAN_ORDERS_FILTER_QUERY_KEYS.payment, queryKey: ORDERS_FILTER_QUERY_KEYS.payment, icon: Monet },
	{ groupName: HUMAN_ORDERS_FILTER_QUERY_KEYS.responsible, queryKey: ORDERS_FILTER_QUERY_KEYS.responsible, icon: UserCircle },
	{ groupName: HUMAN_ORDERS_FILTER_QUERY_KEYS.shipment, queryKey: ORDERS_FILTER_QUERY_KEYS.shipment, icon: Truck },
	{ groupName: HUMAN_ORDERS_FILTER_QUERY_KEYS.client, filterTip: 'Шукати в назві', queryKey: ORDERS_FILTER_QUERY_KEYS.client, icon: UserCircle },
	// { groupName: HUMAN_ORDERS_FILTER_QUERY_KEYS.manager, filterTip: 'Шукати в назві', queryKey: ORDERS_FILTER_QUERY_KEYS.manager, icon: User },
	{ groupName: HUMAN_ORDERS_FILTER_QUERY_KEYS.date, filterTip: 'Шукати по даті', queryKey: ORDERS_FILTER_QUERY_KEYS.date, icon: Calendar },
	{ groupName: HUMAN_ORDERS_FILTER_QUERY_KEYS.stock, queryKey: ORDERS_FILTER_QUERY_KEYS.stock, icon: Box },
	{ groupName: HUMAN_ORDERS_FILTER_QUERY_KEYS.contract, queryKey: ORDERS_FILTER_QUERY_KEYS.contract, icon: Document },
];

export const clientsFilterGroups = [
	{ groupName: HUMAN_CLIENTS_FILTER_QUERY_KEYS.client, filterTip: 'Шукати за прізвищем', queryKey: CLIENTS_FILTER_QUERY_KEYS.client, icon: Case },
	{ groupName: HUMAN_CLIENTS_FILTER_QUERY_KEYS.manager, filterTip: 'Шукати за прізвищем', queryKey: CLIENTS_FILTER_QUERY_KEYS.manager, icon: User },
	{ groupName: HUMAN_CLIENTS_FILTER_QUERY_KEYS.segment, queryKey: CLIENTS_FILTER_QUERY_KEYS.segment, icon: Chart },
	// { groupName: HUMAN_CLIENTS_FILTER_QUERY_KEYS.limit, queryKey: CLIENTS_FILTER_QUERY_KEYS.limit, icon: Coin },
	// { groupName: HUMAN_CLIENTS_FILTER_QUERY_KEYS.delay, queryKey: CLIENTS_FILTER_QUERY_KEYS.delay, icon: Calendar },
	// { groupName: HUMAN_CLIENTS_FILTER_QUERY_KEYS.lastSell, queryKey: CLIENTS_FILTER_QUERY_KEYS.lastSell, icon: Clock },
];

export const updateSearchParams = (
	values: ClientAppliedFiltersState | OrdersAppliedFiltersState,
	searchParams: URLSearchParams,
	patcherFn: SetURLSearchParams,
) => {
	const existingSearchParams = new URLSearchParams(searchParams);
	const newSearchParams = new URLSearchParams(existingSearchParams);
	const entries = Object.entries(values);

	entries.forEach(([key, value]) => {
		if (value && value.length > 0) {
			newSearchParams.set(key, value?.join(','));
		} else {
			newSearchParams.delete(key);
		}
	});

	patcherFn(newSearchParams);
};

export const normalizeData = (data) => {
	if (Array.isArray(data)) {
		return data
			.filter((item) => item.id !== '')
			.map((item) => {
				if (item.id && item.title) {
					return { value: String(item.id), label: translateNameOfBadge(item.title) };
				} else if (item.id && item.name) {
					return { value: String(item.id), label: translateNameOfBadge(item.name) };
				} else if (typeof item === 'string') {
					return { value: item, label: translateNameOfBadge(item) };
				} else {
					return {
						value: String(item.id || item),
						label: translateNameOfBadge(item.title) || translateNameOfBadge(item.name) || translateNameOfBadge(item),
					};
				}
			});
	}
	return [];
};
