import type { Whoami } from 'models/auth';
import type { Client } from 'models/client';
import { Option } from 'models/common/options';
import type { Order } from 'models/order';
import type { OrderProduct } from 'models/product';
import type { OrderService } from 'models/service';
import { uuid } from 'utils/shared';

interface CreateDraftEntityPayload {
	client: Client;
	manager: Whoami;
	products?: OrderProduct[];
	services?: OrderService[];
	stock?: Option;
	organization?: Option;
	contract?: Option;
}
export const createDraftOrder = ({ client, manager, products = [], services = [], contract, stock, organization }: CreateDraftEntityPayload) => {
	const newOrder = {
		client,
		id: '',
		sum: 0,
		number: 'new',
		status: 'calculation' as const,
		logisticsStatus: 'Очікує загрузку' as const,
		paymentStatus: 'no_payment' as const,
		isReserved: false,
		isPaid: false,
		isClosed: false,
		isWithoutPayment: false,
		type: 'regular' as const,
		note: '',
		responsible: {
			id: manager.id,
			'1c_uuid': manager['1c_uuid'],
			name: manager.name,
		},
		contract,
		organization,
		typePrice: {
			id: '',
			title: '',
		},
		stock: stock || manager.stock,
		products,
		services,
		realizations: [],
		payments: [],
		returns: [],
		createdAt: new Date(),
		date: new Date(),
		subOrders: [],
		weight: 0,
		volume: 0,
		parentId: null,
	};

	return newOrder;
};

export const createOrderLikeEntity = ({ client, manager, organization }: CreateDraftEntityPayload): Order => {
	const newOrder = {
		client,
		id: '',
		sum: 0,
		number: uuid(),
		status: 'calculation' as const,
		logisticsStatus: 'Очікує загрузку' as const,
		paymentStatus: 'no_payment' as const,
		isReserved: false,
		isPaid: false,
		isClosed: false,
		isWithoutPayment: false,
		type: 'regular' as const,
		note: '',
		responsible: {
			id: manager.id,
			'1c_uuid': manager['1c_uuid'],
			name: manager.name,
		},
		contract: client?.contracts?.[0] || {
			id: '',
			title: '',
			client: undefined,
			organization: {
				id: '',
				title: '',
			},
			expiredAt: '',
		},
		organization: {
			id: organization?.value ?? '',
			title: organization?.label ?? '',
		},
		typePrice: {
			id: '',
			title: '',
		},
		stock: manager.stock,
		products: [],
		services: [],
		realizations: [],
		payments: [],
		returns: [],
		createdAt: new Date(),
		date: new Date(),
		subOrders: [],
		weight: 0,
		volume: 0,
		parentId: null,
	};

	return newOrder;
};
