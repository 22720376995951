import type { ExternalControlledSingleAutocompleteProps, Suggestion } from '../types';

export const useSingleExternalControlledAutocomplete = ({ value, setValue }: ExternalControlledSingleAutocompleteProps) => {
	const setSuggestion = (newSuggestion: Suggestion | string) => {
		setValue?.(newSuggestion);
	};

	return {
		setSuggestion,
		onUnselect: undefined,
		suggestion: value as Suggestion | string,
		hasReachedLimit: false,
	};
};
