import { OptionSchema, ServerSideOptionSchema } from 'models/common/options';
import { PhoneSchema } from 'models/common/phones';
import { standardizeOption } from 'models/common/preprocess';
import { ClientIdSchema, ClientManagerIdSchema, SegmentIdSchema } from 'models/common/uuid';
import { ContractSchema } from 'models/contract';
import { z } from 'zod';

import { SegmentSchema } from './segment';
import { withStandardizedName } from './utils';

export const ClientNameSchema = z.object({ name: z.string() });
export const ClientOptionSchema = standardizeOption<typeof OptionSchema, { id: string; name: string }>(OptionSchema, ({ id, name }) => ({
	label: name,
	value: id,
}));

export const ClientManagerSchema = z.object({
	id: ClientManagerIdSchema,
	'1c_uuid': z.string().min(1),
	name: z.string().min(1),
});
export const ClientPaymentStatisticSchema = z.object({
	availableSum: z.number(),
	limit: z.number(),
	debt: z.number(),
});
export const ClientPaymentDetailSchema = z.object({
	sum: z.number(),
	amount: z.number(),
	fromAmount: z.number(),
});
export const ClientStatusSchema = z.union([z.literal('green'), z.literal('white'), z.literal('yellow'), z.literal('red')]);

export const BaseClientSchema = z.object({
	id: ClientIdSchema,
	email: z.string().nullable(),
	code: z.string().min(1),
	avatar: z.string().nullish(),
});

export const OrderClientSchema = BaseClientSchema.extend({ name: z.string() });
export const ContractClientSchema = BaseClientSchema;
export const ContractOptionSchema = standardizeOption<typeof OptionSchema, { title: string; id: string }>(OptionSchema, ({ title, id }) => ({
	value: id,
	label: title,
}));

export const BaseIndividualClientSchema = z.object({
	ipnCode: z.string(),
});
export const BaseLegalClientSchema = z.object({
	edrpouCode: z.string(),
});

export const BaseClientPreviewSchema = BaseClientSchema.extend({
	createdAt: z.coerce.date(),
	lastDeal: z.coerce.date(),
	ordersCount: z.coerce.number(),
	segment: SegmentSchema.nullable(),
	manager: ClientManagerSchema.nullable(),
	activities: z.array(ServerSideOptionSchema),
	limit: ClientPaymentStatisticSchema.optional(),
	activityType: z.string().optional(),
	clientStatus: z.number().optional(),
	comment: z.string().nullish(),
});

// Preview schemas are used in lists
export const IndividualClientPreviewSchema = BaseClientPreviewSchema.merge(BaseIndividualClientSchema);
export const LegalClientPreviewSchema = BaseClientPreviewSchema.merge(BaseLegalClientSchema);
export const ClientPreviewSchema = withStandardizedName(
	IndividualClientPreviewSchema.merge(ClientNameSchema).or(LegalClientPreviewSchema.merge(ClientNameSchema)),
);

const BaseCreateClientSchema = BaseClientSchema.omit({ id: true, code: true }).extend({
	phones: z.array(PhoneSchema),
	passport: z.string(),
	address: z.string(),
	segmentId: SegmentIdSchema,
	managerId: ClientManagerIdSchema,
	comment: z.string().nullable(),
	activities: z.array(z.string()),
});
export const CreateIndividualClientSchema = BaseCreateClientSchema.merge(BaseIndividualClientSchema);
export const CreateLegalClientSchema = BaseCreateClientSchema.merge(BaseLegalClientSchema);
export const CreateClientSchema = CreateIndividualClientSchema.or(CreateLegalClientSchema);

export const UpdateClientSchema = CreateClientSchema;

const BaseDetailedClientSchema = BaseCreateClientSchema.omit({
	segmentId: true,
	managerId: true,
}).extend({
	manager: z
		.object({
			id: z.number(),
			name: z.string(),
			email: z.string().email(),
			createdAt: z.coerce.date(),
		})
		.nullable(),
	id: ClientIdSchema,
	segment: SegmentSchema,
	contracts: z.array(ContractSchema.omit({ expiredAt: true })),
	activities: z.array(ServerSideOptionSchema),
	createdAt: z.coerce.date(),
	daysOfDelay: z.number(),
	lastDeal: z.coerce.date(),
	ordersCount: z.number(),
	status: ClientStatusSchema,
	firstRealization: z.coerce.date(),
	totalSum: z.number().positive(),
	available: ClientPaymentStatisticSchema,
	waitingForPayment: ClientPaymentDetailSchema,
	prepaidPayments: ClientPaymentDetailSchema,
	overduePayments: ClientPaymentDetailSchema,
});

export const IndividualClientSchema = BaseIndividualClientSchema.merge(BaseDetailedClientSchema);
export const LegalClientSchema = BaseLegalClientSchema.merge(BaseDetailedClientSchema);
export const ClientSchema = withStandardizedName(IndividualClientSchema.merge(ClientNameSchema).or(LegalClientSchema.merge(ClientNameSchema)));

export type ClientPreview = z.infer<typeof ClientPreviewSchema>;
export type Client = z.infer<typeof ClientSchema>;
export type ClientOptions = z.infer<typeof ClientOptionSchema>;
export type CreateClient = z.infer<typeof CreateClientSchema>;
export type UpdateClient = z.infer<typeof UpdateClientSchema>;
export type ClientOption = z.infer<typeof ClientOptionSchema>;
