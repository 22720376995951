/* eslint-disable @typescript-eslint/no-explicit-any */
import { ROUTES_URLS } from 'const';
import { redirect } from 'react-router-dom';

export const API_URL = process.env.REACT_APP_API_URL || 'https://json-server.alterego.biz.ua/';

type Config = RequestInit;

export const api = {
	get: async <TData = any>(url: string, config?: Config) => {
		try {
			const response = await fetch(`${API_URL}${url}`, config);

			if (!response.ok) {
				return redirect(ROUTES_URLS.HOME);
			}

			const data = await response.json();

			return data as Promise<TData>;
		} catch {
			return redirect(ROUTES_URLS.HOME);
		}
	},
	post: async (url: string, rawBody: unknown) => {
		try {
			const isString = typeof rawBody === 'string';
			const body = isString ? rawBody : JSON.stringify(rawBody);

			const response = await fetch(`${API_URL}${url}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body,
			});

			if (!response.ok) {
				// eslint-disable-next-line no-console
				console.error('Error: ', response);
				return null;
			}

			const data = await response.json();

			return data;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
	},
	put: async (url: string, rawBody: unknown) => {
		try {
			const isString = typeof rawBody === 'string';
			const body = isString ? rawBody : JSON.stringify(rawBody);

			const response = await fetch(`${API_URL}${url}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body,
			});

			if (!response.ok) {
				// eslint-disable-next-line no-console
				console.error('Error: ', response);
				return null;
			}

			const data = await response.json();

			return data;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
	},
	delete: async (url: string) => {
		try {
			const response = await fetch(`${API_URL}${url}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (!response.ok) {
				// eslint-disable-next-line no-console
				console.error('Error: ', response);
				return null;
			}

			const data = await response.json();

			return data;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
	},
};
