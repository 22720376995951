import { isNumber, isObject, isString } from 'utils/type-guards';

import type { IProps, Suggestion } from '../types';

export const resolveValue = (option?: string | Suggestion) => {
	return isString(option) ? option : isObject<Suggestion>(option) ? option?.value : isNumber(option) ? String(option) : undefined;
};
export const resolveDisplayValue = (option?: string | Suggestion) => {
	return isString(option) ? option : isObject<Suggestion>(option) ? option?.label : isNumber(option) ? String(option) : undefined;
};

export const filterIncluded = (options: IProps['valuesList'], value: string) => {
	if (!value) return [];
	return (
		options?.filter((suggestion) => {
			const resolvedValue = resolveDisplayValue(suggestion);

			return resolvedValue ? resolvedValue.toLowerCase().includes(value.toLowerCase()) : false;
		}) ?? []
	);
};

export const findIndex = (options: IProps['valuesList'], value: string) => {
	if (!value) return -1;
	return (
		options?.findIndex((suggestion) => {
			const resolvedValue = resolveDisplayValue(suggestion);
			return resolvedValue ? resolvedValue.toLowerCase() === value.toLowerCase() : false;
		}) ?? -1
	);
};

export const getHydrateStateData = (options: IProps['valuesList'], value?: string | Suggestion | string[] | Suggestion[]) => {
	const resolvedOptions = options ?? [];
	const isArray = Array.isArray(value);
	const resolvedValue = isArray ? value.map(resolveDisplayValue) : resolveDisplayValue(value);
	const userInput = isArray ? '' : (resolvedValue as string);

	const filteredSuggestions = isArray
		? (value as (string | Suggestion)[]).filter((v) =>
				options?.some((option) => {
					const optionValue = resolveDisplayValue(option);
					const valueValue = resolveDisplayValue(v);
					return optionValue && valueValue ? optionValue.toLowerCase().includes(valueValue.toLowerCase()) : false;
				}),
		  )
		: filterIncluded(resolvedOptions, resolvedValue as string);

	const activeSuggestion = isArray
		? (value as (string | Suggestion)[]).findIndex((v) => options?.some((option) => resolveDisplayValue(option) === resolveDisplayValue(v)))
		: findIndex(resolvedOptions, resolvedValue as string);

	return {
		userInput,
		hasFocus: false,
		activeSuggestion,
		filteredSuggestions,
		shouldDebounce: true,
		showSuggestions: false,
	};
};

export const mergeSuggestions = (newSuggestion: Suggestion | string, suggestions: Suggestion[] | string[]) => {
	const found = suggestions.find((suggestion) => resolveValue(suggestion) === resolveValue(newSuggestion));

	if (found) return suggestions;

	return [...suggestions, newSuggestion];
};
