import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';
import type { AlertDialogueFooterProps } from './types';

const AlertDialogueFooter: React.FC<AlertDialogueFooterProps> = ({ children, className, justify = 'space-between' }) => {
	return (
		<footer className={clsx(styles.footer, className)} style={{ '--alert-dialogue-footer-justify': justify } as React.CSSProperties}>
			{children}
		</footer>
	);
};

export default AlertDialogueFooter;
