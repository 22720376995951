import AlertDialogue from 'components/AlertDialogue';
import AlertDialogueContent from 'components/AlertDialogue/components/AlertDialogueContent';
import AlertDialogueControlButton from 'components/AlertDialogue/components/AlertDialogueControlButton';
import AlertDialogueFooter from 'components/AlertDialogue/components/AlertDialogueFooter';
import AlertDialogueHeader from 'components/AlertDialogue/components/AlertDialogueHeader';
import AlertDialogueMessage from 'components/AlertDialogue/components/AlertDialogueMessage';
import React from 'react';

const messages = {
	unReservation: {
		reason: `
    ВСІ створені на поточний момент ПІДЗАЯВКИ, якщо вони не порожні, також будуть ЗБЕРЕЖЕНІ на сервері.
    Але без зміни їх статусу.`,
		tip: 'Статус "Знято з резерва" буде додано лише для поточної підзаявки',
	},
};

interface Props {
	onClose: () => void;
	data: number;
}

const CancelSuborderReservationAlertDialogue = ({ data, onClose }: Props) => {
	const headerText = `Помилка зняття з резервації Заявка ${data}?`;
	const message = messages.unReservation;

	return (
		<AlertDialogue onOutsideClick={onClose}>
			<AlertDialogueHeader onClose={onClose}>{headerText}</AlertDialogueHeader>
			<AlertDialogueContent>
				<AlertDialogueMessage>{message.reason}</AlertDialogueMessage>
				<AlertDialogueMessage>{message.tip}</AlertDialogueMessage>
			</AlertDialogueContent>

			<AlertDialogueFooter justify="flex-end">
				<AlertDialogueControlButton variant="submit" onClick={onClose}>
					Зрозуміло
				</AlertDialogueControlButton>
			</AlertDialogueFooter>
		</AlertDialogue>
	);
};

export default CancelSuborderReservationAlertDialogue;
