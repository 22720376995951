import { useCallback } from 'react';

export const useStopPropagationCallback = <TElement>(cb: VoidCallback) => {
	const callback = useCallback(
		(e?: React.MouseEvent<TElement>) => {
			e?.stopPropagation();
			cb?.(e);
		},
		[cb],
	);

	return callback;
};
