type UseButtonTitleProps = {
	index: number;
	ordersCount: number;
};

export const useButtonTitle = ({ index, ordersCount }: UseButtonTitleProps) => {
	const buttonTitles = [];

	for (let i = 1; i <= ordersCount; i++) {
		if (index !== i) {
			const title = {
				label: `${i}`,
				index: i,
			};
			buttonTitles.push(title);
		}
	}

	return buttonTitles;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extendRowProps = (row: any) => {
	if (row.original?.isColorant) {
		return {
			className: 'colorant-row',
		};
	}
};
