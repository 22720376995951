import { ROUTES_URLS } from 'const';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as EyeIcon } from 'static/images/eye.svg';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';
import { ReactComponent as RefreshIcon } from 'static/images/refresh.svg';
import { prepareUrl } from 'utils/shared';

import styles from './styles.module.css';

const RefreshActions = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const navigateToSelectProduct = () => {
		const url = prepareUrl(ROUTES_URLS.ORDER_SELECT_PRODUCT, { id });

		navigate(url);
	};

	return (
		<ul data-refresh-actions className={styles.list}>
			<li>
				<button type="button" className={styles.button}>
					<RefreshIcon />
				</button>
			</li>
			<li>
				<button type="button" className={styles.button}>
					<EyeIcon className={styles.eye} />
				</button>
			</li>
			<li>
				<button type="button" className={styles.button} onClick={navigateToSelectProduct}>
					<PlusIcon className={styles.plus} />
				</button>
			</li>
		</ul>
	);
};

export default RefreshActions;
