import Counter from 'components/Counter';
import { useTypedOrderControllerFromContext } from 'pages/OrderRework/OrderController';
import React from 'react';
import { Controller } from 'react-hook-form';

import styles from './styles.module.css';
import type { ServiceCounterProps } from './types';

const ServiceCounter: React.FC<ServiceCounterProps> = ({ suborderIndex, entityId }) => {
	const { control } = useTypedOrderControllerFromContext();

	return (
		<Controller
			name={`suborders.${suborderIndex}.data.services.${entityId}.amount`}
			control={control}
			render={({ field }) => {
				const numberedValue = Number(field.value);

				return (
					<Counter
						value={isNaN(numberedValue) ? 1 : numberedValue}
						onQuantityChange={(v) => {
							field.onChange(v);
						}}
						onBlur={() => {
							if (!field.value) {
								field.onChange(1);
							}
						}}
						className={styles.servicesCounter}
					/>
				);
			}}
		/>
	);
};

export default ServiceCounter;
