import { ExternalControlledMultipleSelectProps, SelectOption } from '../types';
import { getOptionLabel } from '../utils';

export const useExternalControlledMultipleState = ({ value, setValue }: ExternalControlledMultipleSelectProps) => {
	const setSelectedOption = (newOption: (SelectOption & Record<string, unknown>) | string) => {
		const hasOption = value.some((option) => getOptionLabel(option) === getOptionLabel(newOption));
		if (hasOption) return;

		const patchedValue = [...value, newOption] as (SelectOption & Record<string, unknown>)[] | string[];

		setValue?.(patchedValue);
	};

	const handleUnselect = (label: string) => {
		const filtered = value.filter((option) => getOptionLabel(option) !== label) as (SelectOption & Record<string, unknown>)[] | string[];

		setValue?.(filtered);
	};

	return {
		selectedOption: value,
		setSelectedOption,
		onUnselect: handleUnselect,
	};
};
