import { useState } from 'react';

export const useActiveTab = () => {
	const [activeTab, setActiveTab] = useState<number>(0);

	const onSwitchTab = (index: number) => setActiveTab(index);
	const getSwitchTabHandler = (index: number) => () => setActiveTab(index);

	return {
		activeTab,
		onSwitchTab,
		getSwitchTabHandler,
	};
};
