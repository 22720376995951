import type { RowSelectionModel } from 'components/Table/lib/table/types/table';
import { useEffect, useState } from 'react';
import { localStorageService } from 'services/localStorageService';

export type UseSelectedRowsProps = {
	saveConfigKey: string;
};

export const useSelectedRows = (config?: UseSelectedRowsProps) => {
	const key = 'selection-rows::' + (config?.saveConfigKey ?? '');
	const needSave = !!config?.saveConfigKey;

	const [rowSelectionModel, setRowSelectionModel] = useState<RowSelectionModel>(needSave ? hydrateState(key) : {});

	useEffect(() => {
		if (needSave) {
			localStorageService.save(key, rowSelectionModel);
		}
	}, [rowSelectionModel, needSave]);

	return { rowSelectionModel, setRowSelectionModel };
};

function hydrateState(key: UseSelectedRowsProps['saveConfigKey']) {
	return function hydrate(): RowSelectionModel {
		const model = localStorageService.load<RowSelectionModel>(key);

		if (model) {
			return model;
		}
		const fallbackModel = {};

		return fallbackModel;
	};
}
