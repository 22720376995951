/* eslint-disable new-cap */
import clsx from 'clsx';
import Button from 'components/Button';
import ClickAwayListener from 'components/ClickAwayListener';
import type { VisibilityModel } from 'components/Table/lib/table/types/table';
import Toggle from 'components/Toggle';
import { breakPoints } from 'const';
import React, { useState } from 'react';
import MediaQuery from 'react-responsive';

import styles from './style.module.css';
import type { IProps } from './types';

const ColumnsSettingsMenu = <TData,>({ open, columns, className, visibilityModel, setColumns, onClose, style }: IProps<TData>) => {
	const [tempVisibilityModel, setTempVisibilityModel] = useState<VisibilityModel>(visibilityModel);
	const [isDirty, setIsDirty] = useState(false);

	const handleSaveChanges = () => {
		setColumns((prevModel) => ({ ...prevModel, ...tempVisibilityModel }));
		onClose();
	};

	const handleCancelChanges = () => {
		setTempVisibilityModel(visibilityModel);
		setIsDirty(false);
		onClose();
	};

	const visibilityChangeHandler = (id: string) => () => {
		setTempVisibilityModel((prevModel) => ({ ...prevModel, [id]: Boolean(!prevModel?.[id]) }));
		setIsDirty(true);
	};

	const drawerContent = (
		<>
			<h3>Налаштуйте стовпчики таблиці:</h3>

			<div className={styles.columnWrapper}>
				{columns
					?.filter((column) => !column?.meta?.isVisibleOnDemandOnly)
					.map((column) => {
						const isHidden = Boolean(tempVisibilityModel?.[column?.id]);

						return (
							column.enableHiding && (
								<div key={column.id} className={styles.rowBox}>
									<span>
										{column.meta?.icon}
										<span>{column?.meta?.getVisibilityToggleTitle?.()}</span>
									</span>

									<Toggle onChange={visibilityChangeHandler(column?.id)} checked={!isHidden} />
								</div>
							)
						);
					})}
			</div>

			<div className={styles.buttonsWrapper}>
				<Button text="Скасувати" variant="bordered" onClick={handleCancelChanges} />
				<Button text="Застосувати" variant="default" onClick={handleSaveChanges} disabled={!isDirty} className={styles.disableBtn} />
			</div>
		</>
	);

	return (
		open && (
			<>
				<MediaQuery minWidth={breakPoints.MOBILE} maxWidth={breakPoints.DESKTOP - 1}>
					<div className={clsx(styles.modal, className)} style={style}>
						<ClickAwayListener onClickAway={onClose}>
							<div className={clsx(styles.box)}>{drawerContent}</div>
						</ClickAwayListener>
					</div>
				</MediaQuery>

				<MediaQuery minWidth={breakPoints.DESKTOP}>
					<ClickAwayListener onClickAway={onClose}>
						<div className={clsx(styles.box, className)}>{drawerContent}</div>
					</ClickAwayListener>
				</MediaQuery>
			</>
		)
	);
};

export default ColumnsSettingsMenu;
