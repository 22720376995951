import { useRef, useState, useTransition } from 'react';
import { isNumber, isString } from 'utils/type-guards';

export type UseInsertColumnsProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	columns: any[];
	incrementOffset?: boolean;
	pivot: number | string;
};

export const useInsertColumns = ({ columns, pivot, incrementOffset = false }: UseInsertColumnsProps) => {
	const [patchedColumns, setPatchedColumns] = useState(columns);
	const [, startTransition] = useTransition();
	const pivotIndexRef = useRef<number>(isString(pivot) ? columns.findIndex((column) => column.accessor === pivot) : pivot);
	const stalePivotRef = useRef<number>(pivotIndexRef.current);

	const insertColumn = (position: number, newColumn: unknown) => {
		startTransition(() => {
			const updatedColumns = [...patchedColumns];
			updatedColumns.splice(position, 0, newColumn);
			setPatchedColumns(updatedColumns);
		});
	};

	const insertBefore = (newColumn: unknown, position?: string | number) => {
		const index = isString(position)
			? patchedColumns.findIndex((col) => col.accessor === position)
			: isNumber(position)
			? position
			: pivotIndexRef.current;
		insertColumn(index, newColumn);

		if (incrementOffset) {
			pivotIndexRef.current = pivotIndexRef.current - 1;
		}
	};

	const insertAfter = (newColumn: unknown, position?: string | number) => {
		const index = isString(position)
			? patchedColumns.findIndex((col) => col.accessor === position)
			: isNumber(position)
			? position
			: pivotIndexRef.current;
		insertColumn(index + 1, newColumn);

		if (incrementOffset) {
			pivotIndexRef.current = pivotIndexRef.current + 1;
		}
	};

	const unInsert = (position: string | number) => {
		startTransition(() => {
			const index = isString(position) ? patchedColumns.findIndex((col) => col.accessor === position) : position;
			const updatedColumns = patchedColumns.filter((_, idx) => idx !== index);
			setPatchedColumns(updatedColumns);

			if (incrementOffset) {
				pivotIndexRef.current = pivotIndexRef.current - 1;
			}
		});
	};

	return { insertBefore, insertAfter, unInsert, columns: patchedColumns, stalePivot: stalePivotRef.current };
};
