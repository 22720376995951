import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';
import type { IProps } from './types';

const Mobile: React.FC<IProps> = ({ columns, title, data, onRowClick, getActiveRow }) => {
	return (
		<table className={clsx(styles.table, styles.mobile)}>
			<caption className={styles.title}>{title}</caption>

			<thead>
				<tr>
					{columns.map((column, colIndex: number) => (
						<th key={colIndex} title={column.header}>
							<span className={styles.clampOneLine}>{column.Header}</span>
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{data.map((row, rowIndex: number) => (
					<tr key={rowIndex} onClick={() => onRowClick?.(row)} className={clsx({ [styles.active]: getActiveRow?.(row) })}>
						{columns.map((column, colIndex: number) => (
							<td key={colIndex} title={row[column.accessor]}>
								<span className={styles.clampOneLine}>{row[column.accessor] || '-'}</span>
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default Mobile;
