import { Breadcrumb } from 'components/BreadCrumbs/types';
import Spinner from 'components/Spinner';
import { ROUTES_URLS } from 'const';
import { useOrderRouter } from 'hooks/useOrderRouter';
import type { Client } from 'models/client';
import Clients from 'pages/Clients';
import React from 'react';
import { useLazyGetClientByIdQuery } from 'store/reducers/clients/clientsSliceApi';
import { useGetOrganizationsOptionListQuery, useLazyGetWhoamISafeQuery } from 'store/reducers/users/usersSliceApi';
import { bakeOrderBreadcrumbs } from 'utils/orders';
import { prepareUrl } from 'utils/shared';

import { useOrderOperationMethods } from '../hooks/useOrderOperationMethods';

const OrderSelectClientModule: React.FC = () => {
	const orderRouter = useOrderRouter();
	const [getInfoAboutMe, { isFetching: isMeFetching }] = useLazyGetWhoamISafeQuery();
	const { data: organizationList } = useGetOrganizationsOptionListQuery();
	const [getClientById, { isFetching: isClientFetching }] = useLazyGetClientByIdQuery<Client & { isFetching: boolean }>();
	const { addClientToNewOrder, getValues } = useOrderOperationMethods();

	const orderId = getValues(`suborders.${0}.data.id`);

	const onRowClick = async (client: Client) => {
		const [me, fullClient] = await Promise.all([getInfoAboutMe().unwrap(), getClientById(client?.id).unwrap()]);
		const [organization] = organizationList ?? [];

		addClientToNewOrder({ client: fullClient, fallbackManager: me, organization });
		orderRouter.toAddProducts({ isNew: true });
	};

	const breadcrumbs: Breadcrumb[] = [{ label: 'Заявки', href: prepareUrl(ROUTES_URLS.ORDERS) }, bakeOrderBreadcrumbs({ id: orderId })];
	const needShowSpinner = isClientFetching || isMeFetching;

	return (
		<>
			<Clients searchOnClick orderPageView selectable={false} breadcrumbs={breadcrumbs} onRowClick={onRowClick} />

			{needShowSpinner && <Spinner />}
		</>
	);
};

export default OrderSelectClientModule;
