import clsx from 'clsx';
import Button from 'components/Button';
import Drawer from 'components/Drawer';
import MainSearch from 'components/MainSearch';
import { MainSearchAutocomplete } from 'components/MainSearch/components/MainSearchAutocomplete';
import { VoiceSearchButton } from 'components/MainSearch/components/VoiceSearchButton';
import Modal from 'components/Modal';
import ModalBody from 'components/Modal/ModalBody';
import ModalHeader from 'components/Modal/ModalHeader';
import Textarea from 'components/Textarea';
import { breakPoints } from 'const';
import { useBoolean } from 'hooks/useBoolean';
import { useOrderRouter } from 'hooks/useOrderRouter';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import BottomPanel from 'layouts/PageLayout/BottomPanel';
import { CatalogueProduct, CatalogueProductWithAmount } from 'models/product/catalogue-product';
import { useOrderOperationMethods } from 'pages/OrderRework/hooks/useOrderOperationMethods';
import { useTypedOrderControllerFromContext } from 'pages/OrderRework/OrderController';
import React from 'react';
import { Controller } from 'react-hook-form';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import PlusIcon from 'static/images/plus.svg';
import PlusBlue from 'static/images/plus-blue.svg';
import TrashIcon from 'static/images/trash.svg';
import { useGetCatalogueProductsQuery } from 'store/reducers/orders/ordersSliceApi';
import { isObject, isString } from 'utils/type-guards';

import styles from './styles.module.css';
import type { IProps } from './types';

const useGetGoodsAsOptions = (queryArg: string | string[]) => {
	const query = isString(queryArg) ? queryArg : queryArg[0];
	const { data, isFetching, isLoading } = useGetCatalogueProductsQuery(query);

	return {
		data,
		isFetching,
		isLoading,
	};
};

const transformQuery = (item: unknown) => {
	if (isObject(item) && 'id' in item && 'title' in item) {
		return { id: item.id as string, title: item.title as string, payload: item };
	}

	return { id: '', title: '' };
};

const EmptyOrder: React.FC<IProps> = ({ className, suborderIndex }) => {
	const { addEntityToOrder } = useOrderOperationMethods();
	const router = useOrderRouter();
	const popupInterface = useBoolean();
	const { control } = useTypedOrderControllerFromContext();
	const popupInterfaceToggle = useStopPropagationCallback(popupInterface.toggle);

	const isMobile = useMediaQuery({ maxWidth: breakPoints.MOBILE - 1 });

	const handleAddProductClick = () => {
		router.toAddProducts();
	};

	const onProductAdd = (product: CatalogueProduct) => {
		const candidate = { ...product, amount: 1 } as CatalogueProductWithAmount;
		addEntityToOrder({ candidates: [candidate], entityName: 'products', to: 0 });
	};

	const popupContent = (
		<Controller
			name={`suborders.${suborderIndex}.data.note`}
			control={control}
			render={({ field }) => (
				<>
					<Textarea
						name="comment"
						{...(!isMobile && { label: 'Додати коментар' })}
						placeholder="Наприклад, про компанію з якою ви працюєте"
						className={clsx(styles.textarea, { [styles.small]: isMobile })}
						setValue={field.onChange}
						value={field.value}
					/>

					<div className={styles.controlsWrapper}>
						<Button onClick={() => field.onChange('')} icon={TrashIcon} variant="default" text="Видалити" background="#D71B87" />
						<Button icon={PlusIcon} variant="default" text="Зберегти" />
					</div>
				</>
			)}
		/>
	);

	return (
		<div className={clsx(styles.wrapper, className)}>
			<MainSearch queryKey="search" useLoadAsyncDataQuery={useGetGoodsAsOptions} transformQueryResult={transformQuery}>
				<VoiceSearchButton />
				<MainSearchAutocomplete
					placeholder="Знайти товар..."
					onSelect={(item) => {
						onProductAdd(item.payload);
					}}
				/>
			</MainSearch>

			<div className={styles.subWrapper}>
				<p className={clsx('text-md-semibold', styles.addFirstProduct, styles.empty)}>Заявка порожня...</p>
				<p className={clsx(styles.addFirstProduct, styles.empty)}>Додайте перший товар до списку</p>

				<div className={styles.controlsWrapper}>
					<MediaQuery minWidth={breakPoints.MOBILE - 1}>
						<Button
							text="Додати коментар"
							onClick={popupInterfaceToggle}
							icon={PlusBlue}
							variant="rounded"
							background="#ffffff"
							hoverBg="#000"
							className={styles.inverseButton}
						/>
						<Button
							text="Додати товар"
							onClick={handleAddProductClick}
							icon={PlusIcon}
							variant="rounded"
							background="#2E90FA"
							hoverBg="#000"
							className={styles.addProductButton}
							disableClassName={styles.disableBtn}
						/>
					</MediaQuery>

					<MediaQuery minWidth={breakPoints.MOBILE}>
						<Drawer open={popupInterface.isOn} title="Додати" onClose={popupInterfaceToggle}>
							{popupContent}
						</Drawer>
					</MediaQuery>

					<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
						<Modal open={popupInterface.isOn} onClose={popupInterfaceToggle} className={styles.modalOverlay}>
							<ModalHeader title="Додати коментар" onXCloseClick={popupInterfaceToggle} />
							<ModalBody className={clsx(styles.modalBody, 'hide-scroll-bar')}>{popupContent}</ModalBody>
						</Modal>
					</MediaQuery>
				</div>

				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<BottomPanel className={styles.bottomPanel}>
						<Button
							text="Додати коментар"
							onClick={popupInterfaceToggle}
							icon={PlusIcon}
							variant="default"
							background="#ffffff"
							hoverBg="#000"
						/>
						<Button
							text="Додати товар"
							onClick={handleAddProductClick}
							icon={PlusIcon}
							variant="default"
							background="red"
							hoverBg="#000"
							className={styles.bottomPanelAddProductButton}
						/>
					</BottomPanel>
				</MediaQuery>
			</div>
		</div>
	);
};

export default EmptyOrder;
