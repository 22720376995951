import React from 'react';
import { ReactComponent as CalculatorIcon } from 'static/images/plus-minus.svg';

import styles from './styles.module.css';

const CalculatorButton = () => {
	return (
		<button type="button" className={styles.button}>
			<CalculatorIcon />
		</button>
	);
};

export default CalculatorButton;
