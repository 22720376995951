import { ROUTES_URLS } from 'const';
import React from 'react';
import { Navigate, Outlet, useLoaderData } from 'react-router-dom';

const RestrictedRoute = () => {
	const token = useLoaderData();

	return !token ? <Outlet /> : <Navigate replace to={ROUTES_URLS.HOME} />;
};

export default RestrictedRoute;
