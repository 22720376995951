import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { IService } from 'models/IServices';
import type { RootState } from 'store';
import { formatNumberToUAH, kVolumeFormatter, kWeightFormatter, toArray } from 'utils/shared';

import { selectAllOrganizations } from '../users/selectors';
import type { ProductsCatalogue } from './types';
import { createCatalogueItem, groupServicesByType } from './utils';

const rootState = (state: RootState) => state;
const orderPreviewerSliceStateSelector = createDraftSafeSelector(rootState, (state) => state.orderViewer);
const selectSelf = createDraftSafeSelector(rootState, (state) => state.orderViewer);

/*
 * selects orders stats and formats them
 */
const formattedTotalPrice = createDraftSafeSelector(selectSelf, (state) => {
	return formatNumberToUAH(state.sum);
});
const formattedTotalWeight = createDraftSafeSelector(selectSelf, (state) => `Вага - ${kWeightFormatter(state.totalWeight, 2)}`);
const formattedTotalVolume = createDraftSafeSelector(selectSelf, (state) => `Об’єм - ${kVolumeFormatter(state.totalVolume, 2)}`);

const statsSelectors = [formattedTotalPrice, formattedTotalWeight, formattedTotalVolume];
export const selectFormattedOrderStats = createDraftSafeSelector(statsSelectors, (totalPrice, totalWeight, totalVolume, weight, volume) => {
	return {
		totalPrice,
		totalWeight,
		totalVolume,
		weight,
		volume,
	};
});

const orderFallbackData = { products: [], services: {}, suborders: [], original: {} };

/*
 * selects ETALON order - which is mean the ORDER has come from SERVER
 */
export const selectEtalonOrder = createDraftSafeSelector(selectSelf, (state) => {
	if (!state.etalonOrder) return orderFallbackData;

	const { products, services, suborders } = state.etalonOrder;

	const etalonOrderData = { products, services, suborders, original: state.etalonOrder };

	return etalonOrderData;
});

const draftOrderFallbackData = Object.assign(orderFallbackData, { selectedProducts: [], selectedServices: [] });

/*
 * selects DRAFT order - which a writable copy of ETALON order
 */
export const selectDraftOrder = createDraftSafeSelector(selectSelf, (state) => {
	if (!state.draftOrder) return draftOrderFallbackData;

	const { products, services, suborders, selectedProducts, selectedServices } = state.draftOrder;

	const draftOrderData = { products, services, suborders, selectedProducts, selectedServices, original: state.draftOrder };

	return draftOrderData;
});
/*
 * selects SUBORDERS
 */
export const selectSuborders = createDraftSafeSelector(selectSelf, (state) => state?.suborders ?? []);
export const selectPaintToningSuborder = createDraftSafeSelector(selectSuborders, (suborders) =>
	suborders.find((suborder) => suborder.type === 'paint_toning'),
);

/*
 * selects index based values
 */
export const selectActiveSuborderIndex = createDraftSafeSelector(selectSelf, (state) => state.activeSuborderIndex);
export const selectActiveSuborderCategoryIndex = createDraftSafeSelector(selectSelf, (state) => state.activeCategoryTabIndex);

/*
 * selects limits on suborder creation
 */
export const selectSubordersCountLimit = createDraftSafeSelector(selectSelf, (state) => state.subordersLimit);
export const selectPaintToningSubordersCountLimit = createDraftSafeSelector(selectSelf, (state) => state.paintToningSubordersLimit);

/*
 * selects and groups SERVICES by type
 */
export const selectAllDraftOrderGroupedByTypeServices = createDraftSafeSelector(selectDraftOrder, (state) => {
	const services = state.services;
	const group = groupServicesByType(services);

	return group;
});
export const selectAllActiveSuborderGroupedServices = createDraftSafeSelector(
	[selectSuborders, selectActiveSuborderIndex],
	(suborders, activeIndex) => {
		const activeSuborder = suborders[activeIndex];
		const services = activeSuborder?.services;

		if (!services)
			return {
				regularServices: [] as IService[],
				paintToningServices: [] as IService[],
			};
		const group = groupServicesByType(services);

		return group;
	},
);

/*
 * select TRANSFERRING items info
 */
export const selectDisplayDetailsOfTransferringItem = createDraftSafeSelector(selectSelf, (state) => state.displayDetailsOfTransferringItem);

/*
 * selects ids of locked items user cannot edit or select
 */
export const selectLockedIdsList = createDraftSafeSelector(selectSelf, (state) => {
	const locker = state.locker;

	const idsList = toArray(locker).reduce((acc, vault) => acc.concat(vault), []);

	return idsList;
});

export const selectLockedServices = (id: string) =>
	createDraftSafeSelector(selectSelf, (state) => {
		const lockedServices = state.suborders
			.slice(1)
			.map((suborder, index) => {
				const services = suborder.services;

				if (!services) return null;

				let service;

				if (Array.isArray(services)) {
					service = services.find((item) => item.id === id);
				} else {
					service = services[id];
				}

				if (!service) return null;

				return {
					suborderIndex: index + 1,
					count: service.quantity,
				};
			})
			.filter(Boolean);

		return lockedServices;
	});

/*
 * selects boolean value telling we can toggle draft order type
 */
export const selectCanToggleDraftOrderType = createDraftSafeSelector(selectSelf, (state) => {
	return !state.hasAtLeastOnePaintToningSuborderOnServer;
});

export const selectLastForcedRerenderTimestamp = createDraftSafeSelector(selectSelf, (state) => state.lastForcedRerenderTimestamp);

export const selectSuborderToBeDeleteCandidateIndex = createDraftSafeSelector(selectSelf, (state) => state.suborderDeleteCandidate);

export const selectSplitOrderMetadata = createDraftSafeSelector(
	(state: RootState) => state,
	(state) => {
		const orderViewerState = state.orderViewer;
		const activeSuborderIndex = orderViewerState.activeSuborderIndex;

		const order = orderViewerState.draftOrder ?? {};
		const organizations = selectAllOrganizations(state);
		// @ts-ignore
		const { meta, contractType, client: name, id: clientId } = order?.client ?? {};
		const { department: rootOrderWarehouse } = meta ?? {};
		// @ts-ignore
		const responsible = orderViewerState.suborders?.[activeSuborderIndex]?.responsible;

		const warehouse = orderViewerState.suborders?.[activeSuborderIndex]?.stock ?? rootOrderWarehouse;
		const suborders = orderViewerState.suborders;
		const activeSuborderWarehouse = suborders[activeSuborderIndex]?.stock ?? warehouse;

		return {
			client: { label: name, value: clientId },
			contractType: { label: contractType?.label, value: contractType?.value },
			organization: organizations[0],
			responsible: { label: responsible?.name, value: responsible?.['1c_uuid'] },
			stock: { label: activeSuborderWarehouse?.title, value: activeSuborderWarehouse?.id },
		};
	},
);

export const selectMajorOption = (id: string, target?: 'order' | 'suborder') =>
	createDraftSafeSelector(selectSelf, (state) => {
		if (target && target === 'suborder') {
			const service = state.suborders[state.activeSuborderIndex]?.services?.[id];

			if (service && 'type' in service) {
				return service.type;
			}

			return '';
		} else {
			const service = state.draftOrder?.services[id] ?? {};

			if (service && 'type' in service) {
				return service.type;
			}

			return '';
		}
	});
export const selectDependantOption = (id: string, target?: 'order' | 'suborder') =>
	createDraftSafeSelector(selectSelf, (state) => {
		if (target && target === 'suborder') {
			const service = state.suborders[state.activeSuborderIndex]?.services?.[id];

			if (service && 'workType' in service) {
				return service.workType;
			}

			return '';
		} else {
			const service = state.draftOrder?.services[id] ?? {};

			if (service && 'workType' in service) {
				return service.workType;
			}

			return '';
		}
	});

export const selectItemsCandidatesToDelete = createDraftSafeSelector(orderPreviewerSliceStateSelector, (state) => state.itemsDeleteCandidates);
export const selectHasReachedPaintToningSubordersLimit = createDraftSafeSelector(
	orderPreviewerSliceStateSelector,
	(state) => state.paintToningSubordersCount === state.paintToningSubordersLimit,
);

// WAREHOUSE REMAINING SELECTORS
export const selectProductsCatalogue = createDraftSafeSelector(orderPreviewerSliceStateSelector, (state) => {
	const products = state.etalonOrder?.products ?? [];
	const initialCatalogue: ProductsCatalogue = {
		freeLeftovers: {},
		leftovers: {},
		reserves: {},
	};

	const productsCatalogue = products.reduce((catalogue, product) => {
		return {
			...catalogue,
			freeLeftovers: { ...catalogue.freeLeftovers, ...createCatalogueItem(product.freeLeftovers, product.id) },
			leftovers: { ...catalogue.leftovers, ...createCatalogueItem(product.leftovers, product.id) },
			reserves: { ...catalogue.reserves, ...createCatalogueItem(product.reserves, product.id) },
		};
	}, initialCatalogue);

	return productsCatalogue;
});
