import { flexRender } from '@tanstack/react-table';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { ReactComponent as ArrowDown } from 'static/images/arrow-down.svg';

import styles from './styles.module.css';
import type { MobileHeaderProps } from './types';

const MobileHeader: React.FC<MobileHeaderProps> = ({
	headerGroups,
	renderHeaders,
	bgColor = 'var(--primary-50)',
	className,
	gridCustomLayout,
	justify = 'unset',
	pb,
	pl,
	pr,
	pt,
	px,
	py,
}) => {
	const emptyColumns = renderHeaders.filter((column) => column === '.');
	const insertEmptyColumn = emptyColumns.length > 0;

	const style = useMemo(
		() => ({
			backgroundColor: bgColor,
			justifyContent: justify,
			paddingTop: pt,
			paddingBottom: pb,
			paddingRight: pr,
			paddingLeft: pl,
			paddingBlock: py,
			paddingInline: px,
			'--mobile-header-grid-layout': gridCustomLayout,
		}),
		[bgColor, justify, pt, pb, pr, pl, py, px, gridCustomLayout],
	);

	return (
		<thead>
			{headerGroups.map((headerGroup, index) => (
				<tr
					data-mobile-header
					style={style}
					key={index}
					className={clsx(
						styles.mobileHeader,
						{
							[styles.grid]: !!gridCustomLayout,
						},
						className,
					)}
				>
					{insertEmptyColumn && emptyColumns.map((_, idx) => <th key={'empty' + idx} className={styles.th} />)}

					{headerGroup.headers
						.filter((column) => renderHeaders.includes(column.id))
						.map((column) => {
							const component = column.id === 'id' ? <ArrowDown /> : flexRender(column.column.columnDef.header, column.getContext());

							return (
								<th key={column.id} className={styles.th} style={{ width: '100px' }}>
									<span className={styles.thContent}>{component}</span>
								</th>
							);
						})}
				</tr>
			))}
		</thead>
	);
};

export default MobileHeader;
