import clsx from 'clsx';
import Button from 'components/Button';
import React, { MouseEvent } from 'react';
import EditIcon from 'static/images/editWhite.svg';
import PlusIcon from 'static/images/plus.svg';

import styles from '../styles.module.css';
import type { SelectTableFooterProps } from './type';

const SelectTableFooter: React.FC<SelectTableFooterProps> = ({ className, onAddItem, onQuantityChange }) => {
	const handleQuantityChange = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		onQuantityChange?.();
	};
	const handleAddItem = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		onAddItem?.();
	};

	return (
		<td className={clsx(styles.footer, className)}>
			<Button
				variant="bordered"
				onClick={handleQuantityChange}
				text="Кількість"
				icon={EditIcon}
				className={clsx('text-sm-semibold', styles.controlsButton, styles.editButton)}
			/>
			<Button variant="default" onClick={handleAddItem} text="Додати" icon={PlusIcon} className={styles.controlsButton} />
		</td>
	);
};

export default SelectTableFooter;
