import clsx from 'clsx';
import Tooltip from 'components/Tooltip';
import { useAppSelector } from 'hooks/redux';
import { useOrderViewer } from 'hooks/useOrderViewer';
import React, { useEffect, useRef } from 'react';
import { ReactComponent as InfoIcon } from 'static/images/info-circle.svg';
import { selectLockedServices } from 'store/reducers/orderViewer/selectors';

import styles from './styles.module.css';
import { BaseGateProps, GateWithTooltipProps } from './types';

function useServiceGate({ itemId }: Pick<BaseGateProps, 'itemId'>) {
	const { utils, booleans } = useOrderViewer();
	const lockedServices = useAppSelector(selectLockedServices(itemId));

	const shouldRenderGate = utils.isOnMainOrderPage() ? lockedServices.length > 0 : lockedServices.length > 0 && booleans.isOnRootOrderTab;

	return { shouldRenderGate, lockedServices };
}

const GateWithToolTip: React.FC<GateWithTooltipProps> = ({ children, rowIndex, lockedServices }) => {
	const text = (
		<>
			<p className={styles.textLeft}>Розбито на замовлення:</p>
			{lockedServices.map((info, index) => (
				<p key={index} className={clsx(styles.semibold, styles.textLeft)}>
					Заявка {info.suborderIndex} - {info.count} шт
				</p>
			))}
		</>
	);

	const isFirstRow = rowIndex === 0;
	const tooltipPosition = isFirstRow ? 'bottom' : 'top';

	return (
		<>
			{children}

			<Tooltip withArrowPointer text={text} className={styles.tooltip} position={tooltipPosition}>
				<InfoIcon className={styles.infoIcon} />
			</Tooltip>
		</>
	);
};

export const LockedServiceCellGate = ({ children, rowIndex, itemId }: BaseGateProps) => {
	const { shouldRenderGate, lockedServices } = useServiceGate({ itemId });

	if (!shouldRenderGate) return children;

	return (
		<span className={clsx(styles.gate, styles.gap1)}>
			<GateWithToolTip lockedServices={lockedServices} rowIndex={rowIndex}>
				{children}
			</GateWithToolTip>
		</span>
	);
};
export const LockedServiceCellWithDeleteButtonGate = ({ children, itemId }: Omit<BaseGateProps, 'rowIndex'>) => {
	const wrapperRef = useRef<HTMLSpanElement>(null);
	const { shouldRenderGate } = useServiceGate({ itemId });

	useEffect(() => {
		if (wrapperRef.current && shouldRenderGate) {
			const button = wrapperRef.current.querySelector('button') as HTMLButtonElement;

			if (button) {
				button.disabled = true;
			}
		}
	}, [itemId, shouldRenderGate]);

	if (!shouldRenderGate) return children;

	return (
		<span ref={wrapperRef} className={styles.serviceGate}>
			{children}
		</span>
	);
};
export const LockedServiceCellCounterGate = ({ children, itemId }: Omit<BaseGateProps, 'rowIndex'>) => {
	const wrapperRef = useRef<HTMLSpanElement>(null);
	const { shouldRenderGate } = useServiceGate({ itemId });

	useEffect(() => {
		if (wrapperRef.current && shouldRenderGate) {
			const buttons = Array.from(wrapperRef.current.querySelectorAll('button')) as HTMLButtonElement[];

			buttons.forEach((button) => {
				if (button) {
					button.disabled = true;
				}
			});
		}
	}, [itemId, shouldRenderGate]);

	if (!shouldRenderGate) return children;

	return (
		<span ref={wrapperRef} className={styles.serviceGate}>
			{children}
		</span>
	);
};
export const LockedServiceCellSelectFieldGate = ({ children, itemId }: Omit<BaseGateProps, 'rowIndex'>) => {
	const wrapperRef = useRef<HTMLSpanElement>(null);
	const { shouldRenderGate } = useServiceGate({ itemId });

	useEffect(() => {
		if (wrapperRef.current && shouldRenderGate) {
			const button = wrapperRef.current.querySelector('button') as HTMLButtonElement;

			if (button) {
				button.style.opacity = '0.5';
				button.style.pointerEvents = 'none';
			}
		}
	}, [itemId, shouldRenderGate]);

	if (!shouldRenderGate) return children;

	return (
		<span ref={wrapperRef} className={styles.serviceGate}>
			{children}
		</span>
	);
};
