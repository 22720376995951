import clsx from 'clsx';
import React, { ComponentProps, ReactNode } from 'react';

import styles from './styles.module.css';

interface IProps extends ComponentProps<'strong'> {
	limit: number;
	count: number;
	text?: (charsLeft: number) => ReactNode;
}

const CharsCounter = ({ limit, count, text, className, ...restProps }: IProps) => {
	const charsLeft = limit - count;
	const content = text?.(charsLeft) ?? `${charsLeft} знаків`;

	return (
		<strong className={clsx('text-sm-regular color-grey-600', styles.charsCounter, className)} {...restProps}>
			{content}
		</strong>
	);
};

export default CharsCounter;
