import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';
import { SelectorButtonProps } from './types';

const SelectorButton: React.FC<SelectorButtonProps> = ({ label, className, icon: Icon, onClick, disabled }) => {
	return (
		<button type="button" onClick={onClick} className={clsx(styles.button, className)} disabled={disabled}>
			<Icon />
			{label}
		</button>
	);
};

export default SelectorButton;
