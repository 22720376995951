import clsx from 'clsx';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as AscIcon } from 'static/images/asc.svg';
import { ReactComponent as DescIcon } from 'static/images/desc.svg';
import { ReactComponent as ShuffleIcon } from 'static/images/shuffle.svg';

import styles from './styles.module.css';
import type { SortColumnMenuProps } from './types';

const SortColumnMenu = ({ onClick, sortColumnKey }: SortColumnMenuProps) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const handleSortClick = (sortDirection: 'asc' | 'desc' | '') => (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		const newSearchParams = new URLSearchParams(searchParams);

		if (sortDirection === '') {
			newSearchParams.delete('sortDirection');
			newSearchParams.delete('sortBy');
		} else {
			newSearchParams.set('sortDirection', sortDirection);
			newSearchParams.set('sortBy', sortColumnKey);
		}

		setSearchParams(newSearchParams.toString());

		onClick?.();
	};

	return (
		<span className={clsx(styles.sortMenu)}>
			<button
				type="button"
				onClick={handleSortClick('')}
				className={clsx(styles.button, { [styles.isActive]: !searchParams.get('sortDirection') })}
			>
				<ShuffleIcon />
				<span>Звичайне сортування</span>
			</button>

			<button
				type="button"
				onClick={handleSortClick('asc')}
				className={clsx(styles.button, { [styles.isActive]: searchParams.get('sortDirection') === 'asc' })}
			>
				<AscIcon />
				<span>Сортувати за збільшенням</span>
			</button>

			<button
				type="button"
				onClick={handleSortClick('desc')}
				className={clsx(styles.button, { [styles.isActive]: searchParams.get('sortDirection') === 'desc' })}
			>
				<DescIcon />
				<span>Сортувати за зменшенням</span>
			</button>
		</span>
	);
};

export default SortColumnMenu;
