import clsx from 'clsx';
import Button from 'components/Button';
import Drawer from 'components/Drawer';
import CancelReservationErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/CancelReservationErrorAlertDialogue';
import ReservationErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/ReservationErrorAlertDialogue';
import OrderAdditionalDrawer from 'components/OrderPageComponents/OrderAdditionalDrawer';
import Spinner from 'components/Spinner';
import { ErrorToast } from 'components/Toast';
import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import { useOrderStatus } from 'hooks/orders';
import { useBoolean } from 'hooks/useBoolean';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { Order } from 'models/order';
import { useOrderNotifications } from 'pages/OrderRework/hooks/useOrderNotifications';
import { useOrderOperationMethods } from 'pages/OrderRework/hooks/useOrderOperationMethods';
import { Can } from 'pages/OrderRework/OrderAbility/provider';
import { OrderControllerState } from 'pages/OrderRework/OrderController';
import { transformOrderToCreateOrderDTO, transformOrderToUpdateOrderDTO } from 'pages/OrderRework/OrderController/lib/dto';
import React, { lazy, Suspense } from 'react';
import File from 'static/images/file-attachment-02.svg';
import Lock from 'static/images/lock.svg';
import Plus from 'static/images/plusBig.svg';
import Save from 'static/images/save-double.svg';
import Truck from 'static/images/truck-white.svg';
import {
	useCreateOrderMutation,
	useSetOrderFromReserveMutation,
	useSetOrderOnReserveMutation,
	useUpdateOrderMutation,
} from 'store/reducers/orders/ordersSliceApi';
import { checkIfOrderIsReserved } from 'utils/shared';

import styles from './style.module.css';
import { IProps } from './types';

const BusinessOffer = lazy(() => import('components/BusinessOffer'));
const Modal = lazy(() => import('components/Modal'));
const ModalHeader = lazy(() => import('components/Modal/ModalHeader'));

const OrderTopBar: React.FC<IProps> = ({ onRequestLogistics }) => {
	const { getValues, handleSubmit, markAsSavedOnServer } = useOrderOperationMethods();

	const [updateOrderAsync, updateOrderRequest] = useUpdateOrderMutation();
	const [createOrderAsync, createOrderRequest] = useCreateOrderMutation();
	const [setOnReserveAsync, onReserveRequest] = useSetOrderOnReserveMutation();
	const [setFromReserveAsync, fromReserveRequest] = useSetOrderFromReserveMutation();

	const dialogue = useOrderAlertDialogue();
	const drawerInterface = useBoolean();
	const businessOfferInterface = useBoolean();
	const notify = useOrderNotifications();

	const drawerInterfaceToggle = useStopPropagationCallback(drawerInterface.toggle);
	const businessOfferInterfaceToggle = useStopPropagationCallback(businessOfferInterface.toggle);

	const orderStatus = useOrderStatus();
	const isReservedOrder = checkIfOrderIsReserved(orderStatus);
	const orderId = getValues(`suborders.${0}.data.id`);

	const handleMakeBusinessOfferSafe = (can: boolean) => {
		if (!can) {
			dialogue.open('businessOfferIsNotAvailable');
		} else {
			businessOfferInterface.toggle();
		}
	};

	const handleOrderSave = handleSubmit(async (values: OrderControllerState) => {
		dialogue.open('saveMainOrder', {
			onSubmit: async () => {
				const order = values?.suborders?.[0]?.data ?? {};
				const isNewOrder = !order.id;

				if (isNewOrder) {
					const createOrderDto = transformOrderToCreateOrderDTO(order);
					await createOrderAsync(createOrderDto).unwrap();
					markAsSavedOnServer({ suborderIndexes: [0] });
				} else {
					const updateOrderDTO = transformOrderToUpdateOrderDTO(order);
					await updateOrderAsync(updateOrderDTO).unwrap();
				}

				notify.successOrderSave();
			},
		});
	});

	const handleOrderReservation = handleSubmit(async (values: OrderControllerState) => {
		const isReserved = values.suborders?.[0].data.isReserved;

		if (isReserved) {
			dialogue.open('mainOrderCancelReservation', {
				onSubmit: async () => {
					const order = values?.suborders?.[0]?.data ?? {};

					const updateOrderDTO = transformOrderToUpdateOrderDTO(order);
					await setFromReserveAsync(updateOrderDTO as unknown as Order).unwrap();

					notify.successOrderCancelReservation();
				},
				onCancel: () => dialogue.close(),
			});
		} else {
			dialogue.open('mainOrderReservation', {
				onSubmit: async () => {
					const order = values?.suborders?.[0]?.data ?? {};

					const updateOrderDTO = transformOrderToUpdateOrderDTO(order);
					await setOnReserveAsync(updateOrderDTO as unknown as Order).unwrap();

					notify.successOrderReservation();
				},
				onCancel: () => dialogue.close(),
			});
		}
	});

	const handleOrderReservationSafe = useStopPropagationCallback(handleOrderReservation);
	const handleOrderSaveSafe = useStopPropagationCallback(handleOrderSave);

	return (
		<>
			<div className={styles.topBarWrapper}>
				<Can passThrough I="click" on={`order.${0}.request-logistic`}>
					{(can) => (
						<Button
							onClick={onRequestLogistics}
							icon={Truck}
							variant="rounded"
							background={'#2E90FA'}
							hoverBg="#000"
							className={clsx(styles.rounded, { ['disabled-button']: !can })}
							disabled={!can}
						/>
					)}
				</Can>
				<Can passThrough I="click" on={`order.${0}.save`}>
					{(can) => (
						<Button
							onClick={handleOrderSaveSafe}
							icon={Save}
							variant="rounded"
							background={'#d71b87'}
							hoverBg="#000"
							className={clsx(styles.rounded, { ['disabled-button']: !can })}
							disabled={!can}
						/>
					)}
				</Can>
				<Can passThrough I="click" on={`order.${0}.business-offer`}>
					{(can) => (
						<Button
							variant="rounded"
							background={'#2E90FA'}
							hoverBg="#000"
							onClick={() => handleMakeBusinessOfferSafe(can)}
							className={clsx(styles.rounded, { ['disabled-button']: !can })}
							icon={File}
							disabled={!can}
						/>
					)}
				</Can>
				<Can passThrough I="click" on={`order.${0}.reserve`}>
					{(can) => (
						<Button
							background="var(--error-50)"
							onClick={handleOrderReservationSafe}
							icon={Lock}
							variant="rounded"
							className={clsx(styles.reserv, styles.rounded, { [styles.active]: isReservedOrder, ['disabled-button']: !can })}
							disabled={!can}
						/>
					)}
				</Can>
				<Can passThrough I="click" on={`order.${0}.drawer`}>
					{(can) => (
						<Button
							onClick={drawerInterfaceToggle}
							icon={Plus}
							variant="rounded"
							background={'var(--primary-500)'}
							hoverBg="#000"
							className={clsx(styles.btn, { ['disabled-button']: !can })}
							disabled={!can}
						/>
					)}
				</Can>
			</div>

			<Suspense fallback={<Spinner />}>
				<Modal
					open={businessOfferInterface.isOn}
					onClose={businessOfferInterfaceToggle}
					width="640px"
					maxWidth="unset"
					className={styles.labels}
				>
					<ModalHeader title={'Створити комерційну пропозицію'} onXCloseClick={businessOfferInterfaceToggle} />
					<span className={styles.divider}></span>

					<BusinessOffer orderId={orderId} />
				</Modal>
			</Suspense>

			<Suspense fallback={<Spinner />}>
				{drawerInterface.isOn && (
					<Drawer open={drawerInterface.isOn} title={'Додати'} onClose={drawerInterfaceToggle}>
						<OrderAdditionalDrawer />
					</Drawer>
				)}
			</Suspense>

			{createOrderRequest.isLoading && <Spinner />}
			{updateOrderRequest.isLoading && <Spinner />}
			{onReserveRequest.isLoading && <Spinner />}
			{fromReserveRequest.isLoading && <Spinner />}
			{/* @ts-ignore */}
			{createOrderRequest.isError && <ErrorToast message={createOrderRequest.error?.message} />}
			{/* @ts-ignore */}
			{updateOrderRequest.isError && <ErrorToast message={updateOrderRequest.error?.message} />}
			{onReserveRequest.isError && (
				<ReservationErrorAlertDialogue
					// @ts-ignore
					message={onReserveRequest.error.message}
					onClose={onReserveRequest.reset}
				/>
			)}
			{fromReserveRequest.isError && (
				<CancelReservationErrorAlertDialogue
					// @ts-ignore
					message={fromReserveRequest.error.message}
					onClose={fromReserveRequest.reset}
				/>
			)}
		</>
	);
};

export default OrderTopBar;
