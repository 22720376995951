import clsx from 'clsx';
import React from 'react';

import styles from './style.module.css';
import { IProps } from './types';

const RadioBtn: React.FC<IProps> = ({ label, value, checked, className, onChange }) => {
	return (
		<div className={clsx(styles.radioButtonContainer, className)}>
			<label className={clsx(styles.radioButton, { [styles.checked]: checked })}>
				<input type="radio" value={value} checked={checked} onChange={onChange} className={styles.radioInput} />
				{checked && <div className={styles.innerCircle} />}
			</label>
			<span className={styles.label}>{label}</span>
		</div>
	);
};

export default RadioBtn;
