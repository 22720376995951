import { useState } from 'react';

type State = 'brand:applied' | 'category:applied' | 'brand:opened' | 'idle';
type StateLoader = () => State;

export const useStateMachine = (initialState?: State | StateLoader) => {
	const [state, setState] = useState<State>(initialState ?? 'idle');

	return {
		state,
		set: setState,
		isIdle: state === 'idle',
		isBrandFilterApplied: state === 'brand:applied',
		isCategoryFilterApplied: state === 'category:applied',
		isBrandMenuOpened: state === 'brand:opened',
	};
};
