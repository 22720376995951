import clsx from 'clsx';
import Tooltip from 'components/Tooltip';
import React, { useState } from 'react';

import { ReactComponent as FoldIcon } from '../../../../static/images/fold.svg';
import { ReactComponent as UnfoldIcon } from '../../../../static/images/unfold.svg';
import styles from './styles.module.css';
import type { IProps } from './types';

export const FoldButton = ({ onClick, className }: IProps) => {
	const [isActive, setIsActive] = useState(false);

	const handleClick = () => {
		setIsActive(!isActive);
		onClick?.();
	};

	const text = isActive ? 'Розгорнути панель фільтрів' : 'Згорнути панель фільтрів';

	return (
		<Tooltip text={text} className={clsx(styles.hideShowButton, className)}>
			<button type="button" onClick={handleClick}>
				{isActive ? <UnfoldIcon /> : <FoldIcon />}
			</button>
		</Tooltip>
	);
};
