import { createColumnHelper } from '@tanstack/react-table';
import Table from 'components/Table';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import type { SavePrice } from 'models/price/save-price';
import React, { useMemo } from 'react';
import { ReactComponent as Delete } from 'static/images/trash.svg';

import type { IProps } from './types';

const columnHelper = createColumnHelper<SavePrice>();

const PriceTable: React.FC<IProps> = ({ products }) => {
	const initialColumns = useMemo(
		() => [
			columnHelper.accessor('id', {
				id: 'id',
				header: () => <HeaderCell>{'№'}</HeaderCell>,
				cell: (cell) => <RegularCell>{`${cell.row.index + 1}.`}</RegularCell>,
				size: 56,
				meta: {
					getCanRenderOnMobile: () => false,
				},
			}),
			columnHelper.accessor((row) => row.code, {
				id: 'code',
				header: () => <HeaderCell>Код</HeaderCell>,
				cell: (cell) => <RegularCell justify="start">{cell.getValue()}</RegularCell>,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Код,',
				},
			}),
			columnHelper.accessor((row) => row.name, {
				id: 'name',
				header: () => <HeaderCell>Товари цінової групи</HeaderCell>,
				cell: (cell) => <RegularCell justify="start">{cell.getValue()}</RegularCell>,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Товари цінової групи',
				},
			}),
			columnHelper.accessor((row) => row.units, {
				id: 'units',
				header: () => <HeaderCell>Од-ці</HeaderCell>,
				cell: (cell) => <RegularCell justify="start">{cell.getValue()}</RegularCell>,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Од-ці',
				},
			}),
			columnHelper.accessor((row) => row.price, {
				id: 'price',
				header: () => <HeaderCell>Ціна товару</HeaderCell>,
				cell: (cell) => <RegularCell justify="start">{cell.getValue()}</RegularCell>,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Ціна товару',
				},
			}),
			columnHelper.accessor((row) => row.img ?? '', {
				id: 'img',
				header: () => <HeaderCell>Фото</HeaderCell>,
				cell: (cell) => (
					<RegularCell justify="start">
						<img src={cell.getValue()} alt={cell.row.original.name} />
					</RegularCell>
				),
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Фото',
				},
			}),
			columnHelper.accessor('actions', {
				id: 'actions',
				header: () => <HeaderCell>Дії</HeaderCell>,
				cell: () => (
					<RegularCell>
						<Delete />
					</RegularCell>
				),
				enableHiding: false,
				size: 100,
				meta: {
					getCanRenderOnMobile: () => false,
				},
			}),
			columnHelper.accessor('chooseClient', {
				id: 'chooseClient',
				header: () => <HeaderCell>Обрати клієнта:</HeaderCell>,
				size: 100,
				enableHiding: false,
				meta: {
					getCanRenderOnMobile: () => true,
				},
			}),
			columnHelper.accessor('sort', {
				id: 'sort',
				header: () => <HeaderCell>Сортувати А - Я</HeaderCell>,
				enableHiding: false,
				meta: {
					getCanRenderOnMobile: () => true,
				},
			}),
		],
		[],
	);

	return <Table allData={products} columns={initialColumns} selectable={false} />;
};

export default PriceTable;
