import { ExternalControlledMultipleAutocompleteProps, Suggestion } from '../types';
import { mergeSuggestions, resolveValue } from '../utils';

export const useMultipleExternalControlledAutocomplete = ({ value, setValue, tagLimit }: ExternalControlledMultipleAutocompleteProps) => {
	const setSuggestion = (newSuggestion: Suggestion | string) => {
		const patchedValue = mergeSuggestions(newSuggestion, value) as Suggestion[] | string[];

		setValue?.(patchedValue);
	};

	const handleUnselect = (id: string) => {
		const filtered = value.filter((option) => resolveValue(option) !== id) as Suggestion[] | string[];

		setValue?.(filtered);
	};

	const hasReachedLimit = value?.length === tagLimit;

	return {
		suggestion: value as Suggestion[] | string[],
		setSuggestion,
		onUnselect: handleUnselect,
		hasReachedLimit,
	};
};
