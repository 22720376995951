import 'static/styles/style.css';

import { flexRender } from '@tanstack/react-table';
import clsx from 'clsx';
import Cell from 'components/Table/TableComponents/BasicElements/Cell';
import ExpandedColumn from 'components/Table/TableComponents/BasicElements/ExpandedColumn';
import ExpandedHeader from 'components/Table/TableComponents/BasicElements/ExpandedHeader';
import Row from 'components/Table/TableComponents/BasicElements/Row';
import SelectTableFooter from 'components/Table/TableComponents/Footers/SelectTableFooter';
import React, { useMemo, useState } from 'react';

import TableCheckbox from '../../TableComponents/CheckboxColumn/TableCheckbox';
import styles from './style.module.css';
import type { IProps } from './types';

const SelectProductMobileTable = <TData,>({ row, onChangeQuantityClick, onAddProductClick }: IProps<TData>) => {
	const [open, setOpen] = useState(false);

	const toggleExpand = () => setOpen(!open);

	const allCells = row.getAllCells();
	const titleCell = useMemo(() => allCells?.find(({ column }) => column.id === 'title'), [allCells]);

	if (!open) {
		return (
			<Row gridCustomLayout="44px auto" bottomBorder="var(--gray-200)" onClick={toggleExpand}>
				<td className={clsx(styles.textCenter, 'text-sx-regular color-grey-600')}>{Number(row.id) + 1}.</td>
				<td className={clsx('text-sx-medium color-grey-700', styles.rowTitle)}>
					{flexRender(titleCell.column.columnDef.cell, titleCell.getContext())}
				</td>
			</Row>
		);
	}

	return (
		<ExpandedColumn className={styles.table}>
			<ExpandedHeader onClick={toggleExpand} onCollapse={toggleExpand}>
				<span className={styles.headerContent}>
					<TableCheckbox className={styles.checkbox} />
					<span className={styles.title}>{flexRender(titleCell.column.columnDef.cell, titleCell.getContext())}</span>
				</span>
			</ExpandedHeader>

			{row.getAllCells().map((cell, index) => {
				if (index < 2) return null;

				return (
					<Row
						as="td"
						gridCustomLayout="100px 1fr"
						key={`${cell.id}${index}`}
						bottomBorder="var(--gray-200)"
						gridTemplate="regular"
						vl="var(--gray-200)"
						highlight="even"
					>
						<Cell as="span" className={clsx('line-clamp', 'order-mobile-header-cell')}>
							{cell.column.columnDef.meta?.getMobileHeaderCell?.()}
						</Cell>
						<Cell as="span">{flexRender(cell.column.columnDef.cell, cell.getContext())}</Cell>
					</Row>
				);
			})}

			<SelectTableFooter
				className={styles.footer}
				onAddItem={() => onAddProductClick(row.original)}
				onQuantityChange={() => onChangeQuantityClick(row.original)}
			/>
		</ExpandedColumn>
	);
};

export default SelectProductMobileTable;
