import { useEffect, useState } from 'react';

import { InnerControlledSelectProps } from '../types';

export const useInnerControlledState = ({ defaultValue }: InnerControlledSelectProps) => {
	const [selectedOption, setSelectedOption] = useState(defaultValue);

	useEffect(() => {
		setSelectedOption(defaultValue);
	}, [defaultValue]);

	return {
		selectedOption,
		setSelectedOption,
		onUnselect: undefined,
	};
};
