import { defineAbility } from '@casl/ability';

import { SuborderTab } from '../OrderController';

interface UseDefineAbilityPayload {
	suborders: SuborderTab[];
}

export const useDefineOrderAbility = ({ suborders }: UseDefineAbilityPayload) => {
	const ability = defineAbility((can, cannot) => {
		suborders.forEach((suborder, index) => {
			if (suborder.data.status === 'reserve') {
				can('click', `order.${index}.reserve`);
			} else if (suborder.data.status === 'closed' || suborder.data.status === 'partially_shipped' || suborder.data.status === 'shipped') {
				cannot('manage', 'order.all');
			} else {
				can('change', `order.${index}.client`);
				can('change', `order.${index}.organization`);
				can('change', `order.${index}.contract`);
				can('change', `order.${index}.stock`);
				can('change', `order.${index}.responsible`);
				can('change', `order.${index}.isPaid`);
				can('change', `order.${index}.isWithoutPayment`);
				can('add', `order.${index}.products`);
				can('delete', `order.${index}.products`);
				can('change', `order.${index}.products.table`);
				can('add', `order.${index}.services`);
				can('delete', `order.${index}.services`);
				can('change', `order.${index}.services.table`);
				can('change', `order.${index}.columns`);
				can('change', `order.${index}.price`);
				can('split', `order.${index}`);
				can('click', `order.${index}.business-offer`);
				can('click', `order.${index}.reserve`);
				can('click', `order.${index}.save`);
				can('click', `order.${index}.request-logistic`);
				can('click', `order.${index}.drawer`);
				can('click', 'order.structure');
				can('split', 'order');

				if (suborder.data.isSaved) {
					cannot('delete', `suborder.${index}`);
				}

				// once root order was split on server - we cannot add or delete entities from it
				if (index === 0 && suborders?.slice(1).some((s) => s.data.isSaved)) {
					cannot('add', `order.${0}.products`);
					cannot('delete', `order.${0}.products`);
					cannot('add', `order.${0}.services`);
					cannot('delete', `order.${0}.services`);
					cannot('change', `order.${0}.price`);
					cannot('change', `order.${0}.products.table`);
					cannot('change', `order.${0}.services.table`);
					cannot('change', `order.${index}.client`);
					cannot('change', `order.${index}.organization`);
					cannot('change', `order.${index}.contract`);
					cannot('change', `order.${index}.stock`);
					cannot('change', `order.${index}.responsible`);
				}
				// once we preview suborder as main order - than we cannot split such order
				if (index === 0 && suborder.data.parentId) {
					cannot('split', 'order');
					can('add', `order.${0}.products`);
					can('delete', `order.${0}.products`);
					can('add', `order.${0}.services`);
					can('delete', `order.${0}.services`);
					can('change', `order.${0}.price`);
					can('change', `order.${0}.products.table`);
					can('change', `order.${0}.services.table`);
				}

				if (index === 0 && (!suborder.data.id || !suborder.data.isSaved)) {
					cannot('click', 'order.structure');
					can('click', 'order.business-offer-safe');
					cannot('click', `order.${index}.business-offer`);
				}
			}
		});
	});

	return ability;
};
