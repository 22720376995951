import type { UseMainSearchStateAdapterParams } from '../lib/types';
import { useInMemoryState } from './useInMemoryState';
import { useUrlSearchParamsState } from './useUrlSearchParamsState';

export const useMainSearchStateAdapter = ({ sharable, queryKey = 'search', delay = 300 }: UseMainSearchStateAdapterParams) => {
	if (sharable) {
		return useUrlSearchParamsState({ queryKey, delay });
	}

	return useInMemoryState({ delay, queryKey });
};
