import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { usersSliceApi } from './usersSliceApi';

export const selectAllManagers = createDraftSafeSelector(usersSliceApi.endpoints.getManagers.select(undefined), (response) => response.data ?? []);
export const selectAllWarehouses = createDraftSafeSelector(
	usersSliceApi.endpoints.getWarehouses.select(undefined),
	(response) => response.data ?? [],
);
export const selectAllOrganizations = createDraftSafeSelector(
	usersSliceApi.endpoints.getOrganizations.select(undefined),
	(response) => response?.data ?? [],
);

// MOCK
export const selectUserPosition = createDraftSafeSelector(usersSliceApi.endpoints.getOrganizations.select(undefined), () => 'supervisor' as const);
