import { SegmentIdSchema } from 'models/common/uuid';
import { z } from 'zod';

export const SegmentKeyType = z.union([z.literal('individual'), z.literal('legal')]).nullable();

export const SegmentSchema = z.object({
	id: SegmentIdSchema,
	title: z.string().min(1),
	type: SegmentKeyType,
});

export type SegmentKey = z.infer<typeof SegmentKeyType>;
export type Segment = z.infer<typeof SegmentSchema>;
