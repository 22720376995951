import { useLayoutEffect, useRef, useState } from 'react';

export const useElementWidth = <THTMLElement extends HTMLElement>() => {
	const [width, setWidth] = useState<number>(null);

	const ref = useRef<THTMLElement>(null);

	useLayoutEffect(() => {
		const updateElementWidth = () => {
			if (ref.current) {
				const { width: elWidth } = ref.current.getBoundingClientRect();

				setWidth(elWidth);
			}
		};

		updateElementWidth();

		window.addEventListener('resize', updateElementWidth);

		return () => {
			window.removeEventListener('resize', updateElementWidth);
		};
	}, []);

	return { elementRef: ref, width };
};
