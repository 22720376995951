import type { ColumnItem, VisibilityModel } from 'components/Table/lib/table/types/table';
import { useEffect, useState } from 'react';
import { localStorageService } from 'services/localStorageService';

export interface UseAdjustableColumnsProps {
	saveConfigKey: string;
	initial?: VisibilityModel;
}

export const useAdjustableColumns = <TData>(columns: ColumnItem<TData>[], { saveConfigKey, initial }: UseAdjustableColumnsProps) => {
	const key = 'visibility::' + saveConfigKey;
	const [visibilityModel, setVisibilityModel] = useState<VisibilityModel>(hydrateState(key, initial));

	useEffect(() => {
		localStorageService.save(key, visibilityModel);
	}, [visibilityModel]);

	return { visibilityModel, setVisibilityModel, visibilityModelSaveConfigKey: key };
};

function hydrateState(key: UseAdjustableColumnsProps['saveConfigKey'], initial: VisibilityModel) {
	return function hydrate(): VisibilityModel {
		const visibilityModel = localStorageService.load<VisibilityModel>(key);

		if (visibilityModel) {
			return visibilityModel;
		}
		const fallbackModel = { ...initial };

		return fallbackModel;
	};
}
