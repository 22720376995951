import clsx from 'clsx';
import React, { ForwardedRef, forwardRef } from 'react';

import styles from './styles.module.css';
import type { IProps } from './types';

const Toggle = ({ className, name, hideIcon = false, label, ...restProps }: IProps, ref: ForwardedRef<HTMLInputElement>) => {
	return (
		<div data-toggle className={clsx(styles.wrapper, className)}>
			<input id={name} name={name} ref={ref} type="checkbox" className={clsx(styles.input)} {...restProps} />
			<span className={styles.toggle} data-hide-icon={hideIcon} />

			{!!label && (
				<label className={clsx('text-sm-medium', 'color-grey-700', styles.label)} htmlFor={name}>
					{label}
				</label>
			)}
		</div>
	);
};

export default forwardRef(Toggle);
