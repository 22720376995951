import { ROUTES_URLS } from 'const';
import ModalStackRouterProvider from 'contexts/ModalStackRouterProvider';
import PageLayout from 'layouts/PageLayout';
import Page404 from 'pages/404';
import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import PageErrorBoundary from './components/ErrorBoundaries/PageErrorBoundary';
import RootErrorBoundary from './components/ErrorBoundaries/RootErrorBoundary';
import PrivateRoute from './components/PrivateRoute';
import RestrictedRoute from './components/RestrictedRoute';
import { isAuthenticatedHandler } from './handlers/auth';
import { SharedLayoutRoutes } from './PrivateRoutes';
import { AuthRoutes } from './restrictedRoutes';

const Home = lazy(() => import('pages/Home'));

export const router = createBrowserRouter([
	{
		element: <ModalStackRouterProvider />,
		ErrorBoundary: RootErrorBoundary,
		children: [
			{
				path: ROUTES_URLS.HOME,
				shouldRevalidate: () => false,
				children: [
					{
						loader: isAuthenticatedHandler,
						element: <PrivateRoute />,
						children: [
							{
								path: ROUTES_URLS.HOME,
								element: <Home />,
								shouldRevalidate: () => false,
								index: true,
							},
						],
					},
					{
						loader: isAuthenticatedHandler,
						element: <RestrictedRoute />,
						children: [...AuthRoutes],
					},
					{
						loader: isAuthenticatedHandler,
						element: <PrivateRoute />,
						children: [
							{
								element: <PageLayout />,
								errorElement: <PageErrorBoundary />,
								children: [...SharedLayoutRoutes],
							},
						],
					},
				],
			},
			{
				path: '*',
				element: <Page404 />,
			},
		],
	},
]);
