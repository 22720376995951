import type { PriceChangeOption } from 'models/IPriceChange';

export const PRICE_CHANGE_OPTIONS: PriceChangeOption[] = [
	{
		title: 'Встановити ціни по типу',
		type: 'dropdown',
		url: '/11',
		field: {
			name: 'price_type',
			multiple: false,
			items: [
				{ title: 'Вигідна ціна', value: 'e4ea81a3-8307-11ed-b8b4-932465dbe65a' },
				{ title: 'Вхід', value: '95d8c530-6303-11e3-8680-c52dd1806f02' },
				{ title: 'Мінімальна ОД', value: '82dd3937-2316-11ea-80d5-8107dcf40211' },
				{ title: 'Оптова', value: '2d8b7e50-6709-11e3-b37c-d8698ef02892' },
				{ title: 'Роздрібленний DIY', value: 'ef741b50-b356-11e3-af42-8b1ea7c04b02' },
			],
		},
	},
	{ title: 'Роздріб DIY + Вигідна ціна', type: 'click', url: '/22' },
	{ title: 'Програма лояльності', type: 'click', url: '/33' },
	{ title: 'Розподілити суму за сумами', shortTitle: 'Сума', type: 'simple_input', url: '/44', field: { name: 'by_sum_distribution' } },
	{
		title: 'Розподілити суму за кількістю',
		shortTitle: 'Кількість',
		type: 'simple_input',
		url: '/55',
		field: { name: 'by_quantity_distribution', withCalculator: true },
	},
	{ title: 'Встановити скидку', shortTitle: 'Sale', type: 'simple_input', url: '/66', field: { name: 'discount', withCalculator: true } },
	{ title: 'Змінити ціни на %', shortTitle: 'Відсоток', type: 'simple_input', url: '/77', field: { name: 'discount_in_per_cent' } },
	{
		title: 'Округлити ціні до',
		type: 'dropdown',
		url: '/88',
		field: {
			name: 'round',
			multiple: false,
			items: [
				{ title: '0.01', value: '0.01' },
				{ title: '0.05', value: '0.05' },
				{ title: '0.1', value: '0.1' },
				{ title: '0.5', value: '0.5' },
				{ title: '1', value: '1' },
				{ title: '5', value: '5' },
				{ title: '10', value: '10' },
				{ title: '50', value: '50' },
				{ title: '100', value: '100' },
			],
		},
	},
];
