import clsx from 'clsx';
import React, { ForwardedRef, forwardRef } from 'react';

import styles from './styles.module.css';
import type { IProps } from './types';

const Textarea = ({ label, className, name, value, setValue, ...restProps }: IProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
	return (
		<div data-textarea className={clsx(styles.wrapper, className)}>
			{!!label && (
				<label htmlFor={name} className={clsx('text-md-medium', styles.label)}>
					{label}
				</label>
			)}
			<textarea
				id={name}
				ref={ref}
				name={name}
				value={value}
				className={clsx(styles.textarea, 'no-user-agent-autofill-bg')}
				onChange={(e) => setValue(e.currentTarget.value)}
				{...restProps}
			/>
		</div>
	);
};

export default forwardRef(Textarea);
