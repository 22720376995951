import { useCallback, useEffect, useRef } from 'react';

export function useDebouncedCallbackWithCleanUp<A extends unknown[]>(callback: (...args: A) => void, wait: number) {
	// track args & timeout handle between calls
	const argsRef = useRef<A>();
	const timeout = useRef<ReturnType<typeof setTimeout>>();

	const cleanup = useCallback(() => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
	}, [timeout.current]);

	// make sure our timeout gets cleared if
	// our consuming component gets unmounted
	useEffect(() => cleanup, []);

	function debouncedCallback(...args: A) {
		// capture latest args
		argsRef.current = args;

		// clear debounce timer
		cleanup();

		// start waiting again
		timeout.current = setTimeout(() => {
			if (argsRef.current) {
				callback(...argsRef.current);
			}
		}, wait);
	}

	return { debouncedCallback, debounceCleanUp: cleanup };
}
