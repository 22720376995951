import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import localForage from 'localforage';
import type { OriginalPaginatedRequestResult, PaginatedRequestResult, RequestResult } from 'models/api';
import { transformToPaginatedRequestResult } from 'models/api/transform';
import { Whoami } from 'models/auth';
import { Option } from 'models/common/options';
import {
	EmployeeOptionSchema,
	EmployeePreview,
	EmployeePreviewSchema,
	OrganizationOptionSchema,
	ServerSideEmployeeOption,
	ServerSideOrganizationOption,
} from 'models/employee';
import type { IEmployee, IEmployeeCreateDTO, IEmployeePathDTO } from 'models/IUser';
import { Stock, StockOptionSchema } from 'models/stock';
import { API_URL } from 'services/api';
import { usersQueryKeys } from 'services/queryKeys';
import { logger } from 'utils/logger';

import apiClient from '../auth/apiClient';

const getAuthToken = async () => {
	try {
		const token = await localForage.getItem('token');
		return token;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error getting auth token:', error);
		return null;
	}
};

const axiosInstance = axios.create({
	baseURL: 'https://unitb-appback.alterego.biz.ua/api/v1',
});

export const usersSliceApi = createApi({
	reducerPath: 'users',
	baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
	tagTypes: [usersQueryKeys.users()],
	endpoints: (builder) => ({
		getEmployees: builder.query<PaginatedRequestResult<EmployeePreview[]>, string>({
			queryFn: async (queryParams) => {
				const authToken = await getAuthToken();
				try {
					const response = await apiClient.get<OriginalPaginatedRequestResult<EmployeePreview[]>>('/users', {
						params: queryParams,
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const result = transformToPaginatedRequestResult<EmployeePreview[]>(response.data);
					const validation = EmployeePreviewSchema.array().safeParse(result.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: {
								data: [],
								page: 1,
								pagesCount: 1,
							},
						};
					}

					return {
						data: {
							data: validation.data,
							page: result.page,
							pagesCount: result.pagesCount,
						},
					};
				} catch (error) {
					throw error;
				}
			},
			providesTags: (result) =>
				result?.data ? [usersQueryKeys.users(), usersQueryKeys.usersOnly(), ...result.data.map(({ id }) => usersQueryKeys.user(id))] : [],
		}),
		getEmployeeById: builder.query<IEmployee, string>({
			queryFn: async (id) => {
				const authToken = await getAuthToken();
				try {
					const response = await apiClient.get(`/users/${id}`, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
			providesTags: (_, __, id) => [usersQueryKeys.user(id)],
		}),
		createEmployee: builder.mutation<IEmployee, IEmployeeCreateDTO>({
			queryFn: async (data) => {
				const authToken = await getAuthToken();
				try {
					const response = await apiClient.post('/users', data, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 201) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
			invalidatesTags: [usersQueryKeys.usersOnly()],
		}),
		updateEmployee: builder.mutation<IEmployee, IEmployeePathDTO>({
			query: (employee) => ({
				url: `/users/${employee.id}`,
				method: 'PUT',
				body: employee,
			}),
			invalidatesTags: [usersQueryKeys.usersOnly()],
			async onQueryStarted(employee, { dispatch, queryFulfilled }) {
				const optimisticOrderUpdate = dispatch(
					usersSliceApi.util.updateQueryData('getEmployeeById', String(employee.id), (draft) => {
						Object.assign(draft ?? {}, employee);
					}),
				);
				try {
					await queryFulfilled;
				} catch {
					optimisticOrderUpdate.undo();
				}
			},
		}),
		getManagers: builder.query({
			query: () => '/managers',
			providesTags: () => [usersQueryKeys.user('MANAGERS')],
		}),
		getManagersOptions: builder.query({
			query: () => '/managers',
			transformResponse: (response) =>
				(response as Record<string, string>[])?.map((manager) => ({
					label: manager['Наименование'],
					value: manager['Наименование'],
				})),
		}),
		getWarehouses: builder.query<Option[], void>({
			queryFn: async () => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get<Stock[]>('/stocks', {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}
					const validation = StockOptionSchema.array().safeParse(response.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return { data: [] };
					}

					return {
						data: validation.data as Option[],
					};
				} catch (error) {
					throw error;
				}
			},
		}),
		getOrganizations: builder.query<unknown, string>({
			query: () => '/organizations',
			providesTags: () => [usersQueryKeys.user('ORGANIZATIONS')],
			transformResponse: (response) =>
				(response as Record<string, string>[])?.map((org) => ({
					label: org['Наименование'],
					value: org['ЗначениеId'],
				})),
		}),
		getInfoAboutMe: builder.query<Whoami, void>({
			queryFn: async () => {
				const authToken = await getAuthToken();
				try {
					const response = await apiClient.get('/auth/me', {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return {
						data: response.data as Whoami,
					};
				} catch (error) {
					throw error;
				}
			},
		}),
		getWhoamISafe: builder.query<Whoami, void>({
			queryFn: async () => {
				const authToken = await getAuthToken();
				try {
					const response = await apiClient.get('/auth/me', {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						const res = await apiClient.get('/auth/refresh');

						return {
							data: res.data,
						};
					}

					return {
						data: response.data as Whoami,
					};
				} catch (error) {
					throw error;
				}
			},
		}),
		getEmployeesOptionList: builder.query<Option[], void>({
			queryFn: async () => {
				const authToken = await getAuthToken();
				try {
					const response = await apiClient.get<RequestResult<ServerSideEmployeeOption[]>>('/users', {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = EmployeeOptionSchema.array().safeParse(response.data.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: [],
						};
					}

					return {
						data: validation.data as Option[],
					};
				} catch (error) {
					throw error;
				}
			},
		}),
		getOrganizationsOptionList: builder.query<Option[], void>({
			queryFn: async () => {
				const authToken = await getAuthToken();
				try {
					const response = await apiClient.get<RequestResult<ServerSideOrganizationOption[]>>('/organizations', {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = OrganizationOptionSchema.array().safeParse(response.data.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: [],
						};
					}

					return {
						data: validation.data as Option[],
					};
				} catch (error) {
					throw error;
				}
			},
		}),
	}),
});

export const {
	useGetEmployeeByIdQuery,
	useGetEmployeesQuery,
	useUpdateEmployeeMutation,
	useGetManagersQuery,
	useGetWarehousesQuery,
	useGetOrganizationsQuery,
	useGetManagersOptionsQuery,
	useCreateEmployeeMutation,
	useGetInfoAboutMeQuery,
	useLazyGetWhoamISafeQuery,
	useGetEmployeesOptionListQuery,
	useGetOrganizationsOptionListQuery,
} = usersSliceApi;
