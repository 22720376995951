import clsx from 'clsx';
import React from 'react';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';

import styles from './styles.module.css';
import type { IProps } from './types';

const SuborderTab: React.FC<IProps> = ({ title, variant, isActive, icon, gap = '8px', withCloseButton, onClick, onClose, error }) => {
	const isAddOrder = variant === 'add-order';

	const handleClose = (e: React.MouseEvent<SVGSVGElement>) => {
		e.stopPropagation();
		onClose?.();
	};

	return (
		<button
			type="button"
			data-variant={variant}
			className={clsx('text-md-semibold', styles.navigationButton, { [styles.active]: isActive, [styles.error]: error })}
			onClick={onClick}
			style={{ gap }}
		>
			{icon}
			{isAddOrder && <PlusIcon className={styles.plusIcon} />}
			{title}
			{!!withCloseButton && <XCloseIcon onClick={handleClose} className={styles.xCloseIcon} />}
		</button>
	);
};

export default SuborderTab;
