import type { Column } from '@tanstack/react-table';

export const getCommonPinningStyles = <TData>(column: Column<TData>): React.CSSProperties => {
	const isPinned = column.getIsPinned();

	return {
		left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
		right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
		opacity: 1,
		position: isPinned ? 'sticky' : 'relative',
		width: column.getSize(),
		zIndex: isPinned ? 1 : 0,
	};
};
