import type {
	PriceChangeOption,
	PriceChangeOptionWithDependantDropdown,
	PriceChangeOptionWithDependantInput,
	PriceChangeOptionWithoutDependantField,
} from 'models/IPriceChange';

export const isPriceChangeOptionWithDependantDropdown = (option: Partial<PriceChangeOption>): option is PriceChangeOptionWithDependantDropdown =>
	option.type === 'dropdown';

export const isPriceChangeOptionWithDependantInput = (option: Partial<PriceChangeOption>): option is PriceChangeOptionWithDependantInput =>
	option.type === 'simple_input';

export const isPriceChangeOptionWithoutDependantField = (option: Partial<PriceChangeOption>): option is PriceChangeOptionWithoutDependantField =>
	option.type === 'click';
