import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from 'services/api';
import { filterTemplatesQueryKeys } from 'services/queryKeys';

import { IFilterTemplates } from './types';

export const filterTemplatesSliceApi = createApi({
	reducerPath: 'filterTemplates',
	baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
	tagTypes: [filterTemplatesQueryKeys.filterTemplates()],
	endpoints: (builder) => ({
		getFilterTemplates: builder.query<IFilterTemplates[], undefined>({
			query: () => '/filter-templates',
			providesTags: () => [filterTemplatesQueryKeys.filterTemplates()],
		}),
		createFilterTemplate: builder.mutation({
			query: (data) => ({
				url: '/filter-templates',
				method: 'POST',
				body: data,
			}),
			async onQueryStarted(data, { dispatch, queryFulfilled }) {
				const optimisticFilterCreate = dispatch(
					// @ts-ignore
					filterTemplatesSliceApi.util.updateQueryData('getFilterTemplates', undefined, (draft) => {
						draft.push(data);
					}),
				);
				try {
					await queryFulfilled;
				} catch {
					optimisticFilterCreate.undo();
				}
			},
		}),
		deleteFilterTemplate: builder.mutation({
			query: (id) => ({
				url: `/filter-templates/${id}`,
				method: 'DELETE',
			}),
			// async onQueryStarted(id, { dispatch, queryFulfilled }) {
			// 	const optimisticFilterDelete = dispatch(
			// 		// @ts-ignore
			// 		filterTemplatesSliceApi.util.updateQueryData('getFilterTemplates', String(id), (draft) => {
			// 			const index = draft.findIndex((template) => template.id === id);
			// 			draft.splice(index, 1);
			// 		}),
			// 	);
			// 	try {
			// 		await queryFulfilled;
			// 	} catch {
			// 		optimisticFilterDelete.undo();
			// 	}
			// },
			invalidatesTags: () => [filterTemplatesQueryKeys.filterTemplates()],
		}),
	}),
});

export const { useGetFilterTemplatesQuery, useCreateFilterTemplateMutation, useDeleteFilterTemplateMutation } = filterTemplatesSliceApi;
