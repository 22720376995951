import TableCellInput from 'components/OrderCreatePageComponents/TableCellInput';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface OrderProductPriceInputProps {
	suborderIndex: number;
	entityId: string;
}

const OrderProductPriceInput: React.FC<OrderProductPriceInputProps> = ({ suborderIndex, entityId }) => {
	const { control, setValue, getValues } = useFormContext();
	const priceFieldName = `suborders.${suborderIndex}.data.products.${entityId}.price` as const;

	return (
		<Controller
			name={priceFieldName}
			control={control}
			render={({ field }) => {
				return (
					<TableCellInput
						value={field.value}
						onChange={(e) => {
							const allSuborders = getValues('suborders') ?? [];

							if (suborderIndex > 0) {
								const price = Number(e.currentTarget.value);

								const sumInAllEntityOccurrences = allSuborders.reduce((acc, suborder, index) => {
									if (index === 0) return acc;
									if (index !== suborderIndex) {
										return (acc +=
											Number(suborder.data.products[entityId].amount) * Number(suborder.data.products[entityId].price));
									}

									acc += Number(price) * Number(suborder.data.products[entityId].amount);
									return acc;
								}, 0);

								setValue(`suborders.${0}.data.products.${entityId}.sum`, String(sumInAllEntityOccurrences));
								allSuborders.forEach((_, index) => {
									setValue(`suborders.${index}.data.products.${entityId}.price`, String(price));
								});
								field.onChange(e);
							} else {
								const price = Number(e.currentTarget.value);
								const amount = Number(getValues(`suborders.${suborderIndex}.data.products.${entityId}.amount`));
								setValue(`suborders.${suborderIndex}.data.products.${entityId}.sum`, String(price * Number(amount)));
								allSuborders.forEach((_, index) => {
									setValue(`suborders.${index}.data.products.${entityId}.price`, String(price));
								});
								field.onChange(e);
							}
						}}
						onBlur={() => {
							if (!field.value) {
								field.onChange('0.00');
							}
						}}
					/>
				);
			}}
		/>
	);
};

export default OrderProductPriceInput;
