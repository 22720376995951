import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import options from './reducers/auth';
import { businessOfferSliceApi } from './reducers/businessOffer';
import { clientsSliceApi } from './reducers/clients/clientsSliceApi';
import { filterTemplatesSliceApi } from './reducers/filterTempates/filterTemplatesSliceApi';
import order from './reducers/order';
import createOrder from './reducers/orders';
import { ordersSliceApi } from './reducers/orders/ordersSliceApi';
import orderViewer from './reducers/orderViewer';
import { paintToningSliceApi } from './reducers/paintToning/paintToningApiSlice';
import { productsSliceApi } from './reducers/products/productsSliceApi';
import search from './reducers/search';
import { usersSliceApi } from './reducers/users/usersSliceApi';

const rootReducer = combineReducers({
	order,
	search,
	options,
	createOrder,
	orderViewer,
	[usersSliceApi.reducerPath]: usersSliceApi.reducer,
	[paintToningSliceApi.reducerPath]: paintToningSliceApi.reducer,
	[clientsSliceApi.reducerPath]: clientsSliceApi.reducer,
	[ordersSliceApi.reducerPath]: ordersSliceApi.reducer,
	[productsSliceApi.reducerPath]: productsSliceApi.reducer,
	[filterTemplatesSliceApi.reducerPath]: filterTemplatesSliceApi.reducer,
	[businessOfferSliceApi.reducerPath]: businessOfferSliceApi.reducer,
});

export const setupStore = () => {
	return configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			}).concat(
				ordersSliceApi.middleware,
				productsSliceApi.middleware,
				clientsSliceApi.middleware,
				usersSliceApi.middleware,
				filterTemplatesSliceApi.middleware,
				paintToningSliceApi.middleware,
				businessOfferSliceApi.middleware,
			),
	});
};

export const store = setupStore();

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
