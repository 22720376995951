import '../styles.css';

import clsx from 'clsx';
import type { ForwardedRef } from 'react';
import React, { forwardRef } from 'react';

import type { RowProps } from './types';

const Row = (
	{
		as = 'tr',
		children,
		className,
		onClick,
		highlight,
		highlightColor,
		vl,
		gridCustomLayout,
		gridTemplate,
		bottomBorder,
		bgColor,
		pl,
		pr,
		pb,
		pt,
		justify,
		align,
	}: RowProps,
	ref: ForwardedRef<HTMLTableRowElement | HTMLSpanElement>,
) => {
	const Tag = as;
	const elementRef = as === 'td' ? (ref as ForwardedRef<HTMLTableCellElement>) : (ref as ForwardedRef<HTMLTableRowElement>);

	return (
		<Tag
			// @ts-ignore
			ref={elementRef}
			// @ts-ignore
			onClick={onClick}
			className={clsx(
				'row',
				{
					[highlight]: !!highlight,
					[gridTemplate]: !!gridTemplate,
					vl: !!vl,
					layout: !!gridCustomLayout,
					border: !!bottomBorder,
				},
				className,
			)}
			style={
				{
					'--row-pl': pl,
					'--row-pr': pr,
					'--row-pt': pt,
					'--row-pb': pb,
					'--row-align': align,
					'--row-vl-color': vl,
					'--row-justify': justify,
					'--row-bg-color': bgColor,
					'--row-highlight': highlightColor,
					'--row-border-color': bottomBorder,
					'--row-grid-layout': gridCustomLayout,
				} as React.CSSProperties
			}
		>
			{children}
		</Tag>
	);
};

export default forwardRef(Row);
