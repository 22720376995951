import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { UseMainSearchStateParams } from '../lib/types';

export const useUrlSearchParamsState = ({ queryKey, delay }: UseMainSearchStateParams) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const initialQuery = useRef<string>(new URLSearchParams(window.location.search).get(queryKey));

	const onChange = useDebouncedCallback(
		(value: string) => {
			const newSearchParams = new URLSearchParams(searchParams);
			const normalizedValue = value.trim();

			if (!normalizedValue) {
				newSearchParams.delete(queryKey);
			} else {
				newSearchParams.set(queryKey, value.trim());
			}

			setSearchParams(newSearchParams);
		},
		delay,
		[searchParams.toString()],
	);

	return {
		onChange,
		query: searchParams.toString(),
		initialQuery: initialQuery.current,
	};
};
