import { useEffect, useRef, useState } from 'react';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

interface IConfig {
	resetIsCopiedAfter?: number;
}

export function useCopyToClipboard(config?: IConfig) {
	const { resetIsCopiedAfter = 2000 } = config || {};
	const timeoutID = useRef<NodeJS.Timeout>(null);

	const [copiedText, setCopiedText] = useState<CopiedValue>(null);
	const [isCopied, setIsCopied] = useState<boolean>(false);

	useEffect(() => {
		if (isCopied) {
			timeoutID.current = setTimeout(() => {
				setIsCopied(false);
				setCopiedText(null);
			}, resetIsCopiedAfter);

			return () => {
				if (timeoutID.current) {
					clearTimeout(timeoutID.current);
				}
			};
		}
	}, [isCopied, resetIsCopiedAfter]);

	const copy: CopyFn = async (text) => {
		if (!navigator?.clipboard) {
			return false;
		}

		try {
			await navigator.clipboard.writeText(text);
			setCopiedText(text);
			setIsCopied(true);
		} catch (error) {
			setCopiedText(null);
			setIsCopied(false);
		}
	};

	return { copy, isCopied, copiedText };
}
