import { useEffect, useState } from 'react';

import type { InnerControlledMultipleSelectProps, SelectOption } from '../types';
import { getOptionLabel } from '../utils';

export const useInnerControlledMultipleState = ({ defaultValue }: InnerControlledMultipleSelectProps) => {
	const [selectedOption, setSelectedOption] = useState<string[] | (SelectOption & Record<string, unknown>)[]>(defaultValue);

	useEffect(() => {
		setSelectedOption(defaultValue);
	}, [defaultValue]);

	const handleSetSelectedOption = (newOption: (SelectOption & Record<string, unknown>) | string) => {
		const hasOption = selectedOption.some((option) => getOptionLabel(option) === getOptionLabel(newOption));

		if (hasOption) return;

		const patchedValue = [...selectedOption, newOption] as (SelectOption & Record<string, unknown>)[] | string[];

		setSelectedOption?.(patchedValue);
	};

	const handleUnselect = (label: string) => {
		const filtered = selectedOption.filter((option) => getOptionLabel(option) !== label) as (SelectOption & Record<string, unknown>)[] | string[];

		setSelectedOption?.(filtered);
	};

	return {
		selectedOption,
		setSelectedOption: handleSetSelectedOption,
		onUnselect: handleUnselect,
	};
};
