import { useTypedOrderControllerFromContext } from 'pages/OrderRework/OrderController';
import React from 'react';

interface ServiceDependantCodeProps {
	suborderIndex: number;
	entityId: string;
}

const ServiceDependantCode: React.FC<ServiceDependantCodeProps> = ({ entityId, suborderIndex }) => {
	const { watch } = useTypedOrderControllerFromContext();
	const value = watch(`suborders.${suborderIndex}.data.services.${entityId}`);

	return <span>{value?.code || '-'}</span>;
};

export default ServiceDependantCode;
