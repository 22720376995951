import clsx from 'clsx';
import IconButton from 'components/IconButton';
import React from 'react';
import XCloseIcon from 'static/images/big-close.svg';

import styles from './styles.module.css';
import type { IProps } from './types';

const ConfigurationToolHeader: React.FC<IProps> = ({ className, title, subtitle, onXCloseClick, hideXCloseButton }) => {
	return (
		<div className={clsx(styles.wrapper, className)}>
			<div className={styles.textContentWrapper}>
				<p className={clsx('text-sm-medium', styles.title)}>{title}</p>
				{!!subtitle && <p className={clsx('text-sm-regular', styles.subtitle)}>{subtitle}</p>}
			</div>

			{!hideXCloseButton && (
				<IconButton className={styles.xClose} width="30px" height="30px" icon={XCloseIcon} background="transparent" onClick={onXCloseClick} />
			)}
		</div>
	);
};

export default ConfigurationToolHeader;
