import localforage from 'localforage';
import { LoaderFunction } from 'react-router-dom';

export const isAuthenticatedHandler = async () => {
	const token = await localforage.getItem('token');

	return token;
};

export const isInviteLinkValid: LoaderFunction = async ({ request }) => {
	const url = new URL(request.url);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const searchParams = url.searchParams;

	// TODO - implement checking of is invitation link valid or not
	return null;
};
