import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';
import type { SpinnerV2Props } from './types';

const positionsMap = {
	center: '0 auto',
	left: '0 0 0 auto',
	right: '0 auto 0 0',
};

const SpinnerV2: React.FC<SpinnerV2Props> = ({ position = 'center', className }) => {
	return (
		<div className={clsx(styles['lds-ring'], className)} style={{ '--spinner-v2-position': positionsMap[position] } as React.CSSProperties}>
			<div />
			<div />
			<div />
			<div />
		</div>
	);
};

export default SpinnerV2;
