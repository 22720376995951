import clsx from 'clsx';
import React from 'react';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';

import styles from './styles.module.css';
import type { AlertDialogueHeaderProps } from './types';

const AlertDialogueHeader: React.FC<AlertDialogueHeaderProps> = ({ children, className, xCloseButtonClassName, onClose }) => {
	return (
		<header className={clsx(styles.header, className)}>
			<div>{children}</div>

			<button type="button" className={clsx(styles.xClose, xCloseButtonClassName)} onClick={onClose}>
				<XCloseIcon />
			</button>
		</header>
	);
};

export default AlertDialogueHeader;
