import React from 'react';
import { Outlet } from 'react-router-dom';

import ModalStackProvider from './ModalStackProvider';

const ModalStackRouterProvider = () => {
	return (
		<ModalStackProvider>
			<Outlet />
		</ModalStackProvider>
	);
};

export default ModalStackRouterProvider;
