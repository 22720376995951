import 'static/styles/style.css';

import clsx from 'clsx';
import type { ForwardedRef } from 'react';
import React, { forwardRef } from 'react';

import styles from './style.module.css';
import type { IProps } from './typed';

const Input = (
	{
		id,
		name,
		value = '',
		placeholder,
		label,
		type,
		icon,
		iconPosition = 'trailing',
		onClickIcon,
		setValue,
		disabled = false,
		readOnly = false,
		className,
		wrapperClassName,
		...restProps
	}: IProps,
	ref: ForwardedRef<HTMLInputElement>,
) => {
	const isIconLeadingPosition = iconPosition === 'leading';

	return (
		<div className={clsx(wrapperClassName)}>
			{!!label && (
				<label htmlFor={id} className={`${styles.label} text-sm-medium color-grey-700`}>
					{label}
				</label>
			)}

			<div className={`${styles.align}`}>
				{icon && isIconLeadingPosition && (
					<img src={icon} alt="icon" className={styles.inputIcon} data-position={iconPosition} onClick={onClickIcon} />
				)}

				<input
					{...restProps}
					ref={ref}
					id={id}
					name={name}
					type={type}
					value={value}
					onChange={(e) => setValue(e.target.value)}
					placeholder={placeholder}
					className={clsx(styles.inputStyle, 'no-user-agent-autofill-bg', className)}
					readOnly={readOnly}
					disabled={disabled}
					autoComplete="new-password"
				/>

				{icon && !isIconLeadingPosition && (
					<img src={icon} alt="icon" className={styles.inputIcon} data-position={iconPosition} onClick={onClickIcon} />
				)}
			</div>
		</div>
	);
};

export default forwardRef(Input);
