import clsx from 'clsx';
import Button from 'components/Button';
import React from 'react';
import DownloadIcon from 'static/images/download-01.svg';

import type { ExecutePriceChangeButtonProps } from './types';

const ExecutePriceChangeButton: React.FC<ExecutePriceChangeButtonProps> = ({ className, disabled }) => {
	return (
		<Button
			variant="rounded"
			type="submit"
			text="Виконати"
			icon={DownloadIcon}
			disabled={disabled}
			className={clsx(className)}
			background="var(--primary-600)"
		/>
	);
};

export default ExecutePriceChangeButton;
