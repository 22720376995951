import clsx from 'clsx';
import { breakPoints } from 'const';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Mobile from './Mobile';
import styles from './styles.module.css';
import type { IProps } from './types';
// ! IMPORTANT ! for this kind of table (current version at least) react-table is not suitable, see https://github.com/TanStack/table/issues/762
/*
 * [  header ][   data   ][   data   ][   data  ]
 * [  header ][   data   ][   data   ][   data  ]
 * [  header ][   data   ][   data   ][   data  ]
 */

const InStockTable: React.FC<IProps> = ({ title, columns, data, onRowClick, getActiveRow }) => {
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });

	if (isMobile) {
		return <Mobile title={title} columns={columns} data={data} onRowClick={onRowClick} getActiveRow={getActiveRow} />;
	}

	return (
		<table className={styles.table}>
			{!!title && <caption className={styles.title}>{title}</caption>}

			<tbody>
				{columns.map((column: AnyArg, colIndex: number) => {
					return (
						<tr key={colIndex}>
							<th title={column.header}>
								<span className={styles.clampOneLine}>{column.Header}</span>
							</th>
							{data.map((row: AnyArg, rowIndex: number) => {
								return (
									<td
										key={rowIndex}
										className={clsx({ [styles.active]: getActiveRow?.(row) })}
										title={row[column.accessor]}
										onClick={() => onRowClick?.(row)}
									>
										<span className={styles.clampOneLine}>{row[column.accessor] || '-'}</span>
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default InStockTable;
