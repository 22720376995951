import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import { useState } from 'react';
import { isString } from 'utils/type-guards';

import type { UseMainSearchStateParams } from '../lib/types';

export const useInMemoryState = ({ queryKey, delay }: UseMainSearchStateParams) => {
	const [query, setQuery] = useState('');

	const onChange = useDebouncedCallback((value: string | React.ChangeEvent<HTMLInputElement>) => {
		const normalizedValue = isString(value) ? value : value?.target?.value ?? '';

		setQuery(`${queryKey}=${normalizedValue.trim()}`);
	}, delay);

	return {
		onChange,
		query,
		initialQuery: '',
	};
};
