import clsx from 'clsx';
import React from 'react';

import styles from './style.module.css';
import { IProps } from './types';

const Chip: React.FC<IProps> = ({ title, color, bgColor, pointColor, hideDot = false, className }) => {
	return (
		<div className={clsx(styles.defaultState, 'text-sm-medium', className)} style={{ color: color, background: bgColor }}>
			{!hideDot && <span className={styles.point} style={{ background: pointColor }}></span>}
			{title}
		</div>
	);
};

export default Chip;
