import clsx from 'clsx';
import React from 'react';

import styles from './style.module.css';
import { IProps } from './types';

const CustomRadioButton: React.FC<IProps> = ({ checked, text, onClick, disabled = false }) => {
	return (
		<button
			className={clsx(styles.customRadio, {
				[styles.active]: checked,
			})}
			disabled={disabled}
			onClick={onClick}
		>
			{text}
		</button>
	);
};

export default CustomRadioButton;
