import clsx from 'clsx';
import PriceModifier from 'components/PriceModifier';
import SpinnerV2 from 'components/Spinner-v2';
import React, { useState } from 'react';
import { useGetPriceTypeListQuery } from 'store/reducers/orders/ordersSliceApi';

import { priceTypeColorMap } from '../config';
import styles from './styles.module.css';
import type { IProps, PriceTypeItem } from './types';

const PriceTypes: React.FC<IProps> = ({ children, className, onClick, disableAll = false, justify = 'space-between' }) => {
	const { data: priceTypeList, isLoading } = useGetPriceTypeListQuery(undefined);
	const [activeIndex, setActiveIndex] = useState<number[]>([]);

	const types = priceTypeList?.map((pricyType) => ({
		...priceTypeColorMap[Number(pricyType.value) - 1],
		modifierDisplayValue: pricyType.label,
	}));

	if (isLoading) {
		return (
			<div className={clsx(styles.modifiersWrapper, className)} style={{ justifyContent: justify }}>
				{children}

				<div className={styles.list} style={{ width: '200px' }}>
					<SpinnerV2 />
				</div>
			</div>
		);
	}

	const handleSelection = (index: number, item: PriceTypeItem) => {
		if (disableAll) return;

		if (activeIndex.includes(index)) {
			setActiveIndex(activeIndex.filter((idx) => idx !== index));
			onClick?.(index, item, false);
		} else {
			setActiveIndex([...activeIndex, index]);
			onClick?.(index, item, true);
		}
	};

	return (
		<div className={clsx(styles.modifiersWrapper, className)} style={{ justifyContent: justify }}>
			{children}

			<ul className={styles.list}>
				{types.map((item, index) => {
					const isActive = activeIndex.includes(index);

					return (
						<li key={index}>
							<PriceModifier
								isActive={isActive}
								modifierDisplayValue={item.modifierDisplayValue}
								activeColor={item.activeColor}
								bgColor={item.bgColor}
								borderColor={item.borderColor}
								fontColor={item.fontColor}
								dotColor={item.dotColor}
								hoverBgColor={item.hoverBgColor}
								onClick={() => handleSelection(index, { label: item.modifierDisplayValue, fontColor: item.fontColor })}
								disabled={disableAll}
							/>
						</li>
					);
				})}
			</ul>
		</div>
	);
};
export default PriceTypes;
