import { ExternalControlledSelectProps, SelectOption } from '../types';

export const useExternalControlledState = ({ value, setValue }: ExternalControlledSelectProps) => {
	const setSelectedOption = (newOption: (SelectOption & Record<string, unknown>) | string) => {
		setValue?.(newOption);
	};

	return {
		selectedOption: value as (SelectOption & Record<string, unknown>) | string,
		setSelectedOption,
		onUnselect: undefined,
	};
};
