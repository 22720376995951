import type { MakeBusinessOfferModel, MakeBusinessOfferRequestDTO, MakeBusinessOfferResult, MakeBusinessOfferResultDTO } from './business-offer';
import { MakeBusinessOfferModelSchema, MakeBusinessOfferResultDTOSchema } from './business-offer';

export const transformToMakeBusinessOfferDTO = (model: MakeBusinessOfferModel): MakeBusinessOfferRequestDTO => {
	const validation = MakeBusinessOfferModelSchema.safeParse(model);

	return {
		name: validation.data.name,
		offer_text: validation.data.offerText,
		order_id: validation.data.orderId,
	};
};
export const transformToMakeBusinessOfferResult = (dto: MakeBusinessOfferResultDTO): MakeBusinessOfferResult => {
	const validation = MakeBusinessOfferResultDTOSchema.safeParse(dto);

	return {
		id: validation.data.id,
		name: validation.data.name,
		orderId: validation.data.order_id,
		pdfUrl: validation.data.pdf_url,
	};
};
