import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as ArrowDownIcon } from 'static/images/arrow-down.svg';
import { ReactComponent as MinusIcon } from 'static/images/minus.svg';

import styles from './styles.module.css';
import type { SortingButtonProps } from './types';

const SORT_DIRECTION = {
	asc: 'asc',
	desc: 'desc',
};

const SortingButton = ({ column, disabled = false }: SortingButtonProps) => {
	const [clickCounts, setClickCounts] = useState<number>(0);
	const [searchParams, setSearchParams] = useSearchParams();

	const handleToggleSortingOrder = () => {
		setClickCounts(clickCounts + 1);

		updateSortingParams(column.id);
	};

	const updateSortingParams = (columnId: string) => {
		const newSearchParams = new URLSearchParams(searchParams);

		let sortDirection;
		switch (clickCounts) {
			case 0:
				sortDirection = SORT_DIRECTION.asc;
				break;
			case 1:
				sortDirection = SORT_DIRECTION.desc;
				break;
			default:
				sortDirection = undefined;
		}

		if (searchParams.get('sortBy') === columnId && clickCounts === 2) {
			newSearchParams.delete('sortBy');
		} else {
			newSearchParams.set('sortBy', columnId);
		}

		if (sortDirection) {
			newSearchParams.set('sortDirection', sortDirection);
		} else {
			newSearchParams.delete('sortDirection');
		}

		setSearchParams(newSearchParams);
	};

	const getIcon = () => {
		switch (clickCounts) {
			case 2:
				return <MinusIcon />;
			case 1:
				return <ArrowDownIcon className={clsx({ [styles.rotate]: true })} />;
			default:
				return <ArrowDownIcon />;
		}
	};

	useEffect(() => {
		if (searchParams.get('sortBy') !== column.id) {
			setClickCounts(0);
		}
	}, [searchParams, column.id]);

	useEffect(() => {
		if (clickCounts === 3) {
			setClickCounts(0);
		}
	}, [clickCounts]);

	useEffect(() => {
		const newSearchParams = new URLSearchParams(searchParams);
		if (newSearchParams.get('sortDirection') === 'asc' && newSearchParams.get('sortBy') === column.id) {
			setClickCounts(1);
		}
	}, []);

	return (
		<button data-sorting-button type="button" disabled={disabled} onClick={handleToggleSortingOrder} className={clsx(styles.sortableBtn)}>
			{getIcon()}
		</button>
	);
};

export default SortingButton;
