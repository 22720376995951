import clsx from 'clsx';
import Button from 'components/Button';
import Chip from 'components/Chip';
import RefreshActions from 'components/OrderCreatePageComponents/ProductSelectTopBar/RefreshActions';
import FullScreenGate from 'components/OrderPageComponents/(VisibilityGates)/FullScreenGate';
import Payable from 'components/OrderPageComponents/Payable';
import { breakPoints } from 'const';
import { calculateTotals, calculateTotalServicesCost } from 'pages/OrderRework/OrderController/lib/utils';
import React from 'react';
import MediaQuery from 'react-responsive';
import LockIcon from 'static/images/lock.svg';
import MultiFileICon from 'static/images/save-multi.svg';
import { formatNumberToUAH, kVolumeFormatter, kWeightFormatter } from 'utils/shared';

import { ProductInternalModelState, ServiceInternalModelState } from '../../../OrderController';
import styles from './styles.module.css';

interface StatsProps {
	isFullScreen: boolean;
	activeTab: number;
	products: ProductInternalModelState[];
	services: ServiceInternalModelState[];
	isReserved?: boolean;
	onSave: VoidCallback;
	onReserve: VoidCallback;
}

export const Stats: React.FC<StatsProps> = ({ services, activeTab, products, isReserved, isFullScreen, onSave, onReserve }) => {
	const totals = calculateTotals(products);
	const totalServicesCost = calculateTotalServicesCost(services);

	const formattedWeight = `Вага - ${kWeightFormatter(totals?.weight || 0)}`;
	const formattedVolume = `Об’єм - ${kVolumeFormatter(totals?.volume || 0)}`;
	const formattedPrice = formatNumberToUAH(totals?.sum || 0 + totalServicesCost);

	return (
		<>
			<FullScreenGate isFullScreen={isFullScreen}>
				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<div className={styles.refreshActionsWrapper}>
						<RefreshActions />
					</div>
				</MediaQuery>
			</FullScreenGate>

			<div className={styles.totalPriceWrapper}>
				<FullScreenGate isFullScreen={isFullScreen}>
					<MediaQuery minWidth={breakPoints.MOBILE}>
						<div className={styles.chipsAndPriceWrapper}>
							<div className={styles.chipsWrapper}>
								<Chip title={formattedWeight} />
								<Chip title={formattedVolume} />
							</div>

							<Payable price={formattedPrice} className={styles.totalPrice} />
						</div>
					</MediaQuery>
					<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
						<div className={styles.chipsWrapper}>
							<Chip title={formattedWeight} />
							<Chip title={formattedVolume} />
						</div>

						<Payable price={formattedPrice} className={styles.totalPrice} />
					</MediaQuery>

					<div className={styles.suborderControlsWrapper}>
						<Button text="Зберегти" variant="rounded" onClick={onSave} background="var(--pink)" icon={MultiFileICon} />
						{activeTab > 0 && (
							<Button
								onClick={onReserve}
								text={isReserved ? 'В резерві' : `Резерв заявки ${activeTab}`}
								variant="rounded"
								background="var(--error-50)"
								icon={LockIcon}
								className={clsx(styles.reserv, { [styles.active]: isReserved })}
							/>
						)}
					</div>
				</FullScreenGate>
			</div>
		</>
	);
};
