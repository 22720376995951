import { useAppSelector } from 'hooks/redux';
/*
 * selectors
 */
import {
	selectActiveSuborderCategoryIndex,
	selectActiveSuborderIndex,
	selectAllActiveSuborderGroupedServices,
	selectAllDraftOrderGroupedByTypeServices,
	selectCanToggleDraftOrderType,
	selectDisplayDetailsOfTransferringItem,
	selectDraftOrder,
	selectEtalonOrder,
	selectFormattedOrderStats,
	selectHasReachedPaintToningSubordersLimit,
	selectItemsCandidatesToDelete,
	selectLastForcedRerenderTimestamp,
	selectLockedIdsList,
	selectPaintToningSuborder,
	selectPaintToningSubordersCountLimit,
	selectSuborders,
	selectSubordersCountLimit,
	selectSuborderToBeDeleteCandidateIndex,
} from 'store/reducers/orderViewer/selectors';
import { checkIfOrderIsReserved, toArray } from 'utils/shared';

const arrayFallback = [];
const recordFallback = {};

export const useSource = () => {
	const hasReachedPainToningSubordersLimit = useAppSelector(selectHasReachedPaintToningSubordersLimit);
	const activeSuborderGroupedByTypeServices = useAppSelector(selectAllActiveSuborderGroupedServices);
	const draftOrderGroupedByTypeServices = useAppSelector(selectAllDraftOrderGroupedByTypeServices);
	const paintToningSubordersCountLimit = useAppSelector(selectPaintToningSubordersCountLimit);
	const maybeDeleteSuborderIndex = useAppSelector(selectSuborderToBeDeleteCandidateIndex);
	const activeSuborderCategoryIndex = useAppSelector(selectActiveSuborderCategoryIndex);
	const lastForcedRerenderTimestamp = useAppSelector(selectLastForcedRerenderTimestamp);
	const canToggleDraftOrderType = useAppSelector(selectCanToggleDraftOrderType);
	const seeDetailsForItem = useAppSelector(selectDisplayDetailsOfTransferringItem);
	const deleteItemsCandidates = useAppSelector(selectItemsCandidatesToDelete);
	const regularSubordersLimit = useAppSelector(selectSubordersCountLimit);
	const activeSuborderIndex = useAppSelector(selectActiveSuborderIndex);
	const paintToningSuborder = useAppSelector(selectPaintToningSuborder);
	const lockedItemsIdsList = useAppSelector(selectLockedIdsList);
	const stats = useAppSelector(selectFormattedOrderStats);
	const etalonOrder = useAppSelector(selectEtalonOrder);
	const draftOrder = useAppSelector(selectDraftOrder);
	const allSuborders = useAppSelector(selectSuborders);

	/*
	 * getting needed ETALON order keys from source data

	 */
	const etalonOrderSuborders = etalonOrder.suborders;
	const etalonOrderProducts = etalonOrder.products;
	const etalonOrderServices = etalonOrder.services;
	const etalonOrderOriginal = etalonOrder.original;

	/*
	 * getting needed DRAFT order keys from source data
	 */
	const draftOrderSuborders = draftOrder?.suborders ?? arrayFallback;
	const draftOrderProducts = draftOrder?.products ?? arrayFallback;
	const draftOrderServices = toArray(draftOrder?.services ?? {}) ?? arrayFallback;
	const draftOrderOriginal = draftOrder?.original ?? recordFallback;
	const draftOrderType = draftOrderOriginal?.type ?? 'regular';

	/*
	 * getting needed ROOT SUB_ORDER keys from source data
	 */
	const [rootSuborder] = allSuborders;
	const rootSuborderProducts = rootSuborder?.products ?? arrayFallback;
	const rootSuborderServices = rootSuborder?.services ?? recordFallback;
	const rootSuborderSelectedProducts = rootSuborder?.selectedProducts ?? arrayFallback;
	const rootSuborderSelectedServices = rootSuborder?.selectedServices ?? recordFallback;

	/*
	 * getting needed ACTIVE SUB_ORDER keys from source data
	 */
	const activeSuborder = allSuborders[activeSuborderIndex];
	const activeSuborderProducts = activeSuborder?.products ?? arrayFallback;
	const activeSuborderServices = activeSuborder?.services ?? recordFallback;
	const activeSuborderSelectedProducts = activeSuborder?.selectedProducts ?? arrayFallback;
	const activeSuborderSelectedServices = activeSuborder?.selectedServices ?? recordFallback;
	const activeSuborderType = activeSuborder?.type ?? 'regular';
	/*
	 * getting needed PAINT TONING SUB_ORDER keys from source data
	 */
	const paintToningSuborderProducts = paintToningSuborder?.products ?? arrayFallback;
	const paintToningSuborderServices = paintToningSuborder?.services ?? recordFallback;
	const paintToningSuborderSelectedProducts = paintToningSuborder?.selectedProducts ?? arrayFallback;
	const paintToningSuborderSelectedServices = paintToningSuborder?.selectedServices ?? recordFallback;

	/*
	 * plain computed values
	 */
	// 1 is an offset for root orders which technically is not a pure suborder
	const hasReachedAllSubordersLimit = allSuborders.length >= regularSubordersLimit + 1;
	const isOnRootOrderTab = activeSuborderIndex === 0;
	const pureSuborders = allSuborders.slice(1); // suborders without root suborder
	const isOnProductsTabInActiveSuborder = activeSuborderCategoryIndex === 0;
	const isOnServicesTabInActiveSuborder = activeSuborderCategoryIndex === 1;
	const isActiveSuborderReserved = checkIfOrderIsReserved(activeSuborder?.status ?? '');
	const isDraftOrderReserved = checkIfOrderIsReserved(draftOrder.original?.status ?? '');
	const isActiveSuborderOfPaintToningType = activeSuborder?.type === 'paint_toning';
	const isActiveSuborderOfRegularType = activeSuborder?.type === 'regular';
	const totalProductsCountInDraftOrder = draftOrder?.products?.length ?? 0;
	const totalServicesCountInDraftOrder = draftOrderServices?.length ?? 0;
	const totalRegularServicesCountInDraftOrder = draftOrderGroupedByTypeServices?.regularServices.length ?? 0;
	const totalPaintToningServicesCountInDraftOrder = draftOrderGroupedByTypeServices?.paintToningServices.length ?? 0;
	const totalProductsCountInActiveSuborder = activeSuborder?.products?.length ?? 0;
	const totalServicesCountInActiveSuborder = toArray(activeSuborder?.services ?? {})?.length ?? 0;
	const totalRegularServicesCountInActiveSuborder = activeSuborderGroupedByTypeServices?.regularServices?.length ?? 0;
	const totalPaintToningServicesCountInActiveSuborder = activeSuborderGroupedByTypeServices?.paintToningServices?.length ?? 0;
	const rerenderKey = activeSuborderIndex + lastForcedRerenderTimestamp;

	return {
		etalonOrder: {
			products: etalonOrderProducts,
			services: etalonOrderServices,
			suborders: etalonOrderSuborders,
			original: etalonOrderOriginal,
		},
		draftOrder: {
			products: draftOrderProducts,
			services: draftOrderServices,
			suborders: draftOrderSuborders,
			original: draftOrderOriginal,
			type: draftOrderType,
			regularServices: draftOrderGroupedByTypeServices.regularServices,
			paintToningServices: draftOrderGroupedByTypeServices.paintToningServices,
			productsCount: totalProductsCountInDraftOrder,
			allServicesCount: totalServicesCountInDraftOrder,
			regularServicesCount: totalRegularServicesCountInDraftOrder,
			paintToningServicesCount: totalPaintToningServicesCountInDraftOrder,
		},

		stats,
		allSuborders,
		pureSuborders,
		lockedItemsIdsList,

		booleans: {
			isOnRootOrderTab,
			isDraftOrderReserved,
			canToggleDraftOrderType,
			isActiveSuborderReserved,
			hasReachedAllSubordersLimit,
			isOnProductsTabInActiveSuborder,
			isOnServicesTabInActiveSuborder,
			hasReachedPainToningSubordersLimit,
			isActiveSuborderOfPaintToningType,
			isActiveSuborderOfRegularType,
		},

		limits: {
			regularSubordersLimit,
			paintToningSubordersCountLimit,
		},
		draftOrderGroupedByTypeServices,
		activeSuborderGroupedByTypeServices,
		lastForcedRerenderTimestamp,
		maybeDeleteSuborderIndex,
		rerenderKey,

		rootSuborder: {
			products: rootSuborderProducts,
			services: rootSuborderServices,
			selectedProducts: rootSuborderSelectedProducts,
			selectedServices: rootSuborderSelectedServices,
			original: rootSuborder,
		},
		activeSuborder: {
			products: activeSuborderProducts,
			services: activeSuborderServices,
			activeCategoryIndex: activeSuborderCategoryIndex,
			selectedProducts: activeSuborderSelectedProducts,
			selectedServices: activeSuborderSelectedServices,
			regularServices: activeSuborderGroupedByTypeServices.regularServices,
			paintToningServices: activeSuborderGroupedByTypeServices.paintToningServices,
			index: activeSuborderIndex,
			original: activeSuborder,
			type: activeSuborderType,
			deleteItemsCandidates,
			productsCount: totalProductsCountInActiveSuborder,
			allServicesCount: totalServicesCountInActiveSuborder,
			regularServicesCount: totalRegularServicesCountInActiveSuborder,
			paintToningServicesCount: totalPaintToningServicesCountInActiveSuborder,
		},
		paintToningSuborder: {
			products: paintToningSuborderProducts,
			services: paintToningSuborderServices,
			selectedProducts: paintToningSuborderSelectedProducts,
			selectedServices: paintToningSuborderSelectedServices,
			original: paintToningSuborder,
		},
		transfer: {
			seeDetailsForItem,
		},
	};
};
