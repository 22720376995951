import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import styles from './style.module.css';
import type { IProps } from './types';

const CustomButton: React.FC<IProps> = ({ title, color, icon, bgColor, hoverBg, defaultActive, externalControl, onClick, disabled }) => {
	const [activeState, setActiveState] = useState(defaultActive);

	const isActive = externalControl ? defaultActive : activeState;

	useEffect(() => {
		if (externalControl) return;

		setActiveState(!!defaultActive);

		return () => {
			setActiveState(false);
		};
	}, [defaultActive]);

	const changeState = () => {
		if (externalControl) return;

		setActiveState(!activeState);
	};

	return (
		<button
			style={{ color: color, '--_hover': hoverBg, backgroundColor: !isActive ? bgColor : hoverBg } as React.CSSProperties}
			className={clsx(styles.buttonStyle, { [styles.external]: externalControl, [styles.active]: isActive })}
			onMouseEnter={changeState}
			onMouseLeave={changeState}
			onClick={onClick}
			disabled={disabled}
		>
			<img src={icon} /> {title}
		</button>
	);
};

export default CustomButton;
