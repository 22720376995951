import 'react-toastify/dist/ReactToastify.css';

import React, { type PropsWithChildren } from 'react';
import { ToastContainer } from 'react-toastify';

const ToasterProvider: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<>
			{children}
			<ToastContainer />
		</>
	);
};

export default ToasterProvider;
