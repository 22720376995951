import clsx from 'clsx';
import React from 'react';
import { ReactComponent as DotIcon } from 'static/images/Dot.svg';

import styles from './styles.module.css';
import type { IProps } from './types';

const PriceModifier: React.FC<IProps> = ({
	modifierDisplayValue,
	className,
	bgColor,
	borderColor,
	fontColor,
	dotColor,
	activeColor,
	hoverBgColor,
	isActive = false,
	disabled = false,
	onClick,
}) => {
	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className={clsx(styles.modifier, className, { [styles.active]: isActive, [styles.disabled]: disabled })}
			style={
				{
					'--modifiers-border-color': borderColor,
					'--modifiers-bg-color': bgColor,
					'--modifiers-color': fontColor,
					'--modifiers-hover-bg-color': hoverBgColor,
					'--modifiers-active-color': activeColor,
				} as React.CSSProperties
			}
		>
			<DotIcon className={styles.icon} style={{ fill: isActive ? bgColor : dotColor }} />
			<span className="text-sm-semibold">{modifierDisplayValue}</span>
		</button>
	);
};

export default PriceModifier;
