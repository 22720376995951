import 'static/styles/style.css';

import clsx from 'clsx';
import React from 'react';
import { ReactComponent as CheckIcon } from 'static/images/checkGreen.svg';

import styles from './style.module.css';
import { IProps } from './types';

const Print: React.FC<IProps> = ({ title, icon, checked, className, onClick }) => {
	return (
		<div className={clsx(styles.printWrapper, className)} onClick={onClick} style={{ cursor: onClick && 'pointer' }}>
			<div className={styles.container}>
				{icon}
				{title}
			</div>
			{checked && <CheckIcon />}
		</div>
	);
};

export default Print;
