import { useMemo } from 'react';
import { isNumber } from 'utils/type-guards';

export const DOTS = '...';

type BaseProps = {
	siblingCount?: number;
	currentPage: number;
	skip?: boolean;
};
type PropsWithOuterCalculatedPages = BaseProps & {
	pageSize: number;
	totalCount: number;
	pagesCount?: never;
};

type PropsWithInnerCalculatedPages = BaseProps & {
	pagesCount: number;
	pageSize?: never;
	totalCount?: never;
};

export type UsePaginationHookProps = PropsWithOuterCalculatedPages | PropsWithInnerCalculatedPages;

// making range of pagination buttons
const range = (start: number, end: number) => {
	const length = end - start + 1;
	return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ totalCount, pagesCount, pageSize, siblingCount = 1, currentPage, skip = false }: UsePaginationHookProps) => {
	const paginationRange = useMemo(() => {
		if (skip) return [];
		// 'pagesCount' lifts up pages calculation logic to the outer component or hook
		const totalPageCount = isNumber(pagesCount) ? pagesCount : Math.ceil(totalCount / pageSize);

		// Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
		const totalPageNumbers = siblingCount + 5;

		// Case 1:
		// If the number of pages is less than the page numbers we want to show in our
		// paginationComponent, we return the range [1..totalPageCount]
		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}

		// Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftItemCount = 3 + 2 * siblingCount;
			const leftRange = range(1, leftItemCount);

			return [...leftRange, DOTS, totalPageCount];
		}

		// Case 3: No right dots to show, but left dots to be shown
		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightItemCount = 3 + 2 * siblingCount;
			const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
			return [firstPageIndex, DOTS, ...rightRange];
		}

		// Case 4: Both left and right dots to be shown
		if (shouldShowLeftDots && shouldShowRightDots) {
			const middleRange = range(leftSiblingIndex, rightSiblingIndex);
			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
		}

		return range(1, totalPageCount);
	}, [totalCount, pagesCount, pageSize, siblingCount, currentPage, skip]);

	return paginationRange;
};
