import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import { breakPoints } from 'const';
import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as TrashIcon } from 'static/images/trash.svg';

import { defaultDocumentDateFormatter, defaultReservationDateFormatter } from './config';
import MobileReservedProductsTable from './MobileReservedProductsTable';
import styles from './styles.module.css';
import type { IProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TODOType = any;
const columnHelper = createColumnHelper<TODOType>();

const ReservedProductsTable = <TData,>({
	data,
	onCancelReservation,
	documentDateFormatter = defaultDocumentDateFormatter,
	reservationDateFormatter = defaultReservationDateFormatter,
}: IProps<TData>) => {
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });

	const columns = useMemo(() => {
		const newColumns = [
			columnHelper.accessor(() => '', {
				id: 'client',
				header: () => 'Клієнт',
				cell: ({ row }) => (
					<>
						<span className={styles.highlighted}>{row.original.client.organization}</span>
						{isMobile ? null : <br />}
						<span>{row.original.client.name}</span>
					</>
				),
			}),
			columnHelper.accessor(() => '', {
				id: 'manager',
				header: () => 'Менеджер',
				cell: () => '',
			}),
			columnHelper.accessor(() => '', {
				id: 'document',
				header: () => 'Документ',
				cell: ({ row }) => {
					const formattedDate = documentDateFormatter(row.original.document.createdAt);
					const [firstChunk, secondChunk] = formattedDate.split(' ');

					return (
						<>
							Замовлення отримувача <span className={styles.highlightedDark}>{row.original.document.number}</span> від&nbsp;
							<span className={styles.highlighted}>{firstChunk}</span>
							&nbsp;
							<span>{secondChunk}</span>
						</>
					);
				},
			}),
			columnHelper.accessor(() => '', {
				id: 'reservedTill',
				header: () => (isMobile ? 'Дата до' : 'Зарезервовано до'),
				cell: ({ row }) => reservationDateFormatter(row.original.reservedTill),
			}),
			columnHelper.accessor(() => '', {
				id: 'quantity',
				header: () => 'Кількість',
				cell: () => '',
			}),
			columnHelper.accessor(() => '', {
				id: 'action',
				header: () => 'Зняти резерв',
				cell: ({ row }) => (
					<button
						type="button"
						className={styles.cancelReservationButton}
						onClick={(e) => {
							e.stopPropagation();
							onCancelReservation?.(row?.original);
						}}
					>
						<TrashIcon className={styles.icon} />
						{isMobile ? <span>Перейти до броні</span> : null}
					</button>
				),
			}),
		];

		return newColumns;
	}, [isMobile]);

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel<TData>(),
	});

	if (isMobile) {
		return <MobileReservedProductsTable data={data} tableInstance={table} onCancelReservation={onCancelReservation} />;
	}

	return (
		<table data-reserved-products-table className={styles.reservedProductsTable}>
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header) => (
							<th key={header.id} className="text-sx-medium color-grey-600">
								{flexRender(header.column.columnDef.header, header.getContext())}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map((row) => {
					return (
						<tr
							data-tbody-table-row
							key={row.id}
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							{row.getVisibleCells().map((cell) => {
								return (
									<td
										key={cell.column.id}
										className={clsx({
											[styles.isPinned]: cell.column.getIsPinned(),
										})}
										style={{
											width: cell.column.getSize(),
										}}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default ReservedProductsTable;
