// toolbar icons
import bold from 'static/images/rich-text-editor/bold.svg';
import italic from 'static/images/rich-text-editor/italic.svg';
import link from 'static/images/rich-text-editor/link.svg';
import ordered from 'static/images/rich-text-editor/ordered.svg';
import unordered from 'static/images/rich-text-editor/unordered.svg';

import styles from './styles.module.css';

// for more granular control over the toolbar
// see https://jpuri.github.io/react-draft-wysiwyg/#/docs
const toolbar = {
	options: ['inline', 'link', 'list'],
	inline: {
		inDropdown: false,
		className: styles.inlineBlock,
		component: undefined,
		dropdownClassName: undefined,
		options: ['bold', 'italic'],
		bold: { icon: bold, className: styles.toolbarButton },
		italic: { icon: italic, className: styles.toolbarButton },
	},
	list: {
		inDropdown: false,
		className: styles.listBlock,
		component: undefined,
		dropdownClassName: undefined,
		options: ['unordered', 'ordered'],
		unordered: { icon: unordered, className: styles.toolbarButton },
		ordered: { icon: ordered, className: styles.toolbarButton },
	},
	link: {
		inDropdown: false,
		className: styles.linkBlock,
		component: undefined,
		popupClassName: undefined,
		dropdownClassName: undefined,
		showOpenOptionOnHover: false,
		defaultTargetOption: '_blank',
		options: ['link'],
		link: { icon: link, className: styles.toolbarButton },
		linkCallback: undefined,
	},
};

const localization = {
	locale: 'uk',
	translations: {
		'generic.add': 'Додати',
		'generic.cancel': 'Скасувати',

		'components.controls.link.linkTitle': 'Назва',
		'components.controls.link.linkTarget': 'Адреса покликання',
		'components.controls.link.linkTargetOption': 'Відкрити в новій вкладці',
		'components.controls.link.buttonsection.button': 'Link',
	},
};

export const config = {
	toolbar,
	localization,
};
