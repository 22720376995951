import PriceTable from 'components/PriceTable';
import React, { useState } from 'react';
import { ReactComponent as Arrow } from 'static/images/chevron-down.svg';
import { ReactComponent as Layeer3 } from 'static/images/layer-single.svg';
import { ReactComponent as Layeer } from 'static/images/layers-three.svg';
import { ReactComponent as Layeer2 } from 'static/images/layers-two.svg';
import { ReactComponent as Delete } from 'static/images/trash.svg';

import styles from './styles.module.css';
import { IProps } from './types';

const Accordion: React.FC<IProps> = ({ items, level }) => {
	const [openIndex, setOpenIndex] = useState(null);

	const handleClick = (index, e) => {
		e.preventDefault();
		setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	const padding = () => {
		if (level === 1) {
			return 36;
		} else if (level === 2) {
			return 56;
		}
	};

	return (
		<div>
			{items.map((item, index) => (
				<React.Fragment key={index}>
					<div className={styles.accordionBox} onClick={(e) => handleClick(index, e)} style={{ paddingLeft: padding() }}>
						<div className={styles.insideAccordion}>
							{level === 0 && <Layeer />}
							{level === 1 && <Layeer2 />}
							{level === 2 && <Layeer3 />} {item.name}
						</div>
						<div className={styles.iconsWrapper}>
							<Arrow onClick={(e) => handleClick(index, e)} style={{ transform: openIndex === index ? 'rotate(180deg)' : 'none' }} />
							<span className={styles.divider}></span>
							<Delete />
						</div>
					</div>
					{openIndex === index && item.subcategories && <Accordion items={item.subcategories} level={level + 1} />}
					{openIndex === index && item.products && <PriceTable products={item.products} />}
				</React.Fragment>
			))}
		</div>
	);
};

export default Accordion;
