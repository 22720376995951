import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';
import { IProps } from './types';

const TopBarContainer: React.FC<IProps> = ({ children, className }) => {
	return <div className={clsx(styles.topBarContainer, className)}>{children}</div>;
};

export default TopBarContainer;
