import { Whoami } from 'models/auth';
import { ClientOptionSchema, ContractOptionSchema } from 'models/client';
import { EmployeeOptionSchema, OrganizationOptionSchema } from 'models/employee';
import { CatalogueService } from 'models/service';
import { StockOptionSchema } from 'models/stock';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet } from 'react-router-dom';

import { OrderAbilityContext } from '../OrderAbility/provider';
import { useDefineOrderAbility } from '../OrderAbility/useDefineOrderAbility';
import { createOrderLikeEntity } from './lib/entity-creators';
import { OrderControllerState, ProductInternalModelState, ServiceInternalModelState } from './lib/schema';
import { transformOrderProductToProductInternalModelState, transformOrderServiceToInternalServiceStateModel } from './lib/transform';

type OrderLikeEntity = ReturnType<typeof createOrderLikeEntity>;
interface NewOrderController {
	data: [OrderLikeEntity, Record<string, CatalogueService>, Whoami];
}

const NewOrderController: React.FC<NewOrderController> = ({ data }) => {
	const [order, services] = data;

	const client = ClientOptionSchema.safeParse(order.client);
	const contract = ContractOptionSchema.safeParse(order.contract);
	const organization = OrganizationOptionSchema.safeParse(order.organization);
	const stock = StockOptionSchema.safeParse(order.stock);
	const responsible = EmployeeOptionSchema.safeParse(order.responsible);

	const servicesInternalModel = order?.services?.reduce((acc, service) => {
		const model = transformOrderServiceToInternalServiceStateModel(service, services);

		return {
			...acc,
			[model.id]: model,
		};
	}, {}) as Record<string, ServiceInternalModelState>;

	const productsInternalModel = order?.products?.reduce((acc, product) => {
		const model = transformOrderProductToProductInternalModelState(product);

		return {
			...acc,
			[model.id]: model,
		};
	}, {}) as Record<string, ProductInternalModelState>;

	const form = useForm<OrderControllerState>({
		defaultValues: {
			suborders: [
				{
					tabName: 'Основна заявка',
					data: {
						id: '',
						client: client.data,
						contract: contract.data,
						organization: organization.data,
						stock: stock.data,
						responsible: responsible.data,
						index: 0,
						isPaid: order.isPaid,
						isReserved: order.isReserved,
						isWithoutPayment: order.isWithoutPayment,
						products: productsInternalModel,
						services: servicesInternalModel,
						sum: order.sum,
						volume: order.volume,
						weight: order.weight,
						status: 'calculation',
						isSaved: false,
					},
				},
			],
		},
	});

	const ability = useDefineOrderAbility({ suborders: form.getValues('suborders') });

	return (
		<OrderAbilityContext.Provider value={ability}>
			<FormProvider {...form}>
				<Outlet />
			</FormProvider>
		</OrderAbilityContext.Provider>
	);
};

export default NewOrderController;
