import { FilterSearchParamsKey } from 'components/OrderCreatePageComponents/ProductFilter/lib/const';
import { ROUTES_URLS } from 'const';
import { useOrderRouter } from 'hooks/useOrderRouter';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ReactComponent as VerticalDotsIcon } from 'static/images/two-line-vertical-columns.svg';

import styles from './styles.module.css';
import { OrderExtraActionButtonProps } from './types';

const OrderExtraActionButton: React.FC<OrderExtraActionButtonProps> = ({ product, disabled, suborderIndex }) => {
	const router = useOrderRouter();
	const [searchParams] = useSearchParams();
	const location = useLocation();

	const isOnSplitOrder =
		location.pathname.endsWith(ROUTES_URLS.ORDER_PREVIEW_SPLIT_ORDER_SEGMENT) ||
		location.pathname.endsWith(ROUTES_URLS.ORDER_NEW_SPLIT_ORDER_SEGMENT);

	const onSameCategoryProductClick = () => {
		const categoryId = getCategoryId(product);

		if (categoryId) {
			searchParams.set(FilterSearchParamsKey.Category, categoryId);
		}

		if (isOnSplitOrder) {
			router.toAddProducts({ index: suborderIndex, searchParams: searchParams.toString() });
		} else {
			router.toAddProducts({ searchParams: searchParams.toString() });
		}
	};

	const handleClick = useStopPropagationCallback(onSameCategoryProductClick);

	return (
		<button data-order-extra-option-button type="button" onClick={handleClick} className={styles.button} disabled={disabled}>
			<VerticalDotsIcon />
		</button>
	);
};

export default OrderExtraActionButton;

function getCategoryId<TEntity extends { group?: AnyArg }>(entity: TEntity): string | undefined {
	return entity?.group?.id;
}
