import { useCallback, useEffect, useRef, useState } from 'react';
import { isRequestAborted } from 'utils/type-guards';

export type UseLoadableOptionsProps<TData> = {
	asyncLoader: (signal: AbortSignal) => Promise<TData>;
	skip: boolean;
};
type StateMachine = 'idle' | 'loading' | 'success' | 'error';
type State<TData> = {
	options: TData | null;
	requestStatus: StateMachine;
};

const defaultState = {
	options: null,
	requestStatus: 'idle' as const,
};

export const useLoadableOptions = <TData>({ asyncLoader, skip }: UseLoadableOptionsProps<TData>) => {
	const [state, setState] = useState<State<TData>>(defaultState);
	const abortControllerRef = useRef<AbortController>(new AbortController());

	const { options, requestStatus } = state;

	const isLoading = requestStatus === 'loading';
	const isError = requestStatus === 'error';
	const isSuccess = requestStatus === 'success';
	const isIdle = requestStatus === 'idle';

	const hasAllowedRequestStatus = isIdle;
	const shouldExecuteRequest = !skip && !!asyncLoader && hasAllowedRequestStatus && !options;

	const updateRequestStatus = (status: StateMachine) => {
		setState((prev) => ({ ...prev, requestStatus: status }));
	};

	const updateOptionsList = (data: TData) => {
		setState((prev) => ({ ...prev, options: data }));
	};

	const handleOptionsLoad = useCallback(async () => {
		try {
			updateRequestStatus('loading');
			const abortController = new AbortController();
			abortControllerRef.current = abortController;

			const optionsList = await asyncLoader(abortController.signal);

			if (!optionsList) {
				throw new Error('No options available');
			}

			updateRequestStatus('success');
			updateOptionsList(optionsList);
		} catch (error) {
			if (isRequestAborted(error)) {
				updateRequestStatus('idle');

				// eslint-disable-next-line no-console
				return console.log('Request was canceled.');
			}

			updateRequestStatus('error');
		}
	}, [asyncLoader]);

	useEffect(() => {
		return () => {
			abortControllerRef.current.abort();
		};
	}, []);

	useEffect(() => {
		if (shouldExecuteRequest) {
			handleOptionsLoad();
		}
	}, [shouldExecuteRequest, handleOptionsLoad]);

	const refetch = () => {
		abortControllerRef.current.abort();
		handleOptionsLoad();
	};

	const cancel = useCallback(() => {
		abortControllerRef.current.abort();
	}, []);

	const reset = useCallback(() => {
		setState({ requestStatus: 'idle', options: null });
	}, []);

	return { options, isError, isLoading, isSuccess, refetch, cancel, reset };
};
