import { createAsyncThunk } from '@reduxjs/toolkit';
import localForage from 'localforage';
import { IAuthResponse } from 'models/IAuthResponse';
import { ILogin } from 'models/IUser';

import apiClient from './apiClient';

export const loginRequest = createAsyncThunk<{ res: IAuthResponse }, ILogin>('auth/login', async (loginData: ILogin, thunkAPI) => {
	try {
		const response = await apiClient.post<IAuthResponse>('/auth/sign_in', loginData);
		return { res: response.data };
	} catch (e) {
		return thunkAPI.rejectWithValue(e);
	}
});

export const getRefreshToken = createAsyncThunk<{ res: IAuthResponse }>('auth/refresh', async (_, thunkAPI) => {
	try {
		const refreshToken = await localForage.getItem('refresh_token');
		const response = await apiClient.post<IAuthResponse>('/auth/refresh', { refresh_token: refreshToken });
		return { res: response.data };
	} catch (e) {
		return thunkAPI.rejectWithValue(e);
	}
});
