import { ORDER_IS_SAVED_STATUS, ROUTES_URLS } from 'const';
import { ISingleOrder } from 'models/IOrder';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import type { unstable_BlockerFunction as BlockerFunction } from 'react-router-dom';
import { unstable_useBlocker as useBlocker, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { localStorageService } from 'services/localStorageService';
import { createOrderActions } from 'store/reducers/orders';
import { useDeleteOrderMutation, useGetOrderByIdQuery, useUpdateOrderMutation } from 'store/reducers/orders/ordersSliceApi';
import { selectClientInfo, selectGlobalComment, selectOrder, selectOrderComment } from 'store/reducers/orders/selectors';
import { getPriceByTypePriceId } from 'store/reducers/orderViewer/utils';
import { checkIfOrderIsReserved } from 'utils/shared';

import { useAppDispatch, useAppSelector } from './redux';

export const useOrder = () => {
	const { id } = useParams<{ id: string }>();
	const { data: order } = useGetOrderByIdQuery(id, { skip: !id });
	const client = useSelector(selectClientInfo(id));

	if (!order) {
		return {
			id,
			client: {},
			statusList: [],
		};
	}

	return {
		id,
		order,
		client,
	};
};

export const useOrderStatus = () => {
	const { id } = useParams<{ id: string }>();
	const order = useSelector(selectOrder(id));

	// @ts-ignore
	return order?.status ?? '0';
};

// -- CRUD OPERATION HOOKS --//
export const useSaveOrder = () => {
	const { id } = useParams<{ id: string }>();
	const order = useSelector(selectOrder(id));
	const [mutate, state] = useUpdateOrderMutation();

	// @ts-ignore
	const isReserved = checkIfOrderIsReserved(order.status);

	const save = useCallback(
		(pathFn?: PatchFn) => {
			// @ts-ignore
			let orderToBeSaved = { ...order, status: isReserved ? order.status : ORDER_IS_SAVED_STATUS };

			if (pathFn) {
				orderToBeSaved = pathFn(orderToBeSaved);
			}

			// @ts-ignore
			return mutate(orderToBeSaved);
		},
		[order, id],
	);

	return [save, state] as const;
};

export const useDeleteOrder = () => {
	const { id } = useParams<{ id: string }>();
	const [mutate, state] = useDeleteOrderMutation();

	const remove = useCallback(() => {
		// @ts-ignore
		return mutate(id);
	}, [id]);

	return [remove, state] as const;
};

export const useOrderForUpdate = (order: ISingleOrder) => {
	const transformedProducts = order?.products.map((item) => ({
		productId: item.id,
		amount: item.amount || 1,
		price: item.price || getPriceByTypePriceId(item, 'ef741b50-b356-11e3-af42-8b1ea7c04b02') || 0,
	}));

	const serviceLength = Object.keys(order?.services ?? {}).length;

	const transformedServices =
		serviceLength !== 0
			? order?.services?.map((service) => {
					// @ts-ignore
					if (service.workType === undefined) {
						return {
							serviceId: service.id,
							// @ts-ignore
							amount: service.amount || service.quantity,
							price: service.price || 0,
						};
					} else {
						return {
							// @ts-ignore
							serviceId: service.workType.id,
							// @ts-ignore
							amount: service.amount || service.quantity,
							price: service.price || 0,
						};
					}
			  })
			: [];

	const transformedOrder = {
		id: order.id,
		note: order.note,
		// @ts-ignore
		clientId: order.client.id || order.client.value,
		// @ts-ignore
		contractId: order.contract.id || order.contract.value,
		// @ts-ignore
		responsibleId: order.responsible['1c_uuid'] || order.responsible.value,
		// @ts-ignore
		organizationId: order.organization.id || order.organization.value,
		// @ts-ignore
		stockId: order.stock.id || order.stock.value,
		typePriceId: order.typePrice.id,
		products: transformedProducts,
		isReserved: order.isReserved,
		isPaid: order.isPaid,
		isWithoutPayment: order.isWithoutPayment,
		services: transformedServices,
	};

	return transformedOrder;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PatchFn = (order: any) => any;

export const usePatchOrder = () => {
	const { id } = useParams<{ id: string }>();
	const order = useSelector(selectOrder(id));
	const [mutate, state] = useUpdateOrderMutation();

	const patch = useCallback(
		(patchFn: PatchFn) => {
			const patchedOrder = patchFn(order);
			const finalOrder = useOrderForUpdate(patchedOrder);
			// @ts-ignore
			return mutate(finalOrder);
		},
		[order, id],
	);

	return [patch, state] as const;
};

export const useUpdateProductsInOrder = () => {
	const { id } = useParams();
	const order = useSelector(selectOrder(id));
	const [mutate, { isLoading }] = useUpdateOrderMutation();

	const updateOrder = (product, amount?) => {
		// @ts-ignore
		const newOrder = useOrderForUpdate({ ...order, products: [...(order?.products ?? []), { ...product, amount }] });
		// @ts-ignore
		return mutate(newOrder);
	};

	return { updateOrder, isLoading };
};

export const useUpdateManyProductsInOrder = () => {
	const { id } = useParams();
	const order = useSelector(selectOrder(id));
	const [mutate, { isLoading }] = useUpdateOrderMutation();

	const updateOrder = (products) => {
		// @ts-ignore
		const newOrder = useOrderForUpdate({ ...order, products: [...(order?.products ?? []), ...products] });
		// @ts-ignore
		return mutate(newOrder);
	};

	return { updateOrder, isLoading };
};

// --- END CRUD OPERATION HOOKS --- //

// -- OPERATIONS OVER COMMENT -- //
const notifySuccess = (msg: string) => toast.success(msg, { autoClose: 1000, style: { zIndex: 999999999, position: 'fixed' } });

type UseOrderCommentProps = {
	onSuccess?(): void;
	onError?(): void;
	successAlert?: boolean;
	errorAlert?: boolean;
	global?: boolean;
};

export const useOrderComment = (props?: UseOrderCommentProps) => {
	const { id } = useOrder();
	const [patch, { isLoading, isError }] = usePatchOrder();
	const commentFromOrder = useSelector(selectOrderComment(id));
	const dispatch = useAppDispatch();
	const globalComment = useAppSelector(selectGlobalComment);

	const [comment, setComment] = useState<string>(commentFromOrder); // temp
	const { onSuccess, onError, successAlert = true, errorAlert = true, global = false } = props ?? {};

	useEffect(() => {
		if (global && globalComment) {
			setComment(globalComment);
		} else {
			setComment(commentFromOrder);
		}
	}, [commentFromOrder, global]);

	useEffect(() => {
		if (global) {
			dispatch(createOrderActions.setComment(comment));
		}
	}, [global, comment, dispatch]);

	useEffect(() => {
		if (isError) {
			if (errorAlert) toast.error('Помилка при збереженні коментаря', { autoClose: 1000, style: { zIndex: 999999999, position: 'fixed' } });

			onError?.();
		}
	}, [isError, errorAlert, onError]);

	const save = useCallback(async () => {
		if (!comment) return toast.error('Вкажіть коментар', { autoClose: 1000, style: { zIndex: 999999999, position: 'fixed' } });
		if (comment === commentFromOrder) return onSuccess?.();
		// @ts-ignore
		await patch((prevOrder) => ({ ...prevOrder, comment }));

		if (successAlert) notifySuccess('Коментар збережено');

		onSuccess?.();
	}, [comment, commentFromOrder, onSuccess, patch, successAlert]);

	const remove = useCallback(async () => {
		// @ts-ignore
		await patch((prevOrder) => ({ ...prevOrder, comment: '' }));

		if (successAlert) notifySuccess('Коментар видалено');

		onSuccess?.();
	}, [onSuccess, patch, successAlert]);

	return {
		orderId: id,
		value: comment,
		setComment,
		save,
		delete: remove,
		isLoading,
		isError,
	};
};
// --- END OVER COMMENT OPERATION --- //

// --- ORDER PROMPT BEFORE LEAVE ON CLIENT SELECT STEP OF CREATING ORDER FLOW ---//
export const useBeforeIncompleteOrderLeave = () => {
	const { order } = useOrder();
	const [deleteOrder] = useDeleteOrder();
	// @ts-ignore
	const hasSelectedClient = !!order?.client?.client;

	const shouldBlockNavigationToTargetRoute = useCallback<BlockerFunction>(
		({ currentLocation, nextLocation }) => {
			const isDifferentRoute = currentLocation.pathname !== nextLocation.pathname;

			return !hasSelectedClient && isDifferentRoute;
		},
		[hasSelectedClient],
	);

	const blocker = useBlocker(shouldBlockNavigationToTargetRoute);
	const isBlocked = blocker.state === 'blocked';

	const proceed = async () => {
		const targetRoute = blocker.location.pathname;
		// @ts-ignore
		const isSameRoute = targetRoute.endsWith(ROUTES_URLS.ORDER_CREATE);

		if (isSameRoute) {
			return blocker.reset();
		}

		localStorageService.remove('incomplete-order');

		await deleteOrder();
		blocker.proceed();
	};

	return { isBlocked, reset: blocker.reset, proceed };
};

type UseLongestStringWidthConfig = { limit: number };

export const useLongestStringWidth = <TData>(
	data: TData | TData[],
	extractor: (data: TData) => string,
	config?: UseLongestStringWidthConfig,
): number => {
	const { limit = 100_000 } = config ?? {};

	const longestStringWidth = useMemo(() => {
		if (!data && data) return 0;

		if (!Array.isArray(data)) {
			const str = extractor(data);

			return Math.min(str?.length, limit);
		}

		if (data.length === 0) return 0;

		const longestStringLength = data.reduce<number>((max, str) => {
			const currentLength = extractor(str).length;
			return currentLength > max ? currentLength : max;
		}, 0);

		return Math.min(longestStringLength, limit);
	}, [data, limit]);

	return longestStringWidth;
};
