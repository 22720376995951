import { useTypedOrderControllerFromContext } from 'pages/OrderRework/OrderController';
import React from 'react';

interface OrderProductSumDisplayFieldProps {
	suborderIndex: number;
	fieldIndex: number;
	entityId: string;
}

const OrderProductSumDisplayField: React.FC<OrderProductSumDisplayFieldProps> = ({ suborderIndex, entityId }) => {
	const { watch } = useTypedOrderControllerFromContext();
	const sumField = `suborders.${suborderIndex}.data.products.${entityId}.sum` as const;

	const sum = watch(sumField);

	return <span>{sum}</span>;
};

export default OrderProductSumDisplayField;
