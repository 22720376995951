import clsx from 'clsx';
import React from 'react';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';

import styles from './styles.module.css';
import type { IProps } from './types';

const OptionTag: React.FC<IProps> = ({ title, className, hideCloseButton = false, onUnselect }) => {
	return (
		<span className={clsx(styles.tag, className)}>
			<span className={styles.title}>{title}</span>

			{!hideCloseButton && (
				<button type="button" onClick={onUnselect} className={styles.close}>
					<XCloseIcon />
				</button>
			)}
		</span>
	);
};

export default OptionTag;
