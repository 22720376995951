import clsx from 'clsx';
import React, { type PropsWithChildren, ComponentProps } from 'react';

import styles from './styles.module.css';

type IProps = PropsWithChildren<ComponentProps<'div'>>;

const BottomPanel: React.FC<IProps> = ({ children, className, ...restProps }) => {
	return (
		<div className={clsx(styles.bottomPanel, className)} {...restProps}>
			{children}
		</div>
	);
};

export default BottomPanel;
