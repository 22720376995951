import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';
import type { AlertDialogueContentProps } from './types';

const AlertDialogueContent: React.FC<AlertDialogueContentProps> = ({ children, className, ...restProps }) => {
	return (
		<div className={clsx(styles.content, className)} {...restProps}>
			{children}
		</div>
	);
};

export default AlertDialogueContent;
