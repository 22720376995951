export const priceTypeColorMap = [
	{
		bgColor: '#FDF2FA',
		borderColor: '#FECDD6',
		fontColor: '#C01048',
		dotColor: '#F63D68',
		hoverBgColor: '#FCE7F6',
		activeColor: '#F63D68',
	},
	{
		bgColor: '#fff6e9',
		borderColor: '#f1c995',
		fontColor: '#f79009',
		dotColor: '#fbb457',
		hoverBgColor: '#fdead2',
		activeColor: '#fbb457',
	},
	{
		bgColor: '#F0F5FF',
		borderColor: '#3D90E3',
		fontColor: '#175CD3',
		dotColor: '#003a8c',
		hoverBgColor: '#cadbfc',
		activeColor: '#003a8c',
	},
	{
		bgColor: '#ECFDF3',
		borderColor: '#A6F4C5',
		fontColor: '#027A48',
		dotColor: '#12B76A',
		hoverBgColor: '#D1FADF',
		activeColor: '#12B76A',
	},
];
