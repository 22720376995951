import '../styles.css';

import clsx from 'clsx';
import React from 'react';
import { ReactComponent as ChevronDownIcon } from 'static/images/chevron-down.svg';

import type { ExpandedHeaderProps } from './types';

const ExpandedHeader = ({
	onClick,
	icon: Icon = ChevronDownIcon,
	onCollapse,
	children,
	className,
	bgColor,
	color,
	pb,
	pt,
	pl,
	pr,
}: ExpandedHeaderProps) => {
	return (
		<td
			className={clsx('expanded-header text-sx-medium', className)}
			onClick={onClick}
			style={
				{
					'--expanded-header-bg-color': bgColor,
					'--expanded-header-color': color,
					'--expanded-header-pl': pl,
					'--expanded-header-pr': pr,
					'--expanded-header-pt': pt,
					'--expanded-header-pb': pb,
				} as React.CSSProperties
			}
		>
			{children}

			<button type="button" onClick={onCollapse}>
				<Icon className="icon" style={{ marginTop: '2px' }} />
			</button>
		</td>
	);
};

export default ExpandedHeader;
