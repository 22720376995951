import 'static/styles/style.css';

import clsx from 'clsx';
import React from 'react';
import { ReactComponent as Close } from 'static/images/big-close.svg';

import styles from './style.module.css';
import { IProps } from './types';

const Drawer: React.FC<IProps> = ({ open, title, onClose, children, className }) => {
	return (
		open && (
			<>
				<div data-drawer className={clsx(styles.drawerContainer, styles.drawerOpen, className)}>
					<div className={styles.header}>
						<h2>{title}</h2>
						<Close className={styles.close} onClick={onClose} />
					</div>
					{children}
				</div>
				<div className={styles.overlay} onClick={onClose}></div>
			</>
		)
	);
};

export default Drawer;
