import { ExternalControlledMultipleSelectProps, ExternalControlledSelectProps, InnerControlledMultipleSelectProps } from '../types';

export const isExternallyControlledSelect = (props: Record<string, unknown>): props is ExternalControlledSelectProps => {
	return 'setValue' in props && 'value' in props;
};

export const isExternallyControlledMultipleSelect = (props: Record<string, unknown>): props is ExternalControlledMultipleSelectProps => {
	return 'setValue' in props && 'value' in props && 'multiple' in props && props.multiple === true;
};

export const isInnerControlledMultipleSelect = (props: Record<string, unknown>): props is InnerControlledMultipleSelectProps => {
	return !isExternallyControlledSelect(props) && 'multiple' in props && props.multiple === true;
};
