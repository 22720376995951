import 'static/styles/style.css';

import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import styles from './styles.module.css';
import { IProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Button: React.FC<IProps> = ({
	id,
	text,
	onClick,
	className,
	icon,
	variant,
	background,
	active,
	type = 'button',
	disableClassName,
	hoverBg,
	...restProps
}) => {
	const [activeState, setActiveState] = useState(false);

	useEffect(() => {
		setActiveState(active);
	}, [active]);

	const cssProperties = { '--button-hover-bg': hoverBg } as React.CSSProperties;

	if (variant === 'default') {
		return (
			<button
				id={id}
				className={clsx(styles.primaryButton, 'text-md-medium', className, restProps.disabled && disableClassName)}
				onClick={onClick}
				type={type}
				style={{ backgroundColor: background, borderColor: background, ...cssProperties } as React.CSSProperties}
				{...restProps}
			>
				{icon && <img src={icon} />} {text}
			</button>
		);
	} else if (variant === 'rounded') {
		return (
			<button
				id={id}
				style={{ backgroundColor: background, ...cssProperties }}
				className={clsx(styles.roundedButton, 'text-md-medium', className, restProps.disabled && disableClassName)}
				onClick={onClick}
				type={type}
				{...restProps}
			>
				{icon && <img src={icon} />} {text}
			</button>
		);
	} else if (variant === 'small') {
		return (
			<button
				className={clsx(styles.smallButton, 'text-sm-regular', className, {
					[styles.smallButton]: activeState,
					[styles.smallActive]: activeState,
					'text-sm-semibold': activeState,
				})}
				onClick={onClick}
				type={type}
				{...restProps}
			>
				{icon && <img src={icon} />} {text}
			</button>
		);
	} else if (variant === 'bordered') {
		return (
			<button
				style={{ padding: !text && '10px 10px' }}
				className={clsx(styles.borderedButton, 'text-sm-regular', className, {
					[styles.borderedButton]: activeState,
					[styles.smallActive]: activeState,
					'text-sm-semibold': activeState,
				})}
				onClick={onClick}
				type={type}
				{...restProps}
			>
				{icon && <img src={icon} />} {text}
			</button>
		);
	} else if (variant === 'smallRounded') {
		return (
			<button id={id} className={clsx(styles.smallRounded, 'text-sm-regular', className)} onClick={onClick} type={type} {...restProps}>
				{icon && <img src={icon} />} {text}
			</button>
		);
	} else if (variant === 'inverse') {
		return (
			<button
				id={id}
				className={clsx(styles.inverse, 'text-sm-regular', className, { [styles.inverseActive]: activeState })}
				onClick={onClick}
				type={type}
				{...restProps}
			>
				{icon && <img src={icon} />} {text}
			</button>
		);
	} else if (variant === 'inverseBordered') {
		return (
			<button id={id} className={clsx(styles.inverseBordered, 'text-sm-regular', className)} onClick={onClick} type={type} {...restProps}>
				{icon && <img src={icon} />} {text}
			</button>
		);
	}
	return null;
};

export default Button;
