import clsx from 'clsx';
import styles from 'components/Input/style.module.css';
import type { IProps as InputProps } from 'components/Input/typed';
import React, { ForwardedRef, forwardRef } from 'react';
import InputMask from 'react-input-mask';
type PhoneInputProps = Omit<InputProps, 'ref' | 'type'> & { mask?: string };

const defaultMask = '38 (999) 999 99 99';

const PhoneInput = (
	{
		mask = defaultMask,
		id,
		name,
		value,
		placeholder,
		label,
		icon,
		iconPosition = 'trailing',
		onClickIcon,
		setValue,
		disabled = false,
		readOnly = false,
		className,
		...restProps
	}: PhoneInputProps,
	ref: ForwardedRef<InputMask>,
) => {
	const isIconLeadingPosition = iconPosition === 'leading';
	const newMask = value ? mask : '';

	return (
		<div>
			{!!label && (
				<label htmlFor={id} className={`${styles.label} text-sm-medium color-grey-700`}>
					{label}
				</label>
			)}

			<div className={`${styles.align}`}>
				{icon && isIconLeadingPosition && (
					<img src={icon} alt="icon" className={styles.inputIcon} data-position={iconPosition} onClick={onClickIcon} />
				)}

				<InputMask
					{...restProps}
					mask={newMask}
					ref={ref}
					id={name || id}
					name={name}
					type="text"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					placeholder={placeholder}
					className={clsx(styles.inputStyle, 'no-user-agent-autofill-bg', className)}
					readOnly={readOnly}
					disabled={disabled}
					autoComplete="new-password"
				/>

				{icon && !isIconLeadingPosition && (
					<img src={icon} alt="icon" className={styles.inputIcon} data-position={iconPosition} onClick={onClickIcon} />
				)}
			</div>
		</div>
	);
};

export default forwardRef(PhoneInput);
