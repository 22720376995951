import type { PropsWithChildren } from 'react';
import React from 'react';

import EmptySuborder from '../EmptySuborder';

interface IProps extends PropsWithChildren {
	isEmpty: boolean;
}

const EmptyProductsGate: React.FC<IProps> = ({ children, isEmpty }) => {
	return !isEmpty ? children : <EmptySuborder />;
};

export default EmptyProductsGate;
