import clsx from 'clsx';
import { useCopyToClipboard } from 'components/FormComponents/useCopyToClipboard';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import styles from '../styles.module.css';
import type { IProps } from './types';

const CopyToClipboard = ({ name, beforeCopyText, afterCopyText, className, ...restProps }: IProps) => {
	const { copy, isCopied } = useCopyToClipboard();
	const { watch } = useFormContext();

	const value = watch(name);

	function handleCopy() {
		copy(value);
	}

	return (
		<button type="button" onClick={handleCopy} className={clsx(styles.action, className)} {...restProps}>
			{isCopied ? afterCopyText : beforeCopyText}
		</button>
	);
};

export default CopyToClipboard;
