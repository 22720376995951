import clsx from 'clsx';
import { useOrderColumns } from 'columns/order';
import { useRegularServicesColumns } from 'columns/services';
import BreadCrumbs from 'components/BreadCrumbs';
import type { Breadcrumb } from 'components/BreadCrumbs/types';
import Button from 'components/Button';
import EmptyProductsGate from 'components/OrderPageComponents/(EmptyStateGates)/EmptyProductsGate';
import EmptyServicesGate from 'components/OrderPageComponents/(EmptyStateGates)/EmptyServicesGate';
import FullScreenGate from 'components/OrderPageComponents/(VisibilityGates)/FullScreenGate';
import Spacer from 'components/Spacer';
import Spinner from 'components/Spinner';
import Table from 'components/Table';
import { TablistInMemory } from 'components/Tablist';
import Panel from 'components/Tablist/Panel';
import { breakPoints, ROUTES_URLS } from 'const';
import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import { useActiveTab } from 'hooks/useActiveTab';
import { useBoolean } from 'hooks/useBoolean';
import { useOrderRouter } from 'hooks/useOrderRouter';
import { usePinnedColumns } from 'hooks/usePinnedColumns';
import { useSelectedRows } from 'hooks/useSelectedRows';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import ActionButton from 'layouts/PageLayout/ActionButton';
import React, { Suspense, useMemo } from 'react';
import MediaQuery from 'react-responsive';
import PlusIcon from 'static/images/plus.svg';
import { bakeOrderBreadcrumbs } from 'utils/orders';
import { prepareUrl } from 'utils/shared';

import ChangeProductPricePanel from '../ChangeProductPricePanel';
import EmptyOrder from '../components/EmptyOrder';
import ServiceSelectors from '../components/ServiceSelectors';
import { useOrderNotifications } from '../hooks/useOrderNotifications';
import { OnEntityDeletePayload, useOrderOperationMethods } from '../hooks/useOrderOperationMethods';
import { Can } from '../OrderAbility/provider';
import OrderActionButtons from '../OrderActionButtons';
import { ServiceInternalModelState } from '../OrderController';
import { getOrderControllerEntityRowId } from '../OrderController/lib/transform';
import { getCanSelectProductRowHandler, getCanSelectServiceRowHandler } from '../OrderController/lib/utils';
import OrderEntitiesControlPanel from '../OrderEntitiesControlPanel';
import OrderTopSide from './components/OrderTopSide';
import styles from './styles.module.css';

const draftOrderIndex = 0; // as we consider draft order as first entity in suborders array

const OrderModule: React.FC = () => {
	const router = useOrderRouter();
	const dialogue = useOrderAlertDialogue();

	const fullScreen = useBoolean();
	const changePriceMode = useBoolean();
	const changePriceModeToggle = useStopPropagationCallback(changePriceMode.toggle);
	const notify = useOrderNotifications();
	const { activeTab, onSwitchTab } = useActiveTab();
	const { onEntityDelete, addEntityToOrder, watch, getValues } = useOrderOperationMethods();
	const selectionModel = useSelectedRows();

	const suborders = getValues('suborders');
	const servicesRecord = watch(`suborders.${0}.data.services`);
	const productsRecord = watch(`suborders.${0}.data.products`);
	const services = Object.values(servicesRecord ?? {});
	const products = Object.values(productsRecord ?? {});

	// products columns
	const { columns, pinningModel, setPinningModel, visibilityModel, setVisibilityModel, visibilityModelSaveConfigKey } = useOrderColumns({
		adjustSaveKey: '/preview/order',
		suborderIndex: draftOrderIndex,
	});

	const isNewOrder = window.location.pathname.startsWith('/' + ROUTES_URLS.ORDER_NEW_ROOT + '/' + ROUTES_URLS.ORDER_ROOT_SEGMENT);
	const isOnProductEntitiesTab = activeTab === 0;
	const productsCount = products.length;
	const servicesCount = services.length;
	const entitySelectionModel = selectionModel.rowSelectionModel;
	const selectedEntities = useMemo(() => Object.keys(entitySelectionModel ?? {}), [entitySelectionModel]);
	const hasSelectedEntities = selectedEntities.length > 0;
	const isChangePriceAvailable = isOnProductEntitiesTab && hasSelectedEntities && !changePriceMode.isOn;
	const isEmptyOrder = productsCount === 0 && servicesCount === 0;
	const hasServerSavedSuborders = false;
	const entityName = isOnProductEntitiesTab ? 'products' : 'services';

	// delete entities handlers
	const handleEntityDelete = () => {
		onEntityDelete({ entityName, suborderIndex: draftOrderIndex, candidates: selectedEntities });
	};
	const handleEntityDeleteSafe = () => {
		const key = entityName === 'products' ? 'deleteProducts' : 'deleteServices';
		dialogue.open(key, { onSubmit: handleEntityDelete, data: selectedEntities.length });
	};
	const handleOneServiceDeleteSafe = (payload: OnEntityDeletePayload) => {
		dialogue.open('deleteServices', { onSubmit: () => onEntityDelete(payload), data: selectedEntities.length });
	};

	// services columns
	const servicesColumns = useRegularServicesColumns({ suborderIndex: draftOrderIndex, onEntityDelete: handleOneServiceDeleteSafe });
	const servicesPinning = usePinnedColumns({ saveConfigKey: '/preview/order/services/' + draftOrderIndex });

	const onOrderSave = () => {};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const onReservation = () => {};

	// add services handler
	const onAddService = (service: ServiceInternalModelState) => {
		addEntityToOrder({ candidates: [service], entityName: 'services', to: draftOrderIndex });
		notify.successEntityAddedToOrder('services');
	};

	const { id: orderId, number: orderNumber, createdAt } = suborders[draftOrderIndex]?.data ?? {};

	const crumbs: Breadcrumb[] = [
		{ label: 'Заявки', href: prepareUrl(ROUTES_URLS.ORDERS) },
		bakeOrderBreadcrumbs({ id: orderId, number: orderNumber, createdAt }),
	];

	const orderEntityControlPanel = (
		<OrderEntitiesControlPanel
			entityColumns={columns}
			isFullScreen={fullScreen.isOn}
			onFullScreenChange={fullScreen.toggle}
			onVisibilityModelChange={setVisibilityModel}
			visibilityModel={visibilityModel}
			hasSelectedEntities={hasSelectedEntities}
			isChangePriceAvailable={isChangePriceAvailable}
			onDelete={handleEntityDeleteSafe}
			onChangePriceModeToggle={changePriceMode.toggle}
			isChangePriceMode={changePriceMode.isOn}
			visibilitySaveConfigKey={visibilityModelSaveConfigKey}
			isPriceTypeColumnInsertionDisabled={!isOnProductEntitiesTab}
			suborderIndex={draftOrderIndex}
		>
			<OrderActionButtons />
		</OrderEntitiesControlPanel>
	);

	return (
		<div className="main-wrapper">
			<div className={clsx('container', styles.container)}>
				<BreadCrumbs crumbs={crumbs} />

				<FullScreenGate isFullScreen={fullScreen.isOn}>
					<OrderTopSide suborderIndex={draftOrderIndex} hasEntities={productsCount > 0} isFullScreen={fullScreen.isOn} />
				</FullScreenGate>

				<Spacer height="14px" />

				{isEmptyOrder && <EmptyOrder suborderIndex={0} />}

				{!isEmptyOrder && (
					<TablistInMemory
						slot={orderEntityControlPanel}
						onTabChange={onSwitchTab}
						activeIndex={activeTab}
						className={clsx({ [styles.disableTabNavigation]: changePriceMode.isOn })}
					>
						<Panel title="Додані товари" itemsCount={productsCount} className={styles.panel}>
							<Suspense fallback={<Spinner />}>
								{changePriceMode.isOn && (
									<Can I="change" an={`order.${draftOrderIndex}.price`}>
										<ChangeProductPricePanel
											onCancel={changePriceModeToggle}
											onClose={changePriceModeToggle}
											suborderIndex={draftOrderIndex}
											selectionModel={selectionModel.rowSelectionModel}
											className={styles.changeProductPricePanel}
										/>
									</Can>
								)}
							</Suspense>

							<EmptyProductsGate isEmpty={products.length === 0}>
								<Can passThrough I="change" an={`order.${draftOrderIndex}.products.table`}>
									{(can) => (
										<Table
											className={clsx(styles.table, {
												['table-disabled']: changePriceMode.isOn,
												['table-body-disabled']: !can,
											})}
											visibilityModel={visibilityModel}
											onPinningModelChange={setPinningModel}
											pinningModel={pinningModel}
											allData={products}
											pageType="order"
											getRowId={getOrderControllerEntityRowId}
											getCanSelectRow={getCanSelectProductRowHandler(draftOrderIndex, suborders)}
											rowSelectionModel={selectionModel.rowSelectionModel}
											onRowSelectionModelChange={selectionModel.setRowSelectionModel}
											columns={columns}
										/>
									)}
								</Can>
							</EmptyProductsGate>
						</Panel>
						<Panel title="Додані послуги" itemsCount={servicesCount} className={styles.panel}>
							<Can passThrough I="add" a={`order.${draftOrderIndex}.services`}>
								{(can) => <ServiceSelectors appearance="button-list" onAddOrder={onAddService} disabled={!can} />}
							</Can>

							<EmptyServicesGate isEmpty={services.length === 0} className={styles.emptySuborder}>
								<Can passThrough I="change" an={`order.${draftOrderIndex}.services.table`}>
									{(can) => (
										<Table
											className={clsx(styles.table, { ['table-body-disabled']: !can })}
											onPinningModelChange={servicesPinning.setPinningModel}
											pinningModel={servicesPinning.pinningModel}
											allData={services}
											getRowId={getOrderControllerEntityRowId}
											pageType="order"
											rowSelectionModel={selectionModel.rowSelectionModel}
											onRowSelectionModelChange={selectionModel.setRowSelectionModel}
											columns={servicesColumns}
											getCanSelectRow={getCanSelectServiceRowHandler(draftOrderIndex, suborders)}
										/>
									)}
								</Can>
							</EmptyServicesGate>
						</Panel>
					</TablistInMemory>
				)}

				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<Suspense>
						<ActionButton
							variant="default"
							onClick={() => router.toAddProducts()}
							text="Додати товар"
							icon={PlusIcon}
							className={styles.addProductButton}
						/>
						<div className="safe-area-bottom" />
					</Suspense>
				</MediaQuery>

				{!changePriceMode.isOn && !isEmptyOrder && (
					<MediaQuery minWidth={breakPoints.MOBILE}>
						<div className={styles.fixedBtn}>
							{!hasServerSavedSuborders && (
								<Can I="add" an={`order.${draftOrderIndex}.products`}>
									<Button
										text="Додати товар"
										variant="rounded"
										icon={PlusIcon}
										onClick={() => router.toAddProducts()}
										className={styles.addProductButton}
									/>
								</Can>
							)}
							{isNewOrder && (
								<Button
									text="Створити заявку"
									variant="rounded"
									background="var(--primary-600)"
									className={styles.btn}
									onClick={onOrderSave}
									disableClassName={styles.disableBtn}
								/>
							)}
						</div>
					</MediaQuery>
				)}
			</div>
		</div>
	);
};

export default OrderModule;
