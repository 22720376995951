export const orderQueryKeys = {
	orders: () => 'Orders' as const,
	order<TUuid>(id: TUuid) {
		return { type: this.orders(), id };
	},
	ordersOnly() {
		return this.order('ORDERS_ONLY');
	},
	productFilters: () => 'ProductFilters' as const,
	clientFilters: () => 'ClientFilters' as const,
	services: () => 'orderServices' as const,
	service<TUuid>(id: TUuid) {
		return { type: this.services(), id };
	},
};

export const productQueryKeys = {
	products: () => 'Products' as const,
	product<TUuid>(id: TUuid) {
		return { type: this.products(), id };
	},
};

export const filterTemplatesQueryKeys = {
	filterTemplates: () => 'Templates' as const,
};

export const clientsQueryKeys = {
	clients: () => 'Clients' as const,
	client<TUuid>(id: TUuid) {
		return { type: this.clients(), id };
	},
};

export const usersQueryKeys = {
	users: () => 'Users' as const,
	user<TUuid>(id: TUuid) {
		return { type: this.users(), id };
	},
	usersOnly() {
		return this.user('USERS_ONLY');
	},
};
