import { useModalContext } from 'contexts/ModalStackProvider';
import React from 'react';

export type UseModalProps<TKeys> = {
	modal: (ctx: { closeModal(): void; closeAll(): void } & Record<string, AnyArg>) => JSX.Element;
	key: TKeys;
	rerenderKey?: string;
};

export const useModal = <TKeys,>({ modal: ModalComponent, key }: UseModalProps<TKeys>) => {
	const ctx = useModalContext();

	const openModal = (props?: AnyArg) => {
		ctx.open(<ModalComponent closeAll={ctx.closeAll} closeModal={ctx.close.bind(null, key)} {...props} />, { key });
	};

	const closeModal = () => ctx.close(key);

	return { openModal, closeModal, isOpened: ctx.getIsOpenedStatus(key), closeAll: ctx.closeAll };
};
