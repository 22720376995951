import { ReactComponent as CreationDate } from 'static/images/calendar-check-01.svg';
import { ReactComponent as Calendar } from 'static/images/calendar-plus.svg';
import { ReactComponent as Case } from 'static/images/case.svg';
import { ReactComponent as Chart } from 'static/images/chart.svg';
import { ReactComponent as Clock } from 'static/images/clock-fast.svg';
import { ReactComponent as Coin } from 'static/images/coins.svg';
import { ReactComponent as ClientStatus } from 'static/images/face-smile.svg';
import { ReactComponent as File } from 'static/images/file-attachment.svg';
import Funnel from 'static/images/filter-funnel.svg';
import { ReactComponent as ActivityType } from 'static/images/glasses-02.svg';
import { ReactComponent as Comment } from 'static/images/message-text-square-02.svg';
import { ReactComponent as Edrpu } from 'static/images/notification-text.svg';
import Plus from 'static/images/plus.svg';
import { ReactComponent as User } from 'static/images/user-02.svg';
import { ReactComponent as UserCircle } from 'static/images/user-circle.svg';

export const clientsIcons = {
	Calendar: Calendar,
	Case: Case,
	Chart: Chart,
	Clock: Clock,
	Coin: Coin,
	File: File,
	User: User,
	UserCircle: UserCircle,
	Funnel: Funnel,
	Plus: Plus,
	EdrpouCode: Edrpu,
	ActivityType: ActivityType,
	CreationDate: CreationDate,
	ClientStatus: ClientStatus,
	Comment: Comment,
};
