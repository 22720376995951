import { paintBase, paintCollections } from 'components/PaintToningPageComponents/lib/mockData';

export const loadPaintCollectionOptions = () => {
	return new Promise((res) => {
		setTimeout(() => {
			res(paintCollections);
		}, 100);
	});
};

export const loadPaintBaseToneOptions = () => {
	return new Promise((res) => {
		setTimeout(() => {
			res(paintBase);
		}, 100);
	});
};
