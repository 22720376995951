import { Breadcrumb } from 'components/BreadCrumbs/types';
import { getColorByIndex } from 'components/ColorfulSelect/utils';
import { Order } from 'models/order';

export const bakeOrderBreadcrumbs = (order: Order): Breadcrumb => {
	if (!order.id) return { label: 'Створення заявки' };

	return {
		label: `Заявка №${order.number} від ${new Date(order.createdAt).toLocaleDateString('uk-UA')}`,
	};
};

export const bindColorToStatus = (status: string | number) => {
	const color = getColorByIndex(status);

	return color;
};

export const translateNameOfBadge = (title: string) => {
	switch (title) {
		case 'calculation':
			return 'Прорахунок';
		case 'no_payment':
			return 'Без оплати';
		case 'reserve':
			return 'Резерв';
		case 'partially_shipped':
			return 'Частково відвантажено';
		case 'shipped':
			return 'Відвантажено';
		case 'closed':
			return 'Закрито';
		case 'overpayment':
			return 'Переплата';
		case 'paid':
			return 'Оплачено';
		case 'partially_payment':
			return 'Часткова оплата';
		default:
			return title;
	}
};
