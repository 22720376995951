import React from 'react';
import { ReactComponent as DotIcon } from 'static/images/Dot.svg';

import type { IProps } from './types';

const FilterIcon: React.FC<IProps> = ({ isActive, style, ...restProps }) => {
	const fill = isActive ? '#12B76A' : '#717BBC';

	return <DotIcon style={{ fill, width: '10px', height: '10px', ...style }} {...restProps} />;
};

export default FilterIcon;
