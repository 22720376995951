import { zodResolver } from '@hookform/resolvers/zod';
import FormField from 'components/FormComponents/FormField';
import Input from 'components/Input';
import Modal from 'components/Modal';
import ModalBody from 'components/Modal/ModalBody';
import ModalControls from 'components/Modal/ModalControls';
import ModalHeader from 'components/Modal/ModalHeader';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withdrawReservationSchema } from 'validation/schemas/common';

import styles from './styles.module.css';
import type { WithdrawalReservationForm, WithdrawReservationModalProps } from './types';

const WithdrawReservationModal: React.FC<WithdrawReservationModalProps> = ({
	onSubmit,
	onClose,
	warehouse = '',
	inReservation = 1,
	defaultQuantity = 1,
	disable = false,
}) => {
	const form = useForm<WithdrawalReservationForm>({
		resolver: zodResolver(withdrawReservationSchema(inReservation)),
		defaultValues: { withdrawQuantity: defaultQuantity },
		shouldUnregister: true,
	});

	const handleFormSubmit = (values: WithdrawalReservationForm) => {
		onSubmit(values);
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(handleFormSubmit)}>
				<Modal open noAwayClick stackable onClose={onClose} maxWidth="500px" minHeight="330px" maxHeight="450px" className={styles.modal}>
					<ModalHeader title="Зняти товари з резерву" disabled={disable} onXCloseClick={onClose} />

					<ModalBody className={styles.modalBody}>
						<h3 className={styles.inReserveTitle}>
							Зарезервовано: <span className={styles.inReserveQuantity}>{inReservation}</span>
						</h3>
						<p className={styles.inReserveWarehouse}>
							Склад: <span className={styles.inReserveWarehouseName}>{warehouse}</span>
						</p>

						<FormField
							name="withdrawQuantity"
							label="Видалити з резерву"
							component={Input}
							fieldClassName={styles.withdrawReservationInput}
							disabled={disable}
						/>
					</ModalBody>

					<ModalControls
						submitButtonType="submit"
						cancelButtonText="Скасувати"
						submitButtonText="Виконати"
						className={styles.modalControls}
						onCancel={onClose}
						disable={disable}
					/>
				</Modal>
			</form>
		</FormProvider>
	);
};

export default WithdrawReservationModal;
