import { Colorant } from 'models/IPaintToning';
import { uuid } from 'utils/shared';

export const paintBrands = [
	{ label: 'Ceresit', value: 'ceresit' },
	{ label: 'Shtock', value: 'shtock' },
	{ label: 'Lux', value: 'lux' },
	{ label: 'Metaltech', value: 'metaltech' },
	{ label: 'LionXL', value: 'lionXL' },
];
export const paintCollections = [
	{ label: 'ASC-3', value: 'asc-3' },
	{ label: 'NBV', value: 'nbv' },
	{ label: 'AAA', value: 'aaa' },
	{ label: 'C-567-N', value: 'c-567-n' },
	{ label: 'TQ-56', value: 'tq-56' },
];
export const paintPalette = [
	{ label: 'KX - титанічно-білий', value: 'KX', note: null, colorCode: '#ffffff' },
	{ label: 'V - пурпурний', value: 'V', note: null, colorCode: '#7B61FF' },
	{ label: 'RN - червоний', value: 'RN', note: null, colorCode: '#FF4747' },
	{ label: 'A3-R - НАСИЧЕНИЙ червоний', value: 'A3-R', note: null, colorCode: '#FF0000' },
	{ label: 'R - органічний червоний', value: 'R', note: null, colorCode: '#FF4747' },
	{ label: 'F - червоно-коричневий', value: 'F', note: 'Неорганічний', colorCode: '#EB3E08' },
	{ label: 'I - коричневий залізоокчидний', value: 'I', note: 'Неорганічний', colorCode: '#DE890A' },
	{ label: 'L - темно-коричневий', value: 'L', note: 'Неорганічний', colorCode: '#7E3D00' },
	{ label: 'C - жовто-оксидний', value: 'C', note: 'Неорганічний', colorCode: '#F0BB54' },
	{ label: 'T - середньо-жовтий', value: 'T', note: null, colorCode: '#FFD706' },
	{ label: 'AN - жовтий', value: 'AN', note: null, colorCode: '#FFF500' },
	{ label: 'AXX - органічно-жовтий', value: 'AXX', note: null, colorCode: '#F9F489' },
	{ label: 'D - зелений', value: 'D', note: null, colorCode: '#00BA65' },
	{ label: 'E - синій', value: 'E', note: null, colorCode: '#1570EF' },
	{ label: 'B - чорний', value: 'B', note: 'Неорганічний', colorCode: '#475467' },
	{ label: 'BX - НАСИЧЕНИЙ чорний', value: 'BX', note: 'Неорганічний', colorCode: '#000000' },
];
export const paintPaletteCodes = [
	{ label: 'SDFD-4', value: 'SDFD-4' },
	{ label: '99-DSDMS-3', value: '99-DSDMS-3' },
	{ label: 'JJ-454-KK', value: 'JJ-454-KK' },
	{ label: 'PPODS', value: 'PPODS' },
	{ label: 'MMXNCS-9', value: 'MMXNCS-9' },
];
export const paintTare = [
	{
		label: '5 кг відро',
		value: 'v-5',
	},
	{
		label: '7 кг відро',
		value: 'v-7',
	},
	{
		label: '12 кг відро',
		value: 'v-12',
	},
	{
		label: '50 кг мішок',
		value: 'm-50',
	},
	{
		label: '100 кг бочка',
		value: 'b-100',
	},
];

export const paintProductCodes = [
	{ label: 'VABC123', value: 'ABC123' },
	{ label: 'DEF456', value: 'DEF456' },
	{ label: 'GHI789', value: 'GHI789' },
	{ label: 'JKL012', value: 'JKL012' },
];
export const paintBase = [
	{ label: 'База A', value: 'База A - біла' },
	{ label: 'База B', value: 'База B - прозора' },
	{ label: 'База C', value: 'База C - прозорва біла' },
];

export const getRandomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
export const generateRandomColorantsArray = (colorCode: string): Colorant[] => {
	const colorants: Colorant[] = [];
	const numberOfColorants = getRandomNumber(1, 10);

	for (let i = 0; i < numberOfColorants; i++) {
		const id = uuid();
		const title = 'Пігмент';
		const code = numberOfColorants > 1 ? (Math.random() > 0.5 ? colorCode : '0') : colorCode;
		const quantityInMl = code === '0' ? 0 : getRandomNumber(10, 100);
		const mlPriceWithTax = code === '0' ? 0 : getRandomNumber(50, 200);
		const priceWithTax = code === '0' ? 0 : quantityInMl * mlPriceWithTax;

		colorants.push({
			id,
			title,
			code,
			quantityInMl,
			mlPriceWithTax,
			priceWithTax,
		});
	}

	return colorants;
};
