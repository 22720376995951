export const defaultDocumentDateFormatter = (date: number | string | Date) => {
	const options = {
		second: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		month: '2-digit',
		minute: '2-digit',
		year: '2-digit',
	} as const;

	const formattedDateTime = new Intl.DateTimeFormat('uk-UA', options).format(new Date(date));

	return formattedDateTime.replace(/,/g, '');
};

export const defaultReservationDateFormatter = (date: number | string | Date) => {
	return new Intl.DateTimeFormat('uk-UA').format(new Date(date));
};
