import clsx from 'clsx';
import React from 'react';

import type { PanelProps } from './types';

const Panel: React.FC<PanelProps> = ({ children, className, onClick }) => {
	return (
		<div onClick={onClick} className={clsx(className)}>
			{children}
		</div>
	);
};
export default Panel;
