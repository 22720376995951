import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';
import type { IProps } from './types';

const ModalBody: React.FC<IProps> = ({ children, className, ...restProps }) => {
	return (
		<div className={clsx(styles.modalBody, className)} {...restProps}>
			{children}
		</div>
	);
};

export default ModalBody;
