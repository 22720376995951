import clsx from 'clsx';
import {
	SearchResultDataItem,
	SearchResultListCompositionStrategy,
	SearchResultListProps,
	SearchResultListRenderPropsStrategy,
} from 'components/MainSearch/lib/types';
import { useMainSearch } from 'components/MainSearch/Provider';
import SpinnerV2 from 'components/Spinner-v2';
import React, { Children } from 'react';
import { isObject } from 'utils/type-guards';

import styles from './styles.module.css';

const isRenderPropsStrategy = <T extends SearchResultDataItem>(props: unknown): props is SearchResultListRenderPropsStrategy<T> => {
	return isObject<T>(props) && 'render' in props;
};

export const SearchResultList = <T extends SearchResultDataItem>(props: SearchResultListProps<T>) => {
	const { onOptionClick } = useMainSearch();

	let listElements: React.ReactNode[] = [];
	let needRenderEmptyState = false;
	let needRenderOptions = false;

	const { isLoading } = props;

	if (isRenderPropsStrategy(props)) {
		const { items, render, emptyState } = props;
		listElements = items.map((item, index) =>
			render({
				item,
				index,
				onClick: () => {
					props?.onSelect(item);
					onOptionClick();
				},
			}),
		);
		needRenderEmptyState = !isLoading && !!emptyState && listElements.length < 1;
		needRenderOptions = !isLoading && listElements.length > 0;
	} else {
		const { children, emptyState } = props as SearchResultListCompositionStrategy<T>;
		listElements = Children.toArray(children);
		needRenderEmptyState = !isLoading && !!emptyState && listElements.length < 1;
		needRenderOptions = !isLoading && listElements.length > 0;
	}

	return (
		<div className={styles.searchBox}>
			<ul className={styles.scrollArea}>
				{needRenderEmptyState && (
					<li className={styles.emptyState}>
						<strong>{props.emptyState}</strong>
					</li>
				)}

				{needRenderOptions && listElements}

				{isLoading && (
					<li className={clsx(styles.loadingSpinner)}>
						<SpinnerV2 />
					</li>
				)}
			</ul>
		</div>
	);
};
