import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import localForage from 'localforage';
import {
	MakeBusinessOfferRequestDTO,
	MakeBusinessOfferResult,
	MakeBusinessOfferResultDTO,
	transformToMakeBusinessOfferResult,
} from 'models/business-offer';
import { API_URL } from 'services/api';
import { usersQueryKeys } from 'services/queryKeys';

import apiClient from '../auth/apiClient';

const getAuthToken = async () => {
	try {
		const token = await localForage.getItem('token');
		return token;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error getting auth token:', error);
		return null;
	}
};

export const businessOfferSliceApi = createApi({
	reducerPath: 'businessOffer',
	baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
	tagTypes: [usersQueryKeys.users()],
	endpoints: (builder) => ({
		makeBusinessOffer: builder.mutation<MakeBusinessOfferResult, MakeBusinessOfferRequestDTO>({
			queryFn: async (dto) => {
				const authToken = await getAuthToken();
				try {
					const response = await apiClient.post<{ data: MakeBusinessOfferResultDTO }>('/business-offers', dto, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status < 200 && response.status >= 300) {
						throw new Error(response.statusText);
					}

					const businessOffer = transformToMakeBusinessOfferResult(response.data.data);

					return {
						data: businessOffer,
					};
				} catch (error) {
					throw error;
				}
			},
		}),
	}),
});

export const { useMakeBusinessOfferMutation } = businessOfferSliceApi;
