import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISearchState } from './types';

const initialState = {
	searchBy: 'none',
} as ISearchState;

export const authSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setSearchBy: (state, action: PayloadAction<Pick<ISearchState, 'searchBy'>>) => {
			state.searchBy = action.payload.searchBy;
		},
	},
});

export default authSlice.reducer;
export const { setSearchBy } = authSlice.actions;
