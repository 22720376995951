/* eslint-disable no-console */
import Button from 'components/Button';
import React from 'react';
import SettingsIcon from 'static/images/settings.svg';

import styles from './style.module.css';
import { SettingButtonProps } from './types';

const SettingButton: React.FC<SettingButtonProps> = ({ onClick, children }) => {
	return (
		<div className={styles.box}>
			<Button onClick={(e) => onClick(e)} icon={SettingsIcon} variant="bordered" />
			{children}
		</div>
	);
};

export default SettingButton;
