import { ROUTES_URLS } from 'const';
import { prepareUrl } from 'utils/shared';

const searchConfigDictionary = {
	[prepareUrl(ROUTES_URLS.ORDERS)]: {
		searchBy: ['client', 'number', 'price'],
		buttonText: 'Клієнт',
	},
	[prepareUrl(ROUTES_URLS.CLIENTS)]: {
		searchIn: ['client'],
		buttonText: 'Клієнт',
	},
};

const defaultConfig = {
	searchBy: null,
	buttonText: 'Пошук',
};

export const getSearchConfig = () => {
	const location = window.location.pathname;

	return searchConfigDictionary[location] || defaultConfig;
};
