import clsx from 'clsx';
import React from 'react';
import { ReactComponent as MinusIcon } from 'static/images/minus.svg';
import { ReactComponent as PlusIcon } from 'static/images/plus-circle.svg';
import { ReactComponent as TrashIcon } from 'static/images/trash.svg';

import styles from './styles.module.css';
import type { CounterProps } from './types';

const Counter: React.FC<CounterProps> = ({
	onQuantityChange,
	onBlur,
	disabled = false,
	className,
	value: counter,
	withDeleteButton = false,
	onDelete,
}) => {
	const isDecrementDisabled = counter <= 1;

	const increment = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		onQuantityChange?.(counter + 1);
	};

	const decrement = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		if (isDecrementDisabled) return;

		onQuantityChange?.(counter - 1);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onQuantityChange?.(Number(e.target.value));
	};

	const isDecrementButtonDisabled = disabled || isDecrementDisabled;
	const isIncrementButtonDisabled = disabled;

	return (
		<div data-counter className={clsx(styles.counter, className)}>
			{withDeleteButton && (
				<button className={styles.counterButton} type="button" onClick={onDelete}>
					<TrashIcon />
				</button>
			)}
			<button className={styles.counterButton} type="button" onClick={decrement} disabled={isDecrementButtonDisabled}>
				<MinusIcon />
			</button>

			<input type="number" onBlur={onBlur} className={styles.counterAmount} value={counter} onChange={onChange} />

			<button disabled={isIncrementButtonDisabled} className={styles.counterButton} type="button" onClick={increment}>
				<PlusIcon />
			</button>
		</div>
	);
};

export default Counter;
