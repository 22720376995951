import clsx from 'clsx';
import React from 'react';
import { ReactComponent as TrashIcon } from 'static/images/trash.svg';

import styles from './styles.module.css';
import type { IProps } from './types';

const ServiceDeleteButton: React.FC<IProps> = ({ onDelete, className }) => {
	const handleServiceDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		onDelete?.();
	};

	return (
		<button type="button" onClick={handleServiceDelete} className={clsx(styles.button, className)}>
			<TrashIcon className={styles.icon} />
		</button>
	);
};

export default ServiceDeleteButton;
