import clsx from 'clsx';
import FormField from 'components/FormComponents/FormField';
import { AutocompleteFieldForClient, AutocompleteFieldWithOnSelectAction } from 'components/FormComponents/Inputs/inputs';
import { ROUTES_URLS } from 'const';
import { AnyOption, Option, OptionSchema } from 'models/common/options';
import { standardizeOption } from 'models/common/preprocess';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetClientsOptionsQuery, useGetContractListQuery } from 'store/reducers/clients/clientsSliceApi';
import { useGetEmployeesOptionListQuery, useGetOrganizationsOptionListQuery, useGetWarehousesQuery } from 'store/reducers/users/usersSliceApi';
import { prepareUrl } from 'utils/shared';
import { isNullish, isObject } from 'utils/type-guards';

import { Can } from '../OrderAbility/provider';
import { useTypedOrderControllerFromContext } from '../OrderController';
import styles from './styles.module.css';

const fallbackList = [];

interface OrderInternalInfoModuleProps {
	suborderIndex: number;
}

const OrderInternalDataControlModule: React.FC<OrderInternalInfoModuleProps> = ({ suborderIndex }) => {
	const navigate = useNavigate();
	const { getValues, formState } = useTypedOrderControllerFromContext();
	const clientId = getValues(`suborders.${suborderIndex}.data.client.value`);

	const { data: stocksOptionList = fallbackList, ...stocksRequest } = useGetWarehousesQuery();
	const { data: clientsOptionList = fallbackList, ...clientsRequest } = useGetClientsOptionsQuery();
	const { data: managersOptionList = fallbackList, ...managersRequest } = useGetEmployeesOptionListQuery();
	const { data: organizationsOptionList = fallbackList, ...organizationsRequest } = useGetOrganizationsOptionListQuery();
	const { data: rawContractsOptionList = fallbackList, ...contractsRequest } = useGetContractListQuery(clientId, { skip: !clientId });

	const contractsOptionList = useMemo(() => {
		const schema = standardizeOption<typeof OptionSchema, AnyOption>(OptionSchema, ({ id, title }) => ({ label: title, value: id }));

		return rawContractsOptionList.map((contract) => schema.safeParse(contract).data) as Option[];
	}, [rawContractsOptionList]);

	const onClientRedirectClick = (option: Option) => {
		if (!option) return;
		if (isObject<Option>(option) && isNullish(option.value)) return;

		if (option.value) {
			const url = prepareUrl(ROUTES_URLS.CLIENT_PREVIEW, { id: option.value });
			navigate(url);
		}
	};

	const getFieldError = (fieldName: string) => formState.errors?.suborders?.[suborderIndex]?.data?.[fieldName]?.message;

	return (
		<form className={styles.form} style={{ '--columns': 5 } as React.CSSProperties}>
			<Can passThrough I="change" an={`order.${suborderIndex}.client`}>
				{(can) => (
					<FormField
						forceFocus
						name={`suborders.${suborderIndex}.data.client`}
						label="Клієнт"
						placeholder="Виберіть клієнта"
						component={AutocompleteFieldForClient}
						valuesList={clientsOptionList}
						fieldClassName={clsx(styles.formField, { [styles.error]: !!getFieldError('client') })}
						disabled={!can || clientsRequest.isLoading}
						onClick={onClientRedirectClick}
					/>
				)}
			</Can>
			<Can passThrough I="change" an={`order.${suborderIndex}.organization`}>
				{(can) => (
					<FormField
						forceFocus
						name={`suborders.${suborderIndex}.data.organization`}
						label="Організація"
						placeholder="Виберіть організацію"
						valuesList={organizationsOptionList}
						fieldClassName={clsx(styles.formField, { [styles.error]: !!getFieldError('organization') })}
						disabled={!can || organizationsRequest.isLoading}
						component={AutocompleteFieldWithOnSelectAction}
					/>
				)}
			</Can>
			<Can passThrough I="change" an={`order.${suborderIndex}.contract`}>
				{(can) => (
					<FormField
						forceFocus
						name={`suborders.${suborderIndex}.data.contract`}
						label="Договір"
						placeholder="Виберіть договір"
						valuesList={contractsOptionList}
						fieldClassName={clsx(styles.formField, { [styles.error]: !!getFieldError('contract') })}
						disabled={!can || contractsRequest.isLoading}
						component={AutocompleteFieldWithOnSelectAction}
					/>
				)}
			</Can>
			<Can passThrough I="change" an={`order.${suborderIndex}.stock`}>
				{(can) => (
					<FormField
						forceFocus
						name={`suborders.${suborderIndex}.data.stock`}
						label="Склад"
						placeholder="Виберіть склад"
						valuesList={stocksOptionList}
						fieldClassName={clsx(styles.formField, { [styles.error]: !!getFieldError('stock') })}
						disabled={!can || stocksRequest.isLoading}
						component={AutocompleteFieldWithOnSelectAction}
					/>
				)}
			</Can>
			<Can passThrough I="change" an={`order.${suborderIndex}.responsible`}>
				{(can) => (
					<FormField
						forceFocus
						name={`suborders.${suborderIndex}.data.responsible`}
						label="Менеджер"
						placeholder="Виберіть менеджера"
						valuesList={managersOptionList}
						fieldClassName={clsx(styles.formField, { [styles.error]: !!getFieldError('responsible') })}
						disabled={!can || managersRequest.isLoading}
						component={AutocompleteFieldWithOnSelectAction}
					/>
				)}
			</Can>
		</form>
	);
};

export default OrderInternalDataControlModule;
