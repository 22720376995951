import clsx from 'clsx';
import TipCard from 'components/TipCard';
import React from 'react';

const message = `
  Додайте товари та послуги з основної заявки через розбиття, або через додавання товарів з каталогу.
`;

const EmptySuborder: React.FC<{ className?: string }> = ({ className }) => {
	return <TipCard message={message} className={clsx(className)} />;
};

export default EmptySuborder;
