import clsx from 'clsx';
import React from 'react';

import styles from './style.module.css';
import { IProps } from './tydes';

const IconButton: React.FC<IProps> = ({ className, icon, width, height, background, onClick }) => {
	return (
		<div className={clsx(styles.iconButton, className)} style={{ background: background, width: width, height: height }} onClick={onClick}>
			<img src={icon} />
		</div>
	);
};

export default IconButton;
