/*
 * overrides standard selection flow in React table V7
 * we gonna have some rows that are unselectable
 * more info see https://github.com/TanStack/table/issues/2988#issuecomment-979508258
 */
export function overrideAllSelection(
	props: Record<string, unknown> & {
		rows: Record<string, unknown> & { isDisabled?: boolean; isSelected: boolean; toggleRowSelected: (params: unknown) => void }[];
	},
) {
	const { rows, ...restProps } = props ?? {};
	const changeableRows = false ? [...rows] : rows.filter((row) => !row?.isDisabled);

	const allSelected = changeableRows.every((row) => row?.isSelected);
	const allUnselected = changeableRows.every((row) => !row?.isSelected);

	const indeterminate = !allSelected && !allUnselected;
	const checked = !allUnselected;

	const onChange = () => {
		changeableRows.forEach((row) => row.toggleRowSelected(!allSelected));
	};

	const override = {
		...restProps,
		checked,
		indeterminate,
		onChange,
	};

	return override;
}

/*
 * this will extends original row coming from React Table
 * by adding a custom key, telling us the row is sealed for selection
 * this works in pair with function above
 */
export function assignIsDisabledLabel(isDisabledCb: (rowData: unknown) => boolean) {
	return function assigner(obj: Record<string, unknown>) {
		// @ts-ignore
		const objWithLabel = Object.assign(obj, { isDisabled: isDisabledCb(obj.original) });
		return objWithLabel;
	};
}
