import { ServerSideOptionSchema } from 'models/common/options';
import { z } from 'zod';

import { OrderStatusSchema, PaymentStatus } from './order';

export const BySumMaxOrderFilterSchema = z.string();
export const ByPaymentStatusOrderFilterSchema = z.array(PaymentStatus);
export const ByStatusOrderFilterSchema = OrderStatusSchema;
export const ByClientOrderFilterSchema = ServerSideOptionSchema;
export const ByResponsibleEmployeeOrderFilterSchema = ServerSideOptionSchema.omit({ id: true }).extend({ id: z.number() });
export const ByStockOrderFilterSchema = ServerSideOptionSchema;
export const ByContractOrderFilterSchema = z.string();

const withStandardizedTitle = <T extends z.ZodSchema>(schema: T) =>
	z.preprocess((input) => {
		if (Array.isArray(input)) {
			return input.map((item) => ({ ...item, title: item?.name }));
		}

		return input;
	}, schema);

export const OrderFiltersSchema = z.object({
	sumMax: BySumMaxOrderFilterSchema,
	paymentStatuses: ByPaymentStatusOrderFilterSchema,
	stocks: ByStockOrderFilterSchema.array(),
	contracts: z.array(ByContractOrderFilterSchema),
	clients: withStandardizedTitle(ByClientOrderFilterSchema.array()),
	statuses: ByStatusOrderFilterSchema.array(),
	responsible: withStandardizedTitle(ByResponsibleEmployeeOrderFilterSchema.array()),
});

export type OrderFilters = z.infer<typeof OrderFiltersSchema>;
