import clsx from 'clsx';
import React, { ComponentProps, ForwardedRef, forwardRef } from 'react';

import styles from './styles.module.css';

export type TableCellInputProps = ComponentProps<'input'>;

const TableCellInput = ({ className, width, style, ...restProps }: TableCellInputProps, ref: ForwardedRef<HTMLInputElement>) => {
	return (
		<span className={styles.wrapper}>
			<input
				ref={ref}
				className={clsx(styles.tableCellInput, 'no-user-agent-autofill-bg', className)}
				{...restProps}
				style={{ '--table-cell-input-width': width, ...style } as React.CSSProperties}
			/>
		</span>
	);
};

export default forwardRef(TableCellInput);
