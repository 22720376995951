import { isMultipleSelectionWithInnerControl, isSingleSelectionWithExternalControl, isSingleSelectionWithInnerControl } from '../guards';
import type {
	ExternalControlledMultipleAutocompleteProps,
	ExternalControlledSingleAutocompleteProps,
	InnerControlledMultipleAutocompleteProps,
	InnerControlledSingleAutocompleteProps,
} from '../types';
import { useMultipleExternalControlledAutocomplete } from './_useMultipleExternalControlledAutocomplete';
import { useMultipleInnerControlledAutocomplete } from './_useMultipleInnerControlledAutocomplete';
import { useSingleExternalControlledAutocomplete } from './_useSingleExternalControlledAutocomplete';
import { useSingleInnerControlledAutocomplete } from './_useSingleInnerControlledAutocomplete';

export const useStateController = (
	props:
		| InnerControlledSingleAutocompleteProps
		| ExternalControlledSingleAutocompleteProps
		| InnerControlledMultipleAutocompleteProps
		| ExternalControlledMultipleAutocompleteProps,
) => {
	if (isSingleSelectionWithInnerControl(props)) {
		return useSingleInnerControlledAutocomplete.call(null, props);
	}

	if (isSingleSelectionWithExternalControl(props)) {
		return useSingleExternalControlledAutocomplete.call(null, props);
	}

	if (isMultipleSelectionWithInnerControl(props)) {
		return useMultipleInnerControlledAutocomplete.call(null, props);
	}

	return useMultipleExternalControlledAutocomplete.call(null, props);
};
