import Img from 'static/images/Table.jpg';

export const mockDataPrice = [
	{
		name: 'Вентиляційні системи',
		subcategories: [
			{
				name: 'Канали пластикові',
				subcategories: [
					{
						name: 'Пластикові канали 60*204',
						products: [
							{
								id: 1,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 2,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 3,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 4,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 5,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 6,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
						],
					},
					{
						name: 'Пластикові канали 100*100',
						products: [
							{
								id: 1,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 2,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 3,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 4,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 5,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 6,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
						],
					},
				],
			},
		],
	},
	{
		name: 'Вентиляційні системи 2 ',
		subcategories: [
			{
				name: 'Канали пластикові',
				subcategories: [
					{
						name: 'Пластикові канали 60*204',
						products: [
							{
								id: 1,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 2,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 3,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 4,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 5,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 6,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
						],
					},
					{
						name: 'Пластикові канали 100*100',
						products: [
							{
								id: 1,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 2,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 3,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 4,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 5,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 6,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
						],
					},
				],
			},
		],
	},
	{
		name: 'Вентиляційні системи 3',
		subcategories: [
			{
				name: 'Канали пластикові',
				subcategories: [
					{
						name: 'Пластикові канали 60*204',
						products: [
							{
								id: 1,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 2,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 3,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 4,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 5,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 6,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
						],
					},
					{
						name: 'Пластикові канали 100*100',
						products: [
							{
								id: 1,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 2,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 3,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 4,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 5,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
							{
								id: 6,
								name: 'Товар 2',
								price: 150,
								img: Img,
								code: '32323232',
								units: 'шт',
							},
						],
					},
				],
			},
		],
	},
];
