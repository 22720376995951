import { format, isValid } from 'date-fns';

export const sinceDateFormatter = (date: Date | string | number): string => {
	const dateString = new Date(date).toLocaleDateString('uk-UA');
	return `з ${dateString}`;
};

export const formatAndExtractDateTime = (date: Date | string | number) => {
	const newDate = isValid(date) ? new Date(date) : new Date();

	const dateOnly = format(newDate, 'dd.MM.yyyy');
	const timeOnly = format(newDate, 'HH:mm');

	return [dateOnly, timeOnly] as const;
};
