/* eslint-disable @typescript-eslint/no-shadow */
import { Product } from 'store/reducers/orders/types';

export function filterProductsByFilters(products: Product[], filters: [string, string][]): Product[] {
	const hasBrandFilter = filters.some(([key]) => key === 'brand');
	const hasInStockFilter = filters.some(([key]) => key === 'inStock');
	const hasProductFilters = filters.some(([key]) => key === 'product');
	const hasQueryFilter = filters.some(([key]) => key === 'query');
	const hasFilters = filters.length > 0;

	if (!hasFilters) {
		return products;
	}

	let filteredProducts = [...products];

	if (hasQueryFilter) {
		filteredProducts = filteredProducts.filter((product) =>
			filters.some(([, value]) => product?.title?.toLowerCase().includes(value.toLowerCase())),
		);
	}

	if (hasInStockFilter) {
		filteredProducts = filteredProducts.filter((product) => Number(product.quantity) > 0);
	}

	if (hasBrandFilter) {
		const [, brandFilter] = filters.find(([key]) => key === 'brand') || [];
		if (brandFilter) {
			filteredProducts = filteredProducts.filter((product) => String(product.brand).toLowerCase() === String(brandFilter).toLowerCase());
		}
	}

	if (hasProductFilters) {
		filters.forEach(([key, fk]) => {
			if (key === 'product') {
				filteredProducts = filteredProducts.filter((product) =>
					// @ts-ignore
					product.parentIds.includes(fk),
				);
			}
		});
	}

	return filteredProducts;
}

export const filterClientsByFilters = (clients: Record<string, string>[], filters: [string, string][]) => {
	if (!clients) return [];

	const isEmptyFilters = filters.length === 0 || filters[0][0] === 'undefined';

	if (isEmptyFilters) return clients;

	const filteredClients = clients.filter((client) => {
		return filters.some(([, filter]) => {
			return client['Сегмент'] === filter;
		});
	});

	return filteredClients;
};

export const filterOrdersByFilters = (orders: Record<string, string>[], filters: [string, string][]) => {
	if (!orders) return [];

	const isEmptyFilters = filters.length === 0;
	if (isEmptyFilters) return orders;
	const normalizedFilters = filters.flatMap(([key, values]) => {
		return values.split(',').map((value) => [key, value.trim()]);
	});

	const filteredOrders = orders.filter((order) => {
		return normalizedFilters.some(([key, value]) => {
			if (key === 'status') {
				return order.status === value || (value === '5' && !order.status);
			}

			if (key === 'number') {
				const numberValue = Number(value);
				// @ts-ignore
				return order.number === numberValue;
			}
			// @ts-ignore
			if (key === 'manager' && order.client.responsible === 'Юрченко Людмила') {
				return true;
			}
			// @ts-ignore
			if (key === 'responsible' && order.client.responsible === value) {
				return true;
			}

			if (key === 'shipment' && order.status === value) {
				return true;
			}

			// @ts-ignore
			if (key === 'contract' && order.client.contractType === value) {
				return true;
			}

			// @ts-ignore
			if (order.client.meta.hasOwnProperty(key)) {
				// @ts-ignore
				return order.client.meta[key] === value;
			}

			if (order.client.hasOwnProperty(key)) {
				return order.client[key] === value;
			}

			return false;
		});
	});
	return filteredOrders;
};

export const filterOrdersByClientAndFilters = (id: string, orders: Record<string, string>[], filters: [string, string][]) => {
	if (!orders) return [];

	const isEmptyFilters = filters.length === 0;

	// @ts-ignore
	const clientsOrders = orders.filter((order) => order?.client?.id === id);

	if (isEmptyFilters) return clientsOrders;

	const filteredOrders = clientsOrders.filter((order) => {
		return filters.some(([, filter]) => {
			return order.status === filter;
		});
	});

	return filteredOrders;
};

function isSpecialCharacter(char: string): boolean {
	const specialCharacters = ['!'];
	return specialCharacters.includes(char);
}

export function sortTreeAlphabetically(node) {
	if (node.children) {
		node.children = node.children.map(sortTreeAlphabetically);

		node.children.sort((a, b) => {
			const nameA = a.name.toLowerCase();
			const nameB = b.name.toLowerCase();

			if (isSpecialCharacter(nameA[0]) && !isSpecialCharacter(nameB[0])) {
				return 1; // Move special characters to the end
			} else if (!isSpecialCharacter(nameA[0]) && isSpecialCharacter(nameB[0])) {
				return -1; // Move special characters to the end
			} else {
				return nameA.localeCompare(nameB);
			}
		});
	}

	return node;
}

export const mockDataService = {};
