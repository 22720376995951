import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import type { SelectOption } from 'components/Select/types';
import { SUBORDERS_LIMIT } from 'const';
import { ISingleOrder, Products } from 'models/IOrder';
import { IService, IServiceType, IServiceWorkType } from 'models/IServices';
import { orderNotificationService } from 'services/orderNotificationService';
import { constructNewIdSet, numberify, toArray, uuid } from 'utils/shared';

import { ordersSliceApi } from '../orders/ordersSliceApi';
import type {
	IOrderPreviewSliceInitialState,
	Suborder,
	SuborderTransferConfig,
	SuborderTransferConfigWithData,
	UpdateProductProperty,
} from './types';
import {
	assignLockedItems,
	createBlankSuborder,
	getHydrateOrderStateData,
	getPriceByTypePriceId,
	getTotalSum,
	isSuborderEmpty,
	patchProduct,
	prepareBlankService,
	syncRootSuborderWithRecentDelete,
	syncRootSuborderWithRecentUpdate,
	unassignLockedItems,
} from './utils';

const initialState: IOrderPreviewSliceInitialState = {
	suborders: [],
	subordersLimit: SUBORDERS_LIMIT,
	rootOrderId: null,
	subordersCount: 0,
	activeSuborderIndex: 0,
	activeCategoryTabIndex: 0,
	sum: 0,
	totalVolume: 0,
	totalWeight: 0,
	type: 'regular',
	transferProductPreview: null,
	lastForcedRerenderTimestamp: null,
	suborderDeleteCandidate: null,
	locker: {},
	hasAtLeastOnePaintToningSuborderOnServer: false,
	itemsDeleteCandidates: [],
	paintToningSubordersLimit: 3,
	paintToningSubordersCount: 0,

	// ==== refactor
	etalonOrder: null,
	draftOrder: null,
	displayDetailsOfTransferringItem: null,
};

export const orderPreviewSlice = createSlice({
	name: 'order-viewer',
	initialState,
	reducers: {
		updateProductProperty: (state, action: PayloadAction<UpdateProductProperty & { target?: 'draft' | 'suborder'; forceRerender?: boolean }>) => {
			const { id, target, forceRerender, ...properties } = action.payload;

			const suborder = state.suborders[state.activeSuborderIndex];
			if (target && target === 'suborder') {
				const rootSuborder = state.suborders[0];
				suborder.products = patchProduct(suborder.products, id, properties) as Products[];

				if (state.activeSuborderIndex !== 0) {
					rootSuborder.products = patchProduct(rootSuborder.products, id, properties) as Products[];
				}
			} else {
				const draftOrderProducts = state.draftOrder?.products ?? [];

				if (!draftOrderProducts) {
					state.draftOrder.products = [];
				}

				state.draftOrder.products = patchProduct(state.draftOrder.products, id, properties);
			}

			if (forceRerender) {
				state.lastForcedRerenderTimestamp = Date.now();
			}
		},
		updateTypePrice: (state, action: PayloadAction<{ id: string; priceId: string; target?: 'draft' | 'suborder' }>) => {
			const { id, priceId, target } = action.payload;

			if (target === 'draft') {
				let currProduct = state.draftOrder.products.find((item) => item.id === id);
				const newPrice = getPriceByTypePriceId(currProduct, priceId);

				if (newPrice) {
					currProduct = { ...currProduct, price: newPrice, sum: newPrice * currProduct.amount };
					state.draftOrder.products = state.draftOrder.products.map((product) => {
						if (product.id === id && newPrice) {
							return {
								...currProduct,
							};
						} else {
							return product;
						}
					});
				} else {
					orderNotificationService.error(`У товарі ${currProduct.title} відсутній цей тип ціни`);
				}
				state.draftOrder.sum = getTotalSum(state.draftOrder);
			} else if (target === 'suborder') {
				let currProduct = state.suborders[state.activeSuborderIndex].products.find((item) => item.id === id);
				const newPrice = getPriceByTypePriceId(currProduct, priceId);

				if (newPrice) {
					currProduct = { ...currProduct, price: newPrice, sum: newPrice * currProduct.amount };
					state.suborders[state.activeSuborderIndex].products = state.suborders[state.activeSuborderIndex].products.map((product) => {
						if (product.id === id && newPrice) {
							return {
								...currProduct,
							};
						} else {
							return product;
						}
					});
				} else {
					orderNotificationService.error(`У товарі ${currProduct.title} відсутній цей тип ціни`);
				}
				state.draftOrder.sum = state.draftOrder.sum = getTotalSum(state.suborders[state.activeSuborderIndex]);
			}
		},
		unDoTypePriceChanges: (state, action: PayloadAction<{ target: 'draft' | 'suborder' }>) => {
			const { target } = action.payload;
			if (target === 'draft') state.draftOrder.products = state.etalonOrder.products;
			state.draftOrder.sum = getTotalSum(state.draftOrder);
		},
		changePriceInProductOrService: (
			state,
			action: PayloadAction<{
				price: number;
				target: 'draft' | 'suborder';
				object: 'products' | 'services';
				objectId: string;
				manualDiscount?: number;
			}>,
		) => {
			const { price, target, object, objectId, manualDiscount } = action.payload;
			if (target === 'draft') {
				state.draftOrder[object] = state.draftOrder[object].map((item) =>
					item.id === objectId
						? { ...item, price: price, sum: price * item.amount, manualDiscount: manualDiscount && manualDiscount }
						: item,
				);
			} else {
				// @ts-ignore
				state.suborders[state.activeSuborderIndex] = state.suborders[state.activeSuborderIndex].map((item) =>
					item.id === objectId
						? { ...item, price: price, sum: price * item.amount, manualDiscount: manualDiscount && manualDiscount }
						: item,
				);
			}
			state.draftOrder.sum = getTotalSum(state.draftOrder);
		},
		createBlankSuborder: (state, action: PayloadAction<Partial<{ type: 'paint_toning' | 'regular' }>>) => {
			const { type } = action.payload ?? {};

			const indexOfPaintToningSuborder = state.suborders.findIndex((suborder) => suborder?.type === 'paint_toning');
			const lastSuborderIndex = state.suborders.length - 1;
			const lastSuborder = state.suborders[lastSuborderIndex];
			const lastOrderNumber = lastSuborder.number;
			const rootWarehouse = state.draftOrder?.client?.meta?.department;
			const subordersCount = state.suborders?.length ?? 1;
			const newSuborderIndex = indexOfPaintToningSuborder < 0 ? subordersCount : indexOfPaintToningSuborder;

			const blankSuborder = createBlankSuborder({
				orderId: state.rootOrderId,
				number: lastOrderNumber + 1,
				index: newSuborderIndex,
				stock: rootWarehouse,
				type,
			});

			if (type === 'paint_toning') {
				state.paintToningSubordersCount++;
			}

			if (indexOfPaintToningSuborder >= 0) {
				state.suborders.splice(newSuborderIndex, 0, blankSuborder);
			} else {
				state.suborders.push(blankSuborder);
			}

			state.suborders[state.activeSuborderIndex].selectedProducts = [];
			state.subordersCount++;
		},
		createSuborderWithProducts: (state, action: PayloadAction<{ from?: number; type?: 'regular' | 'paint_toning' }>) => {
			const { from, type } = action.payload;

			const indexOfPaintToningSuborder = state.suborders.findIndex((suborder) => suborder?.type === 'paint_toning');
			const lastSuborder = state.suborders.at(-1);
			const lastOrderNumber = lastSuborder.number;
			const products = state.suborders[from].selectedProducts;
			const rootWarehouse = state.draftOrder?.client?.meta?.department;
			const subordersCount = state.suborders?.length ?? 1;
			const newSuborderIndex = indexOfPaintToningSuborder < 0 ? subordersCount : indexOfPaintToningSuborder;

			const blankSuborder = createBlankSuborder({
				orderId: state.rootOrderId,
				number: lastOrderNumber + 1,
				index: newSuborderIndex,
				stock: rootWarehouse,
				type,
			});
			const suborderProducts = Array.isArray(products) ? products : [products];

			const suborderWithProducts: Suborder = {
				...blankSuborder,
				products: suborderProducts,
			};

			if (from > 0) {
				const candidates = constructNewIdSet(products);
				state.suborders[from].products = state.suborders[from].products.filter((product) => !candidates.has(String(product.id)));
			}

			if (type === 'paint_toning') {
				state.paintToningSubordersCount++;
			}

			if (indexOfPaintToningSuborder >= 0) {
				state.suborders.splice(newSuborderIndex, 0, suborderWithProducts);
			} else {
				state.suborders.push(suborderWithProducts);
			}

			assignLockedItems({ locker: state.locker, holder: suborderWithProducts.tempId, items: suborderProducts });

			state.suborders[from].selectedProducts = [];
			state.subordersCount++;
		},
		createSuborderWithServices: (state, action: PayloadAction<{ from?: number; data: IService[] }>) => {
			const { data: selectedServices } = action.payload;

			const lastSuborder = state.suborders.at(-1);
			const lastOrderNumber = lastSuborder.number;
			const rootWarehouse = state.draftOrder?.client?.meta?.department;
			const newSuborderIndex = state.suborders.length ?? 1;

			const blankSuborder = createBlankSuborder({
				orderId: state.rootOrderId,
				number: lastOrderNumber + 1,
				index: newSuborderIndex,
				stock: rootWarehouse,
			});

			const suborderWithServices: Suborder = {
				...blankSuborder,
				// @ts-ignore
				services: selectedServices.map((service) => {
					// Generate a unique ID if the service doesn't have one
					const id = service?.id ?? uuid();
					return { ...service, id };
				}),
			};

			assignLockedItems({ locker: state.locker, holder: suborderWithServices.tempId, items: suborderWithServices.services });
			state.suborders.push(suborderWithServices);
			state.subordersCount++;
		},
		removeSuborder: (state, action: PayloadAction<{ id: string; index: number }>) => {
			const { id, index } = action.payload;
			const doesActiveIndexMatchTargetIndex = index === state.activeSuborderIndex;
			const target = state.suborders[index];

			state.suborders.splice(index, 1);

			const isEmpty = isSuborderEmpty(target);

			if (!isEmpty) {
				if (target.products?.length) {
					const rootSuborder = state.suborders[0];
					const etalonOrder = state.etalonOrder;
					const etalonOrderProductsMap = new Map(etalonOrder.products.map((product) => [product.id, product]));
					const targetProductsMap = new Map(target.products.map((product) => [product.id, product]));

					rootSuborder.products = rootSuborder.products.map((product) => {
						if (targetProductsMap.has(product.id)) {
							const etalonProduct = etalonOrderProductsMap.get(product.id);

							return etalonProduct as Products;
						}

						return product;
					});
				}

				if (toArray(target.services ?? {}).length) {
					const rootSuborder = state.suborders[0];
					const etalonOrder = state.etalonOrder;
					const etalonOrderServicesMap = new Map(etalonOrder.services.map((service) => [service.id, service]));
					const targetServices = target.services;

					toArray(rootSuborder.services).forEach((service) => {
						const targetService = targetServices[service.id];

						if (targetService) {
							const etalonService = etalonOrderServicesMap.get(service.id) as IService;

							rootSuborder.services[service.id] = etalonService;
						}
					});
				}

				state.lastForcedRerenderTimestamp = Date.now();
			}

			unassignLockedItems({ locker: state.locker, holder: id, cascade: true });

			/*
			 * pushing user to prev suborder tab if target tab matches active one user is currently on
			 */
			if (doesActiveIndexMatchTargetIndex) {
				const newActiveIndex = index - 1;
				state.activeSuborderIndex = newActiveIndex < 0 ? 0 : newActiveIndex;
			}
			if (target.type === 'paint_toning') {
				state.paintToningSubordersCount += 1;
			}
		},
		setIndexOfSuborderToDelete: (state, action: PayloadAction<{ index: number }>) => {
			state.suborderDeleteCandidate = action.payload.index;
		},
		clearIndexOfSuborderToDelete: (state) => {
			state.suborderDeleteCandidate = null;
		},
		changeWarehouse: (state, action: PayloadAction<SelectOption>) => {
			const activeSuborder = state.suborders[state.activeSuborderIndex];

			activeSuborder.stock = action.payload;
		},
		transfer: (state, action: PayloadAction<SuborderTransferConfig | SuborderTransferConfigWithData>) => {
			const { from, to } = action.payload;

			const limit = state.subordersLimit;
			const data = new Set(state.suborders[from].selectedProducts.map((product) => product.id));

			// Case 1: Transfer from root order (0) to a suborder
			if (from === 0 && to >= 1 && to <= limit) {
				const targetSuborder = state.suborders[to];

				const existingProductIds = new Set(targetSuborder.products?.map((product) => product.id));
				const productsToAdd = state.suborders[from].selectedProducts.filter((product) => !existingProductIds.has(product.id));
				const merged = [...targetSuborder.products, ...productsToAdd];
				state.suborders[to].products = merged;

				assignLockedItems({ locker: state.locker, holder: targetSuborder.tempId, items: merged });
				return;
			}

			// Case 2: Transfer from a suborder to the root order (0)
			if (from >= 1 && from <= limit && to === 0) {
				state.suborders[from].products = state.suborders[from].products.filter((product) => !data.has(product.id));
				return;
			}

			// Case 3: Transfer within suborders
			if (from >= 1 && from <= limit && to >= 1 && to <= limit) {
				const sourceSuborder = state.suborders[from];
				const targetSuborder = state.suborders[to];
				const existingProductsMap = new Map(targetSuborder?.products.map((product) => [product.id, { ...product }]));

				if ('data' in action.payload) {
					const transferredProducts = action.payload.data;
					const sourceProductsMap = new Map(sourceSuborder.products.map((product) => [product.id, product]));

					transferredProducts.forEach((transferredProduct) => {
						const etalonProduct = sourceProductsMap.get(transferredProduct.id);

						const delta = numberify(etalonProduct.amount) - numberify(transferredProduct.amount);

						/**
						 * delta > 0 means we transfer to target suborder only some PART of item
						 */
						if (delta > 0) {
							etalonProduct.amount = delta;
							targetSuborder.products.push(transferredProduct);
							state.lastForcedRerenderTimestamp = Date.now();
						} else {
							/**
							 * delta <=0 means we transfer to target suborder WHOLE or MORE
							 */
							sourceProductsMap.delete(transferredProduct.id);

							if (!existingProductsMap.has(transferredProduct.id)) {
								targetSuborder.products.push(transferredProduct);
							} else {
								const targetProduct = existingProductsMap.get(transferredProduct.id);
								targetProduct.amount = transferredProduct.amount;
							}

							/**
							 * when delta > 0 we need to update root suborder with higher amount items picked
							 */
							if (delta < 0) {
								const rootSuborder = state.suborders[0];
								const found = rootSuborder.products?.find((product) => product.id === transferredProduct.id);

								if (!found) return;

								found.amount = transferredProduct.amount;
							}
						}
					});

					sourceSuborder.products = Array.from(sourceProductsMap.values()).map((product) => product);
					sourceSuborder.selectedProducts = [];
				} else {
					const productsToAdd = sourceSuborder.selectedProducts.filter((product) => !existingProductsMap.has(product.id));
					state.suborders[to].products.push(...productsToAdd);

					state.suborders[from].products = sourceSuborder.products.filter(
						(product) => !sourceSuborder.selectedProducts.some((p) => p.id === product.id),
					);
				}
			}
		},
		transferServices: (state, action: PayloadAction<SuborderTransferConfigWithData>) => {
			const { from, to, data: selectedServices } = action.payload;

			const limit = state.subordersLimit;

			// Case 1: Transfer from root order (0) to a suborder
			if (from === 0 && to >= 1 && to <= limit) {
				const targetSuborder = state.suborders[to];

				if (!targetSuborder.services) {
					targetSuborder.services = {};
				}
				selectedServices.forEach((service) => {
					// @ts-ignore
					targetSuborder.services = [...targetSuborder.services, service];
				});

				assignLockedItems({ locker: state.locker, holder: targetSuborder.tempId, items: targetSuborder.services });
				return;
			}

			// Case 3: Transfer within suborders
			if (from >= 1 && from <= limit && to >= 1 && to <= limit) {
				const sourceSuborder = state.suborders[from];
				const targetSuborder = state.suborders[to];

				selectedServices.forEach((service) => {
					// @ts-ignore
					const sourceService = sourceSuborder.services.find((item) => item.id === service.id);

					const delta = numberify(sourceService.quantity) - numberify(service.quantity);

					/**
					 * delta > 0 means we transfer to target suborder only some PART of item
					 */
					if (delta > 0) {
						sourceService.quantity = delta;
						targetSuborder.services[service.id] = service;
						state.lastForcedRerenderTimestamp = Date.now();
					} else {
						/**
						 * delta <=0 means we transfer to target suborder only WHOLE or MORE
						 */
						// @ts-ignore
						sourceSuborder.services = sourceSuborder.services.filter((item) => item.id !== service.id);

						if (!targetSuborder.services[service.id]) {
							targetSuborder.services[service.id] = service;
						} else {
							const targetService = targetSuborder.services[service.id];
							targetService.quantity = service.quantity;
						}

						/**
						 * when delta > 0 we need to update root suborder with higher amount items picked
						 */
						if (delta < 0) {
							const rootSuborder = state.suborders[0];
							const found = rootSuborder.services[service.id];

							if (!found) return;

							found.quantity = service.quantity;
						}
					}
				});
			}
		},
		selectProductToTransfer: (state, action: PayloadAction<{ products: Products[] }>) => {
			const suborder = state.suborders[state.activeSuborderIndex];

			if (suborder) {
				suborder.selectedProducts = action.payload.products;
			}
		},
		setActiveSuborderIndex: (state, action: PayloadAction<{ index: number }>) => {
			const prevActiveIndex = state.activeSuborderIndex;
			const newActiveSuborderIndex = action.payload.index;

			// state.suborders[prevActiveIndex].selectedProducts = [];
			state.suborders[prevActiveIndex].selectedServices = [];

			state.activeCategoryTabIndex = 0;
			state.activeSuborderIndex = newActiveSuborderIndex;
		},
		setActiveCategoryTabIndex: (state, action: PayloadAction<{ index: number }>) => {
			const { index } = action.payload;
			state.activeCategoryTabIndex = index;
		},
		toggleRootOrderType: (state) => {
			const isRegularOrder = state.type === 'regular';

			state.type = isRegularOrder ? 'paint_toning' : 'regular';
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setTransferPreviewProduct: (state, action: PayloadAction<{ product: any }>) => {
			const { product } = action.payload;

			state.transferProductPreview = product;
		},
		clearTransferPreviewProduct: (state) => {
			state.transferProductPreview = null;
		},
		clearOrderViewer: (state) => {
			state.draftOrder = null;
			state.suborders = [];
			state.subordersLimit = SUBORDERS_LIMIT;
			state.rootOrderId = null;
			state.subordersCount = 0;
			state.sum = 0;
			state.totalVolume = 0;
			state.totalWeight = 0;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		addService: (state, action: PayloadAction<{ service: any; target?: 'root' | 'suborder' }>) => {
			const { service: rawService, target } = action.payload;

			const service = { ...prepareBlankService(rawService), isSaved: false };

			if (target && target === 'suborder') {
				const services = state.suborders[state.activeSuborderIndex].services;
				if (!Array.isArray(services)) {
					state.draftOrder.services = [];
				}
				// @ts-ignore
				state.suborders[state.activeSuborderIndex].services = [...state.suborders[state.activeSuborderIndex].services, service];
				syncRootSuborderWithRecentUpdate({ rootSuborder: state.suborders[0], modifiedService: service });
			} else {
				if (!Array.isArray(state.draftOrder.services)) {
					state.draftOrder.services = [];
				}

				state.draftOrder.services = [...state.draftOrder.services, service];
			}
		},

		removeService: (state, action: PayloadAction<{ id: IService['id']; target?: 'root' | 'suborder' }>) => {
			const { id, target } = action.payload;

			if (target && target === 'suborder') {
				// @ts-ignore
				state.suborders[state.activeSuborderIndex].services = state.suborders[state.activeSuborderIndex].services.filter(
					(item) => item.id !== id,
				);
				// syncRootSuborderWithRecentDelete({ rootSuborder: state.suborders[0], serviceId: id });

				unassignLockedItems({
					locker: state.locker,
					holder: state.suborders[state.activeSuborderIndex].tempId,
					cascade: false,
					// @ts-ignore
					items: [{ id }],
				});
			} else {
				state.draftOrder.services = state.draftOrder.services.filter((service) => service.id !== id);
			}
		},
		removeManyServices: (state, action: PayloadAction<{ services: IService[]; target?: 'root' | 'suborder' }>) => {
			const { services } = action.payload;
			const activeSuborder = state.suborders[state.activeSuborderIndex];
			const rootSuborder = state.suborders[0];

			services.forEach(({ id }) => {
				delete activeSuborder.services[id];
				syncRootSuborderWithRecentDelete({ rootSuborder, serviceId: id });
			});
		},
		updateServiceQuantity: (
			state,
			action: PayloadAction<{ id: IService['id']; quantity: number; target?: 'root' | 'suborder'; forceRerender?: boolean }>,
		) => {
			const { id, quantity, target, forceRerender } = action.payload;
			const rootSuborder = state.suborders[0];
			const integerPart = Math.floor(quantity);
			if (target && target === 'suborder') {
				const servicesArray = Object.values(state.suborders[state.activeSuborderIndex].services);
				const service = servicesArray.find((item) => item.id === id);

				if (service) {
					service.quantity = integerPart;
					// syncRootSuborderWithRecentUpdate({ rootSuborder, modifiedService: service });
				}
			} else {
				state.draftOrder.services = state.draftOrder.services.map((item) => (item.id === id ? { ...item, quantity: integerPart } : item));
				const service = state.draftOrder.services.find((item) => item.id === id);
				service.quantity = integerPart;

				syncRootSuborderWithRecentUpdate({ rootSuborder, modifiedService: service });
			}

			if (forceRerender) {
				state.lastForcedRerenderTimestamp = Date.now();
			}
		},
		updateServicePrice: (
			state,
			action: PayloadAction<{ id: IService['id']; price: number | string; target?: 'root' | 'suborder'; forceRerender?: boolean }>,
		) => {
			const { id, price, target, forceRerender } = action.payload;

			const rootSuborder = state.suborders[0];

			if (target && target === 'suborder') {
				// @ts-ignore
				const service = state.suborders[state.activeSuborderIndex].services.find((item) => item.id === id);

				service.price = numberify(price);
				syncRootSuborderWithRecentUpdate({ rootSuborder, modifiedService: service });
			} else {
				const service = state.draftOrder.services[id];

				state.draftOrder.services = state.draftOrder.services.map((item) =>
					item.id === id
						? {
								...item,
								price: price,
						  }
						: item,
				);
				syncRootSuborderWithRecentUpdate({ rootSuborder, modifiedService: service });
			}

			if (forceRerender) {
				state.lastForcedRerenderTimestamp = Date.now();
			}
		},
		setServiceType: (state, action: PayloadAction<{ id: string; option: IServiceType; target?: 'root' | 'suborder' }>) => {
			const { id, option, target } = action.payload;

			if (target && target === 'suborder') {
				// @ts-ignore
				state.suborders[state.activeSuborderIndex].services = state.suborders[state.activeSuborderIndex].services.map((item) =>
					item.id === id
						? {
								...item,
								firstType: option,
								type: option?.services || undefined,
								workType: option?.services[0],
								price: getPriceByTypePriceId(option?.services[0], '82dd3937-2316-11ea-80d5-8107dcf40211'),
								serviceId: option?.services[0].code,
						  }
						: item,
				);
			} else {
				state.draftOrder.services = state.draftOrder.services.map((item) =>
					item.id === id
						? {
								...item,
								firstType: option,
								type: option?.services || undefined,
								workType: option?.services[0],
								price: getPriceByTypePriceId(option?.services[0], '82dd3937-2316-11ea-80d5-8107dcf40211'),
								serviceId: option?.services[0].code,
						  }
						: item,
				);
			}
		},
		setServiceWorkType: (state, action: PayloadAction<{ id: string; option: IServiceWorkType; target?: 'root' | 'suborder' }>) => {
			const { id, option, target } = action.payload;
			if (target && target === 'suborder') {
				// @ts-ignore
				state.suborders[state.activeSuborderIndex].services = state.suborders[state.activeSuborderIndex].services.map((item) =>
					item.id === id
						? {
								...item,
								workType: option,
								price: getPriceByTypePriceId(option, '82dd3937-2316-11ea-80d5-8107dcf40211') || 100,
								// @ts-ignore
								serviceId: option.code,
						  }
						: item,
				);
			} else {
				const service = state.draftOrder.services[id];

				if (service && 'workType' in service) {
					service.workType = option;
					// @ts-ignore
					serviceId = option.code;
					service.price = getPriceByTypePriceId(option, '82dd3937-2316-11ea-80d5-8107dcf40211') || 100;
				}
				state.draftOrder.services = state.draftOrder.services.map((item) =>
					item.id === id
						? {
								...item,
								workType: option,
								price: getPriceByTypePriceId(option, '82dd3937-2316-11ea-80d5-8107dcf40211') || 100,
								// @ts-ignore
								serviceId: option.code,
						  }
						: item,
				);
			}
		},
		updatePaintToningService: (state, action: PayloadAction<{ id: string } & Record<string, unknown>>) => {
			const { id, ...restProps } = action.payload;
			const rootSuborder = state.suborders[0];

			Object.entries(restProps).forEach(([key, value]) => {
				const service = state.suborders[state.activeSuborderIndex].services[id];

				if (service) {
					service[key] = value;
				}

				syncRootSuborderWithRecentUpdate({ rootSuborder, modifiedService: service });
			});
		},
		setItemsDeleteCandidates: (state, action: PayloadAction<{ items: Products[] | IService[] }>) => {
			state.itemsDeleteCandidates = action.payload.items;
		},
		clearItemsDeleteCandidates: (state) => {
			state.itemsDeleteCandidates = [];
		},
		clearAllExceptOrder: (state) => {
			state.subordersLimit = SUBORDERS_LIMIT;
			state.rootOrderId = null;
			state.activeSuborderIndex = 0;
		},
		syncDraftOrderWithRootSuborder: (state) => {
			if (!state.suborders) {
				state.suborders = [];
			}

			state.activeSuborderIndex = 0;
			state.suborders[0] = { ...state.draftOrder };
		},
		syncWithUseGetOrderQuery: (state, action: PayloadAction<ISingleOrder>) => {
			const order = action.payload;

			const { orderId, subordersCount, suborders, hasPaintToningSuborder, locker, draftOrder, paintToningSubordersCount } =
				// @ts-ignore
				getHydrateOrderStateData(order, state?.draftOrder, state?.draftOrder?.services ?? {});

			state.etalonOrder = order;
			state.draftOrder = draftOrder;
			state.subordersCount = subordersCount;
			state.rootOrderId = orderId;
			state.suborders = suborders;
			// @ts-ignore
			state.sum = draftOrder?.sum;
			state.hasAtLeastOnePaintToningSuborderOnServer = hasPaintToningSuborder;
			state.locker = locker;
			state.activeSuborderIndex = state?.activeSuborderIndex || 0;
			state.paintToningSubordersCount = paintToningSubordersCount;
			// state.draftOrder.services=
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		syncSubOrderWhenUpdate: (state, action: PayloadAction<any>) => {
			const subOrderPayload = action.payload;
			const subOrderProducts = subOrderPayload.products;
			const subOrderProductsIds = subOrderProducts.map((product) => product.productId);

			state.etalonOrder.products = state.etalonOrder.products.filter((product) => {
				return subOrderProductsIds.includes(product.id);
			});

			state.draftOrder.products = state.draftOrder.products.filter((product) => {
				return subOrderProductsIds.includes(product.id);
			});
			state.suborders[state.activeSuborderIndex].products = state.suborders[state.activeSuborderIndex].products.filter((product) => {
				return subOrderProductsIds.includes(product.id);
			});
		},
		/*
		 * paint toning flow
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		updateDraftOrderData: (state, action: PayloadAction<any>) => {
			const formData = action.payload;
			state.draftOrder = { ...state.draftOrder, ...formData };
		},
		clearDraftOrderData: (state) => {
			state.draftOrder = {};
		},
		replaceRealSubOrder: (state, action: PayloadAction<{ order: ISingleOrder; tempId: string }>) => {
			const { order, tempId } = action.payload;
			const index = state.suborders.findIndex((suborder) => suborder.tempId === tempId);
			const tabTitle = state.suborders[index]?.meta.tabTitle;

			if (index !== -1) {
				// @ts-ignore
				state.suborders[index] = { ...order, meta: { isSaved: true, tabTitle: tabTitle } };
			}
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		swapServices: (state, action: PayloadAction<{ services: any; target?: 'root' | 'suborder'; index: number }>) => {
			const { services, target, index } = action.payload;
			if (target && target === 'suborder' && services !== undefined) {
				state.suborders[index].services = services;
			} else {
				if (state.draftOrder) state.draftOrder.services = services;
			}
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			isAnyOf(ordersSliceApi.endpoints.getOrderById.matchFulfilled, ordersSliceApi.endpoints.updateOrder.matchFulfilled),
			(state, action) => {
				const order = action.payload;

				const { orderId, subordersCount, suborders, hasPaintToningSuborder, locker, draftOrder, paintToningSubordersCount } =
					// @ts-ignore
					getHydrateOrderStateData(order, state.suborders[0], state?.draftOrder?.services ?? {});

				state.etalonOrder = order;
				state.draftOrder = draftOrder;
				state.subordersCount = subordersCount;
				state.suborders = suborders;
				state.rootOrderId = orderId;
				state.hasAtLeastOnePaintToningSuborderOnServer = hasPaintToningSuborder;
				state.locker = locker;
				state.activeSuborderIndex = state?.activeSuborderIndex || 0;
				state.paintToningSubordersCount = paintToningSubordersCount;
			},
		);
		builder.addMatcher(isAnyOfRefreshRerenderKeyTriggerActions(), (state) => {
			state.lastForcedRerenderTimestamp = Date.now();
		});
	},
});

// const recalculateTriggerActions = [
// 	orderPreviewSlice.actions.updateProductProperty,
// 	orderPreviewSlice.actions.updateServiceQuantity,
// 	orderPreviewSlice.actions.updateServicePrice,
// 	orderPreviewSlice.actions.addService,
// 	orderPreviewSlice.actions.removeManyServices,
// 	orderPreviewSlice.actions.removeService,
// 	orderPreviewSlice.actions.removeSuborder,
// ];

// function isAnyOfRecalculateOrderStatsTriggers() {
// 	return isAnyOf(...recalculateTriggerActions);
// }

const refreshRerenderKeyTriggerActions = [orderPreviewSlice.actions.createBlankSuborder, orderPreviewSlice.actions.createSuborderWithProducts];

function isAnyOfRefreshRerenderKeyTriggerActions() {
	return isAnyOf(...refreshRerenderKeyTriggerActions);
}

export const orderPreviewActions = orderPreviewSlice.actions;
export default orderPreviewSlice.reducer;
