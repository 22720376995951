import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import localForage from 'localforage';
import type { OriginalPaginatedRequestResult, RequestResult } from 'models/api';
import { transformToPaginatedRequestResult } from 'models/api/transform';
import type { ClientOption, ClientPreview } from 'models/client';
import { Client, ClientOptionSchema, ClientPreviewSchema, ClientSchema } from 'models/client';
import { ClientFilters, ClientFiltersSchema } from 'models/client/filters';
import { Segment, SegmentSchema } from 'models/client/segment';
import { Option } from 'models/common/options';
import { ClientId } from 'models/common/uuid';
import { Contract, ContractSchema } from 'models/contract';
import { API_URL } from 'services/api';
import { clientsQueryKeys } from 'services/queryKeys';
import { logger } from 'utils/logger';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TODOType = any;

const getAuthToken = async () => {
	try {
		const token = await localForage.getItem('token');
		return token;
	} catch (error) {
		logger.error('Error getting auth token:', error);
		return null;
	}
};

const axiosInstance = axios.create({
	baseURL: 'https://unitb-appback.alterego.biz.ua/api/v1',
});

export const clientsSliceApi = createApi({
	reducerPath: 'clients',
	baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
	tagTypes: [clientsQueryKeys.clients(), 'filters'],
	endpoints: (builder) => ({
		createClient: builder.mutation<TODOType, TODOType>({
			queryFn: async (data) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.post('/clients', data, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 201) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
			invalidatesTags: [clientsQueryKeys.clients()],
		}),
		/**
		 * Due to error below we cannot use generics for builder.query<TData, TAgr>
		 * Type instantiation is excessively deep and possibly infinite.ts(2589)
		 */
		getClients: builder.query({
			queryFn: async (payload: string | string[]) => {
				const authToken = await getAuthToken();

				try {
					const queryParams = Array.isArray(payload) ? payload[0] : payload;
					const response = await axiosInstance.get<OriginalPaginatedRequestResult<ClientPreview[]>>('/clients', {
						params: new URLSearchParams(queryParams),
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const result = transformToPaginatedRequestResult<ClientPreview[]>(response.data);
					const validation = ClientPreviewSchema.array().safeParse(result.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: {
								data: [],
								page: 1,
								pagesCount: 1,
								total: 0,
							},
						};
					}
					return {
						data: {
							data: validation.data,
							page: result.page,
							pagesCount: result.pagesCount,
							total: result.total,
						},
					};
				} catch (error) {
					throw error;
				}
			},
			providesTags: (result) => (result ? [clientsQueryKeys.clients(), ...result.data.map(({ id }) => clientsQueryKeys.client(id))] : []),
		}),
		getSegments: builder.query<Segment[], string>({
			queryFn: async (queryParams) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get<Segment[]>('/segments', {
						params: new URLSearchParams(queryParams),
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = SegmentSchema.array().safeParse(response.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return { data: [] };
					}

					return {
						data: validation.data,
					};
				} catch (error) {
					throw error;
				}
			},
		}),
		getClientsOptions: builder.query<Option[], void>({
			queryFn: async () => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get<RequestResult<ClientOption[]>>('/clients/all', {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = ClientOptionSchema.array().safeParse(response.data.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return { data: [] };
					}

					return {
						data: validation.data as Option[],
					};
				} catch (error) {
					throw error;
				}
			},
		}),
		/**
		 * Due to error below we cannot use generics for builder.query<TData, TAgr>
		 * Type instantiation is excessively deep and possibly infinite.ts(2589)
		 */
		getClientById: builder.query({
			queryFn: async (id: ClientId) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get<RequestResult<Client>>(`/clients/${id}`, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = ClientSchema.safeParse(response.data.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: {},
						};
					}
					return {
						data: validation.data,
					};
				} catch (error) {
					throw error;
				}
			},
			providesTags: (_, __, id) => [clientsQueryKeys.client(id)],
		}),
		getContractList: builder.query<Contract[], string>({
			queryFn: async (clientId) => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get<RequestResult<Contract[]>>(`/contracts/client/${clientId}`, {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}
					const validation = ContractSchema.array().safeParse(response.data.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: [],
						};
					}
					return {
						data: validation.data,
					};
				} catch (error) {
					throw error;
				}
			},
		}),
		getClientsFiltersData: builder.query<ClientFilters, void>({
			queryFn: async () => {
				const authToken = await getAuthToken();
				try {
					const response = await axiosInstance.get<ClientFilters>('/clients/filters', {
						headers: {
							Authorization: `Bearer ${authToken}`,
							'Content-Type': 'application/json',
						},
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}
					const validation = ClientFiltersSchema.safeParse(response.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return { data: {} };
					}
					return {
						data: validation.data,
					};
				} catch (error) {
					throw error;
				}
			},
			providesTags: (result) => (result ? [clientsQueryKeys.clients(), 'filters'] : []),
		}),
	}),
});

export const {
	useGetClientsQuery,
	useGetClientByIdQuery,
	useGetSegmentsQuery,
	useGetClientsOptionsQuery,
	useCreateClientMutation,
	useGetContractListQuery,
	useGetClientsFiltersDataQuery,
	useLazyGetClientsQuery,
	useLazyGetClientByIdQuery,
} = clientsSliceApi;
