/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import DateAndTimeComponent from 'components/Table/TableComponents/DateAndTimeComponent';
import type { IProps as DateAndTimeComponentProps } from 'components/Table/TableComponents/DateAndTimeComponent/types';
import TextWrapper from 'components/Table/TableComponents/TextWrapper';
import type { IProps as TextWrapperProps } from 'components/Table/TableComponents/TextWrapper/types';
import { breakPoints } from 'const';
import React, { ComponentProps } from 'react';
import { useMediaQuery } from 'react-responsive';

import SortingButton from '../SortingButton';
import type { SortingButtonProps } from '../SortingButton/types';
import styles from './styles.module.css';
import { useTableCellElementStyles } from './useTableCellElementStyles';

type HeaderCellContentProps =
	| ({ sortable?: false } & TextWrapperProps)
	| ({
			sortable: true;
			forceSortableMobile?: boolean;
	  } & SortingButtonProps &
			TextWrapperProps);

export const HeaderCellContent = ({ className, children, value, sortable, ...props }: HeaderCellContentProps) => {
	const { header } = useTableCellElementStyles();
	const hasColumn = 'column' in props;
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });
	// @ts-ignore
	const shouldRenderSortingButton = (props.forceSortableMobile || !isMobile) && sortable && hasColumn;

	return (
		<TextWrapper className={clsx(header, styles.headerCell, { [styles.sortable]: !!sortable }, className)} value={value} {...props}>
			{children}

			{shouldRenderSortingButton && (
				<SortingButton withIndeterminate={!!props?.withIndeterminate} disabled={!!props.disabled} column={props.column} />
			)}
		</TextWrapper>
	);
};
export const RegularCellContent = ({ className, children, value, ...props }: TextWrapperProps) => {
	const { cell } = useTableCellElementStyles();

	return (
		<TextWrapper className={clsx(cell, className)} value={value} {...props}>
			{children}
		</TextWrapper>
	);
};

export const DatetimeCellContent = ({ className, ...props }: DateAndTimeComponentProps) => {
	const { cell: cellStyles } = useTableCellElementStyles();

	return <DateAndTimeComponent className={clsx(cellStyles, className)} {...props} />;
};

export const ActionCellContent = ({
	className,
	value,
	children,
	onChangeClick,
	...props
}: TextWrapperProps & { onChangeClick?: ComponentProps<'button'>['onClick'] }) => {
	const { cell } = useTableCellElementStyles();

	return (
		<TextWrapper className={clsx(cell, styles.actionCellContentWrapper, className)} {...props} width="100%">
			<span className={styles.actionCellContentWrapper}>
				{value} {children}
			</span>

			<button type="button" onClick={onChangeClick} className={clsx('text-xs-regular', styles.actionCellButton)}>
				Змінити
			</button>
		</TextWrapper>
	);
};
