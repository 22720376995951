import styles from './styles.module.css';

export const preventDefaultBehavior = (e: MouseEvent | Event) => {
	e.preventDefault();
	e.stopPropagation();
};

export const disableUserSelect = () => {
	document.body.classList.add(styles.tooltipBody);
};

export const enableUserSelect = () => {
	document.body.classList.remove(styles.tooltipBody);
};
