import clsx from 'clsx';
import BreadCrumbs from 'components/BreadCrumbs';
import { Breadcrumb } from 'components/BreadCrumbs/types';
import Button from 'components/Button';
import Checkbox from 'components/ColorfulSelect/Checkbox';
import CreatePriceTopBar from 'components/CreatePriceTopBar';
import { loadTypeOfPrices } from 'components/FormComponents/optionsLoaders';
import Input from 'components/Input';
import EmptyFilterProductState from 'components/OrderCreatePageComponents/EmptyFilterProductState';
import { useProductConfigurationToolModal } from 'components/OrderCreatePageComponents/ProductConfigurationToolModal/useProductConfigurationToolModal';
import ProductFilter from 'components/OrderCreatePageComponents/ProductFilter';
import PriceTypes from 'components/OrderCreatePageComponents/ProductSelectTopBar/PriceTypes';
import { PriceTypeItem } from 'components/OrderCreatePageComponents/ProductSelectTopBar/PriceTypes/types';
import TableActionsPanel from 'components/OrderCreatePageComponents/TableActionsPanel';
import RadioButtonSelect from 'components/RadioButtonSelect';
import { SelectOption } from 'components/Select/types';
import Spacer from 'components/Spacer';
import Spinner from 'components/Spinner';
import Table from 'components/Table';
import SelectProductMobileTable from 'components/Table/MobileViews/SelectProductMobileTable';
import MobileHeader from 'components/Table/TableComponents/MobileHeader';
import Toggle from 'components/Toggle';
import { breakPoints, ROUTES_URLS } from 'const';
import { useAdjustableColumns } from 'hooks/useAdustableColumns';
import { useInsertColumns } from 'hooks/useInsertColumns';
import { usePinnedColumns } from 'hooks/usePinnedColumns';
import { useSelectedRows } from 'hooks/useSelectedRows';
import { Products } from 'models/IOrder';
import { clientsIcons } from 'pages/Prices/icons';
import React, { MouseEvent, Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Arrow from 'static/images/arrow-block-left.svg';
import { ReactComponent as Arrows } from 'static/images/currency-dollar.svg';
import Eye from 'static/images/eyeWhite.svg';
import { ReactComponent as Info } from 'static/images/info-circle.svg';
import Plus from 'static/images/plus.svg';
import { Product } from 'store/reducers/orders/types';
import { selectAllProducts } from 'store/reducers/products/selectors';
import { createPriceTypeColumn } from 'utils/columns';
import { prepareUrl } from 'utils/shared';
import { isString } from 'utils/type-guards';

import { useCreatePriceColumns } from './columns';
import styles from './styles.module.css';
import { IProps } from './types';

const SAVE_CONFIG_KEY = '/Create-price';

const CreatePrice: React.FC<IProps> = ({ breadcrumbs, searchOnClick }) => {
	const [name, setName] = useState('');
	const [pricesTypeFilter, setPricesTypeFilter] = useState<SelectOption>();
	const [searchParams, setSearchParams] = useSearchParams();
	const [tableMode, setTableMode] = useState(false);
	const [checkedPhoto, setChechekedPhoto] = useState(false);
	const contentContainerRef = useRef<HTMLDivElement>(null);

	const { openModal } = useProductConfigurationToolModal({ mode: 'create' });
	const products = useSelector(selectAllProducts(searchParams.toString()));
	const navigate = useNavigate();

	const crumbs: Breadcrumb[] = useMemo(
		() =>
			breadcrumbs ?? [
				{ label: 'Прайси', href: prepareUrl(ROUTES_URLS.PRICES) },
				{ label: 'Прайс-лист №', href: '' },
			],
		[breadcrumbs],
	);

	const handleClickCreate = () => {
		navigate(prepareUrl(ROUTES_URLS.PRICES_SAVE));
	};

	const initialColumns = useCreatePriceColumns({ onModalOpen: openModal });
	const { visibilityModel } = useAdjustableColumns(initialColumns, { saveConfigKey: SAVE_CONFIG_KEY });
	const { pinningModel, setPinningModel } = usePinnedColumns({ saveConfigKey: SAVE_CONFIG_KEY });
	const { rowSelectionModel, setRowSelectionModel } = useSelectedRows({ saveConfigKey: SAVE_CONFIG_KEY });
	const { columns, insertAfter, unInsert } = useInsertColumns({ columns: initialColumns, pivot: 'price', incrementOffset: true });

	const showSpinner = !products;
	const showEmptyState = !!products && products.length === 0;
	const showTable = !!products && products.length > 0;

	const handlePricesTypeSelect = (newOption: string | SelectOption) => {
		if (isString(newOption)) {
			setPricesTypeFilter({ label: newOption, value: newOption });
		} else {
			setPricesTypeFilter(newOption);
		}
	};

	const handleColumnsInsert = (_: number, item: PriceTypeItem, isActive: boolean) => {
		if (isActive) {
			const priceTypeColumn = createPriceTypeColumn({ header: item.label, cell: '—', accessor: item.label, color: item.fontColor });

			insertAfter(priceTypeColumn);
		} else {
			const position = columns.findIndex((col) => col.accessor === item.label);
			unInsert(position);
		}
	};

	const handleCheckPhoto = () => {
		setChechekedPhoto(!checkedPhoto);
	};

	const addProductToOrder = async (product: Product) => {
		// @ts-ignore
		await patch((prevOrder) => ({ ...prevOrder, products: [product, ...prevOrder.products] }));
		toast.success('Товар додано до заявки');
	};

	const pickProduct = (product: Products, e?: MouseEvent<HTMLElement>) => {
		e?.stopPropagation();
		// @ts-ignore
		openModal(product);
	};

	const handleToggleInputChange = () => {
		const newUrlSearchParams = new URLSearchParams(searchParams);

		if (newUrlSearchParams.has('inStock')) {
			newUrlSearchParams.delete('inStock');
		} else {
			newUrlSearchParams.append('inStock', 'true');
		}

		setSearchParams(newUrlSearchParams);
	};

	const handleShowTable = () => {
		setTableMode(true);
	};

	const handleHideTable = () => {
		setTableMode(false);
	};

	const handleNavigateToSavePrice = () => {
		navigate(prepareUrl(ROUTES_URLS.PRICES_SAVE));
	};

	const RenderMobileTableRow = useCallback(
		({ row }) => <SelectProductMobileTable readonly row={row} onChangeQuantityClick={pickProduct} onAddProductClick={addProductToOrder} />,
		[],
	);
	const RenderTableActionPanelSlot = useCallback((props) => <TableActionsPanel className={styles.tableActionPanel} {...props} />, []);

	const RenderMobileHeader = useCallback(
		({ headerGroups }) => (
			<Suspense fallback={<Spinner />}>
				<MobileHeader
					gridCustomLayout="44px auto"
					headerGroups={headerGroups}
					renderHeaders={['.', 'title']}
					justify="flex-start"
					className={styles.mobileHeader}
					bgColor="var(--gray-50)"
				/>
			</Suspense>
		),
		[],
	);

	return (
		<div className={clsx('main-wrapper', styles.mainWrapper)}>
			<div className={clsx('container', styles.container)}>
				<BreadCrumbs crumbs={crumbs} backLink={prepareUrl(ROUTES_URLS.PRICES)} />

				<MediaQuery minWidth={breakPoints.MOBILE}>
					<CreatePriceTopBar
						searchOnClick={searchOnClick}
						onChooseButtonClick={handleShowTable}
						tableMode={tableMode}
						handleBack={handleHideTable}
						nameOfMainBtn={'Повернутись до налаштування'}
					/>
				</MediaQuery>
				<Spacer height="31px" />
				{!tableMode && (
					<div className={styles.filtersBox}>
						<Input type={'text'} value={name} setValue={setName} placeholder="Додати назву нового прайсу" className={styles.input} />
						<RadioButtonSelect
							placeholder="Оберіть тип цін"
							loadAsyncOptions={loadTypeOfPrices}
							value={pricesTypeFilter}
							setValue={handlePricesTypeSelect}
							className={styles.typeOfPrice}
							leadingIcon={Arrows}
						/>
						<Checkbox label={'Фото'} checked={checkedPhoto} onChange={handleCheckPhoto} className={styles.checkbox} />
					</div>
				)}
				{tableMode ? (
					<div ref={contentContainerRef} className={clsx(styles.contentContainer, 'hide-scroll-bar')}>
						<div className={clsx(styles.productFilterContainer, 'hide-scroll-bar')}>
							<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
								<PriceTypes onClick={handleColumnsInsert} className={clsx(styles.priceTypes, 'hide-scroll-bar')}>
									<Toggle hideIcon label="Тільки в наявності" onChange={handleToggleInputChange} />
								</PriceTypes>
							</MediaQuery>

							<ProductFilter />
						</div>

						<div className={clsx(styles.tableContainer)}>
							<MediaQuery minWidth={breakPoints.MOBILE}>
								<div className={styles.addBtnBox}>
									<Toggle hideIcon label="Тільки в наявності" onChange={handleToggleInputChange} />
									<Button
										text="Додати всю категорію"
										// onClick={handleChoosePriceButtonClick}
										icon={Plus}
										variant="default"
										background={'var(--primary-600)'}
									/>
								</div>
							</MediaQuery>

							{showSpinner && <Spinner />}

							{showEmptyState && <EmptyFilterProductState>За вказаними фільтрами товарів не знайдено</EmptyFilterProductState>}

							{showTable && (
								<>
									<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
										<p className={clsx('text-md-semibold color-grey-900', styles.tableCaption)}>Товари:</p>
									</MediaQuery>

									<Table
										selectable
										columns={columns}
										pageType="order"
										pinningModel={pinningModel}
										onPinningModelChange={setPinningModel}
										visibilityModel={visibilityModel}
										rowSelectionModel={rowSelectionModel}
										onRowSelectionModelChange={setRowSelectionModel}
										mobileViewComponent={RenderMobileTableRow}
										mobileHeader={RenderMobileHeader}
										allData={products}
										actionsPanelSlot={RenderTableActionPanelSlot}
									/>

									<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
										<div className="safe-area-bottom" />
									</MediaQuery>
								</>
							)}
						</div>
					</div>
				) : (
					<div className={styles.infoBox}>
						<Info className={styles.infoIcon} />
						Оберіть налаштування, та оберіть номенклатуру, для створення прайс-листа
					</div>
				)}
				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<div className="safe-area-bottom" />
					<div className={styles.bottomPanel}>
						{!tableMode ? (
							<>
								<Button
									variant="default"
									text="Оберіть товар"
									icon={Eye}
									className={styles.filterBtn}
									background="var(--pink)"
									onClick={handleShowTable}
								/>
								<Button
									variant="default"
									text="Коментар"
									icon={clientsIcons.Plus}
									className={styles.addClientBtn}
									background="var(--primary-500)"
								/>
							</>
						) : (
							<div className={styles.bottomButtons}>
								<Button
									variant="default"
									text="Повернутись до налаштування"
									icon={Arrow}
									className={styles.backBtn}
									background="var(--pink)"
									onClick={handleHideTable}
								/>
								<Button
									variant="default"
									text="Додати всю категорію"
									icon={clientsIcons.Plus}
									className={styles.addClientBtn}
									background="var(--primary-500)"
									onClick={handleNavigateToSavePrice}
								/>
							</div>
						)}
					</div>
				</MediaQuery>
			</div>
			<Button text="Створити прайс" variant="rounded" background="var(--primary-600)" className={styles.btn} onClick={handleClickCreate} />
		</div>
	);
};

export default CreatePrice;
