import OrderAlertDialoguesProvider from 'contexts/OrderAlertDialoguesProvider';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import type { Whoami } from 'models/auth';
import type { CatalogueService } from 'models/service';
import React from 'react';
import { useGetOrderByIdQuery, useGetServicesQuery } from 'store/reducers/orders/ordersSliceApi';
import { useGetInfoAboutMeQuery } from 'store/reducers/users/usersSliceApi';

import { createOrderLikeEntity } from './lib/entity-creators';

type OrderLikeEntity = ReturnType<typeof createOrderLikeEntity>;

type AwaitedNewOrderRouteProps = {
	children: (props: { data: [OrderLikeEntity, Record<string, CatalogueService>, Whoami] }) => JSX.Element;
};

interface UseGetServicesQueryReturn {
	data: Record<string, CatalogueService>;
	isFetching: boolean;
}

const AwaitedNewOrderRoute = ({ children }: AwaitedNewOrderRouteProps) => {
	const {
		data = {
			client: {},
			contract: {},
			organization: {},
			stock: {},
			responsible: {},
		},
		isError,
		isFetching,
	} = useGetOrderByIdQuery('order-like-entity');
	const { data: services, ...serviceReq } = useGetServicesQuery<UseGetServicesQueryReturn>();
	const { data: me, ...meReq } = useGetInfoAboutMeQuery();

	let order: OrderLikeEntity = { ...data };

	if (isFetching || serviceReq.isFetching || meReq.isFetching) return <PageContentSkeleton />;
	if (isError) {
		order = createOrderLikeEntity({
			client: {},
			manager: me,
			contract: {
				value: '',
				label: '',
			},
			organization: {
				value: '',
				label: '',
			},
			services: [],
			products: [],
		});
	}

	const payload = {
		data: [order, services, me] as [OrderLikeEntity, Record<string, CatalogueService>, Whoami],
	};

	return <OrderAlertDialoguesProvider>{children(payload)}</OrderAlertDialoguesProvider>;
};

export default AwaitedNewOrderRoute;
