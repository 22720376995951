import { LoaderFunction } from 'react-router-dom';

export const paintToningLoader: LoaderFunction = async ({ request }) => {
	const url = new URL(request.url);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const searchParams = url.searchParams;

	// const promise = store.dispatch(usersSliceApi.endpoints.getEmployees.initiate(searchParams.toString())).unwrap();
	return null;
};
