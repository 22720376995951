import clsx from 'clsx';
import Button from 'components/Button';
import CalculatorButton from 'components/CalculatorButton';
import RadioButtonSelectField from 'components/ChangeProductPricePanel/RadioButtonSelectField';
import DependantField from 'components/FormComponents/DependantField';
import FormField from 'components/FormComponents/FormField';
import Input from 'components/Input';
import Spinner from 'components/Spinner';
import SpinnerV2 from 'components/Spinner-v2';
import { breakPoints } from 'const';
import { PriceChangeOption } from 'models/IPriceChange';
import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import MediaQuery from 'react-responsive';
import { ReactComponent as CoinSwapIcon } from 'static/images/coins-swap-02.svg';
import SaveIcon from 'static/images/save.svg';
import XCloseIcon from 'static/images/x-close.svg';
import { useChangeProductsPriceMutation, useGetPriceChangeOptionsQuery } from 'store/reducers/orders/ordersSliceApi';
import { getPriceByTypePriceId } from 'store/reducers/orderViewer/utils';
import { roundNumber } from 'utils/shared';

import { useOrderNotifications } from '../hooks/useOrderNotifications';
import { ProductInternalModelState, useTypedOrderControllerFromContext } from '../OrderController';
import ExecutePriceChangeButton from './ExecutePriceChangeButton';
import styles from './styles.module.css';
import { isPriceChangeOptionWithDependantDropdown, isPriceChangeOptionWithDependantInput } from './typeGuards';
import { ChangePriceFormValues, ChangeProductPricePanelProps, Option } from './types';

const ChangeProductPricePanel: React.FC<ChangeProductPricePanelProps> = ({ className, suborderIndex, selectionModel, onCancel, onClose }) => {
	const { setValue, getValues } = useTypedOrderControllerFromContext();
	const [, { isLoading }] = useChangeProductsPriceMutation();
	const notify = useOrderNotifications();
	const form = useForm<ChangePriceFormValues<PriceChangeOption>>();
	const rollbackProducts = useRef<Record<string, ProductInternalModelState>>(null);
	const suborders = getValues('suborders');
	const productsRepository = getValues(`suborders.${suborderIndex}.data.products`);
	const candidatesIds = Object.keys(selectionModel ?? {});
	const { data: options = [], isLoading: areOptionsLoading } = useGetPriceChangeOptionsQuery();
	const isSaved = useRef<boolean>(false);

	if (!rollbackProducts.current) {
		rollbackProducts.current = productsRepository;
	}

	useEffect(() => {
		return () => {
			if (!isSaved.current && rollbackProducts.current) {
				setValue(`suborders.${suborderIndex}.data.products`, rollbackProducts.current);
			}
		};
	}, [rollbackProducts.current]);

	const handlePriceChangeCancel = () => {
		if (rollbackProducts.current) {
			setValue(`suborders.${suborderIndex}.data.products`, rollbackProducts.current);
		}

		onCancel();
	};

	const notifyAboutChangePriceSuccess = () => {
		notify.success('Ціну зімінено!');
	};

	const handlePriceChangeSave = () => {
		notify.success('Зміну цін збережено');
		isSaved.current = true;
		onClose();
	};
	const syncPriceChangeAcrossSuborders = (changedProducts: Record<string, ProductInternalModelState>) => {
		const changedProductsEntries = Object.entries(changedProducts ?? {});

		suborders.forEach((suborder, index) => {
			if (suborderIndex === index) return;

			const updatedProducts = { ...suborder.data.products };

			changedProductsEntries.forEach(([changedProductId, changedProduct]) => {
				if (updatedProducts[changedProductId]) {
					updatedProducts[changedProductId] = {
						...updatedProducts[changedProductId],
						price: changedProduct.price,
						sum: changedProduct.sum,
					};
				}
			});

			setValue(`suborders.${index}.data.products`, updatedProducts);
		});
	};

	const handleSubmit = async (values: ChangePriceFormValues<PriceChangeOption>) => {
		if (values.changeOption.url === '/77') {
			const percent = values.discount_in_per_cent;
			const newProductsEntries = candidatesIds.map((candidateId) => {
				const product = productsRepository[candidateId];

				const price = product.price ? Number(product.price) : getPriceByTypePriceId(product.id, 'ef741b50-b356-11e3-af42-8b1ea7c04b02');
				const newPrice = Number(price) + Number(price) * (Number(percent) / 100);
				const sum = newPrice * Number(product.amount);

				return [
					product.id,
					{
						...product,
						price: roundNumber(newPrice, 2),
						sum: roundNumber(sum, 2),
					},
				];
			});

			const updatedProductsRepository = { ...productsRepository, ...Object.fromEntries(newProductsEntries) };
			setValue(`suborders.${suborderIndex}.data.products`, updatedProductsRepository);
			syncPriceChangeAcrossSuborders(updatedProductsRepository);
			notifyAboutChangePriceSuccess();
		} else if (values.changeOption.url === '/66') {
			const percent = values.discount;
			const newProductsEntries = candidatesIds.map((candidateId) => {
				const product = productsRepository[candidateId];

				const price = product.price ? Number(product.price) : getPriceByTypePriceId(product, 'ef741b50-b356-11e3-af42-8b1ea7c04b02');
				const newPrice = Number(price) - Number(price) * (Number(percent) / 100);
				const sum = newPrice * Number(product.amount);
				return [
					product.id,
					{
						...product,
						price: roundNumber(newPrice, 2),
						sum: roundNumber(sum, 2),
						...(percent && { manualDiscount: percent }),
					},
				];
			});
			const updatedProductsRepository = { ...productsRepository, ...Object.fromEntries(newProductsEntries) };
			setValue(`suborders.${suborderIndex}.data.products`, updatedProductsRepository);
			syncPriceChangeAcrossSuborders(updatedProductsRepository);
			notifyAboutChangePriceSuccess();
		} else if (values.changeOption.url === '/44') {
			const inputSum = values.by_sum_distribution;

			const totalSum = candidatesIds.reduce((accumulator, candidateId) => {
				const product = productsRepository[candidateId];

				const price = product.price ? Number(product.price) : getPriceByTypePriceId(product, 'ef741b50-b356-11e3-af42-8b1ea7c04b02');
				const amount = product.amount ? Number(product.amount) : 1;

				return accumulator + price * amount;
			}, 0);

			const firstStep = inputSum / totalSum;

			const newProductsEntries = candidatesIds.map((candidateId, index) => {
				const product = productsRepository[candidateId];

				const price = product.price ? Number(product.price) : getPriceByTypePriceId(product, 'ef741b50-b356-11e3-af42-8b1ea7c04b02');
				const multiply = Number(firstStep) * Number(price);
				const multiplyPlusItemPrice = Number(multiply) + Number(price);
				const lastRow = inputSum - multiply;

				if (index + 1 !== candidatesIds.length) {
					return [
						product.id,
						{
							...product,
							price: roundNumber(multiplyPlusItemPrice, 2),
							sum: roundNumber(Number(multiplyPlusItemPrice) * Number(product.amount), 2),
						},
					];
				} else {
					return [
						product.id,
						{
							...product,
							price: roundNumber(lastRow + Number(price), 2),
							sum: roundNumber((lastRow + Number(price)) * Number(product.amount), 2),
						},
					];
				}
			});

			const updatedProductsRepository = { ...productsRepository, ...Object.fromEntries(newProductsEntries) };
			setValue(`suborders.${suborderIndex}.data.products`, updatedProductsRepository);
			syncPriceChangeAcrossSuborders(updatedProductsRepository);

			if (candidatesIds.length < 2) {
				notify.info('Це тип ціни можливо застосувати лише для двох товарів і більше');
			} else {
				notifyAboutChangePriceSuccess();
			}
		} else if (values.changeOption.url === '/55') {
			const inputSum = values.by_quantity_distribution;
			const countOfItems = candidatesIds.length;
			const firstStep = inputSum / countOfItems;

			const newProductsEntries = candidatesIds.map((candidateId, index) => {
				const product = productsRepository[candidateId];

				const quantity = product.amount ? Number(product.amount) : 1;
				const price = product.price ? Number(product.price) : getPriceByTypePriceId(product, 'ef741b50-b356-11e3-af42-8b1ea7c04b02');
				const multiply = firstStep * quantity;
				const multiplyPlusItemPrice = multiply + Number(price);
				const lastRow = inputSum - multiply;
				if (index + 1 !== candidatesIds.length) {
					return [
						product.id,
						{
							...product,
							price: roundNumber(multiplyPlusItemPrice, 2),
							sum: roundNumber(Number(product.amount) * multiplyPlusItemPrice, 2),
						},
					];
				} else {
					return [
						product.id,
						{
							...product,
							price: roundNumber(lastRow + Number(price), 2),
							sum: roundNumber((lastRow + Number(price)) * Number(product.amount), 2),
						},
					];
				}
			});

			const updatedProductsRepository = { ...productsRepository, ...Object.fromEntries(newProductsEntries) };
			setValue(`suborders.${suborderIndex}.data.products`, updatedProductsRepository);
			syncPriceChangeAcrossSuborders(updatedProductsRepository);

			if (candidatesIds.length < 2) {
				notify.info('Це тип ціни можливо застосувати лише для двох товарів і більше');
			} else {
				notifyAboutChangePriceSuccess();
			}
		} else if (values.changeOption.url === '/22') {
			notify.warn('Наразі обраний тип ціни недоступний!');
		} else if (values.changeOption.url === '/33') {
			notify.warn('Наразі обраний тип ціни недоступний!');
		} else if (values.changeOption.url === '/88') {
			notify.warn('Наразі обраний тип ціни недоступний!');
		} else {
			const priceId = values.price_type.value;
			const productsWithNewPriceSettled = candidatesIds.map((candidateId) => {
				const product = productsRepository[candidateId];
				const price = getPriceByTypePriceId(product, priceId);
				const sum = Number(price) * Number(product.amount);

				return [product.id, { ...product, price: String(price), sum }];
			});
			const updatedProductsRepository = { ...productsRepository, ...Object.fromEntries(productsWithNewPriceSettled) };
			setValue(`suborders.${suborderIndex}.data.products`, updatedProductsRepository);
			syncPriceChangeAcrossSuborders(updatedProductsRepository);
			notifyAboutChangePriceSuccess();
		}
	};

	if (areOptionsLoading) {
		return (
			<div className={clsx(styles.changePricePanel, className)}>
				<SpinnerV2 position="center" />
			</div>
		);
	}

	return (
		<FormProvider {...form}>
			<form className={clsx(styles.changePricePanel, className)} onSubmit={form.handleSubmit(handleSubmit)}>
				<RadioButtonSelectField
					rotateChevronWhenExpand
					firstOptionAsDefault
					name="changeOption"
					leadingIcon={CoinSwapIcon}
					valuesList={options}
					className={styles.majorField}
				/>

				<DependantField<Option> watch="changeOption">
					{({ value }) => {
						if (!value) return null;

						if (isPriceChangeOptionWithDependantDropdown(value)) {
							const dependantFieldOption = value.field.items.map((option) => ({ label: option.title, value: option.value }));

							return (
								<RadioButtonSelectField
									rotateChevronWhenExpand
									firstOptionAsDefault
									key={value.field.name}
									shouldUnregister={true}
									name={value.field.name}
									valuesList={dependantFieldOption}
									className={styles.dependantSelect}
								/>
							);
						}

						if (isPriceChangeOptionWithDependantInput(value)) {
							return (
								<>
									<FormField
										key={value.field.name}
										shouldUnregister={true}
										name={value.field.name}
										component={Input}
										className={styles.dependantInput}
										placeholder="0.00"
										{...(value.shortTitle && { label: value.shortTitle, fieldClassName: styles.field })}
									/>

									{!!value.field.withCalculator && <CalculatorButton />}
								</>
							);
						}

						return null;
					}}
				</DependantField>

				<ExecutePriceChangeButton disabled={isLoading} className={styles.executeButton} />
			</form>

			{isLoading && <Spinner />}

			<MediaQuery minWidth={breakPoints.MOBILE}>
				<div className={clsx(styles.fixedBtn, styles.priceChangeControls)}>
					<Button text="Скасувати" variant="rounded" icon={XCloseIcon} onClick={handlePriceChangeCancel} className={styles.cancelButton} />
					<Button text="Зберегти" variant="rounded" icon={SaveIcon} className={styles.saveButton} onClick={handlePriceChangeSave} />
				</div>
			</MediaQuery>
		</FormProvider>
	);
};

export default ChangeProductPricePanel;
