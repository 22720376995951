import clsx from 'clsx';
import React, { ForwardedRef, forwardRef } from 'react';

import styles from './styles.module.css';
import type { ExpandedColumnProps } from './types';

const ExpandedColumn = ({ className, children, ...restProps }: ExpandedColumnProps, ref: ForwardedRef<HTMLTableRowElement>) => {
	return (
		<tr ref={ref} className={clsx(styles.expandedColumn, className)} {...restProps}>
			{children}
		</tr>
	);
};

export default forwardRef(ExpandedColumn);
