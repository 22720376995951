import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import ClientComponent from 'components/Table/TableComponents/ClientComponent';
import { DatetimeCellContent } from 'components/Table/TableComponents/ContentOrientedCells';
import ProgressBar from 'components/Table/TableComponents/ProgressBar';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import { breakPoints, ROUTES_URLS } from 'const';
import { ClientPreview } from 'models/client';
import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { formatAndExtractDateTime } from 'utils/dates';
import { prepareUrl } from 'utils/shared';

import { clientsIcons } from './icons';
import styles from './style.module.css';

const columnHelper = createColumnHelper<ClientPreview>();

export const useClientsColumns = (handleClick: (row: ClientPreview) => void) => {
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });

	const initialColumns = useMemo(
		() => [
			columnHelper.accessor((row) => row.code, {
				id: 'code',
				header: () => <HeaderCell>Код клієнта</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				size: 100,
				enableHiding: true,
				enableSorting: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Код клієнта',
					getMobileHeaderCell: () => 'Код клієнта',
					icon: <clientsIcons.Case />,
					sortQueryKey: 'code',
				},
			}),
			columnHelper.accessor((row) => row.name ?? 'Клієнт', {
				id: 'name',
				header: () => <HeaderCell>Клієнт</HeaderCell>,
				cell: (cell) => (
					<RegularCell>
						<ClientComponent cursor="pointer" onClick={() => handleClick(cell.row.original)} client={cell.getValue()} minWidth="233px" />
					</RegularCell>
				),
				enableHiding: true,
				enableSorting: true,
				size: 233,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Клієнт',
					getMobileHeaderCell: () => 'Клієнт',
					icon: <clientsIcons.Case />,
					sortQueryKey: 'client',
				},
			}),
			columnHelper.accessor((row) => row.segment?.title, {
				id: 'segment',
				header: () => <HeaderCell>Сегмент</HeaderCell>,
				cell: (cell) => {
					return <RegularCell>{cell.getValue()}</RegularCell>;
				},
				size: 233,
				enableHiding: true,
				enableSorting: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Сегмент',
					getMobileHeaderCell: () => 'Сегмент',
					icon: <clientsIcons.Chart />,
					sortQueryKey: 'segment',
				},
			}),
			columnHelper.accessor((row) => row.ordersCount, {
				id: 'seeOrdersAction',
				header: () => <HeaderCell>Заявки</HeaderCell>,
				cell: (cell) => (
					<RegularCell>
						<Link
							to={prepareUrl(ROUTES_URLS.CLIENT_ORDERS, { id: cell.row.original.id })}
							className={clsx('text-sm-regular', { disabled: cell.getValue() > 0 })}
							style={{ color: 'var(--primary-700)', textDecoration: 'underline' }}
						>
							Переглянути
						</Link>
					</RegularCell>
				),
				size: 134,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Заявки',
					getMobileHeaderCell: () => 'Заявки',
					icon: <clientsIcons.File />,
				},
			}),
			columnHelper.accessor((row) => row.limit, {
				id: 'limit',
				header: () => <HeaderCell>Ліміт</HeaderCell>,
				cell: (cell) => {
					const { limit = 0, availableSum = 0 } = cell.getValue() ?? {};
					const value = availableSum < 0 ? limit + Math.abs(availableSum) : availableSum;

					return (
						<RegularCell className={styles.width}>
							<ProgressBar className={styles.progress} limit={limit} value={value} variant="money" />
						</RegularCell>
					);
				},
				size: 250,
				enableSorting: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Ліміт',
					getMobileHeaderCell: () => 'Ліміт',
					icon: <clientsIcons.Coin />,
					sortQueryKey: 'limit',
				},
			}),
			columnHelper.accessor((row) => row.lastDeal, {
				id: 'lastDeal',
				header: () => <HeaderCell>{isMobile ? 'Ост. прод.' : 'Останній продаж'}</HeaderCell>,
				cell: (cell) => {
					const [date] = formatAndExtractDateTime(cell.getValue());

					return (
						<RegularCell>
							<DatetimeCellContent date={date} />
						</RegularCell>
					);
				},
				size: 164,
				enableSorting: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Останній продаж',
					getMobileHeaderCell: () => 'Останній продаж',
					icon: <clientsIcons.Clock />,
					sortQueryKey: 'lastDeal',
				},
			}),
			columnHelper.accessor((row) => row.manager?.name ?? '-', {
				id: 'manager',
				header: () => <HeaderCell>Менеджер</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				size: 233,
				enableSorting: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Менеджер',
					getMobileHeaderCell: () => 'Менеджер',
					icon: <clientsIcons.User />,
					sortQueryKey: 'manager',
				},
			}),
			columnHelper.accessor((row) => ('edrpouCode' in row ? row.edrpouCode : ''), {
				id: 'edrpouCode',
				header: () => <HeaderCell>Код ЄДРПОУ</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				size: 233,
				enableSorting: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Код ЄДРПОУ',
					getMobileHeaderCell: () => 'Код ЄДРПОУ',
					icon: <clientsIcons.EdrpouCode />,
					sortQueryKey: 'ipnCode',
				},
			}),
			columnHelper.accessor((row) => row.activityType, {
				id: 'activityType',
				header: () => <HeaderCell>Вид діяльності</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				size: 233,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Вид діяльності',
					getMobileHeaderCell: () => 'Вид діяльності',
					icon: <clientsIcons.ActivityType />,
				},
			}),
			columnHelper.accessor((row) => row.createdAt, {
				id: 'createdAt',
				header: () => <HeaderCell>Дата створення</HeaderCell>,
				cell: (cell) => {
					const [formattedDate, formattedTime] = formatAndExtractDateTime(cell.getValue());

					return (
						<RegularCell justify={isMobile ? 'start' : 'center'}>
							<DatetimeCellContent date={formattedDate} time={formattedTime} />
						</RegularCell>
					);
				},
				size: 233,
				enableSorting: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Дата створення',
					getMobileHeaderCell: () => 'Дата створення',
					icon: <clientsIcons.CreationDate />,
					sortQueryKey: 'createdAt',
				},
			}),
			columnHelper.accessor((row) => row.clientStatus, {
				id: 'clientStatus',
				header: () => <HeaderCell>Стан клієнта</HeaderCell>,
				cell: (cell) => <RegularCell>{String(cell.getValue())}</RegularCell>,
				size: 233,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Стан клієнта',
					getMobileHeaderCell: () => 'Стан клієнта',
					icon: <clientsIcons.ClientStatus />,
				},
			}),
			columnHelper.accessor((row) => row.comment, {
				id: 'comment',
				header: () => <HeaderCell>Коментар</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				size: 233,
				enableSorting: true,
				meta: {
					getCanRenderOnMobile: () => true,
					getVisibilityToggleTitle: () => 'Коментар',
					getMobileHeaderCell: () => 'Коментар',
					icon: <clientsIcons.Comment />,
					sortQueryKey: 'comment',
				},
			}),
			columnHelper.accessor('chooseClient', {
				id: 'chooseClient',
				header: () => <HeaderCell>Обрати клієнта:</HeaderCell>,
				size: 100,
				enableHiding: true,
				meta: {
					getCanRenderOnMobile: () => false,
				},
			}),
			columnHelper.accessor('sort', {
				id: 'sort',
				header: () => <HeaderCell>Сортувати А - Я</HeaderCell>,
				size: 100,
				meta: {
					getCanRenderOnMobile: () => false,
				},
			}),
		],
		[isMobile],
	);

	return initialColumns;
};
