import clsx from 'clsx';
import { useClickOutside } from 'hooks/useClickOutside';
import React, { Children, cloneElement, isValidElement, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { ReactComponent as TwoLineVerticalColumnsDotsIcon } from 'static/images/two-line-vertical-columns.svg';

import cssStyles from './styles.module.css';
import type { ColumnControlsMenuProps } from './types';

const ColumnControlsMenu = ({ children, className }: ColumnControlsMenuProps) => {
	const containerRef = useClickOutside(closeMenu);
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const [boundaryElement, setBoundaryElement] = useState(null);
	const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: 'bottom',
		strategy: 'absolute',
		modifiers: [
			{
				name: 'preventOverflow',
				options: {
					boundary: boundaryElement,
				},
			},
		],
	});

	useEffect(() => {
		const boundary = document.querySelector('.container');

		if (boundary) {
			setBoundaryElement(boundary);
		}
	}, []);

	function closeMenu() {
		setIsMenuOpened(false);
	}

	const toggle = () => {
		setIsMenuOpened(!isMenuOpened);
	};

	useEffect(() => {
		if (attributes?.popper?.['data-popper-reference-hidden'] && isMenuOpened) {
			closeMenu();
		}
	}, [attributes]);

	return (
		<span ref={containerRef} className={clsx(cssStyles.menu, className)}>
			<button ref={setReferenceElement} type="button" data-is-active={isMenuOpened} onClick={toggle} className={clsx(cssStyles.trigger)}>
				<TwoLineVerticalColumnsDotsIcon />
			</button>

			{isMenuOpened &&
				createPortal(
					<span ref={setPopperElement} className={clsx(cssStyles.dropdown)} style={styles.popper} {...attributes.popper}>
						{Children.map(children, (child) => {
							if (isValidElement(child)) {
								return cloneElement(child as React.ReactElement<{ onClick: () => void }>, { onClick: closeMenu });
							}

							return child;
						})}
					</span>,
					document.querySelector('#portal'),
				)}
		</span>
	);
};

export default ColumnControlsMenu;
