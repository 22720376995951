import CancelSuborderReservationAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/CancelSuborderReservationAlertDialogue';
import Spinner from 'components/Spinner';
import { useStackState } from 'hooks/useStackState';
import React, { createContext, lazy, ReactNode, Suspense, useCallback, useContext, useMemo, useState } from 'react';

const DeleteProductAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/DeleteProductAlertDialogue'));
const DeleteSuborderAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/DeleteSuborderAlertDialogue'));
const DeleteServicesAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/DeleteServicesAlertDialogue'));
const ChangeOrderTypeAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/ChangeOrderTypeAlertDialogue'));
const SuborderReservationAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/SuborderReservationAlertDialogue'));
const SaveMainOrderAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/SaveMainOrderAlertDialogue'));
const MainOrderReservationAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/MainOrderReservationAlertDialogue'));
const DeleteMainOrderProductAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/DeleteMainOrderProductAlertDialogue'));
const CancelSpittingAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/CancelSpittingAlertDialogue'));
const SaveSplittingAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/SaveSplittingAlertDialogue'));
const ShowLastPriceColumnAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/ShowLastPriceColumnAlertDialogue'));
const NoLastPriceFoundAlertDialogue = lazy(() => import('components/OrderPageComponents/(AlertDialogues)/NoLastPriceFoundAlertDialogue'));
const MainOrderCancelReservationAlertDialogue = lazy(
	() => import('components/OrderPageComponents/(AlertDialogues)/MainOrderCancelReservationAlertDialogue'),
);
const BusinessOfferIsNotAvailableAlertDialogue = lazy(
	() => import('components/OrderPageComponents/(AlertDialogues)/BusinessOfferIsNotAvailableAlertDialogue'),
);
const SuborderRedirectForbiddenAlertDialogue = lazy(
	() => import('components/OrderPageComponents/(AlertDialogues)/SuborderRedirectForbiddenAlertDialogue'),
);
const DeleteMainOrderServicesAlertDialogue = lazy(
	() => import('components/OrderPageComponents/(AlertDialogues)/DeleteMainOrderServicesAlertDialogue'),
);
const InvalidPaintToningProductsAlertDialogue = lazy(
	() => import('components/OrderPageComponents/(AlertDialogues)/InvalidProductsTransferAlertDialogue'),
);

export type StackedModal = ReactNode;
export type Config<TKey> = { key: TKey };
export type StackedModalObject<TKeys> = Config<TKeys> & {
	modal: StackedModal;
};

export type AlertDialoguePayload = {
	onSubmit: () => void;
	onCancel?: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data?: any;
};
export type OrderAlertDialoguesContext = {
	open: (key: ModalKey, payload?: AlertDialoguePayload) => void;
	close: () => void;
};
export type ModalProviderProps = {
	children: ReactNode;
};

export const Context = createContext<OrderAlertDialoguesContext>(null);

export const useOrderAlertDialogue = () => {
	const ctx = useContext(Context);

	if (!ctx) {
		throw new Error('The hook useOrderAlertDialogue must be used inside OrderAlertDialoguesContext provider');
	}

	return ctx;
};

const ContextProvider = Context.Provider;

const MODAL_KEYS = {
	deleteProducts: 'deleteProducts',
	deleteInMemorySuborder: 'deleteInMemorySuborder',
	deleteServices: 'deleteServices',
	deleteMainOrderServices: 'deleteMainOrderServices',
	deleteMainOrderProducts: 'deleteMainOrderProducts',
	changeOrderType: 'changeOrderType',
	invalidPaintToningProducts: 'invalidPaintToningProducts',
	invalidRegularProducts: 'invalidRegularProducts',
	suborderReservation: 'suborderReservation',
	suborderCancelReservation: 'suborderCancelReservation',
	mainOrderReservation: 'mainOrderReservation',
	mainOrderCancelReservation: 'mainOrderCancelReservation',
	saveSplitting: 'saveSplitting',
	saveMainOrder: 'saveMainOrder',
	cancelSplitting: 'cancelSplitting',
	closeSuborderTab: 'closeSuborderTab',
	showLastPriceColumn: 'showLastPriceColumn',
	noLastPriceFound: 'noLastPriceFound',
	suborderPageRedirectForbidden: 'suborderPageRedirectForbidden',
	businessOfferIsNotAvailable: 'businessOfferIsNotAvailable',
} as const;

type ModalKey = keyof typeof MODAL_KEYS;

const OrderAlertDialoguesProvider: React.FC<ModalProviderProps> = ({ children }) => {
	const [modalKey, setModalKey] = useState<ModalKey | null>(null);
	const [payloadList, { clear, push }] = useStackState<AlertDialoguePayload>([]);

	const open = useCallback((key: ModalKey, payloadData) => {
		if (payloadData) {
			push(payloadData);
		}
		setModalKey(key);
	}, []);

	const close = useCallback((cancelCb?: AlertDialoguePayload['onCancel']) => {
		clear();
		cancelCb?.();
		setModalKey(null);
	}, []);

	const ctx = useMemo<OrderAlertDialoguesContext>(
		() => ({
			open,
			close,
		}),
		[open, close],
	);

	const { onCancel, onSubmit, data } = payloadList[0] ?? {};

	return (
		<ContextProvider value={ctx}>
			{children}

			<Suspense fallback={<Spinner />}>
				{modalKey === MODAL_KEYS.deleteProducts && (
					<DeleteProductAlertDialogue
						onSubmit={onSubmit}
						onClose={close.bind(null, onCancel)}
						data={data}
						onCancel={close.bind(null, onCancel)}
					/>
				)}
				{modalKey === MODAL_KEYS.deleteServices && (
					<DeleteServicesAlertDialogue
						onSubmit={onSubmit}
						onClose={close.bind(null, onCancel)}
						data={data}
						onCancel={close.bind(null, onCancel)}
					/>
				)}
				{modalKey === MODAL_KEYS.closeSuborderTab && (
					<DeleteSuborderAlertDialogue
						onSubmit={onSubmit}
						onClose={close.bind(null, onCancel)}
						data={data}
						onCancel={close.bind(null, onCancel)}
					/>
				)}
				{modalKey === MODAL_KEYS.showLastPriceColumn && (
					<ShowLastPriceColumnAlertDialogue
						onSubmit={onSubmit}
						onClose={close.bind(null, onCancel)}
						onCancel={close.bind(null, onCancel)}
					/>
				)}
				{modalKey === MODAL_KEYS.businessOfferIsNotAvailable && (
					<BusinessOfferIsNotAvailableAlertDialogue onClose={close.bind(null, onCancel)} />
				)}
				{modalKey === MODAL_KEYS.suborderPageRedirectForbidden && (
					<SuborderRedirectForbiddenAlertDialogue onClose={close.bind(null, onCancel)} />
				)}

				{modalKey === MODAL_KEYS.saveMainOrder && (
					<SaveMainOrderAlertDialogue onSubmit={onSubmit} onClose={close.bind(null, onCancel)} onCancel={close.bind(null, onCancel)} />
				)}
				{modalKey === MODAL_KEYS.saveSplitting && (
					<SaveSplittingAlertDialogue onSubmit={onSubmit} onClose={close.bind(null, onCancel)} onCancel={close.bind(null, onCancel)} />
				)}
				{modalKey === MODAL_KEYS.suborderReservation && (
					<SuborderReservationAlertDialogue
						onSubmit={onSubmit}
						onClose={close.bind(null, onCancel)}
						data={data}
						onCancel={close.bind(null, onCancel)}
					/>
				)}
				{modalKey === MODAL_KEYS.suborderCancelReservation && (
					<CancelSuborderReservationAlertDialogue onClose={close.bind(null, onCancel)} data={data} />
				)}

				{modalKey === MODAL_KEYS.mainOrderReservation && (
					<MainOrderReservationAlertDialogue
						onSubmit={onSubmit}
						onClose={close.bind(null, onCancel)}
						onCancel={close.bind(null, onCancel)}
					/>
				)}
				{modalKey === MODAL_KEYS.mainOrderReservation && (
					<MainOrderReservationAlertDialogue
						onSubmit={onSubmit}
						onClose={close.bind(null, onCancel)}
						onCancel={close.bind(null, onCancel)}
					/>
				)}
				{modalKey === MODAL_KEYS.mainOrderCancelReservation && (
					<MainOrderCancelReservationAlertDialogue
						onSubmit={onSubmit}
						onClose={close.bind(null, onCancel)}
						onCancel={close.bind(null, onCancel)}
					/>
				)}
				{modalKey === MODAL_KEYS.noLastPriceFound && <NoLastPriceFoundAlertDialogue onSubmit={onSubmit} data={data} />}
				{modalKey === MODAL_KEYS.changeOrderType && <ChangeOrderTypeAlertDialogue />}
				{modalKey === MODAL_KEYS.invalidPaintToningProducts && <InvalidPaintToningProductsAlertDialogue whoFailed="paintToning" />}
				{modalKey === MODAL_KEYS.invalidRegularProducts && <InvalidPaintToningProductsAlertDialogue whoFailed="regular" />}
				{modalKey === MODAL_KEYS.deleteMainOrderProducts && <DeleteMainOrderProductAlertDialogue items={[]} />}
				{modalKey === MODAL_KEYS.deleteMainOrderServices && <DeleteMainOrderServicesAlertDialogue items={[]} />}
				{modalKey === MODAL_KEYS.cancelSplitting && <CancelSpittingAlertDialogue />}
			</Suspense>
		</ContextProvider>
	);
};

export default OrderAlertDialoguesProvider;
