import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

interface ToastProps {
	message: string;
}

export const ErrorToast: React.FC<ToastProps> = ({ message }) => {
	useEffect(() => {
		toast.error(message);
	}, []);

	return null;
};
export const SuccessToast: React.FC<ToastProps> = ({ message }) => {
	useEffect(() => {
		toast.success(message);
	}, []);

	return null;
};
export const InfoToast: React.FC<ToastProps> = ({ message }) => {
	useEffect(() => {
		toast.info(message);
	}, []);

	return null;
};
export const WarningToast: React.FC<ToastProps> = ({ message }) => {
	useEffect(() => {
		toast.warning(message);
	}, []);

	return null;
};
