import { ClientEditProhibitedFieldNames } from 'models/IClient';
import Calculator from 'static/images/Calculator.svg';
import Clients from 'static/images/Clients.svg';
import CreateClient from 'static/images/CreateClient.svg';
import CreateOrder from 'static/images/CreateOrder.svg';
import CreateEmployee from 'static/images/dodati_pracivnika.svg';
import FacebookIcon from 'static/images/facebook-02.svg';
import InstagramIcon from 'static/images/instagram.svg';
import Objects from 'static/images/Objects.svg';
import Orders from 'static/images/Orders.svg';
import Pallete from 'static/images/Pallete.svg';
import Price from 'static/images/Price.svg';
import Shipping from 'static/images/Shipping.svg';
import Employees from 'static/images/spivrobitnik.svg';
import TelegramIcon from 'static/images/telegram.svg';
import ViberIcon from 'static/images/viber.svg';
import { prepareUrl } from 'utils/shared';

export const ROUTES_URLS = {
	HOME: '/',
	SIGN_IN: 'sign-in',
	FORGOT_PASSWORD: 'forgot-password',
	// SIGN_UP: '/sign-up',
	ORDER_SELECT_CLIENT: 'order/select-client',
	ORDER_SELECT_PRODUCT: 'order/:id/select-product',
	ORDER_SERVICES: 'services',
	CLIENT_PAGE: 'client/:id',
	ORDER_NEW: 'order/new',
	NEW_ORDER: 'order/create/new-order',

	EMPLOYEE_EDIT: 'employees/:id/edit',
	AUTH_VIA_INVITATION_LINK: 'sign-in/link',
	COMMERCIAL_PROPOSAL: 'commercial-proposal',
	CREATE_ORDER_SELECT_PRODUCT: 'order/select-product',

	CLIENTS: 'clients',
	CLIENT_PREVIEW: 'client/:id',
	CLIENT_CREATE: 'client/create',
	CLIENT_ORDERS: 'clients/:id/orders',

	DOCUMENTS: 'documents/:id',
	PRICES: 'prices',
	PRICES_CREATE: 'prices/new',
	PRICES_SAVE: 'prices/new/save',
	PAINT_TONING: 'paint-toning',

	ORDERS: 'orders',

	ORDER_ROOT_SEGMENT: 'order',

	ORDER_PREVIEW_ROOT: 'preview',
	ORDER_PREVIEW_SEGMENT: ':id',
	ORDER_PREVIEW_PRODUCTS_SEGMENT: 'products',
	ORDER_PREVIEW_SERVICES_SEGMENT: 'services',
	ORDER_PREVIEW_ADD_PRODUCTS_SEGMENT: 'add-products',
	ORDER_PREVIEW_SPLIT_ORDER_SEGMENT: 'split',

	ORDER_NEW_ROOT: 'new',
	ORDER_NEW_PRODUCTS_SEGMENT: 'products',
	ORDER_NEW_SERVICES_SEGMENT: 'services',
	ORDER_NEW_SET_CLIENT_SEGMENT: 'set-client',
	ORDER_NEW_ADD_PRODUCTS_SEGMENT: 'add-products',
	ORDER_NEW_SPLIT_ORDER_SEGMENT: 'split',

	ORDER_SPLIT: 'order/:id/split',
	EMPLOYEES: 'employees',
	EMPLOYEE: 'employees/:id',
	EMPLOYEE_CREATE: 'employees/new',
};

export const orderUrlUtil = {
	getPreviewRoot() {
		return ROUTES_URLS.ORDER_PREVIEW_ROOT;
	},
	getNewOrderRoot() {
		return ROUTES_URLS.ORDER_NEW_ROOT;
	},
	getFullPreviewUrl() {
		return prepareUrl(this.getPreviewRoot() + '/' + ROUTES_URLS.ORDER_ROOT_SEGMENT + '/' + ROUTES_URLS.ORDER_PREVIEW_SEGMENT);
	},
	getFullPreviewProductsUrl() {
		return this.getFullPreviewUrl() + '/' + ROUTES_URLS.ORDER_PREVIEW_PRODUCTS_SEGMENT;
	},
	getFullPreviewServicesUrl() {
		return this.getFullPreviewUrl() + '/' + ROUTES_URLS.ORDER_PREVIEW_SERVICES_SEGMENT;
	},
	getFullPreviewSetProductsUrl() {
		return this.getFullPreviewUrl() + '/' + ROUTES_URLS.ORDER_PREVIEW_ADD_PRODUCTS_SEGMENT;
	},
	getFullPreviewSplitOrderUrl() {
		return this.getFullPreviewUrl() + '/' + ROUTES_URLS.ORDER_PREVIEW_SPLIT_ORDER_SEGMENT;
	},
	getFullNewOrderUrl() {
		return this.getNewOrderRoot() + '/' + ROUTES_URLS.ORDER_ROOT_SEGMENT;
	},
	getFullNewOrderProductsUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_PRODUCTS_SEGMENT;
	},
	getFullNewOrderServicesUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_SERVICES_SEGMENT;
	},
	getFullNewOrderSetProductsUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_ADD_PRODUCTS_SEGMENT;
	},
	getFullNewOrderSetClientUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_SET_CLIENT_SEGMENT;
	},
	getFullNewOrderSplitOrderUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_SPLIT_ORDER_SEGMENT;
	},
};

export const breakPoints = {
	MOBILE: 768,
	TABLET: 1024,
	LAPTOP_SM: 1200,
	LAPTOP_MD: 1366,
	DESKTOP: 1920,
};

export const menuItems = [
	{ name: 'Створити заявку', icon: CreateOrder, link: orderUrlUtil.getFullNewOrderSetClientUrl(), id: 1 },
	{ name: 'Заявки', icon: Orders, link: prepareUrl(ROUTES_URLS.ORDERS), id: 2 },
	{ name: 'Прайси', icon: Price, link: prepareUrl(ROUTES_URLS.PRICES), id: 3 },
	{ name: 'Створити клієнта', icon: CreateClient, link: prepareUrl(ROUTES_URLS.CLIENT_CREATE), id: 4 },
	{ name: 'Клієнти', icon: Clients, link: prepareUrl(ROUTES_URLS.CLIENTS), id: 5 },
	{ name: 'Автоперевезення', icon: Shipping, link: '#', id: 6 },
	{ name: 'Калькулятор', icon: Calculator, link: '#', id: 7 },
	{ name: 'Тонування', icon: Pallete, link: prepareUrl(ROUTES_URLS.PAINT_TONING), id: 8 },
	{ name: 'Об’єкти', icon: Objects, link: '#', id: 9 },
	{ name: 'Співробітники', icon: Employees, link: prepareUrl(ROUTES_URLS.EMPLOYEES), id: 10 },
	{ name: 'Створити працівника', icon: CreateEmployee, link: prepareUrl(ROUTES_URLS.EMPLOYEE_CREATE), id: 11 },
];

export const statusesList = ['Усі', 'Відгружено', 'В резерві', 'Прорахунок', 'Оплачено', 'На узгодженні', 'Кредит'];

export const positionsList = ['Усі', 'Прораб', 'Будівельник', 'Будівельна Організація'];

export const MAX_CHARS = 425;

export const ACCEPTED_FILE_TYPES = {
	'image/svg+xml': [],
	'image/png': [],
	'image/jpg': [],
	'image/jpeg': [],
	'image/gif': [],
};
export const MAX_DIMENSION = {
	width: 800,
	height: 400,
};

export const ORDER_RESERVATION_STATUS = '2';
export const ORDER_AGREEMENT_STATUS = '1';
export const ORDER_NEWLY_CREATED_STATUS = ORDER_AGREEMENT_STATUS;

export const CLIENT_STATE_NAMES_LIST = [
	{ name: 'Зелений контрагент', color: 'var(--success-500)' },
	{ name: 'Жовтий контрагент', color: 'var(--warning-500)' },
	{ name: 'Червоний контрагент', color: 'var(--error-500)' },
];

export const CLIENTS_FILTER_QUERY_KEYS = {
	client: 'client[]',
	segment: 'segment[]',
	// limit: 'limit',
	// delay: 'delay',
	// lastSell: 'lastSell',
	manager: 'manager[]',
} as const;

export const ORDERS_FILTER_QUERY_KEYS = {
	sum: 'sum',
	payment: 'paymentStatus[]',
	shipment: 'status[]',
	client: 'client[]',
	// manager: 'manager',
	number: 'number',
	stock: 'stock[]',
	date: 'date',
	contract: 'contract[]',
	responsible: 'responsible[]',
} as const;

export const HUMAN_ORDERS_FILTER_QUERY_KEYS: Record<keyof typeof ORDERS_FILTER_QUERY_KEYS, (type?: 'long' | 'short') => string> = {
	sum: () => 'Сума',
	payment: () => 'Оплата',
	shipment: () => 'Відвантаження',
	client: () => 'Клієнт',
	// manager: () => 'Менеджер',
	number: () => 'Номер',
	stock: () => 'Склад',
	date: () => 'Дата',
	contract: () => 'Контракт',
	responsible: () => 'Відп. особа',
};

export const HUMAN_CLIENTS_FILTER_QUERY_KEYS: Record<keyof typeof CLIENTS_FILTER_QUERY_KEYS, (type?: 'long' | 'short') => string> = {
	client: () => 'Контрагент',
	segment: () => 'Сегмент',
	manager: () => 'Менеджер',
	// delay: () => 'Відстрочка',
	// lastSell: () => 'Ост. продаж',
	// limit: () => 'Ліміт',
};

export const ORDER_IS_SAVED_STATUS = '1';
export const ORDER_IS_RESERVED_STATUS = '2';

export const PHONE_TYPES_OPTION_LIST = [
	{ label: 'Робочий', value: 'phone_number_work' },
	{ label: 'Особистий', value: 'phone_number_personal_main' },
	{ label: 'Додатковий', value: 'phone_number_personal_additional' },
];

export const ORGANIZATIONS = [
	{
		label: 'Юніт Бі',
		value: '999c7041-f145-11e2-b6d1-c189088e7082',
	},
];

export const MESSENGERS_LIST = [
	{ icon: FacebookIcon, messenger: 'facebook', nickNameExample: '@username' },
	{ icon: InstagramIcon, messenger: 'instagram', nickNameExample: '@username' },
	{ icon: TelegramIcon, messenger: 'telegram', nickNameExample: '@username' },
	{ icon: ViberIcon, messenger: 'viber', nickNameExample: '@username' },
];

export const EMPLOYEE_PROFILE_DYNAMIC_FIELDS_LIMIT = {
	phone: 3,
	messengers: MESSENGERS_LIST.length,
};

export const EMPLOYEE_DEPARTMENTS_OPTIONS_LIST = [
	{ label: 'Обʼєктна дистрибуція', value: '0' },
	{ label: 'Традиційна дистрибуція', value: '2' },
];

export const EMPLOYEE_POSITIONS_OPTIONS_LIST = [
	{ label: 'Керівник', value: '0' },
	{ label: 'Фахівець зі збуту', value: '1' },
	{ label: 'Асистент фахівця зі збуту', value: '2' },
];

export const EMPLOYEE_BRANCHES_OPTIONS_LIST = [
	{ label: 'Софія', value: '0' },
	{ label: 'Гатне', value: '1' },
	{ label: 'Ірпінь', value: '2' },
	{ label: 'Бровари', value: '3' },
];

export const CONTRACT_TYPES_OPTION_LIST = [
	{ label: 'Загальний Ф2', value: 'Загальний Ф2' },
	{ label: 'Загальний БД', value: 'Загальний БД' },
	{ label: 'Загальний ЮБ', value: 'Загальний ЮБ' },
];

export const PRICES_FILTERS = [
	{
		label: 'Клієнт',
		value: 'client',
	},
	{
		label: 'Назва',
		value: 'name',
	},
	{
		label: 'Дата',
		value: 'date',
	},
];

export const PRICES_TYPE_PRICE = [
	{
		label: 'Оптова',
		value: 'wholesale',
	},
	{
		label: 'Роздрібна',
		value: 'retail',
	},
	{
		label: 'Мінімальна',
		value: 'min',
	},
	{
		label: 'Мінімальна +2%',
		value: 'min+2%',
	},
];

export const GROUPS = [
	{
		label: 'Дорівнює',
		value: 'equals',
	},
	{
		label: 'Не дорівнює',
		value: 'noEquals',
	},
	{
		label: 'В списку',
		value: 'inTheList',
	},
	{
		label: 'Не у списку',
		value: 'notOnTheList',
	},
	{
		label: 'В групі зі списку',
		value: 'inGroupFromList',
	},
	{
		label: 'Не в групі зі списку',
		value: 'notInTheGroupFromList',
	},
	{
		label: 'В групі',
		value: 'inGroup',
	},
	{
		label: 'Не в групі',
		value: 'notInTheGroup',
	},
];

export const CLIENT_PHONE_ASSOCIATED_OPTION_LIST = [
	{ label: 'Мобільний', value: 'mobile' },
	{ label: 'Міський', value: 'city' },
];

export const SEGMENT_SUBCATEGORY_OPTIONS_LIST = [
	{ label: 'Декоративні штукатурки', value: 'Декоративні штукатурки' },
	{ label: 'Електромонтажні', value: 'Електромонтажні' },
	{ label: 'Зварювальні', value: 'Зварювальні' },
	{ label: 'Інше', value: 'Інше' },
	{ label: 'Малярні', value: 'Мобільний' },
	{ label: 'Механізована стяжка', value: 'Механізована стяжка' },
	{ label: 'Механізовані штукатірні роботи', value: 'Механізовані штукатірні роботи' },
	{ label: 'Монолітні', value: 'Монолітні' },
	{ label: 'Монтаж гіпсокартону', value: 'Монтаж гіпсокартону' },
	{ label: 'Монтаж камінів/печей/домоводів', value: 'Монтаж камінів/печей/домоводів' },
	{ label: 'Монтаж підвісних стель', value: 'Монтаж підвісних стель' },
	{ label: 'Мулярні (кладка цеглиб блоку...)', value: 'Мулярні (кладка цеглиб блоку...)' },
	{ label: 'Облицювальні', value: 'Облицювальні' },
	{ label: 'Покрівельні (пласка покрівля)', value: 'Покрівельні (пласка покрівля)' },
	{ label: 'Покрівельні (скатна покрівля)', value: 'Покрівельні (скатна покрівля)' },
	{ label: 'Сантехнічні', value: 'Сантехнічні' },
	{ label: 'Укладання підл. покриттів (ламінату, паркету...)', value: 'Укладання підл. покриттів (ламінату, паркету...)' },
	{ label: 'Улаштування підлог', value: 'Улаштування підлог' },
	{ label: 'Улаштування пром. підлог', value: 'Улаштування пром. підлог' },
	{ label: 'Установка дверей/вікон', value: 'Установка дверей/вікон' },
	{ label: 'Фасади вентільовані', value: 'Фасади вентільовані' },
	{ label: 'Фасадні "мокра система"', value: 'Фасадні "мокра система"' },
];
export const SEGMENTS_WITH_SPECIALIZATION = ['Будівельна організація спеціалізована', 'Виконроб'];

export const EDRPOU_CODE_LENGTH = 8;

// ==== VIRTUAL LIST CONFIG
export const FILTER_ITEM_SIZE = 20;
export const GUTTER = 20;
export const MAX_LIST_HEIGHT = 485;

export const CLIENT_DISABLED_FIELDS_NAMES_LIST: ClientEditProhibitedFieldNames[] = ['contract', 'state'];
export const SUBORDERS_LIMIT = 5;

// === CLIENT SEARCH PARAMS CONFIG
export const CLIENT_PAGE_FILTER_KEYS = {
	unpaid: 'unpaid',
	pending: 'pending',
	late: 'late',
};

export const SERVER_ENTITY_ID = {
	ServiceDYIPriceType: 'ef741b50-b356-11e3-af42-8b1ea7c04b02',
	ProductDYIPriceType: 'ef741b50-b356-11e3-af42-8b1ea7c04b02',
	ProductMinimalPriceType: '82dd3937-2316-11ea-80d5-8107dcf40211',
	ProductEnterPriceType: '95d8c530-6303-11e3-8680-c52dd1806f02',
	ProductWholeSalePriceType: '2d8b7e50-6709-11e3-b37c-d8698ef02892',
	ServicesNotAllowedManuallyEditPrice: ['2cf3b1b3-5e6c-11e3-8adb-e3aa534060cd', '1ec61b3f-9146-11e6-80d5-3497f6001881'],
};
