import TableCellInput from 'components/OrderCreatePageComponents/TableCellInput';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { roundNumber } from 'utils/shared';

interface OrderProductAmountInputProps {
	suborderIndex: number;
	entityId: string;
}

const OrderProductAmountInput: React.FC<OrderProductAmountInputProps> = ({ suborderIndex, entityId }) => {
	const { control, setValue, getValues } = useFormContext();
	const amountFieldName = `suborders.${suborderIndex}.data.products.${entityId}.amount` as const;

	return (
		<Controller
			name={amountFieldName}
			control={control}
			render={({ field }) => {
				return (
					<TableCellInput
						value={field.value}
						onChange={(e) => {
							if (suborderIndex > 0) {
								const amount = Number(e.currentTarget.value);
								const allSuborders = getValues('suborders')?.slice(1) ?? [];

								const sumInAllEntityOccurrences = allSuborders.reduce((acc, suborder, index) => {
									if (index + 1 !== suborderIndex) {
										return (acc +=
											Number(suborder.data.products[entityId].amount) * Number(suborder.data.products[entityId].price));
									}

									acc += Number(amount) * Number(suborder.data.products[entityId].price);
									return acc;
								}, 0);

								const amountOfAllEntityOccurrences = allSuborders.reduce((acc, suborder, index) => {
									if (index + 1 !== suborderIndex) {
										return (acc += Number(suborder.data.products[entityId].amount));
									}

									acc += Number(amount);
									return acc;
								}, 0);

								const price = Number(getValues(`suborders.${suborderIndex}.data.products.${entityId}.price`));
								setValue(`suborders.${suborderIndex}.data.products.${entityId}.sum`, String(roundNumber(price * Number(amount), 2)));
								setValue(`suborders.${0}.data.products.${entityId}.sum`, String(roundNumber(sumInAllEntityOccurrences, 2)));
								setValue(`suborders.${0}.data.products.${entityId}.amount`, String(amountOfAllEntityOccurrences));
								field.onChange(e);
							} else {
								const amount = Number(e.currentTarget.value);
								const price = Number(getValues(`suborders.${suborderIndex}.data.products.${entityId}.price`));
								setValue(`suborders.${suborderIndex}.data.products.${entityId}.sum`, String(roundNumber(price * Number(amount), 2)));
								field.onChange(e);
							}
						}}
						onBlur={() => {
							if (!field.value) {
								field.onChange('1.00');
							}
						}}
					/>
				);
			}}
		/>
	);
};

export default OrderProductAmountInput;
