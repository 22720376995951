import 'static/styles/style.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import ProviderStore from 'store/Provider';

import App from './App';

const container = document.getElementById('root');
if (container) {
	const root = createRoot(container);
	root.render(
		<ProviderStore>
			<App />
		</ProviderStore>,
	);
}
