import clsx from 'clsx';
import { useClickOutside } from 'hooks/useClickOutside';
import React from 'react';

import styles from './styles.module.css';
import type { AlertDialogueProps } from './types';

const AlertDialogue: React.FC<AlertDialogueProps> = ({ children, className, backdropClassName, onOutsideClick }) => {
	const ref = useClickOutside<HTMLDivElement>(onOutsideClick);

	return (
		<div className={clsx(styles.backdrop, backdropClassName)}>
			<div ref={ref} className={clsx(styles.dialogue, className)}>
				{children}
			</div>
		</div>
	);
};

export default AlertDialogue;
