import type { IColorEntity } from '../ColorfulBadge/types';
const colorsPaletteEntities: Record<string, IColorEntity> = {
	1: { fontColor: '#175CD3', dotColor: '#2E90FA', bgColor: '#EFF8FF' },
	2: { fontColor: '#344054', dotColor: '#667085', bgColor: '#F2F4F7' },
	3: { fontColor: '#B54708', dotColor: '#F79009', bgColor: '#FFFAEB' },
	4: { fontColor: '#B42318', dotColor: '#F04438', bgColor: '#FEF3F2' },
	5: { fontColor: '#027A48', dotColor: '#12B76A', bgColor: '#ECFDF3' },
	6: { fontColor: '#C11574', dotColor: '#EE46BC', bgColor: '#FDF2FA' },
};

export const getColorByIndex = (key: number | string): IColorEntity => {
	if (typeof key === 'number') {
		const numKeys = Object.keys(colorsPaletteEntities).length;

		const normalizedIndex = ((key - 1) % numKeys) + 1;
		return colorsPaletteEntities[normalizedIndex];
	} else {
		switch (key) {
			case 'calculation':
				return colorsPaletteEntities[1];
			case 'reserve':
				return colorsPaletteEntities[2];
			case 'partially_shipped':
				return colorsPaletteEntities[3];
			case 'shipped':
				return colorsPaletteEntities[4];
			case 'closed':
				return colorsPaletteEntities[5];
			case 'no_payment':
				return colorsPaletteEntities[6];
			case 'partially_payment':
				return colorsPaletteEntities[1];
			case 'paid':
				return colorsPaletteEntities[2];
			case 'overpayment':
				return colorsPaletteEntities[3];
		}
	}
};
