import { useTypedOrderControllerFromContext } from 'pages/OrderRework/OrderController';
import React from 'react';
import { Controller } from 'react-hook-form';

import styles from './styles.module.css';

interface ServiceDependantInputFieldProps {
	suborderIndex: number;
	disabled?: boolean;
	entityId: string;
}

const ServiceDependantInputField: React.FC<ServiceDependantInputFieldProps> = ({ entityId, suborderIndex, disabled }) => {
	const { control } = useTypedOrderControllerFromContext();

	return (
		<Controller
			name={`suborders.${suborderIndex}.data.services.${entityId}.price`}
			control={control}
			render={({ field }) => {
				return (
					<input
						disabled={disabled}
						readOnly={disabled}
						className={styles.inputField}
						type="number"
						value={field.value}
						onChange={field.onChange}
						onBlur={() => {
							if (!field.value) {
								field.onChange('0.00');
							}
						}}
					/>
				);
			}}
		/>
	);
};

export default ServiceDependantInputField;
