import { isString } from 'utils/type-guards';

const load = <TValue>(key: string) => {
	try {
		const value = localStorage.getItem(key);
		return JSON.parse(value) as TValue;
	} catch {
		return null;
	}
};
const save = (key: string, value: unknown) => {
	try {
		const serialized = isString(value) ? (value as string) : JSON.stringify(value);
		localStorage.setItem(key, serialized);
	} catch {
		return null;
	}
};
const remove = (key: string) => {
	try {
		localStorage.removeItem(key);
	} catch {
		return null;
	}
};

export const localStorageService = {
	save,
	load,
	remove,
};
