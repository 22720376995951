import { useOrderForUpdate } from 'hooks/orders';
import { useAppDispatch } from 'hooks/redux';
import { orderNotificationService } from 'services/orderNotificationService';
import { useCreateOrderMutation, useUpdateOrderMutation, useUpdateSubOrderMutation } from 'store/reducers/orders/ordersSliceApi';
import { orderPreviewActions } from 'store/reducers/orderViewer';

import { UseApiDI } from '../types';

export const useApi = ({ utils, dialogueService, data }: UseApiDI) => {
	const [mutate, requestState] = useUpdateOrderMutation();
	const [createOrderMutation, { isLoading }] = useCreateOrderMutation();
	const [, { isLoading: isDeleting }] = useUpdateSubOrderMutation();
	const dispatch = useAppDispatch();

	const requestSaveDraftOrderUnsafe = async () => {
		const dto = utils.getDraftOrderDTO();

		const newOrder = useOrderForUpdate(dto);

		// @ts-ignore
		return mutate(newOrder)
			.unwrap()
			.then(() => orderNotificationService.success('Заявку збережено!'))
			.catch(() => orderNotificationService.error('Виникла помилка. Заявку не зюережено!'));
	};
	const requestReserveDraftOrderUnsafe = async () => {
		const dto = utils.getDraftOrderDTO();

		dto.isReserved = true;

		const newOrder = useOrderForUpdate(dto);

		// @ts-ignore
		return mutate(newOrder)
			.unwrap()
			.then(() => orderNotificationService.success('Статус змінено!'))
			.catch(() => orderNotificationService.error('Виникла помилка. Статус не змінено!'));
	};
	const requestReserveSuborderSafe = () => {
		if (data.activeSuborder.index === 0) {
			return;
		}

		dialogueService.open('suborderReservation');
	};
	const requestReserveSuborderUnsafe = async () => {
		const dto = utils.getSubordersWithReservationDTO();

		// @ts-ignore
		return mutate(dto)
			.unwrap()
			.then(() => orderNotificationService.success('Статус змінено!'))
			.catch(() => orderNotificationService.error('Виникла помилка. Статс не змінено!'));
	};
	const requestSaveSplittingSafe = () => {
		dialogueService.open('saveSplitting');
	};
	const requestSaveSplittingUnsafe = async () => {
		const dto = utils.getSubordersDTO();

		await Promise.all(
			dto.map(async (item) => {
				try {
					if (item.tempId !== undefined) {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { tempId, title, type, ...newItem } = item;
						const response = await createOrderMutation(newItem).unwrap();
						await dispatch(orderPreviewActions.replaceRealSubOrder({ order: response, tempId: tempId }));
						await orderNotificationService.success(`Заявку на розбиття ${item.title} та збережено!`);
					} else {
						// @ts-ignore
						return mutate(item).unwrap();
					}
				} catch (error) {
					await orderNotificationService.error('Виникла помилка. Заявку не збережено!');
				}
			}),
		);

		dialogueService.close();
	};

	return {
		isLoading,
		isDeleting,
		requestState,
		mutate,
		handlers: {
			requestReserveSuborderSafe,
			requestReserveSuborderUnsafe,
			requestSaveDraftOrderUnsafe,
			requestSaveSplittingSafe,
			requestSaveSplittingUnsafe,
			requestReserveDraftOrderUnsafe,
		},
	};
};
