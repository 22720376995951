import axios from 'axios';
import localForage from 'localforage';
import { store } from 'store';

import { getRefreshToken } from './actions';

const apiClient = axios.create({
	baseURL: 'https://unitb-appback.alterego.biz.ua/api/v1',
	headers: {
		'Content-Type': 'application/json',
	},
});

const refreshToken = async () => {
	try {
		const refreshResponse = await store.dispatch(getRefreshToken()).unwrap();
		const newToken = refreshResponse.res.access_token;
		const newRefreshToken = refreshResponse.res.refresh_token;
		await localForage.setItem('token', newToken);
		await localForage.setItem('refresh_token', newRefreshToken);
	} catch (error) {
		await localForage.removeItem('token');
		await localForage.removeItem('refresh_token');
		// eslint-disable-next-line no-console
		console.error('Failed to refresh token:', error);
	}
};

apiClient.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;

		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;

			try {
				await refreshToken();

				const newToken = await localForage.getItem('token');
				originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

				return apiClient(originalRequest);
			} catch (e) {
				await localForage.removeItem('token');
				await localForage.removeItem('refresh_token');
				return Promise.reject(e);
			}
		}

		return Promise.reject(error);
	},
);

apiClient.interceptors.request.use(
	async (config) => {
		const token = await localForage.getItem('token');
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export default apiClient;
