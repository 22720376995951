import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';
import { TabProps } from './types';

const Tab: React.FC<TabProps> = ({ className, label, active, count = 0, onClick }) => {
	const hasCount = count !== undefined;

	return (
		<button type="button" onClick={onClick} className={clsx(styles.tab, className, { [styles.active]: active })}>
			<span className={styles.text}>{label}</span>

			{hasCount && <span className={styles.countBadge}>{count}</span>}
		</button>
	);
};

export default Tab;
