import { useLoadableOptions } from 'hooks/useLoadableOptions';
import { useScrollableOptionList } from 'hooks/useScrollableOptionList';
import { MouseEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { IProps } from '../types';
import { getOptionLabel, hydrateActiveIndexState, useStateController } from '../utils';

const emptyArray = [];

export const useController = ({
	loadAsyncOptions,
	skip,
	openDropdown,
	valuesList,
	disabled,
	onClick,
	onClose,
	firstOptionAsDefault = false,
	...restProps
}: IProps) => {
	const { selectedOption, setSelectedOption, onUnselect } = useStateController(restProps);
	const [isOpened, setIsOpened] = useState<boolean>(openDropdown);
	const wasDefaultOptionSet = useRef<boolean>(false);

	const shouldSkip = skip === undefined ? !isOpened : skip;
	const { multiple = false } = restProps;

	const {
		options: optionsList,
		isLoading,
		isError,
		refetch,
		cancel,
	} = useLoadableOptions({ asyncLoader: loadAsyncOptions, skip: skip ?? !shouldSkip });

	const options = (!!loadAsyncOptions ? optionsList : valuesList) || emptyArray;
	const [activeIndex, setActiveIndex] = useState<number>(hydrateActiveIndexState(options, selectedOption));

	const { optionRef, listRef } = useScrollableOptionList({ activeOptionIndex: activeIndex });

	const shouldRenderOptions = !isLoading && !!options && options.length > 0;
	const optionsCount = options.length;

	const onOptionsSelect = restProps.onSelect;
	const displayValueResolver = restProps.renderSelectedOption || getOptionLabel;
	const selectedOptionDisplayValue = Array.isArray(selectedOption)
		? selectedOption.map(displayValueResolver)
		: displayValueResolver(selectedOption);

	const hasSelectedOption = Array.isArray(selectedOptionDisplayValue) ? selectedOptionDisplayValue.length > 0 : !!selectedOptionDisplayValue;

	useEffect(() => {
		if (firstOptionAsDefault && loadAsyncOptions && !wasDefaultOptionSet.current && options.length > 0 && !hasSelectedOption) {
			wasDefaultOptionSet.current = true;
			setSelectedOption(options[0]);
			setActiveIndex(0);

			/*
			 * extra action on options select
			 */
			onOptionsSelect?.(options[0]);
		}
	}, [options, firstOptionAsDefault, multiple, selectedOption]);

	useEffect(() => {
		if (firstOptionAsDefault && !wasDefaultOptionSet.current && !loadAsyncOptions && valuesList?.length > 0 && !hasSelectedOption) {
			const selectOptionAsync = async () => {
				await Promise.resolve();

				wasDefaultOptionSet.current = true;
				const newOption = valuesList[0];

				setSelectedOption(newOption);
				setActiveIndex(0);

				/*
				 * extra action on options select
				 */
				onOptionsSelect?.(newOption);
			};

			selectOptionAsync();
		}
	}, [firstOptionAsDefault, loadAsyncOptions, valuesList, multiple, hasSelectedOption]);

	const closeDropdown = useCallback((event?: MouseEvent<HTMLButtonElement>) => {
		event?.stopPropagation();

		cancel();
		setIsOpened(false);
		onClose?.();
	}, []);

	const toggleOpenDropdown = () => {
		if (disabled) return;

		if (isOpened) {
			cancel();
		}

		setIsOpened(!isOpened);
		onClick?.(!isOpened);
	};

	const handleRefetch = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		refetch?.();
	};

	const handleOptionSelect = (idx: number) => {
		const newOption = options[idx];

		setActiveIndex(idx);
		setSelectedOption(newOption);
		/*
		 * extra action on options select
		 */
		onOptionsSelect?.(newOption);
	};

	useLayoutEffect(() => {
		if (isOpened && listRef.current) {
			const dropdownRect = listRef.current.getBoundingClientRect();
			const isOverflowing = dropdownRect.left < 5;

			if (isOverflowing) {
				listRef.current.style.left = '-1px';
				listRef.current.style.right = 'unset';
			}
		}
	}, [isOpened]);

	useEffect(() => {
		function handleArrowKeys(event: KeyboardEvent) {
			if (isOpened) {
				event.preventDefault();

				if (event.key === 'Escape') {
					closeDropdown();
				}

				if (event.key === 'ArrowUp') {
					setActiveIndex(activeIndex === 0 ? optionsCount - 1 : activeIndex - 1);
				}

				if (event.key === 'ArrowDown') {
					setActiveIndex(activeIndex === optionsCount - 1 ? 0 : activeIndex + 1);
				}

				if (event.key === 'Enter') {
					const newOption = options[activeIndex];

					setActiveIndex(activeIndex);
					setSelectedOption(newOption);

					/*
					 * extra action on options select
					 */
					onOptionsSelect?.(newOption);
					closeDropdown();
				}
			}
		}

		document.addEventListener('keydown', handleArrowKeys);

		return () => {
			document.removeEventListener('keydown', handleArrowKeys);
		};
	}, [isOpened, activeIndex]);

	return {
		closeDropdown,
		toggleOpenDropdown,
		handleOptionSelect,
		handleRefetch,
		isLoading,
		isError,
		options,
		isOpened,
		hasSelectedOption,
		selectedOption,
		shouldRenderOptions,
		selectedOptionDisplayValue,
		listRef,
		optionRef,
		activeIndex,
		onUnselect,
	};
};
