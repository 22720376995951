import { ClientIdSchema, ContractIdSchema, ProductIdSchema } from 'models/common/uuid';
import { z } from 'zod';

export const LastPriceRequestSchema = z.object({
	clientId: ClientIdSchema,
	contractId: ContractIdSchema,
});
export const LastPriceResultSchema = z.object({
	productId: ProductIdSchema,
	lastPrice: z.number(),
});

export type LastPriceRequest = z.infer<typeof LastPriceRequestSchema>;
export type LastPriceResult = z.infer<typeof LastPriceResultSchema>;
