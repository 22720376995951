/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */

import React, { Component } from 'react';

import { EmbeddedSelect } from './inputs';

class CustomToolbarComponent extends Component<any> {
	stopPropagation = (event) => {
		event.stopPropagation();
	};

	onChange = (color) => {
		const { onChange } = this.props;
		onChange('color', color.hex);
	};

	renderModal = () => {
		const { color } = this.props.currentState;
		return <EmbeddedSelect name="sss" />;
	};

	render() {
		const { expanded, onExpandEvent } = this.props;
		return (
			<div className="rich-editor-custom-toolbar-slot" aria-haspopup="true" aria-expanded={expanded}>
				{this.renderModal()}
			</div>
		);
	}
}

export default CustomToolbarComponent;
