import clsx from 'clsx';
import React, { forwardRef } from 'react';

import styles from './styles.module.css';
import type { IProps } from './types';

const Checkbox: React.ForwardRefRenderFunction<HTMLInputElement, IProps> = (
	{ className, labelColor, label, checked, disabled, hideLabel = false, ...restProps },
	ref,
) => {
	return (
		<div className={clsx(styles.checkboxWrapper, className)}>
			{hideLabel && (
				<label htmlFor={restProps?.name} className="visually-hidden">
					{label}
				</label>
			)}
			<input
				{...restProps}
				id={restProps?.name}
				className={clsx(styles.input, { [styles.checked]: checked })}
				checked={checked}
				disabled={disabled}
				ref={ref}
				type="checkbox"
			/>

			<div className={clsx(styles.label, { [styles.noLabel]: !label })} style={{ color: labelColor }}>
				<span></span>
				{!hideLabel && label}
			</div>
		</div>
	);
};

export default forwardRef(Checkbox);
