import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';
import type { AlertDialogueMessageProps } from './types';

const AlertDialogueMessage: React.FC<AlertDialogueMessageProps> = ({ children, className, ...restProps }) => {
	return (
		<span className={clsx(styles.message, className)} {...restProps}>
			{children}
		</span>
	);
};

export default AlertDialogueMessage;
