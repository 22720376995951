import type {
	IProps,
	MultipleExternalControl,
	MultipleInnerControl,
	MultipleSelectProps,
	SingleExternalControl,
	SingleInnerControl,
	SingleSelectProps,
} from '../types';

export function isSingleSelectionWithInnerControl(props: IProps): props is SingleSelectProps & SingleInnerControl {
	return !props.multiple && !('value' in props) && !('setValue' in props);
}

export function isSingleSelectionWithExternalControl(props: IProps): props is SingleSelectProps & SingleExternalControl {
	return !props.multiple && 'value' in props && 'setValue' in props;
}

export function isMultipleSelectionWithInnerControl(props: IProps): props is MultipleSelectProps & MultipleInnerControl {
	return props.multiple && !('value' in props) && !('setValue' in props);
}

export function isMultipleSelectionWithExternalControl(props: IProps): props is MultipleSelectProps & MultipleExternalControl {
	return props.multiple && 'value' in props && 'setValue' in props;
}
