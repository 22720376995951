import clsx from 'clsx';
import BreadCrumbs from 'components/BreadCrumbs';
import type { Breadcrumb } from 'components/BreadCrumbs/types';
import Button from 'components/Button';
import TopBarContainer from 'components/OrderCreatePageComponents/TopBarContainer';
import EmptyProductsGate from 'components/OrderPageComponents/(EmptyStateGates)/EmptyProductsGate';
import FullScreenGate from 'components/OrderPageComponents/(VisibilityGates)/FullScreenGate';
import Spinner from 'components/Spinner';
import { TablistInMemory } from 'components/Tablist';
import Panel from 'components/Tablist/Panel';
import { breakPoints, ROUTES_URLS, SUBORDERS_LIMIT } from 'const';
import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import { useActiveTab } from 'hooks/useActiveTab';
import { useBoolean } from 'hooks/useBoolean';
import { useOrderRouter } from 'hooks/useOrderRouter';
import { useSelectedRows } from 'hooks/useSelectedRows';
import BottomPanel from 'layouts/PageLayout/BottomPanel';
import { Stats } from 'pages/OrderRework/OrderSplitModule/components/Stats';
import React, { Suspense, useEffect, useLayoutEffect } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { ScrollRestoration } from 'react-router-dom';
import ReturnIcon from 'static/images/corner-down-right.svg';
import Plus from 'static/images/plus.svg';
import { ReactComponent as FileCheckICon } from 'static/images/save-document-name.svg';
import XCloseIcon from 'static/images/x-close.svg';
import { bakeOrderBreadcrumbs } from 'utils/orders';
import { prepareUrl } from 'utils/shared';

import ChangeProductPricePanel from '../ChangeProductPricePanel';
import { useOrderNotifications } from '../hooks/useOrderNotifications';
import { OnEntityTransferPayload, useOrderOperationMethods } from '../hooks/useOrderOperationMethods';
import { DetailedTransferCandidate } from '../OrderController';
import { getCanSelectProductRowHandler, getCanSelectServiceRowHandler } from '../OrderController/lib/utils';
import OrderEntitiesControlPanel from '../OrderEntitiesControlPanel';
import OrderInternalInfoModule from '../OrderInternalDataControlModule';
import { Products } from './components/Products';
import { Services } from './components/Services';
import SuborderPanel from './components/SuborderPanel';
import SuborderTab from './components/SuborderTab';
import { useTransferModals } from './hooks/useTransferModals';
import styles from './styles.module.css';
import { useButtonTitle } from './utils';

const RootTab = SuborderTab;
const AppendTab = SuborderTab;

const OrderSplitModule = () => {
	const { onEntityDelete, onEntityTransfer, getNewTabDataPayload, trigger, watch, setValue, formState } = useOrderOperationMethods();
	const dialogue = useOrderAlertDialogue();
	const suborderTab = useActiveTab();
	const entityTab = useActiveTab();
	const fullScreen = useBoolean();
	const priceChange = useBoolean();
	const notify = useOrderNotifications();
	const transfer = useTransferModals();

	const suborders = watch('suborders');
	const productsRecord = suborders[suborderTab.activeTab].data.products;
	const servicesRecord = suborders[suborderTab.activeTab].data.services;

	const services = Object.values(servicesRecord ?? {});
	const products = Object.values(productsRecord ?? {});

	const router = useOrderRouter();
	const selectionModel = useSelectedRows();

	const isOnProductEntitiesTab = entityTab.activeTab === 0;
	const entityName = isOnProductEntitiesTab ? 'products' : 'services';
	const selectedEntities = Object.keys(selectionModel.rowSelectionModel ?? {});
	const hasSelectedEntities = selectedEntities.length > 0;
	const tabsCount = suborders.length;
	const resolvedOrdersCount = Math.min(tabsCount, SUBORDERS_LIMIT);
	const isTabAppendAllowed = tabsCount <= SUBORDERS_LIMIT;
	const suborderTabPanelList = suborders;
	const isChangePriceAvailable = isOnProductEntitiesTab && hasSelectedEntities && !priceChange.isOn;

	const buttonTitles = useButtonTitle({
		index: suborderTab.activeTab,
		ordersCount: resolvedOrdersCount,
	});

	// tabs
	const handleNewTabAppend = () => {
		if (suborderTab.activeTab === 0) {
			setValue('suborders', [...suborders, getNewTabDataPayload({ entityName, from: suborderTab.activeTab, data: selectedEntities })]);

			if (selectedEntities.length) {
				notify.successTransfer(entityName);
			}
		} else {
			setValue('suborders', [...suborders, getNewTabDataPayload({ entityName, from: suborderTab.activeTab, data: [] })]);
		}
	};
	const onTabClose = (index: number) => () => {
		setValue(
			'suborders',
			suborders.filter((_, idx) => idx !== index),
		);
		// tabs.remove(index);
		if (index === suborderTab.activeTab) {
			suborderTab.onSwitchTab(Math.max(index - 1, 0));
		}
		notify.successSuborderTabClose(index);
	};
	const handleTabCloseSafe = (index: number) => () => {
		dialogue.open('closeSuborderTab', { onSubmit: onTabClose(index), data: index });
	};

	const handleSuborderTabSwitch = (index: number) => () => {
		suborderTab.onSwitchTab(index);
		selectionModel.setRowSelectionModel({});
	};

	// transfer handlers
	const handleEntityTransfer = (payload: OnEntityTransferPayload) => {
		const hasTargetSuborder = Boolean(suborders[payload.to]);

		if (!hasTargetSuborder) {
			setValue('suborders', [...suborders, getNewTabDataPayload({ entityName, from: suborderTab.activeTab, data: [] })]);
			onEntityTransfer(payload);
		} else {
			onEntityTransfer(payload);
		}
		notify.successTransfer(entityName);
		selectionModel.setRowSelectionModel({});
	};
	const handleEntityTransferSafe = (payload: OnEntityTransferPayload) => () => {
		if (payload.from === 0) {
			handleEntityTransfer(payload);
		} else {
			const allEntities = suborders[payload.from].data[payload.entityName];
			const detailedTransferCandidates = Object.values(allEntities).filter((entity) => payload.candidates.includes(entity.id));

			transfer.open(payload.to, {
				onSubmit: (editedTransferCandidates) => {
					const hasTargetSuborder = Boolean(suborders[payload.to]);

					if (!hasTargetSuborder) {
						setValue('suborders', [...suborders, getNewTabDataPayload({ entityName, from: suborderTab.activeTab, data: [] })]);
						onEntityTransfer({ ...payload, candidates: editedTransferCandidates as DetailedTransferCandidate[] });
					} else {
						onEntityTransfer({ ...payload, candidates: editedTransferCandidates as DetailedTransferCandidate[] });
					}

					notify.successTransfer(entityName);
					selectionModel.setRowSelectionModel({});
				},
				data: {
					candidates: detailedTransferCandidates,
					stock: suborders?.[suborderTab.activeTab]?.data.stock,
				},
			});
		}
	};

	// delete entities handlers
	const handleEntityDelete = (suborderIndex: number) => () => {
		onEntityDelete({ suborderIndex, entityName, candidates: selectedEntities });
		selectionModel.setRowSelectionModel({});
		notify.successEntityDelete(entityName);
		trigger('suborders');
	};
	const handleEntityDeleteSafe = (index: number) => () => {
		const key = entityName === 'products' ? 'deleteProducts' : 'deleteServices';
		dialogue.open(key, { onSubmit: handleEntityDelete(index), data: selectedEntities.length });
	};

	const onCancelSplitting = () => {
		router.toOrder();
	};

	const handleSwitchSuborderTab = (index: number) => {
		suborderTab.onSwitchTab(index);
		trigger('suborders');
	};

	useEffect(() => {
		entityTab.onSwitchTab(0);
	}, [suborderTab.activeTab]);

	useLayoutEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);

		if (searchParams.has('from')) {
			const suborderIndex = Number(searchParams.get('from'));
			const index = Math.min(suborderTabPanelList.length - 1, suborderIndex);
			suborderTab.onSwitchTab(index);
			window.history.replaceState(null, '', window.location.pathname);
		}
	}, []);

	const transferBottomButtons = [
		{
			id: '-1',
			title: 'Додати товар',
			icon: Plus,
			onClick: () => router.toAddProducts({ index: suborderTab.activeTab }),
			background: 'var(--primary-500)',
		},
		...buttonTitles?.map((transferButton, idx) => ({
			id: idx,
			title: transferButton.label,
			icon: ReturnIcon,
			onClick: handleEntityTransferSafe({
				from: suborderTab.activeTab,
				to: transferButton.index,
				entityName,
				candidates: Object.keys(selectionModel.rowSelectionModel ?? {}),
			}),
			background: '#039855',
			hoverBg: '#027A48',
			className: styles.moveToOrder,
			disabled: !hasSelectedEntities,
		})),
		{
			id: 'paint-toning',
			title: 'Заявка на тонування',
			icon: ReturnIcon,
			// onClick: handlePaintToningButtonClick,
			background: '#039855',
			hoverBg: '#027A48',
			className: styles.moveToOrder,
			disabled: true,
		},
		{
			id: 'cancel-splitting',
			title: 'Скасувати розбиття',
			icon: XCloseIcon,
			onClick: onCancelSplitting,
			background: 'var(--gray-400)',
			hoverBg: 'var(--gray-500)',
			className: styles.moveToOrder,
		},
	];

	const { id: orderId, number: orderNumber, createdAt } = suborders[suborderTab.activeTab]?.data ?? {};

	const crumbs: Breadcrumb[] = [
		{ label: 'Заявки', href: prepareUrl(ROUTES_URLS.ORDERS) },
		bakeOrderBreadcrumbs({ id: orderId, number: orderNumber, createdAt }),
	];

	const appendTab = isTabAppendAllowed && <AppendTab isActive={false} variant="add-order" title="Додати заявку" onClick={handleNewTabAppend} />;
	const transferBottomButtonsElements = transferBottomButtons.map(({ id: buttonId, title, ...restProps }) => {
		return <Button key={buttonId} text={title} variant="rounded" data-order={buttonId} {...restProps} />;
	});

	return (
		<div className="main-wrapper">
			<div data-table-container className={clsx('container', styles.container)}>
				<BreadCrumbs crumbs={crumbs} />
				<TablistInMemory
					onTabChange={handleSwitchSuborderTab}
					activeIndex={suborderTab.activeTab}
					slot={appendTab}
					tab={({ tabIndex }) => {
						const { tabName } = suborderTabPanelList[tabIndex];
						const hasErrorInSuborder = Object.keys(formState.errors?.suborders?.[tabIndex]?.data ?? {}).length > 0;

						if (tabIndex === 0) {
							const isMobile = useMediaQuery({ maxWidth: breakPoints.MOBILE - 1 });

							return (
								<RootTab
									key={tabIndex}
									variant="order"
									title={isMobile ? 'Основна' : tabName}
									onClick={handleSuborderTabSwitch(0)}
									icon={<FileCheckICon className={clsx(styles.whiteIcon, { [styles.primaryIcon]: suborderTab.activeTab !== 0 })} />}
									isActive={suborderTab.activeTab === 0}
									error={hasErrorInSuborder}
								/>
							);
						}

						const isSavedOnServer = suborders[tabIndex]?.data?.isSaved;

						return (
							<SuborderTab
								key={tabIndex}
								title={tabName}
								isActive={suborderTab.activeTab === tabIndex}
								variant="order"
								onClick={handleSuborderTabSwitch(tabIndex)}
								onClose={handleTabCloseSafe(tabIndex)}
								withCloseButton={!isSavedOnServer}
								error={hasErrorInSuborder}
							/>
						);
					}}
				>
					{suborderTabPanelList.map(({ tabName }, index) => (
						<Panel key={tabName} title={tabName} className={styles.panel}>
							<SuborderPanel suborderIndex={index}>
								{({
									visibilityModel,
									onPinningModelChange,
									productColumns,
									pinningModel,
									onVisibilityModelChange,
									visibilityModelSaveConfigKey,
									onOrderSaveSafe,
									onOrderReservationSafe,
								}) => (
									<>
										<FullScreenGate isFullScreen={fullScreen.isOn}>
											<TopBarContainer>
												<OrderInternalInfoModule suborderIndex={index} />
											</TopBarContainer>

											<Stats
												key={suborderTab.activeTab}
												isFullScreen={fullScreen.isOn}
												activeTab={suborderTab.activeTab}
												products={products}
												services={services}
												onSave={onOrderSaveSafe}
												onReserve={onOrderReservationSafe}
											/>
										</FullScreenGate>

										<TablistInMemory
											slot={
												<OrderEntitiesControlPanel
													entityColumns={productColumns}
													isFullScreen={fullScreen.isOn}
													onFullScreenChange={fullScreen.toggle}
													onVisibilityModelChange={onVisibilityModelChange}
													visibilityModel={visibilityModel}
													hasSelectedEntities={hasSelectedEntities}
													isChangePriceAvailable={isChangePriceAvailable}
													onDelete={handleEntityDeleteSafe(index)}
													onChangePriceModeToggle={priceChange.toggle}
													visibilitySaveConfigKey={visibilityModelSaveConfigKey}
													isPriceTypeColumnInsertionDisabled={!isOnProductEntitiesTab}
													suborderIndex={suborderTab.activeTab}
												/>
											}
											className={clsx({ [styles.disableTabNavigation]: priceChange.isOn })}
											onTabChange={entityTab.onSwitchTab}
											activeIndex={entityTab.activeTab}
										>
											<Panel title="Додані товари" itemsCount={products.length} className={styles.panel}>
												<Suspense fallback={<Spinner />}>
													{priceChange.isOn && (
														<ChangeProductPricePanel
															onCancel={priceChange.toggle}
															onClose={priceChange.toggle}
															suborderIndex={suborderTab.activeTab}
															selectionModel={selectionModel.rowSelectionModel}
															className={styles.changeProductPricePanel}
														/>
													)}
												</Suspense>

												<EmptyProductsGate isEmpty={products.length === 0}>
													<Products
														className={clsx(styles.table, { ['table-disabled']: priceChange.isOn })}
														columns={productColumns}
														onPinningModelChange={onPinningModelChange}
														pinningModel={pinningModel}
														visibilityModel={visibilityModel}
														onRowSelectionModelChange={selectionModel.setRowSelectionModel}
														selectionModel={selectionModel.rowSelectionModel}
														products={products}
														getCanSelectRow={getCanSelectProductRowHandler(suborderTab.activeTab, suborders)}
													/>
												</EmptyProductsGate>
											</Panel>
											<Panel title="Додані послуги" itemsCount={services.length} className={styles.panel}>
												<Services
													triggerRerenderOnAddEntity
													suborderIndex={index}
													selectionModel={selectionModel.rowSelectionModel}
													onRowSelectionModelChange={selectionModel.setRowSelectionModel}
													getCanSelectRow={getCanSelectServiceRowHandler(suborderTab.activeTab, suborders)}
												/>
											</Panel>
										</TablistInMemory>
									</>
								)}
							</SuborderPanel>
						</Panel>
					))}
				</TablistInMemory>

				<MediaQuery minWidth={breakPoints.MOBILE}>
					<div className={styles.actionButtonsWrapper}>{transferBottomButtonsElements}</div>
				</MediaQuery>

				{/* bottom controls */}
				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<BottomPanel className={clsx(styles.bottomPanel, { [styles.odd]: suborderTabPanelList.length === 2 })}>
						{transferBottomButtonsElements}
					</BottomPanel>
				</MediaQuery>

				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<div className="safe-area-bottom" />
				</MediaQuery>
			</div>

			<ScrollRestoration getKey={(location) => location.pathname} />
		</div>
	);
};

export default OrderSplitModule;
