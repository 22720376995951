import { SelectOption } from 'components/Select/types';
import {
	CLIENT_PHONE_ASSOCIATED_OPTION_LIST,
	CONTRACT_TYPES_OPTION_LIST,
	GROUPS,
	PRICES_FILTERS,
	PRICES_TYPE_PRICE,
	SEGMENT_SUBCATEGORY_OPTIONS_LIST,
} from 'const';

export const loadPhoneAssociatedMessengersOptions = () => CLIENT_PHONE_ASSOCIATED_OPTION_LIST;

export const loadPricesFilterOptions = (): Promise<SelectOption[]> =>
	new Promise((res) => {
		setTimeout(() => {
			res(PRICES_FILTERS);
		}, 200);
	});

export const loadGroupsFilterOptions = (): Promise<SelectOption[]> =>
	new Promise((res) => {
		setTimeout(() => {
			res(GROUPS);
		}, 200);
	});

export const loadTypeOfPrices = (): Promise<SelectOption[]> =>
	new Promise((res) => {
		setTimeout(() => {
			res(PRICES_TYPE_PRICE);
		}, 200);
	});

export const loadContractTypesOptionList = (): Promise<SelectOption[]> =>
	new Promise((res) => {
		setTimeout(() => {
			res(CONTRACT_TYPES_OPTION_LIST);
		}, 200);
	});
export const loadSpecializationOptionList = (): Promise<SelectOption[]> =>
	new Promise((res) => {
		setTimeout(() => {
			res(SEGMENT_SUBCATEGORY_OPTIONS_LIST);
		}, 200);
	});
