import { useEffect, useRef } from 'react';
import { scrollActiveOptionIntoView } from 'utils/shared';

export type UseScrollableOptionListProps = {
	activeOptionIndex: number;
};

export const useScrollableOptionList = ({ activeOptionIndex }: UseScrollableOptionListProps) => {
	const optionRef = useRef<HTMLLIElement>(null);
	const listRef = useRef<HTMLUListElement>(null);

	useEffect(() => {
		scrollActiveOptionIntoView(listRef.current, optionRef.current);
	}, [activeOptionIndex]);

	return { listRef, optionRef };
};
