import axios from 'axios';
import { paintBase, paintBrands, paintCollections, paintPalette, paintProductCodes } from 'components/PaintToningPageComponents/lib/mockData';

import { API_URL } from './api';

export const loadClientsAsOptions = async (query: string, signal: AbortSignal) => {
	const response = await axios.get(`${API_URL}/clients`, { signal });
	const filtered = !query
		? response.data
		: response.data.filter((item) => {
				if (item['Наименование']) {
					return item['Наименование'].toLowerCase().includes(query.trim().toLowerCase());
				} else {
					return item['ФІО'].toLowerCase().includes(query.trim().toLowerCase());
				}
		  }) ?? [];
	return { data: filtered };
};

export const loadProductsAsOptions = async (query: string, signal: AbortSignal) => {
	const response = await axios.get(`${API_URL}/products`, { signal });
	const filtered = !query
		? response.data
		: response.data.filter((item) => {
				return item.title.toLowerCase().includes(query.trim().toLowerCase());
		  }) ?? [];
	return { data: filtered };
};

export const loadAsyncPaintBrandsOptionList = () => {
	return new Promise((res) => {
		setTimeout(() => {
			res(paintBrands);
		}, 1000);
	});
};
export const loadAsyncPaintCollectionOptionList = () => {
	return new Promise((res) => {
		setTimeout(() => {
			res(paintCollections);
		}, 1000);
	});
};
export const loadAsyncPaintPaletteOptionList = () => {
	return new Promise((res) => {
		setTimeout(() => {
			res(paintPalette);
		}, 1000);
	});
};
export const loadAsyncPaintCodesOptionList = () => {
	return new Promise((res) => {
		setTimeout(() => {
			res([]);
		}, 1000);
	});
};
export const loadAsyncPaintProductCodesOptionList = () => {
	return new Promise((res) => {
		setTimeout(() => {
			res(paintProductCodes);
		}, 1000);
	});
};
export const loadAsyncPaintBaseOptionList = () => {
	return new Promise((res) => {
		setTimeout(() => {
			res(paintBase);
		}, 1000);
	});
};
