import clsx from 'clsx';
import React, { ReactNode } from 'react';

import styles from './styles.module.css';

export interface IEmptyTableProps {
	className?: string;
	text: ReactNode;
}

const EmptyTable: React.FC<IEmptyTableProps> = ({ className, text }) => {
	return <div className={clsx(styles.empty, className)}>{text}</div>;
};

export default EmptyTable;
