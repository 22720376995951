import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { filterProductsByFilters } from 'pages/CreateOrder/mockDataServer';
import { API_URL } from 'services/api';
import { productQueryKeys } from 'services/queryKeys';

import { Product } from '../orders/types';

export const productsSliceApi = createApi({
	reducerPath: 'products',
	baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
	tagTypes: [productQueryKeys.products()],
	endpoints: (builder) => ({
		getProducts: builder.query({
			// !TODO: fix this once BE is ready
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			query: (queryParams: string) => '/products',
			providesTags: (result = []) => [productQueryKeys.products(), ...result.map(({ id }) => productQueryKeys.product(id))],
			transformResponse: (response, _, queryParams) => {
				const searchParams = new URLSearchParams(queryParams);
				const searchParamsEntries = Array.from(searchParams.entries());
				const products = filterProductsByFilters(response as Product[], searchParamsEntries);

				return products;
			},
		}),
		getProductById: builder.query<unknown, string>({
			query: (id) => `/products/${id}`,
			providesTags: (_, __, id) => [productQueryKeys.product(id)],
		}),
	}),
});

export const { useGetProductByIdQuery, useGetProductsQuery } = productsSliceApi;
