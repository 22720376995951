import clsx from 'clsx';
import RadioBtn from 'components/RadioBtn';
import Select from 'components/Select';
import React, { ForwardedRef, forwardRef } from 'react';

import styles from './styles.module.css';
import type { RadioButtonItemProps, RadioButtonSelectProps } from './types';

const noop = () => {};

const DropdownItem: React.FC<RadioButtonItemProps> = ({ displayValue, isActive, onClick }) => {
	return (
		<span onClick={onClick} className={clsx(styles.dropdownItem, { [styles.active]: isActive })}>
			<RadioBtn
				checked={isActive}
				onChange={noop}
				value={displayValue}
				label={displayValue}
				className={clsx(styles.radio, { [styles.active]: isActive })}
			/>
		</span>
	);
};

const RadioButtonSelectField = ({ className, ...restProps }: RadioButtonSelectProps, ref: ForwardedRef<HTMLInputElement>) => {
	return <Select ref={ref} renderDropdownItem={DropdownItem} className={clsx(styles.select, className)} {...restProps} />;
};

export default forwardRef(RadioButtonSelectField);
