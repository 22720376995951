import { breakPoints } from 'const';
import { useMediaQuery } from 'react-responsive';

import { getTableStyles } from './tableCellElementStyles';

export const useTableCellElementStyles = () => {
	const isTablet = useMediaQuery({ query: `(min-width: ${breakPoints.MOBILE}px) and (max-width: ${breakPoints.DESKTOP - 1}px)` });
	const isDesktop = useMediaQuery({ query: `(min-width: ${breakPoints.DESKTOP}px)` });
	const { mobile, tablet, desktop } = getTableStyles();
	const stylesConfig = isDesktop ? desktop : isTablet ? tablet : mobile;

	return stylesConfig;
};
