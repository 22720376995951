import { useOrderColumns } from 'columns/order';
import CancelSuborderReservationAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/CancelSuborderReservationAlertDialogue';
import ReservationErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/ReservationErrorAlertDialogue';
import Spinner from 'components/Spinner';
import type {
	ColumnDefinition,
	OnPinningModelChange,
	OnVisibilityModelChange,
	PinningModel,
	VisibilityModel,
} from 'components/Table/lib/table/types/table';
import { ErrorToast } from 'components/Toast';
import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { Order } from 'models/order';
import { useOrderNotifications } from 'pages/OrderRework/hooks/useOrderNotifications';
import { useOrderOperationMethods } from 'pages/OrderRework/hooks/useOrderOperationMethods';
import { OrderControllerState } from 'pages/OrderRework/OrderController';
import { transformOrderToCreateOrderDTO, transformOrderToUpdateOrderDTO } from 'pages/OrderRework/OrderController/lib/dto';
import React from 'react';
import {
	useCreateOrderMutation,
	useSetOrderFromReserveMutation,
	useSetOrderOnReserveMutation,
	useUpdateOrderMutation,
} from 'store/reducers/orders/ordersSliceApi';

interface ChildrenProps<TData> {
	productColumns: ColumnDefinition<TData>;
	visibilityModel: VisibilityModel;
	pinningModel: PinningModel;
	onPinningModelChange: OnPinningModelChange;
	onVisibilityModelChange: OnVisibilityModelChange;
	visibilityModelSaveConfigKey: string;
	onOrderSaveSafe: VoidCallback;
	onOrderReservationSafe: VoidCallback;
}

interface SuborderPanelProps<TData> {
	children: (props: ChildrenProps<TData>) => JSX.Element;
	suborderIndex: number;
}

const SuborderPanel = <TData,>({ children, suborderIndex }: SuborderPanelProps<TData>) => {
	const { handleSubmit, markAsSavedOnServer } = useOrderOperationMethods();
	const { columns, pinningModel, setPinningModel, setVisibilityModel, visibilityModel, visibilityModelSaveConfigKey } = useOrderColumns({
		suborderIndex: suborderIndex,
		adjustSaveKey: '/split/products/' + suborderIndex,
	});

	const dialogue = useOrderAlertDialogue();
	const notify = useOrderNotifications();

	const [updateOrderAsync, updateOrderRequest] = useUpdateOrderMutation();
	const [createOrderAsync, createOrderRequest] = useCreateOrderMutation();
	const [setOnReserveAsync, onReserveRequest] = useSetOrderOnReserveMutation();
	const [setFromReserveAsync, fromReserveRequest] = useSetOrderFromReserveMutation();

	const saveOrder = async (values: OrderControllerState) => {
		const orders = values.suborders
			.map((order) => {
				const hasProducts = Object.values(order.data.products ?? {}).length > 0;
				const hasServices = Object.values(order.data.services ?? {}).length > 0;

				if (!hasProducts && !hasServices) return null;

				const isNew = !order.data.id;
				const dto = isNew ? transformOrderToCreateOrderDTO(order.data) : transformOrderToUpdateOrderDTO(order.data);

				return { isNew, dto };
			})
			.filter(Boolean);

		const response = await Promise.all(
			orders.map(({ isNew, dto }) => {
				return isNew ? createOrderAsync(dto).unwrap() : updateOrderAsync(dto).unwrap();
			}),
		);

		return response;
	};

	const handleOrderSave = handleSubmit(async (values: OrderControllerState) => {
		dialogue.open('saveSplitting', {
			onSubmit: async () => {
				const response = await saveOrder(values);
				markAsSavedOnServer({ suborderIndexes: response.map((_, index) => index) });

				notify.successOrderSaveSplitting();
			},
		});
	});

	const handleOrderReservation = handleSubmit(async (values: OrderControllerState) => {
		const isReserved = values.suborders?.[suborderIndex].data.isReserved;

		if (isReserved) {
			dialogue.open('suborderCancelReservation', {
				onSubmit: async () => {
					const order = values?.suborders?.[suborderIndex]?.data ?? {};

					const updateOrderDTO = transformOrderToUpdateOrderDTO(order);
					await setFromReserveAsync(updateOrderDTO as unknown as Order).unwrap();

					notify.successOrderCancelReservation();
				},
				onCancel: () => dialogue.close(),
				data: suborderIndex,
			});
		} else {
			dialogue.open('suborderReservation', {
				onSubmit: async () => {
					const response = await saveOrder(values);
					const order = response[suborderIndex];

					notify.successOrderSaveSplitting();

					await setOnReserveAsync(order).unwrap();

					notify.successOrderReservation();
				},
				onCancel: () => dialogue.close(),
				data: suborderIndex,
			});
		}
	});

	const onOrderReservationSafe = useStopPropagationCallback(handleOrderReservation);
	const onOrderSaveSafe = useStopPropagationCallback(handleOrderSave);

	return (
		<>
			{children({
				productColumns: columns,
				onPinningModelChange: setPinningModel,
				onVisibilityModelChange: setVisibilityModel,
				pinningModel,
				visibilityModel,
				visibilityModelSaveConfigKey,
				onOrderSaveSafe,
				onOrderReservationSafe,
			})}

			{createOrderRequest.isLoading && <Spinner />}
			{updateOrderRequest.isLoading && <Spinner />}
			{onReserveRequest.isLoading && <Spinner />}
			{fromReserveRequest.isLoading && <Spinner />}

			{/* @ts-ignore */}
			{createOrderRequest.isError && <ErrorToast message={createOrderRequest.error?.message} />}
			{/* @ts-ignore */}
			{updateOrderRequest.isError && <ErrorToast message={updateOrderRequest.error?.message} />}

			{onReserveRequest.isError && (
				<ReservationErrorAlertDialogue
					// @ts-ignore
					message={onReserveRequest.error.message}
					onClose={onReserveRequest.reset}
				/>
			)}
			{fromReserveRequest.isError && (
				<CancelSuborderReservationAlertDialogue
					// @ts-ignore
					message={fromReserveRequest.error.message}
					onClose={fromReserveRequest.reset}
					data={suborderIndex}
				/>
			)}
		</>
	);
};

export default SuborderPanel;
