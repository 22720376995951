import AlertDialogue from 'components/AlertDialogue';
import AlertDialogueContent from 'components/AlertDialogue/components/AlertDialogueContent';
import AlertDialogueControlButton from 'components/AlertDialogue/components/AlertDialogueControlButton';
import AlertDialogueFooter from 'components/AlertDialogue/components/AlertDialogueFooter';
import AlertDialogueHeader from 'components/AlertDialogue/components/AlertDialogueHeader';
import AlertDialogueMessage from 'components/AlertDialogue/components/AlertDialogueMessage';
import React from 'react';

interface Props {
	onClose: () => void;
	message: string;
}

const CancelReservationErrorAlertDialogue = ({ onClose, message }: Props) => {
	const headerText = 'Помилка зняття з резервації';

	return (
		<AlertDialogue onOutsideClick={onClose}>
			<AlertDialogueHeader onClose={onClose}>{headerText}</AlertDialogueHeader>
			<AlertDialogueContent>
				<AlertDialogueMessage>Не вдалося зняти з резервуву заявку.</AlertDialogueMessage>
				<AlertDialogueMessage>
					<b>{message}</b>
				</AlertDialogueMessage>
			</AlertDialogueContent>

			<AlertDialogueFooter justify="flex-end">
				<AlertDialogueControlButton variant="submit" onClick={onClose}>
					Зрозуміло
				</AlertDialogueControlButton>
			</AlertDialogueFooter>
		</AlertDialogue>
	);
};

export default CancelReservationErrorAlertDialogue;
