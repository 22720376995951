import { useCallback, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebouncedCallback = (callback: Function, delay: number, dependencies?: any[]) => {
	const timeout = useRef<NodeJS.Timeout>();

	const comboDeps = dependencies ? [callback, delay, ...dependencies] : [callback, delay];

	return useCallback((...args) => {
		if (timeout.current != null) {
			clearTimeout(timeout.current);
		}

		timeout.current = setTimeout(() => {
			callback(...args);
		}, delay);
	}, comboDeps);
};
