import Button from 'components/Button';
import MainSearch from 'components/NavBarMain/MainSearch';
import React from 'react';
import Plus from 'static/images/plus.svg';
import Users from 'static/images/users.svg';

import styles from './style.module.css';
import type { IProps } from './types';

const ClientsTopBar: React.FC<IProps> = ({ searchOnClick, onCreateClientButtonClick, onFiltersButtonClick }) => {
	const handleCreateClientButtonClick = () => {
		onCreateClientButtonClick?.();
	};

	const handleOpenFilterButtonClick = () => {
		onFiltersButtonClick?.();
	};

	return (
		<div className={styles.topBarWrapper}>
			<MainSearch asRegularInput tableMode={true} searchOnClick={searchOnClick} activeUrlQuery="search" />

			<div className={styles.buttonsWrapper}>
				<Button
					text="Створити клієнта"
					onClick={handleCreateClientButtonClick}
					icon={Plus}
					variant="rounded"
					background="var(--primary-500)"
				/>
				<Button text="Фільтрувати" onClick={handleOpenFilterButtonClick} icon={Users} variant="rounded" background="var(--pink)" />
			</div>
		</div>
	);
};

export default ClientsTopBar;
