import { z } from 'zod';

export const ServerSideOptionSchema = z.object({
	id: z.string(),
	title: z.string(),
});

export const OptionSchema = z.object({
	value: z.string(),
	label: z.string(),
});

export type Option = Required<z.infer<typeof OptionSchema>>;
export type AnyOption = z.infer<typeof ServerSideOptionSchema>;
