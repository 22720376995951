import { ClientIdSchema } from 'models/common/uuid';
import { OrderPreviewSchema } from 'models/order';
import { z } from 'zod';

export const ClientOrderSchema = OrderPreviewSchema;

export const ClientOrdersRequestPayloadSchema = z.object({
	id: ClientIdSchema,
	queryParams: z.string().nullish(),
});

export type ClientOrder = z.infer<typeof ClientOrderSchema>;
export type ClientOrdersRequestPayload = z.infer<typeof ClientOrdersRequestPayloadSchema>;
