import clsx from 'clsx';
import React, { Children, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './styles.module.css';
import Tab from './Tab';
import type { TablistChild, TablistProps } from './types';
import { getChildPathSegment } from './utils';

export const TablistRouter: React.FC<TablistProps> = ({ children, onTabChange, tab, slot }) => {
	const location = useLocation();
	const [active, setActive] = useState(0);

	const navigate = useNavigate();

	useEffect(() => {
		const childrenArr = Array.isArray(children) ? children : [children];
		const state = childrenArr.reduce((acc, item, idx) => ({ ...acc, [item.props.href]: idx }), {} as Record<string, number>);

		const segment = getChildPathSegment(location.pathname);
		const hasPathname = !!state[segment];

		if (!hasPathname) {
			return setActive(0);
		}

		setActive(state[segment]);
	}, [location.pathname, children]);

	const childrenArr = Array.isArray(children) ? children : [children];

	const handleTabChange = (idx: number, href: string) => {
		setActive(idx);
		onTabChange?.(idx);

		navigate(href);
	};

	return (
		<>
			<div className={styles.slotContainer}>
				<ul className={clsx(styles.tablistControls)}>
					{childrenArr.map((item, idx) => {
						const isActive = idx === active;

						if (tab) {
							const TabComponent = tab;

							return (
								<TabComponent
									key={idx}
									href={item.props.href}
									title={item.props.title}
									isActive={isActive}
									onTabChange={(newIdx) => handleTabChange(newIdx, item.props.href)}
								/>
							);
						}

						return (
							<Tab
								active={isActive}
								key={idx}
								label={item.props.title}
								count={item.props.itemsCount}
								onClick={() => handleTabChange(idx, item.props.href)}
							/>
						);
					})}
				</ul>
				{slot}
			</div>

			{children[active]}
		</>
	);
};

export const TablistInMemory: React.FC<Omit<TablistProps, 'rootPath'> & { activeIndex: number }> = ({
	children,
	activeIndex,
	onTabChange,
	tab,
	slot,
	className,
}) => {
	const handleTabChange = (idx: number) => {
		onTabChange?.(idx);
	};

	const childrenArr = Children.toArray(children) as TablistChild[];
	return (
		<>
			<div className={clsx(styles.slotContainer, className)}>
				<ul className={clsx(styles.tablistControls)}>
					{childrenArr.map((item, idx) => {
						const isActive = idx === activeIndex;

						if (tab) {
							const TabComponent = tab;

							return (
								<TabComponent
									key={idx}
									href={item.props.href}
									title={item.props.title}
									isActive={isActive}
									tabIndex={idx}
									onTabChange={handleTabChange}
								/>
							);
						}

						return (
							<Tab
								active={isActive}
								key={idx}
								label={item.props.title}
								count={item.props.itemsCount}
								onClick={() => handleTabChange(idx)}
							/>
						);
					})}
				</ul>
				{slot}
			</div>

			{children[activeIndex]}
		</>
	);
};
