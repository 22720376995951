import type { ProductCategory } from 'models/product/category';

export const buildFiltersStackLikeStructure = (element: ProductCategory): ProductCategory[] => {
	const result: ProductCategory[] = [];
	let currentElement: ProductCategory | null = element;

	result.push({
		id: currentElement.id,
		title: currentElement.title,
		parent: null,
		isLast: currentElement.isLast,
	});

	while (currentElement.parent) {
		result.push({
			id: currentElement.parent.id,
			title: currentElement.parent.title,
			parent: null,
			isLast: currentElement.parent.isLast,
		});
		currentElement = currentElement.parent;
	}

	result.reverse();

	for (let i = 0; i < result.length - 1; i++) {
		result[i + 1].parent = result[i];
	}

	return result;
};

export const hydrate = (searchParams: URLSearchParams) => () => {
	const hasCategory = Boolean(searchParams.get('category'));

	if (hasCategory) return 'category:applied';

	return 'idle';
};
