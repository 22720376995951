import clsx from 'clsx';
import React, { MouseEvent } from 'react';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';

import ReservedProductsTable from '../Tables/ReservedProductsTable';
import { reservationCardInfoMock } from './mockData';
import styles from './styles.module.css';
import type { IProps } from './types';

const defaultDateTimeFormatter = (date: number | string | Date) => {
	return new Intl.DateTimeFormat('uk-UA').format(new Date(date));
};

const ProductReservationCard: React.FC<IProps> = ({ warehouse, onClose, onWithdrawReservation, dateTimeFormatter = defaultDateTimeFormatter }) => {
	const handleCloseButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();

		onClose?.();
	};

	const formattedToday = dateTimeFormatter(Date.now());
	const titleTextNode = (
		<>
			Резерв на складі <span className={styles.highlighted}>&ldquo;{warehouse}&rdquo;</span> станом на{' '}
			<span className={styles.highlighted}>{formattedToday}</span>
		</>
	);

	return (
		<div className={clsx(styles.wrapper, 'hide-scroll-bar')}>
			<div className={styles.header}>
				<p className={styles.title}>{titleTextNode}</p>
				<button type="button" className={styles.button} onClick={handleCloseButtonClick}>
					<XCloseIcon />
				</button>
			</div>

			{/* MOCK DATA IS USED */}
			<ReservedProductsTable onCancelReservation={onWithdrawReservation} data={reservationCardInfoMock} />
		</div>
	);
};

export default ProductReservationCard;
