import clsx from 'clsx';
import React, { lazy, Suspense } from 'react';
import { ReactComponent as DotIcon } from 'static/images/Dot.svg';

import styles from './styles.module.css';
import { IProps } from './types';

const ChevronDownIcon = lazy(() => import('static/images/chevron-down.svg').then(({ ReactComponent }) => ({ default: ReactComponent })));

const ColorfulBadge: React.FC<IProps> = ({ fontColor, bgColor, dotColor, text, withChevron = false, className }) => {
	return (
		<span data-colorful-badge className={clsx(styles.colorfulBadge, className)} style={{ background: bgColor }}>
			<DotIcon style={{ fill: dotColor, flexShrink: '0' }} />
			<span style={{ color: fontColor }}>{text}</span>
			<Suspense>{withChevron && <ChevronDownIcon />}</Suspense>
		</span>
	);
};

export default ColorfulBadge;
