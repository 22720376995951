import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';
import type { AlertDialogueControlButtonProps } from './types';

const AlertDialogueControlButton: React.FC<AlertDialogueControlButtonProps> = ({
	children,
	className,
	variant,
	width = 'auto',
	onClick,
	type = 'button',
	...props
}) => {
	return (
		<button
			type={type}
			onClick={onClick}
			data-variant={variant}
			className={clsx(styles.button, className)}
			style={
				{
					'--alert-dialogue-control-button-width': width,
				} as React.CSSProperties
			}
			{...props}
		>
			{children}
		</button>
	);
};

export default AlertDialogueControlButton;
