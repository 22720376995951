import React from 'react';

const PageContentSkeleton = () => {
	return (
		<div style={{ padding: '16px', height: '100%', display: 'flex', flexDirection: 'column' }}>
			<div style={{ width: '100%', height: '80px', marginBottom: '24px' }} className="skeleton" />
			<div style={{ width: '100%', height: '200px', marginBottom: '16px' }} className="skeleton" />

			<div style={{ width: '100%', height: 'calc(100% - 260px - 100px - 16px * 2 - 8px)', marginTop: 'auto' }}>
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
			</div>
		</div>
	);
};

export default PageContentSkeleton;
