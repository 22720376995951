import clsx from 'clsx';
import type { ComponentProps, MouseEvent } from 'react';
import React from 'react';
import { ReactComponent as DoubleLeftChevronIcon } from 'static/images/chevron-left-double.svg';
import type { SvgIcon } from 'types/components';

import styles from './styles.module.css';

export interface IProps extends ComponentProps<'div'> {
	icon?: SvgIcon;
	title: string;
	onGoBackClick?(e?: MouseEvent<HTMLButtonElement>): void;
}
const FilterHeader: React.FC<IProps> = ({ className, title, onGoBackClick, icon: Icon = DoubleLeftChevronIcon, ...restProps }) => {
	return (
		<div className={clsx(styles.productFilterHeader, className)} {...restProps}>
			<button
				type="button"
				className={styles.button}
				onClick={(e) => {
					e.stopPropagation();
					onGoBackClick?.();
				}}
			>
				<Icon className={styles.icon} />
			</button>

			<strong className={clsx('text-md-medium', styles.title)}>{title}</strong>
		</div>
	);
};

export default FilterHeader;
