import Button from 'components/Button';
import { ROUTES_URLS } from 'const';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.css';

const Page404: React.FC = () => {
	const navigate = useNavigate();
	const redirectToHome = () => navigate(ROUTES_URLS.HOME, { replace: true });

	return (
		<section className={styles.notFound}>
			<div className={styles.wrapper}>
				<h1>404</h1>
				<p>Сторінку не знайдено</p>
			</div>

			<Button type="button" variant="default" text="На головну" onClick={redirectToHome} />
		</section>
	);
};

export default Page404;
