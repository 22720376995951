import { zodResolver } from '@hookform/resolvers/zod';
import { Whoami } from 'models/auth';
import { ClientOptionSchema, ContractOptionSchema } from 'models/client';
import { OrganizationOptionSchema } from 'models/employee';
import { Order } from 'models/order';
import type { CatalogueService } from 'models/service';
import { StockOptionSchema } from 'models/stock';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet } from 'react-router-dom';

import { OrderAbilityContext } from '../OrderAbility/provider';
import { useDefineOrderAbility } from '../OrderAbility/useDefineOrderAbility';
import { OrderControllerState, OrderControllerStateSchema, ProductInternalModelState, ServiceInternalModelState } from './lib/schema';
import {
	transformOrderProductToProductInternalModelState,
	transformOrderServiceToInternalServiceStateModel,
	transformSuborderToSuborderTab,
} from './lib/transform';

interface ExistingOrderController {
	data: [Order, Record<string, CatalogueService>, Whoami];
}

const ExistingOrderController: React.FC<ExistingOrderController> = ({ data }) => {
	const [order, services, me] = data;

	const client = ClientOptionSchema.safeParse(order.client);
	const contract = ContractOptionSchema.safeParse(order.contract);
	const organization = OrganizationOptionSchema.safeParse(order.organization);
	const stock = StockOptionSchema.safeParse(order.stock);

	const servicesInternalModel = order.services.reduce((acc, service) => {
		const model = transformOrderServiceToInternalServiceStateModel(service, services);

		return {
			...acc,
			[model.id]: model,
		};
	}, {}) as Record<string, ServiceInternalModelState>;

	const productsInternalModel = order.products.reduce((acc, product) => {
		const model = transformOrderProductToProductInternalModelState(product);

		return {
			...acc,
			[model.id]: model,
		};
	}, {}) as Record<string, ProductInternalModelState>;

	const form = useForm<OrderControllerState>({
		resolver: zodResolver(OrderControllerStateSchema),
		defaultValues: {
			suborders: [
				{
					tabName: 'Основна заявка',
					data: {
						id: order.id,
						client: client.data,
						contract: contract.data,
						organization: organization.data,
						stock: stock.data,
						responsible: {
							label: me.name,
							value: me['1c_uuid'],
						},
						index: 0,
						isPaid: order.isPaid,
						isReserved: order.isReserved,
						isWithoutPayment: order.isWithoutPayment,
						products: productsInternalModel,
						services: servicesInternalModel,
						sum: order.sum,
						volume: order.volume,
						weight: order.weight,
						createdAt: order.createdAt,
						note: order.note,
						number: order.number,
						status: order.status,
						isSaved: true,
						parentId: null,
						realizations: order.realizations,
					},
				},
				...(order.subOrders?.map((suborder, index) => transformSuborderToSuborderTab({ suborder, services, index })) ?? []),
			],
		},
	});

	const ability = useDefineOrderAbility({ suborders: form.getValues('suborders') });

	return (
		<OrderAbilityContext.Provider value={ability}>
			<FormProvider {...form}>
				<Outlet />
			</FormProvider>
		</OrderAbilityContext.Provider>
	);
};

export default ExistingOrderController;
