export const reservationCardInfoMock = [
	{
		id: 1,
		client: { name: 'Іван Петров', organization: 'OOO Example' },
		manager: 'Ольга Іванова',
		document: { createdAt: '2023-11-23T10:30:00', number: '12345' },
		reservedTill: '2024-01-01T00:00:00',
		quantity: 3,
	},
	{
		id: 2,
		client: { name: 'Марія Сидоренко', organization: 'ТОВ Maria' },
		manager: 'Василь Петренко',
		document: { createdAt: '2023-11-23T11:15:00', number: '54321' },
		reservedTill: '2023-12-15T00:00:00',
		quantity: 2,
	},
	{
		id: 3,
		client: { name: 'Петро Коваленко', organization: 'ЧП Petro' },
		manager: 'Наталія Григоренко',
		document: { createdAt: '2023-11-23T12:00:00', number: '67890' },
		reservedTill: '2024-02-20T00:00:00',
		quantity: 1,
	},
	{
		id: 4,
		client: { name: 'Олена Лисенко', organization: 'Olena-Olena' },
		manager: 'Ігор Литвиненко',
		document: { createdAt: '2023-11-23T14:45:00', number: '13579' },
		reservedTill: '2024-03-10T00:00:00',
		quantity: 5,
	},
	{
		id: 5,
		client: { name: 'Андрій Мельник', organization: 'AAA' },
		manager: 'Тетяна Бондаренко',
		document: { createdAt: '2023-11-23T16:20:00', number: '24680' },
		reservedTill: '2023-05-05T00:00:00',
		quantity: 2,
	},
	{
		id: 6,
		client: { name: 'Катерина Савченко', organization: 'KEM Trading' },
		manager: 'Михайло Шевченко',
		document: { createdAt: '2023-11-23T18:00:00', number: '11223' },
		reservedTill: '2023-09-18T00:00:00',
		quantity: 4,
	},
	{
		id: 7,
		client: { name: 'Ігор Зайцев', organization: 'Igor Ltd. Limited' },
		manager: 'Олена Козак',
		document: { createdAt: '2023-11-23T20:30:00', number: '99887' },
		reservedTill: '2023-11-22T00:00:00',
		quantity: 3,
	},
	{
		id: 8,
		client: { name: 'Наталія Гончар', organization: 'ВАТ Natalia' },
		manager: 'Володимир Мороз',
		document: { createdAt: '2023-11-23T22:45:00', number: '33445' },
		reservedTill: '2024-04-07T00:00:00',
		quantity: 2,
	},
	{
		id: 9,
		client: { name: 'Сергій Ткачук', organization: 'S.Ray' },
		manager: 'Юлія Шевченко',
		document: { createdAt: '2023-11-23T09:15:00', number: '55667' },
		reservedTill: '2023-06-30T00:00:00',
		quantity: 1,
	},
	{
		id: 10,
		client: { name: 'Юлія Павленко', organization: 'UU Limited' },
		manager: 'Андрій Кравченко',
		document: { createdAt: '2023-11-23T07:30:00', number: '77889' },
		reservedTill: '2023-08-12T00:00:00',
		quantity: 3,
	},
];

export const reservationCardInfoMockColumns = [
	{ Header: 'Клієнт', accessor: 'client' },
	{ Header: 'Менеджер', accessor: 'manager' },
	{ Header: 'Документ', accessor: 'document' },
	{ Header: 'Зарезервовано до', accessor: 'reservedTill' },
	{ Header: 'Кількість', accessor: 'quantity' },
];
