import { OriginalPaginatedRequestResultSchema, PaginatedRequestResult } from './response';

export const transformToPaginatedRequestResult = <TData>(response: unknown): PaginatedRequestResult<TData> => {
	const result = OriginalPaginatedRequestResultSchema.safeParse(response);

	return {
		data: result.data.data,
		page: result.data.meta.current_page,
		pagesCount: result.data.meta.last_page,
		total: result.data.meta.total,
	};
};
