import { ROUTES_URLS } from 'const';
import React, { lazy } from 'react';

import { isInviteLinkValid } from './handlers/auth';

const Auth = lazy(() => import('pages/Auth'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const AuthInvitationLink = lazy(() => import('pages/AuthInvitationLink'));

export const AuthRoutes = [
	{
		path: ROUTES_URLS.SIGN_IN,
		element: <Auth />,
	},
	{
		path: ROUTES_URLS.FORGOT_PASSWORD,
		element: <ForgotPassword />,
	},
	{
		path: ROUTES_URLS.AUTH_VIA_INVITATION_LINK,
		loader: isInviteLinkValid,
		element: <AuthInvitationLink />,
	},
];
