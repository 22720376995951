import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

type DependantFieldProps<TValue> = {
	watch: string;
	children(props: { value: TValue }): ReactNode;
};

const DependantField = <TValue,>({ watch, children }: DependantFieldProps<TValue>) => {
	const form = useFormContext();
	const majorFieldValue = form.watch(watch) as TValue;

	const elements = children({ value: majorFieldValue });

	return <>{elements}</>;
};

export default DependantField;
