import { type ProductInternalModelState, useTypedOrderControllerFromContext } from 'pages/OrderRework/OrderController';
import React from 'react';

type WatchForKey = Extract<keyof ProductInternalModelState, 'leftovers' | 'freeLeftovers' | 'reserves'>;

interface ProductAvailabilityControllerProps {
	entity: ProductInternalModelState;
	watchKey: WatchForKey;
	suborderIndex: number;
}

const ProductAvailabilityController: React.FC<ProductAvailabilityControllerProps> = ({ entity, watchKey, suborderIndex }) => {
	const { watch } = useTypedOrderControllerFromContext();

	const repository = entity?.[watchKey] ?? [];
	const stock = watch(`suborders.${suborderIndex}.data.stock`);
	const value = repository?.find((data) => data?.stock?.id === stock?.value)?.amount;

	return <>{value ?? '—'}</>;
};

export default ProductAvailabilityController;
