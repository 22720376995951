import type { PropsWithChildren } from 'react';
import React, { Suspense } from 'react';
import { Await, useLoaderData } from 'react-router-dom';

interface AwaitedRouteProps extends PropsWithChildren {
	fallback?: React.ReactNode;
}

const AwaitedRoute: React.FC<AwaitedRouteProps> = ({ children, fallback }) => {
	const { promise } = useLoaderData() as { promise: Promise<AnyArg> };

	return (
		<Suspense fallback={fallback || 'loading'}>
			<Await resolve={promise}>{children}</Await>
		</Suspense>
	);
};

export default AwaitedRoute;
