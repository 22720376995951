export const clientErrorMessages = {
	organizationName: {
		required: 'Назва компанії обовʼязкова',
	},
	edrpouCode: {
		required: 'Код ЄДРПОУ обовʼязковий',
		notValid: 'Має бути 8 цифр',
	},
	contract: {
		required: 'Тип контракта обовʼязковий',
	},
	facilities: {
		required: 'Інформація про обʼєкти обовʼязкова',
	},
	passport: {
		required: 'Номер паспорта обовʼязковий',
	},
	ipnCode: {
		required: 'ІПН є обовʼязковий',
	},
	state: {
		required: 'Стан клієнта є обовʼязковим',
	},
} as const;
