import clsx from 'clsx';
import type { MainSearchAutocompleteProps, RenderPropsStrategyItemParams, SearchResultDataItem } from 'components/MainSearch/lib/types';
import React, { ForwardedRef, forwardRef } from 'react';

import { useMainSearch } from '../../Provider';
import { MainSearchInput } from '../MainSearchInput';
import { SearchResultList } from '../SearchResultList';
import { SearchResultListItem } from '../SearchResultListItem';

const DefaultRender = <T extends SearchResultDataItem>({ item, onClick }: RenderPropsStrategyItemParams<T> & { onClick?: () => void }) => {
	return (
		<SearchResultListItem key={item.id} onClick={onClick}>
			{item.title}
		</SearchResultListItem>
	);
};

export const InputComponent = (
	{
		placeholder,
		disabled,
		className,
		render,
		onSelect,
		emptyState = 'Жодного клієнта не знайдено. Спробуйте інший запит',
	}: MainSearchAutocompleteProps<SearchResultDataItem>,
	ref: ForwardedRef<HTMLInputElement>,
) => {
	const { items, isDropdownOpened, isLoading, openDropdown, onOptionClick } = useMainSearch();
	const resolvedRender = render || DefaultRender;

	const handleSelect = (option: SearchResultDataItem) => {
		onSelect(option);
		onOptionClick();
	};

	return (
		<>
			<MainSearchInput className={clsx(className)} ref={ref} placeholder={placeholder} disabled={disabled} onFocus={openDropdown} />

			{isDropdownOpened && (
				<SearchResultList onSelect={handleSelect} items={items} emptyState={emptyState} render={resolvedRender} isLoading={isLoading} />
			)}
		</>
	);
};

export const MainSearchAutocomplete = forwardRef(InputComponent) as <T extends SearchResultDataItem>(
	props: MainSearchAutocompleteProps<T> & { ref?: ForwardedRef<HTMLInputElement> },
) => JSX.Element;
