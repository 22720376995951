import Button from 'components/Button';
import { ROUTES_URLS } from 'const';
import React from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { prepareUrl } from 'utils/shared';
import { isObject } from 'utils/type-guards';

import styles from './styles.module.css';

const PageErrorBoundary = () => {
	const navigate = useNavigate();
	const error = useRouteError();

	const goToHomePage = () => {
		const url = prepareUrl(ROUTES_URLS.HOME);
		navigate(url);
	};

	if (isObject(error) && 'status' in error) {
		const { status } = error;

		let errorMsg: string | React.ReactNode = '';
		let errorExplanationMsg: string | React.ReactNode = '';

		switch (status) {
			case 400:
				errorMsg = 'Неправильний запит на сервер';
				errorExplanationMsg = (
					<>
						Сервер працює, але данні за цим запитом не вдається знайти.
						<br />
						Можливо данних більше не існує.
					</>
				);
				break;
			case 404:
				errorMsg = <>Дані не знайдено.</>;
				errorExplanationMsg = (
					<>
						Сервер працює, але ми не можемо знайти інформацію за цим запитом.
						<br />
						Можливо данних більше не існує або вони були видалені.
					</>
				);
				break;
			default:
				errorMsg = 'Щось пішло не за планом!';
				errorExplanationMsg = 'Сталась невідома помилка. Перейдить будь-ласка на головну, та спробуйте пізніше';
		}

		return (
			<section className={styles.pageErrorBoundary}>
				<h1 className={styles.errorMessage}>
					<strong style={{ color: 'var(--error-500)' }}>Помилка!</strong>&nbsp;
					{errorMsg}
				</h1>
				<h2 className={styles.errorMessageExplanation}>{errorExplanationMsg}</h2>

				<Button variant="default" text="На головну" onClick={goToHomePage} className={styles.redirectButton} />
			</section>
		);
	}

	throw error;
};

export default PageErrorBoundary;
