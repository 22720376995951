import { isObject } from 'utils/type-guards';
import { z } from 'zod';

import { Option } from './options';

export const standardizeOption = <T extends z.ZodSchema, TData>(schema: T, getter: (data: TData) => Option) =>
	z.preprocess((input) => {
		if (isObject<z.ZodAny>(input)) {
			return getter(input as unknown as TData);
		}

		return input;
	}, schema);
