import { useEffect, useState } from 'react';

import type { InnerControlledSingleAutocompleteProps, Suggestion } from '../types';

export const useSingleInnerControlledAutocomplete = ({ defaultValue }: InnerControlledSingleAutocompleteProps) => {
	const [suggestion, setSuggestion] = useState(defaultValue as string | Suggestion);

	useEffect(() => {
		setSuggestion(defaultValue);
	}, [defaultValue]);

	return {
		suggestion,
		setSuggestion,
		onUnselect: undefined,
		hasReachedLimit: false,
	};
};
