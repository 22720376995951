import OrderAlertDialoguesProvider from 'contexts/OrderAlertDialoguesProvider';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import { Whoami } from 'models/auth';
import { Order } from 'models/order';
import { CatalogueService } from 'models/service';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrderByIdQuery, useGetServicesQuery } from 'store/reducers/orders/ordersSliceApi';
import { useGetInfoAboutMeQuery } from 'store/reducers/users/usersSliceApi';

type AwaitedExistingOrderRouteProps = {
	children: (props: { data: [Order, Record<string, CatalogueService>, Whoami] }) => JSX.Element;
};

interface UseGetServicesQueryReturn {
	data: Record<string, CatalogueService>;
	isFetching: boolean;
}

const AwaitedExistingOrderRoute = ({ children }: AwaitedExistingOrderRouteProps) => {
	const id = useParams().id;
	const { data, isFetching, isError } = useGetOrderByIdQuery(id);
	const { data: services, ...serviceReq } = useGetServicesQuery<UseGetServicesQueryReturn>();
	const { data: me, ...meReq } = useGetInfoAboutMeQuery();

	if (isFetching || serviceReq.isFetching || meReq.isFetching) return <PageContentSkeleton />;
	if (isError) return 'uups';

	const payload = {
		data: [data, services, me] as [Order, Record<string, CatalogueService>, Whoami],
	};

	return <OrderAlertDialoguesProvider>{children(payload)}</OrderAlertDialoguesProvider>;
};

export default AwaitedExistingOrderRoute;
