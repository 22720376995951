import Button from 'components/Button';
import MainSearch from 'components/NavBarMain/MainSearch';
import React from 'react';
import Arrow from 'static/images/arrow-block-left.svg';
import Users from 'static/images/eyeWhite.svg';
import Plus from 'static/images/plus.svg';

import styles from './style.module.css';
import type { IProps } from './types';

const CreatePriceTopBar: React.FC<IProps> = ({ searchOnClick, tableMode, onChooseButtonClick, onFiltersButtonClick, handleBack, nameOfMainBtn }) => {
	const handleChoosePriceButtonClick = () => {
		onChooseButtonClick?.();
	};

	const handleOpenFilterButtonClick = () => {
		onFiltersButtonClick?.();
	};

	const handleBackButtonClick = () => {
		handleBack?.();
	};

	return (
		<div className={styles.topBarWrapper}>
			<MainSearch asRegularInput tableMode={true} searchOnClick={searchOnClick} />
			<div className={styles.buttonsWrapper}>
				{tableMode ? (
					<Button text={nameOfMainBtn} onClick={handleBackButtonClick} icon={Arrow} variant="rounded" background={'#D71B87'} />
				) : (
					<>
						<Button text="Оберіть товар" onClick={handleChoosePriceButtonClick} icon={Users} variant="rounded" background={'#D71B87'} />
						<Button text="Додати коментар" onClick={handleOpenFilterButtonClick} icon={Plus} variant="rounded" background={'#2E90FA'} />
					</>
				)}
			</div>
		</div>
	);
};

export default CreatePriceTopBar;
