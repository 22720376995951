/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
// import { flexRender } from '@tanstack/react-table';
// import clsx from 'clsx';
// import React from 'react';

// import { useTable } from 'react-table';
// import MobileReservedProductTableTd from './MobileReservedProductTableTd';
// import MobileReservedProductTableTr from './MobileReservedProductTableTr';
// import styles from './styles.module.css';
import type { IProps } from './types';

const MobileReservedProductsTable = <TData,>({}: IProps<TData>) => {
	return null;
	// return (
	// 	<table data-reserved-products-table className={clsx(styles.reservedProductsTable, styles.mobile)}>
	// 		<tbody className={styles.tbody}>
	// 			{table.getRowModel().rows.map((row) => {
	// 				return (
	// 					<MobileReservedProductTableTr
	// 						data-tbody-table-row
	// 						key={row.id}
	// 						onClick={(e) => {
	// 							e.stopPropagation();
	// 						}}
	// 					>
	// 						{row.getVisibleCells().map((cell) => {
	// 							return (
	// 								<MobileReservedProductTableTd
	// 									cell={<>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>}
	// 									content={cell.render('Cell')}
	// 								/>
	// 							);
	// 						})}
	// 					</MobileReservedProductTableTr>
	// 				);
	// 			})}
	// 		</tbody>
	// 	</table>
};

export default MobileReservedProductsTable;
