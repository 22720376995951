import { toast } from 'react-toastify';

const config = { autoClose: 1000 };

export const orderNotificationService = {
	transferred: (to: number) => toast.success(to === 0 ? 'Товари перенесено до Основної заявки' : `Товари перенесено до Заявки ${to}`, config),
	transferredServices: (to: number) =>
		toast.success(to === 0 ? 'Послуги перенесено до Основної заявки' : `Послуги перенесено до Заявки ${to}`, config),
	updatingSuccess: (msg?: string) => {
		toast.success(msg ?? 'Заявку збережено', config);
	},
	updatingError: () => {
		toast.success('Помилка! Заявку не збережено', config);
	},
	success: (msg: string) => {
		toast.success(msg, config);
	},
	error: (msg: string) => {
		toast.error(msg, config);
	},
};
