import { useRef } from 'react';

import styles from './styles.module.css';

export const useBackdrop = () => {
	const ref = useRef<HTMLDivElement>(null);

	const toggleVisibility = () => {
		if (ref.current) {
			ref.current.classList.toggle(styles.show);
		}
	};
	const hide = () => {
		if (ref.current) {
			ref.current.classList.remove(styles.show);
		}
	};

	return { ref, toggleVisibility, hide };
};
