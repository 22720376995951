import FormField from 'components/FormComponents/FormField';
import RadioButtonSelect from 'components/RadioButtonSelect';
import React from 'react';

import type { RadioButtonSelectFieldProps } from './types';

const RadioButtonSelectField: React.FC<RadioButtonSelectFieldProps> = ({
	name,
	valuesList,
	firstOptionAsDefault,
	loadAsyncOptions,
	shouldUnregister,
	...restProps
}) => {
	return (
		<FormField
			name={name}
			component={RadioButtonSelect}
			valuesList={valuesList}
			loadAsyncOptions={loadAsyncOptions}
			firstOptionAsDefault={firstOptionAsDefault}
			shouldUnregister={shouldUnregister}
			{...restProps}
		/>
	);
};

export default RadioButtonSelectField;
