import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { getProgressBarFillColor } from 'utils/shared';

import styles from './style.module.css';
import { IProps } from './types';

const ProgressBar: React.FC<IProps> = ({ value, className, variant, minWidth, limit }) => {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const calculatePercentage = () => {
			if (variant === 'money') {
				if (limit === 0) {
					setProgress(0);
				} else {
					setProgress((value / limit) * 100);
				}
			} else if (variant === 'days') {
				setProgress((value / 7) * 100);
			}
		};
		calculatePercentage();
	}, [value, variant]);

	return (
		<span className={clsx(styles.wrapper, className)} style={{ minWidth: minWidth }}>
			{
				<span className="text-sm-regular" style={{ whiteSpace: 'nowrap' }}>
					{variant === 'money' ? `${value} ₴` : `${value}/7 днів`}
				</span>
			}
			<span className={styles.backgroundProgress}>
				<span
					className={styles.progressBar}
					style={{
						backgroundColor: getProgressBarFillColor(progress),
						width: `${progress}%`,
					}}
				/>
			</span>
		</span>
	);
};

export default ProgressBar;
