import '../styles.css';

import clsx from 'clsx';
import type { ForwardedRef } from 'react';
import React, { forwardRef } from 'react';

import type { CellProps } from './types';

const Cell = (
	{ as, children, className, onClick, display, bgColor, pl, pr, pb, pt, justify, align, width, gap }: CellProps,
	ref: ForwardedRef<HTMLTableCellElement>,
) => {
	const TableElement = as;

	return (
		<TableElement
			ref={ref}
			onClick={onClick}
			className={clsx('cell', { display: !!display }, className)}
			style={
				{
					'--cell-width': width,
					'--cell-pl': pl,
					'--cell-pr': pr,
					'--cell-pt': pt,
					'--cell-pb': pb,
					'--cell-align': align,
					'--cell-justify': justify,
					'--cell-bg-color': bgColor,
					'--cell-display': display,
					'--cell-gap': gap,
				} as React.CSSProperties
			}
		>
			{children}
		</TableElement>
	);
};

export default forwardRef(Cell);
