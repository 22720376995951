import { ROUTES_URLS } from 'const';
import React from 'react';
import { Navigate, Outlet, useLoaderData } from 'react-router-dom';

const PrivateRoute = () => {
	const token = useLoaderData();

	return token ? <Outlet /> : <Navigate replace to={ROUTES_URLS.SIGN_IN} />;
};

export default PrivateRoute;
