import { GoodsUnit } from 'models/IOrder';
import { IServiceType, IServiceWorkType } from 'models/IServices';
import { OrderProduct } from 'models/product';
import { useCallback } from 'react';
import { orderActions } from 'store/reducers/order';
import { createOrderData, selectIsModalOpen, selectPreviewedProduct, selectProductsInOrderCart } from 'store/reducers/order/selectors';
import { IOrderSliceInitialState } from 'store/reducers/order/types';

import { usePatchOrder } from './orders';
import { useAppDispatch, useAppSelector } from './redux';
import { DataFormatterFn } from './useOrderViewer/types';

export const useOrderCart = () => {
	const dispatch = useAppDispatch();
	const [mutate, { isLoading, isError }] = usePatchOrder();

	const previewProduct = useAppSelector(selectPreviewedProduct);
	const maybeSelectedProducts = useAppSelector(selectProductsInOrderCart);
	const isModalOpened = useAppSelector(selectIsModalOpen);
	const createOrderDataState = useAppSelector(createOrderData);

	const maybeSelectOne = (params: {
		product: IOrderSliceInitialState['previewProduct'];
		mode: IOrderSliceInitialState['mode'];
		view?: IOrderSliceInitialState['view'];
	}) => {
		dispatch(orderActions.maybeSelectOne(params));
	};

	const clearMaybeSelectOne = () => {
		dispatch(orderActions.clearMaybeSelectOne());
	};
	const clearMaybeSelectedAll = () => {
		dispatch(orderActions.clearMaybeSelectedAll());
	};
	const maybeSelectMany = (products: OrderProduct[]) => {
		dispatch(orderActions.maybeSelectMany({ products }));
	};
	const updateProductQuantityInCart = (quantity: number | string) => {
		dispatch(orderActions.updateProductQuantityInCart({ quantity: Number(quantity) }));
	};
	const updateProductQuantityByIdInCart = (id: string, quantity: number | string) => {
		dispatch(orderActions.updateProductQuantityByIdInCart({ id, quantity: Number(quantity) }));
	};
	const updateProductMeasureUnitInCart = (unit: GoodsUnit) => {
		dispatch(orderActions.updateProductMeasureUnitInCart({ unit }));
	};
	const deleteProductFromOrderCart = (id: string) => {
		dispatch(orderActions.deleteProductFromOrderCart({ id }));
	};
	const toggleProductInOrderCart = (product: OrderProduct, isSelected: boolean) => {
		dispatch(orderActions.toggleProductInOrderCart({ product, isSelected }));
	};
	const updateProductsWithPreviewProduct = () => {
		dispatch(orderActions.updateSelectedManyWithPreviewProduct());
	};
	const updateDataForCreatingMode = (data) => {
		dispatch(orderActions.updateDataForCreatingMode(data));
	};
	const clearCreateOrderData = () => {
		dispatch(orderActions.clearCreateOrderData());
	};
	const addServicesInNewOrder = (service) => {
		dispatch(orderActions.addServicesInNewOrder(service));
	};
	const deleteServiceInNewOrder = (id: string) => {
		dispatch(orderActions.deleteServiceInNewOrder({ id }));
	};
	const updateServiceQuantityInNewOrder = (id: string, quantity: number | string) => {
		dispatch(orderActions.updateServiceQuantityInNewOrder({ id, quantity }));
	};
	const changeServicePriceInNewService = (id: string, formatter?: DataFormatterFn) => {
		const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;

			const formattedPrice = formatter ? formatter(value) : value;
			// @ts-ignore
			dispatch(orderActions.changeServicePriceInNewService({ id, formattedPrice }));
		};
		return inputChangeHandler;
	};
	const setServiceTypeInNewOrder = (id: string, option: IServiceType) => {
		dispatch(orderActions.setServiceTypeInNewOrder({ id, option }));
	};
	const setServiceWorkTypeInNewOrder = (id: string, option: IServiceWorkType) => {
		dispatch(orderActions.setServiceWorkTypeInNewOrder({ id, option }));
	};
	const unDoTypePriceChangesInNewOrder = useCallback(() => {
		dispatch(orderActions.unDoTypePriceChangesInNewOrder());
	}, []);
	const updateTypePriceInProductsInNewOrder = useCallback((id: string, priceId: string) => {
		dispatch(orderActions.updateTypePriceInNewOrder({ id, priceId }));
	}, []);

	const changePriceInProductOrService = useCallback((price: number, object: 'products' | 'services', objectId: string, manualDiscount?: number) => {
		dispatch(orderActions.changePriceInProductOrService({ price, object, objectId, manualDiscount }));
	}, []);

	const uptateAmountOfProducts = (id: string, property: string, formatter?: DataFormatterFn) => {
		const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;
			const formattedValue = formatter ? formatter(value) : value;

			dispatch(orderActions.uptateAmountOfProducts({ id, property, formattedValue }));
		};
		return inputChangeHandler;
	};

	const saveInOneClick = () => {
		// const patcher = (order) => {
		// 	const products = Array.from(order?.products ?? []) as OrderProduct[];
		// 	let foundProductIndex = -1;
		// 	const foundProduct = products.find((product, index) => {
		// 		if (String(product.id) === String(previewProduct.id)) {
		// 			foundProductIndex = index;
		// 			return true;
		// 		}
		// 		return false;
		// 	});
		// 	const hasSameProduct = !!foundProduct;
		// 	let newProduct = previewProduct;
		// 	if (hasSameProduct) {
		// 		const amount = String(Number(foundProduct.amount) + Number(quantity));
		// 		newProduct = { ...foundProduct, ...previewProduct, amount };
		// 		products[foundProductIndex] = newProduct;
		// 	} else {
		// 		products.push({ ...previewProduct, amount: String(quantity) });
		// 	}
		// 	return { ...order, products };
		// };
		// return mutate(patcher);
	};
	const save = () => {
		// const patcher = (order) => {
		// 	const products = Array.from(order?.products ?? []) as OrderProduct[];
		// 	let foundProductIndex = -1;
		// 	const foundProduct = products.find((product, index) => {
		// 		if (String(product.id) === String(previewProduct.id)) {
		// 			foundProductIndex = index;
		// 			return true;
		// 		}
		// 		return false;
		// 	});
		// 	const hasSameProduct = !!foundProduct;
		// 	let newProduct = previewProduct;
		// 	if (hasSameProduct) {
		// 		const amount = String(Number(foundProduct.amount) + Number(previewProduct.amount));
		// 		newProduct = { ...foundProduct, ...previewProduct, amount };
		// 		products[foundProductIndex] = newProduct;
		// 	} else {
		// 		products.push(previewProduct);
		// 	}
		// 	return { ...order, products };
		// };
		// return mutate(patcher);
	};

	const saveMany = () => {
		const patcher = (order) => {
			return order;
		};

		return mutate(patcher);
	};

	const getPreviewProduct = () => previewProduct;

	const openModal = () => {
		dispatch(orderActions.openModal());
	};
	const closeModal = () => {
		dispatch(orderActions.closeModal());
	};

	return {
		changePriceInProductOrService,
		unDoTypePriceChangesInNewOrder,
		updateTypePriceInProductsInNewOrder,
		clearCreateOrderData,
		changeServicePriceInNewService,
		updateServiceQuantityInNewOrder,
		setServiceTypeInNewOrder,
		setServiceWorkTypeInNewOrder,
		deleteServiceInNewOrder,
		updateProductsWithPreviewProduct,
		updateDataForCreatingMode,
		updateProductQuantityByIdInCart,
		updateProductMeasureUnitInCart,
		updateProductQuantityInCart,
		uptateAmountOfProducts,
		addServicesInNewOrder,
		deleteProductFromOrderCart,
		toggleProductInOrderCart,
		clearMaybeSelectedAll,
		clearMaybeSelectOne,
		getPreviewProduct,
		maybeSelectMany,
		maybeSelectOne,
		saveInOneClick,
		closeModal,
		openModal,
		saveMany,
		save,
		previewProduct,
		isLoading,
		isError,
		isModalOpened,
		products: maybeSelectedProducts,
		createOrderDataState,
	};
};
