import clsx from 'clsx';
import IconButton from 'components/IconButton';
import React from 'react';
import XCloseIcon from 'static/images/x-close.svg';

import styles from './styles.module.css';
import type { IProps } from './types';

const ModalHeader: React.FC<IProps> = ({ title, onXCloseClick, hideXCloseButton = false, className, disabled = false, ...restProps }) => {
	return (
		<header className={clsx(styles.modalHeader, className)} {...restProps}>
			<div className={styles.title}>{title}</div>

			{!hideXCloseButton && (
				<IconButton
					className={clsx(styles.xClose, { [styles.disabled]: disabled })}
					width="40px"
					icon={XCloseIcon}
					background="transparent"
					onClick={onXCloseClick}
				/>
			)}
		</header>
	);
};

export default ModalHeader;
