import clsx from 'clsx';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import styles from './styles.module.css';

const Spinner: React.FC<{ className?: string; overlayBg?: string }> = ({ className, overlayBg }) => {
	useEffect(() => {
		document.body.style.pointerEvents = 'none !important';

		return () => {
			document.body.style.pointerEvents = 'initial';
		};
	}, []);

	return createPortal(
		<div className={clsx(styles.overlay, className)} style={{ ...(overlayBg && { backgroundColor: overlayBg }) }}>
			<span className={styles.loader}></span>
		</div>,
		document.querySelector('#portal'),
	);
};

export default Spinner;
