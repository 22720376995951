import React from 'react';

type FullScreenGateProps = React.PropsWithChildren & {
	isFullScreen: boolean;
};

const FullScreenGate: React.FC<FullScreenGateProps> = ({ children, isFullScreen }) => {
	if (isFullScreen) {
		return <div style={{ display: 'none' }}>{children}</div>;
	}

	return children;
};

export default FullScreenGate;
